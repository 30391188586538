import { ofType } from 'redux-observable';
import { map, pluck, switchMap, takeUntil } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { AuthActionTypes } from 'lib/auth/action-types';
import { IInitPhotosListenerAction, PhotosActionTypes } from 'lib/photos/action-types';
import { fetchPhotosSuccess } from 'lib/photos/actions';
import { IEpic } from '../types';
import { mapCleanTimestamps } from '../utils';

export const initPhotosListener: IEpic<IInitPhotosListenerAction> = (action$) =>
  action$.pipe(
    ofType(PhotosActionTypes.INIT_PHOTOS_LISTENER),
    pluck('payload'),
    switchMap((supplierId) =>
      Suppliers._.getById(supplierId)
        .Photos.query()
        .observe()
        .pipe(
          map((data) => fetchPhotosSuccess(mapCleanTimestamps(data))),
          takeUntil(action$.pipe(ofType(AuthActionTypes.SIGN_OUT_SUCCESS))),
        ),
    ),
  );
