import { addRecommendation, addRecommendationDone } from './actions/add-recommendation';
import { deleteRecommendation } from './actions/delete-recommendation';
import { deleteWeddingFair } from './actions/delete-wedding-fair';
import { initRecommendationsListener } from './actions/listener-actions';
import { saveWeddingFair, saveWeddingFairError } from './actions/save-wedding-fair';
import updateRecommendations from './actions/update-recommendations';
import { updateRecommendationsDone } from './actions/update-recommendations-done';
import updateWeddingFairs from './actions/update-wedding-fairs';

export enum RecommendationsActions {
  INIT_RECOMMENDATIONS_LISTENER = '[Recommendations] Init recommendations listener',
  INIT_WEDDING_FAIRS_LISTENER = '[Recommendations] Init wedding fairs listener',
  STOP_WEDDING_FAIRS_LISTENER = '[Recommendations] Stop wedding fairs listener',
  UPDATE_RECOMMENDATIONS = '[Recommendations] Update recommendations',
  UPDATE_RECOMMENDATIONS_DONE = '[Recommendations] Update recommendations done',
  DELETE_RECOMMENDATION = '[Recommendations] Delete recommendation',
  DELETE_RECOMMENDATION_DONE = '[Recommendations] Delete recommendation done',
  ADD_RECOMMENDATION = '[Recommendations] Add recommendation',
  ADD_RECOMMENDATION_DONE = '[Recommendations] Add recommendation done',
  UPDATE_WEDDING_FAIRS = '[Recommendations] Update wedding fairs',
  SAVE_WEDDING_FAIR = '[Recommendations] Save wedding fair',
  SAVE_WEDDING_FAIR_DONE = '[Recommendations] Save wedding fair done',
  SAVE_WEDDING_FAIR_ERROR = '[Recommendations] Save wedding fair error',
  DELETE_WEDDING_FAIR = '[Recommendations] Delete wedding fair',
  DELETE_WEDDING_FAIR_DONE = '[Recommendations] Delete wedding fair done',
}

export type UpdateRecommendationsAction = ReturnType<typeof updateRecommendations>;

export type UpdateRecommendationsDoneAction = ReturnType<typeof updateRecommendationsDone>;

export type DeleteRecommendationAction = ReturnType<typeof deleteRecommendation>;

export type AddRecommendationAction = ReturnType<typeof addRecommendation>;

export type AddRecommendationDoneAction = ReturnType<typeof addRecommendationDone>;

export type UpdateWeddingFairsAction = ReturnType<typeof updateWeddingFairs>;

export type SaveWeddingFairAction = ReturnType<typeof saveWeddingFair>;

export type DeleteWeddingFairAction = ReturnType<typeof deleteWeddingFair>;

export type SaveWeddingFairErrorType = ReturnType<typeof saveWeddingFairError>;

export type IInitRecommendationsListenerAction = ReturnType<typeof initRecommendationsListener>;
