import type { ISupplier_Type } from '@bridebook/models/source/models/Suppliers.types';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';

export interface NavSubItem {
  value: string;
  label?: string;
  notifier?: number;
  // Markets that have access to the page, if not specified, all markets have access.
  markets?: CountryCodes[];
  // Supplier types that have access to the page, if not specified, all supplier types have access.
  // If 'suppliers' is included, all non-venue suppliers have access.
  supplierTypes?: (ISupplier_Type | 'suppliers')[];
  hint?: {
    title?: string;
    text?: string;
  };
}

export interface NavItem {
  id: NavSection;
  navigationLabel: string;
  pageHeader: string;
  navigationIcon: JSX.Element;
  active?: boolean;
  notifier?: number;
  locked?: boolean;
  subsections: NavSubItem[];
}

export type WithNavigationUrl<T> = T & { navigationUrl: string };

/**
 * This enum is used to identify the section of the navigation, not pages prefixes. Might be tempted
 * to use this enum to identify the pages like admin/*, but it's not the case. Adding anything that
 * is not handled by useNavigation hook will result in a runtime error.
 */
export enum NavSection {
  Dashboard = 'dashboard',
  Couples = 'couples',
  Performance = 'performance',
  GrowYourBusiness = 'grow',
  Profile = 'profile',
  KnowledgeHub = 'knowledge',
  HelpAndSupport = 'support',
}
