import { getI18n } from 'react-i18next';
import { IProgressTrackerConfig } from 'lib/enquiries/types';

const getI18nProgressNames = (): Record<
  IProgressTrackerConfig['id'],
  IProgressTrackerConfig['name']
> => {
  const i18n = getI18n();
  return {
    read: i18n.t('enquiryManager:status.read'),
    replied: i18n.t('enquiryManager:status.replied'),
    inDiscussion: i18n.t('enquiryManager:status.Indiscussion'),
    callOrMeetingHeld: i18n.t('enquiryManager:status.callOrMeetingHeld'),
    meetingScheduled: i18n.t('enquiryManager:status.meetingScheduled'),
    dateOnHold: i18n.t('enquiryManager:status.dateOnHold'),
    booked: i18n.t('enquiryManager:status.booked'),
    followedUpPostMeeting: i18n.t('enquiryManager:status.followedUpPostMeeting'),
    followedUp: i18n.t('enquiryManager:status.followedUp'),
    unread: i18n.t('enquiryManager:status.unread'),
  };
};

/**
 * Returns default progress config with translated step names
 * This util is needed to keep toolbox free of i18n translations
 */
export const getProgressTrackerConfig = (): IProgressTrackerConfig[] => {
  const progressNames = getI18nProgressNames();
  return progressTrackerConfig.map((progress) => ({
    ...progress,
    name: progressNames[progress.id],
  }));
};

const progressTrackerConfig: Omit<IProgressTrackerConfig, 'name'>[] = [
  {
    id: 'read',
    color: 'blushTangerine60',
    font: 'space',
  },
  {
    id: 'replied',
    color: 'space08',
    font: 'space',
  },
  {
    id: 'followedUp',
    color: 'space08',
    font: 'space',
  },
  {
    id: 'inDiscussion',
    color: 'space08',
    font: 'space',
  },
  {
    id: 'meetingScheduled',
    color: 'indigoCrush10',
    font: 'space',
  },
  {
    id: 'callOrMeetingHeld',
    color: 'peachRose40',
    font: 'space',
  },
  {
    id: 'followedUpPostMeeting',
    color: 'space08',
    font: 'space',
  },
  {
    id: 'dateOnHold',
    color: 'indigoCrush10',
    font: 'space',
  },
  {
    id: 'booked',
    color: 'teal40',
    font: 'space',
  },
  {
    id: 'unread',
    color: 'indigoCrush',
    font: 'white',
  },
];
