import { ISupplierDetailVenue } from '@bridebook/models/source/models/Suppliers.types';
import type { Season, WeekDayUI } from '@bridebook/toolbox/src/datepicker/types';
import { getSpecialistWedding } from './data/specialist-wedding';

export interface Category {
  name: string;
  value: string;
  id: number;
  label: string;
}

export interface Social {
  blog?: string;
  facebook?: string;
  instagram?: string;
  linkedin?: string;
  pinterest?: string;
  twitter?: string;
  vimeo?: string;
  youtube?: string;
}

export interface IBrochureRTDB {
  createdAt: number;
  originalFile: string;
  publicId: string;
  title: string;
}

export type SupplierAction =
  | { type: 'UPDATE_FIELD_LIST' }
  | { type: 'UPDATE_SUPPLIER_SCORE' }
  | { type: 'VALIDATE_CONTENT'; payload: any }
  | { type: 'DEACTIVATE_SUPPLIER'; payload: any }
  | { type: 'FETCH_SUPPLIER_SUCCESS'; payload: any }
  | { type: 'FETCH_SUPPLIER'; payload: any }
  | { type: 'SAVE_SUPPLIER'; payload: any }
  | { type: 'SAVE_SUPPLIER_ERROR'; payload: any }
  | { type: 'SAVE_SUPPLIER_SUCCESS'; payload: any }
  | { type: 'ADD_FIELD_TO_LIST'; payload: any }
  | { type: 'VALIDATE_CONTENT_ERROR'; payload: any }
  | { type: 'VALIDATE_CONTENT_START'; payload: any }
  | { type: 'VALIDATE_CONTENT_SUCCESS'; payload: any }
  | { type: 'UPDATE_SUPPLIER_TARGETING'; payload: any };

export type ITargetingMappingDayValues = 'all' | WeekDayUI;

export type ITargetingMappingSeasonValues = 'all' | Season;

export interface ITargeting {
  targetingBudget: number[] | null;
  targetingGuests: number[] | null;
  targetingWeekday: ITargetingMappingDayValues | null;
  targetingSeason: ITargetingMappingSeasonValues | null;
  targetingYear: 'all' | number | null;
}

export type ISaveSupplierAnalytics = (saveAction: string, formSection?: FormSections) => void;

export interface ICountriesSuppliers {
  typeDetails: {
    venue: {
      accommodation: {
        bridalSuite: NonNullable<
          NonNullable<ISupplierDetailVenue['accommodation']>['bridalSuite']
        >[];
      };
      catering: NonNullable<ISupplierDetailVenue['catering']>;
      ceremonyReception: NonNullable<ISupplierDetailVenue['ceremonyReception']>;
      details: NonNullable<ISupplierDetailVenue['details']>;
      foodDrink: NonNullable<ISupplierDetailVenue['foodDrink']>;
      style: NonNullable<ISupplierDetailVenue['style']>;
      tags: (keyof typeof getSpecialistWedding)[];
      type: NonNullable<ISupplierDetailVenue['type']>;
    };
  };
}

export interface IAboutYouInfo {
  name: string;
  personalityTags: string[];
  intro?: string;
  avatarPath: string | null;
}

// Form sections
// IMPORTANT: Do not change enum property names, it will impact analytics
export enum FormSections {
  Benefits = 'benefitsSection',
  BridalSuite = 'bridalSuiteSection',
  CakesDietaryOptions = 'cakesDietaryOptionsSection',
  CakesFlavourOptions = 'cakesFlavourOptionsSection',
  CakesIcingOptions = 'cakesIcingOptionsSection',
  CateringDietaryOptions = 'cateringDietaryOptionsSection',
  CateringFoodType = 'cateringFoodTypeSection',
  CeremonyReception = 'ceremonyReceptionSection',
  Contact = 'contactSection',
  CulturalExperience = 'culturalExperienceSection',
  Description = 'descriptionSection',
  Directions = 'directionsSection',
  Experience = 'experienceSection',
  Faq = 'faqSection',
  FloristEcoOptions = 'floristEcoOptionsSection',
  FloristStyle = 'floristStyleSection',
  FoodDrink = 'foodDrinkSection',
  GeneralServices = 'generalServicesSection',
  Inclusivity = 'inclusivitySection',
  Instagram = 'connectInstagramSection',
  MusicAdditionalServices = 'musicAdditionalServicesSection',
  MusicBusinessType = 'musicBusinessType',
  MusicInstruments = 'musicInstrumentsSection',
  MusicStyle = 'musicStyleSection',
  Overview = 'overviewSection',
  PersonalMessage = 'personalMessageSection',
  PhotoStyle = 'photoStyleSection',
  ProductServices = 'productServicesSection',
  SelfPromotion = 'selfPromotionSection',
  Social = 'socialSection',
  VenueFeatures = 'venueFeaturesSection',
  VenueStyle = 'venueStyleSection',
  VenueType = 'venueTypeSection',
  VideoStyle = 'videoStyleSection',
  // pricing
  PricingCategory = 'pricingCategory',
  AdditionalTargeting = 'additionalTargeting',
  Brochures = 'brochures',
  SupplierPricingForm = 'supplierPricingForm',
  SupplierPricingPackageForm = 'supplierPricingPackageForm',
}

export enum NoneOptions {
  Other = 'other',
  NotApplicable = 'notApplicable',
}
