import { UserCredential, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { verifyPermissions } from 'lib/access-control/utils/verify-permissions';
import { AuthActionTypes, ISignInWithPasswordAction } from 'lib/auth/action-types';
import { signInError, signInSignupSuccess } from 'lib/auth/actions';
import { AuthBridebookError, AuthProviders } from 'lib/auth/types';
import { checkExistingProviders } from 'lib/auth/utils/auth-provider-utils';
import { validateEmailAndPassword } from 'lib/auth/utils/validate-email-pass';
import { IEpic } from 'lib/types';

export const signInWithPasswordEpic: IEpic<ISignInWithPasswordAction, any> = (
  action$,
  { firebaseApp, validate, state$ },
) =>
  action$.pipe(
    ofType(AuthActionTypes.SIGN_IN_WITH_PASSWORD),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { fields },
        },
        state,
      ]) => {
        const getPromise = async () => {
          const { collaboratorInvite } = state.auth;
          const firebaseAuth = getAuth(firebaseApp);
          await validateEmailAndPassword(validate, fields);
          await checkExistingProviders(firebaseAuth, fields.email, AuthProviders.PASSWORD);
          await verifyPermissions(collaboratorInvite, fields.email);
          return signInWithEmailAndPassword(firebaseAuth, fields.email, fields.password);
        };

        return from(getPromise()).pipe(
          mergeMap((userCredential: UserCredential) =>
            of(
              signInSignupSuccess(
                userCredential.user,
                AuthActionTypes.SIGN_IN_WITH_PASSWORD_SUCCESS,
              ),
            ),
          ),
          catchError((error: AuthBridebookError) => of(signInError(error, fields))),
        );
      },
    ),
  );
