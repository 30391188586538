import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import type { Slug } from '@bridebook/toolbox/src/types';
import { env } from 'lib/env';

interface CmsLandingPages {
  home: string;
  pricing: {
    venue: string;
    suppliers?: { selectCategory: string } & Partial<Record<Slug, string>>;
  };
  faq: string;
  aboutUs: string;
  pressMedia: string;
  privacyPolicy: string;
  terms: string;
  cookiePolicy: string;
  contact: string;
  impressum?: string;
  support: string;
  weddingReport?: (year?: number) => string;
}

export const LATEST_REPORT_YEAR = 2024;

export const getUrlHelperLandings = (countryCode: CountryCodes): CmsLandingPages =>
  UrlHelperLandings[countryCode] || UrlHelperLandings['global'];

export const SUPPORT_HOME = 'https://support.bridebook.com';

export const HUBSPOT_CONTACT_FORM = {
  global: `https://share.hsforms.com/1ccB8URZ_Rnazl8kW_diWbw1p8l9`,
  [CountryCodes.GB]: `https://share.hsforms.com/1ZZN12s9aQoapXz9Dpu2Y0A1p8l9`,
  [CountryCodes.DE]: `https://share.hsforms.com/1RZBo0xOVQCSaumUytucrkA1p8l9`,
  [CountryCodes.FR]: `https://share.hsforms.com/19sXAFUUATueW9l22J-RbLg1p8l9`,
};

/**
 * Manages links to CMS landing pages hosted on Webflow at partners.bridebook.com
 */
const UrlHelperLandings: Record<string, CmsLandingPages> = {
  global: {
    home: `${env.CMS_LANDING_URL}/global`,
    pricing: {
      venue: `${env.CMS_LANDING_URL}/global/pricing/venues`,
    },
    faq: `${env.CMS_LANDING_URL}/global/faq`,
    aboutUs: `${env.CMS_LANDING_URL}/global/about-bridebook`,
    pressMedia: `${env.CMS_LANDING_URL}/global/press-media`,
    privacyPolicy: `${env.CMS_LANDING_URL}/global/privacy-policy`,
    terms: `${env.CMS_LANDING_URL}/global/terms`,
    cookiePolicy: `${env.CMS_LANDING_URL}/global/cookie-policy`,
    contact: HUBSPOT_CONTACT_FORM.global,
    support: `${SUPPORT_HOME}`,
  },
  [CountryCodes.GB]: {
    home: `${env.CMS_LANDING_URL}/uk`,
    pricing: {
      venue: `${env.CMS_LANDING_URL}/uk/pricing/venues`,
      suppliers: {
        selectCategory: `${env.CMS_LANDING_URL}/uk/pricing/suppliers`,
        beauty: `${env.CMS_LANDING_URL}/uk/pricing/suppliers/hair-beauty`,
        cakes: `${env.CMS_LANDING_URL}/uk/pricing/suppliers/cake-makers`,
        catering: `${env.CMS_LANDING_URL}/uk/pricing/suppliers/caterers`,
        decoration: `${env.CMS_LANDING_URL}/uk/pricing/suppliers/decor-hire`,
        dress: `${env.CMS_LANDING_URL}/uk/pricing/suppliers/dress-makers`,
        entertainment: `${env.CMS_LANDING_URL}/uk/pricing/suppliers/entertainment`,
        florist: `${env.CMS_LANDING_URL}/uk/pricing/suppliers/florists`,
        jewellery: `${env.CMS_LANDING_URL}/uk/pricing/suppliers/jewellers`,
        marquee: `${env.CMS_LANDING_URL}/uk/pricing/suppliers/marquees`,
        menswear: `${env.CMS_LANDING_URL}/uk/pricing/suppliers/menswear`,
        music: `${env.CMS_LANDING_URL}/uk/pricing/suppliers/musicians`,
        photo: `${env.CMS_LANDING_URL}/uk/pricing/suppliers/photographers`,
        planners: `${env.CMS_LANDING_URL}/uk/pricing/suppliers/planners`,
        stationery: `${env.CMS_LANDING_URL}/uk/pricing/suppliers/stationery`,
        transport: `${env.CMS_LANDING_URL}/uk/pricing/suppliers/transport`,
        video: `${env.CMS_LANDING_URL}/uk/pricing/suppliers/videographers`,
      },
    },
    faq: `${env.CMS_LANDING_URL}/uk/faq`,
    aboutUs: `${env.CMS_LANDING_URL}/uk/about-bridebook`,
    pressMedia: `${env.CMS_LANDING_URL}/uk/press-media`,
    privacyPolicy: `${env.CMS_LANDING_URL}/uk/privacy-policy`,
    terms: `${env.CMS_LANDING_URL}/uk/terms`,
    cookiePolicy: `${env.CMS_LANDING_URL}/uk/cookie-policy`,
    contact: HUBSPOT_CONTACT_FORM.GB,
    support: `${SUPPORT_HOME}`,
    weddingReport: (year?: number) =>
      `${env.CMS_LANDING_URL}/uk/uk-wedding-report-${year || LATEST_REPORT_YEAR}`,
  },
  [CountryCodes.DE]: {
    home: `${env.CMS_LANDING_URL}/de`,
    pricing: {
      venue: `${env.CMS_LANDING_URL}/de/preisgestaltung/locations`,
      suppliers: {
        selectCategory: `${env.CMS_LANDING_URL}/de/preisgestaltung/dienstleister`,
        beauty: `${env.CMS_LANDING_URL}/de/preisgestaltung/dienstleister/haare-beauty`,
        cakes: `${env.CMS_LANDING_URL}/de/preisgestaltung/dienstleister/konditoren`,
        catering: `${env.CMS_LANDING_URL}/de/preisgestaltung/dienstleister/caterer`,
        dress: `${env.CMS_LANDING_URL}/de/preisgestaltung/dienstleister/brautmode`,
        florist: `${env.CMS_LANDING_URL}/de/preisgestaltung/dienstleister/floristen`,
        jewellery: `${env.CMS_LANDING_URL}/de/preisgestaltung/dienstleister/juweliere`,
        music: `${env.CMS_LANDING_URL}/de/preisgestaltung/dienstleister/musiker`,
        photo: `${env.CMS_LANDING_URL}/de/preisgestaltung/dienstleister/fotograf`,
        stationery: `${env.CMS_LANDING_URL}/de/preisgestaltung/dienstleister/papeterie`,
        transport: `${env.CMS_LANDING_URL}/de/preisgestaltung/dienstleister/transport`,
      },
    },
    faq: `${env.CMS_LANDING_URL}/de/faq`,
    aboutUs: `${env.CMS_LANDING_URL}/de/uber-bridebook`,
    pressMedia: `${env.CMS_LANDING_URL}/de/presse-medien`,
    privacyPolicy: `${env.CMS_LANDING_URL}/de/datenschutzrichtlinie`,
    terms: `${env.CMS_LANDING_URL}/de/agb`,
    cookiePolicy: `${env.CMS_LANDING_URL}/de/cookie-richtlinien`,
    contact: HUBSPOT_CONTACT_FORM.DE,
    support: `${SUPPORT_HOME}/de`,
    impressum: `${env.CMS_LANDING_URL}/de/impressum`,
    weddingReport: (year?: number) =>
      `${env.CMS_LANDING_URL}/de/de-wedding-report-${year || LATEST_REPORT_YEAR}`,
  },
  [CountryCodes.FR]: {
    home: `${env.CMS_LANDING_URL}/fr`,
    pricing: {
      venue: `${env.CMS_LANDING_URL}/fr/prix/lieux`,
    },
    faq: `${env.CMS_LANDING_URL}/fr/faq`,
    aboutUs: `${env.CMS_LANDING_URL}/fr/a-propos`,
    pressMedia: `${env.CMS_LANDING_URL}/fr/presse-media`,
    privacyPolicy: `${env.CMS_LANDING_URL}/fr/politique-de-confidentialite `,
    terms: `${env.CMS_LANDING_URL}/fr/conditions-generales`,
    cookiePolicy: `${env.CMS_LANDING_URL}/fr/politique-cookies`,
    contact: HUBSPOT_CONTACT_FORM.FR,
    support: `${SUPPORT_HOME}/fr`,
    impressum: `${env.CMS_LANDING_URL}/fr/mentions-legales`,
  },
  [CountryCodes.IE]: {
    home: `${env.CMS_LANDING_URL}/ie`,
    pricing: {
      venue: `${env.CMS_LANDING_URL}/ie/pricing/venues`,
    },
    faq: `${env.CMS_LANDING_URL}/ie/faq`,
    aboutUs: `${env.CMS_LANDING_URL}/ie/about-bridebook`,
    pressMedia: `${env.CMS_LANDING_URL}/ie/press-media`,
    privacyPolicy: `${env.CMS_LANDING_URL}/ie/privacy-policy`,
    terms: `${env.CMS_LANDING_URL}/ie/terms`,
    cookiePolicy: `${env.CMS_LANDING_URL}/ie/cookie-policy`,
    contact: HUBSPOT_CONTACT_FORM.global,
    support: `${SUPPORT_HOME}`,
  },
};
