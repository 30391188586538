import { YourCouplesActionTypes } from 'lib/couples/action-types';
import { ICoupleActivity, ICoupleBooking, ICoupleBookingDetails } from 'lib/couples/types';

export const triggeredContactCoupleMessageAnalytics = (payload: {
  eventData: ICoupleActivity;
}) => ({
  type: YourCouplesActionTypes.SUPPLIER_OPENED_FORM_ANALYTICS,
  payload,
});

export const closeContactCoupleMessageAnalytics = (payload: { eventData: ICoupleActivity }) => ({
  type: YourCouplesActionTypes.SUPPLIER_CLOSED_FORM_ANALYTICS,
  payload,
});

export const bookingClickedAnalytics = (booking: ICoupleBooking) => ({
  type: YourCouplesActionTypes.BOOKING_CLICKED_ANALYTICS,
  payload: booking,
});

export const connectWithCoupleClickedAnalytics = (booking: ICoupleBookingDetails) => ({
  type: YourCouplesActionTypes.CONNECT_WITH_COUPLE_CLICKED_ANALYTICS,
  payload: booking,
});
