import { IFilterLabelProps } from '@bridebook/analytics';
import { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import serverSideTrack from 'lib/analytics-utils/server-side-track';
import { Action, IUser, State } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';
import { CriticalCMSEvents } from '../analyticsTypes';

const filterLabelProps: IFilterLabelProps = (props) => {
  const newProps = { ...props };
  delete newProps.supplierEmail;
  delete newProps.enteredEmail;
  delete newProps.email;
  delete newProps.supplierNewEmail;
  delete newProps.supplierOldEmail;

  delete newProps.getListedSupplierPhone;
  delete newProps.getListedSupplierEmail;
  delete newProps.getListedSupplierAddress;
  delete newProps.getListedSupplierTown;
  delete newProps.getListedSupplierCounty;
  delete newProps.getListedSupplierPostcode;
  delete newProps.getListedSupplierInviteLink;
  return newProps;
};

const supplierRegistrationPropertiesGeneric = (user: IUser) => {
  const id = typeof user.id === 'undefined' ? user.uid : user.id;
  const currentDate = new Date().toISOString();
  return {
    id,
    profileType: 'supplier',
    supplierRegistered: true,
    supplierRegistrationDate: currentDate,
    supplierEmail: user.contacts?.email,
  };
};

const passwordResetPropertiesGeneric = <T extends { email: string }>(payload: T | null) => ({
  enteredEmail: payload ? payload.email : undefined,
});

export default function authAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const payload = action.payload || {};

  const { track, identifyWithTrack: identify } = bridebookAnalytics.getMethods(
    'Get Listed',
    filterLabelProps,
  );

  switch (action.type) {
    case 'LOG_IN_SUCCESS_ANALYTICS': {
      const { user, callback } = payload;
      const {
        app: { pathname },
        users: { viewer },
      } = getState();
      const userId = typeof user.id === 'undefined' ? user.uid : user.id;

      identify(
        {},
        {
          event: CriticalCMSEvents.LOGGED_IN_AS_SUPPLIER,
          category: 'Login',
          pageCategoryWhenSupplierLoggedIn: pathname,
          enteredEmail: user?.contacts?.email || viewer?.contacts?.email,
        },
        { trackCallback: callback, userId },
      );
      break;
    }

    case 'LOG_IN_ERROR_ANALYTICS': {
      const { fields, error } = payload;
      track({
        event: 'Supplier login failed',
        category: 'Login',
        reasonSupplierLoginFailed: error.message,
        enteredEmail: fields.email,
      });
      break;
    }

    case 'LOGOUT_ANALYTICS': {
      track({
        event: 'Supplier logged out',
        category: 'Logout',
      });
      break;
    }

    case 'REGISTRATION_SUCCESS_ANALYTICS': {
      const { user, countryCode, isFirstUser } = payload;
      const countryName = countryCode ? Gazetteer.getCountryName(countryCode) : undefined;
      const id = typeof user.id === 'undefined' ? user.uid : user.id;
      const {
        app: { pathname },
      } = getState();
      bridebookAnalytics.alias(id);

      const genericProps = supplierRegistrationPropertiesGeneric(user);
      const specificEventProps = {
        category: 'Registration',
        pageCategoryWhenSupplierRegistered: pathname,
        enteredEmail: user?.contacts?.email,
        countryCode,
        countryName,
        isFirstUser,
      };

      serverSideTrack({
        event: CriticalCMSEvents.REGISTERED_AS_SUPPLIER,
        state: getState(),
        identifyProps: genericProps,
        specificEventProps,
      });

      break;
    }

    case 'REGISTRATION_FAILED_ANALYTICS': {
      const { error, fields } = payload;
      track({
        event: CriticalCMSEvents.SUPPLIER_REGISTRATION_FAILED,
        category: 'Registration',
        reasonSupplierRegistrationFailed: error.message,
        enteredEmail: fields.email,
      });
      break;
    }

    case 'FIREBASE_RESET_PASSWORD_SUCCESS_ANALYTICS': {
      track({
        event: 'Supplier requested password change',
        category: 'Password reset',
        ...passwordResetPropertiesGeneric(payload),
      });
      break;
    }

    case 'FIREBASE_RESET_PASSWORD_ERROR_ANALYTICS': {
      const { fields, error } = payload;
      track({
        event: 'Supplier password change request failed',
        category: 'Password reset',
        reasonSupplierFailedPasswordReset: error.message,
        ...passwordResetPropertiesGeneric(fields),
      });
      break;
    }

    case 'USER_UPDATED_AUTH_DETAILS_SUCCESS_ANALYTICS': {
      const { fields } = payload;

      const {
        users: { viewer },
      } = getState();

      identify(
        { supplierEmail: fields.email, userId: viewer.id },
        {
          event: 'Supplier updated auth details',
          category: 'SSO',
          supplierEmail: fields.email,
          supplierOldEmail: viewer?.contacts?.email,
        },
        { userId: viewer?.id },
      );
      break;
    }

    case 'USER_UPDATED_AUTH_DETAILS_ERROR_ANALYTICS': {
      const { fields, error } = payload;
      track({
        event: 'Supplier failed to update auth details',
        category: 'SSO',
        enteredEmail: fields.email,
        reasonSupplierFailedToUpdateAuthDetails: error.message,
      });
      break;
    }

    default:
      break;
  }
}
