import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { cleanFieldsForValidateStep1 } from 'lib/utils';
import { getValidationMessages } from 'lib/utils/validation-msg';
import validate from 'lib/validate';
import { type IGetListedFields, type IGetListedValidateAPIResponse } from '../types';

export const validateGetListedFieldsStep1Server = async (
  fields: IGetListedFields,
): Promise<IGetListedValidateAPIResponse> => {
  const response = await fetch(`/api/search/validatestep1`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      query: cleanFieldsForValidateStep1(fields),
    }),
  });

  if (!response.ok || response.status !== 200) {
    throw new Error(`Something went wrong ${response.statusText}`);
  }

  return await response.json();
};

/**
 * Validate Get Listed form step 1 using "validate" library
 * @method validateGetListedFieldsStep1Client
 * @param fields
 * @returns {Promise}
 */
export const validateGetListedFieldsStep1Client = (fields: IGetListedFields) => {
  let validation = validate<IGetListedFields>(fields);

  validation = validation.prop('name').required();

  validation = validation
    .prop('countryCode')
    .required(() => getValidationMessages().required.country);

  validation = validation.prop('website').required().urLink();

  if (fields.countryCode === CountryCodes.GB) {
    validation = validation.prop('phone').required().ukphone();
  } else {
    validation = validation.prop('phone').required();
  }

  validation = validation.prop('email').required().email();

  return validation.promise;
};
