import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { appError } from '../../app/actions';
import { IEpicDeps } from '../../types';
import { EnquiriesActionTypes, IFetchEnquiry } from '../action-types';
import { fetchEnquirySuccess } from '../actions';
import { fetchEnquiryPromise } from '../utils';

export const fetchEnquiryEpic = (action$: Observable<IFetchEnquiry>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.FETCH_ENQUIRY),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const getPromise = async () => {
        const {
          supplier: { supplier },
          enquiries: { status },
        } = state;
        const { id } = supplier || {};
        const { eid } = payload;
        return fetchEnquiryPromise({ id, eid, status });
      };

      return from(getPromise()).pipe(
        mergeMap((data) => (data ? of(fetchEnquirySuccess(data)) : of())),
        catchError((error: Error) =>
          of(appError({ error, feature: EnquiriesActionTypes.FETCH_ENQUIRY })),
        ),
      );
    }),
  );
