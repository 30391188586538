import { isObject, omit } from 'remeda';
import { dashify } from '@bridebook/toolbox/src';

/**
 * Adds URL prefixes to the properties of an object based on top-level key values.
 *
 * This function takes an object and processes its properties based on their types.
 * - For string properties, it prepends a prefix (derived from the top-level key name) to the string
 *   value.
 * - For function properties, it wraps the function with a decorator that adds a prefix to the URL
 *   it returns.
 * - For nested objects, it recursively adds prefixes to the string and function properties within,
 *   but the prefix is always derived from the top-level key.
 */
export const addUrlPrefixes = <T extends object>(data: T): T => {
  const isNonPrefixable = (value: any) => typeof value === 'string' || typeof value === 'function';

  const prefixableValues = Object.entries(data).reduce((acc, [key, value]) => {
    if (isNonPrefixable(value)) return acc;
    return { ...acc, [key]: value };
  }, {});
  const nonPrefixableValues = omit(data, Object.keys(prefixableValues) as (keyof T)[]);

  const addPrefixes = <T>(data: T, prefix?: string): T => {
    if (isNonPrefixable(data)) {
      if (typeof data === 'string') {
        return (prefix ? `${prefix}${data}` : data) as unknown as T;
      }

      if (typeof data === 'function') {
        return UrlFunctionDecorator(data as UrlFn, prefix || '') as unknown as T;
      }

      return data;
    }

    if (isObject(data)) {
      const result: any = {};
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          result[key] = addPrefixes(data[key], prefix || `/${dashify(key)}`);
        }
      }
      return result as T;
    }

    return data;
  };

  return { ...addPrefixes(prefixableValues), ...nonPrefixableValues } as T;
};

type UrlFn = (...data: any) => unknown;

function UrlFunctionDecorator(urlFn: UrlFn, prefix: string = '') {
  return (data: unknown) => {
    // @ts-expect-error
    const returnValue = urlFn.call(this, data) as string | { href: string; as: string };

    if (isObject(returnValue)) {
      return {
        href: `${prefix}${(returnValue as { href: string; as: string }).href}`,
        as: `${prefix}${(returnValue as { href: string; as: string }).as}`,
      };
    }
    return `${prefix}${returnValue.toString()}`;
  };
}
