import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, mergeMap, pluck } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { appError } from 'lib/app/actions';
import { FollowUpMessagesAction, ISaveFollowUpMessage } from 'lib/follow-up-messages/action-types';
import { followUpMessageSaved, saveFollowUpMessageError } from 'lib/follow-up-messages/actions';
import {
  followUpMessageAddedAnalytics,
  followUpMessageEditedAnalytics,
} from 'lib/follow-up-messages/analytics/actions';
import { IEpicDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';
import { withSupplierId } from 'lib/utils/operators/with-supplier-id';

export const saveFollowUpMessage = (
  action$: Observable<ISaveFollowUpMessage>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(FollowUpMessagesAction.SaveFollowUpMessage),
    pluck('payload'),
    withSupplierId(state$),
    mergeMap(([supplierId, followUpMessage]) => {
      const getPromise = async () => {
        const supplierRef = Suppliers._.getById(supplierId);

        const followUpMessageRef = supplierRef.FollowUps.getById(followUpMessage.id);
        const followUpMessageExists = Boolean(await followUpMessageRef.get());
        const data = {
          title: followUpMessage.title,
          content: followUpMessage.content,
          delay: followUpMessage.delay,
        };

        if (followUpMessageExists) {
          followUpMessageRef.set(data);
        } else {
          followUpMessageRef.create(data);
        }

        return {
          isEdit: followUpMessageExists,
          followUpMessage: {
            ...followUpMessage,
            ...data,
          },
        };
      };
      return from(getPromise()).pipe(
        mergeMap(({ isEdit, followUpMessage }) => {
          const followUpMessageAnalyticsAction = isEdit
            ? followUpMessageEditedAnalytics
            : followUpMessageAddedAnalytics;

          return [
            followUpMessageAnalyticsAction({ followUpMessage }),
            followUpMessageSaved,
            toggleSnackbar('success', getI18n().t('followUpMessages:message.followUpMessageSaved')),
          ];
        }),
        catchError((error) => [
          appError({ error, feature: 'Follow-up Messages' }),
          toggleSnackbar('alert', getI18n().t('followUpMessages:message.saveFollowUpMessageError')),
          saveFollowUpMessageError,
        ]),
      );
    }),
  );
