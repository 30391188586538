import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { ApiEndpoint } from 'lib/api/api-endpoints';
import { AuthActionTypes, IOnAuthAction } from 'lib/auth/action-types';
import { IEpic } from 'lib/types';
import { saveTokenDone, signOutDone } from '../actions';

export const saveSessionEpic: IEpic<IOnAuthAction> = (action$, { state$ }) =>
  action$.pipe(
    ofType(AuthActionTypes.ON_AUTH),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { firebaseUser },
        },
        state,
      ]) => {
        const actions: any[] = [];
        const getPromise = async () => {
          if (firebaseUser) {
            await authenticatedFetch(ApiEndpoint.login, {
              method: 'POST',
              headers: new Headers({ 'Content-Type': 'application/json' }),
            });
            return firebaseUser;
          }
          const logout = async () => {
            const { viewer } = state.users;
            if (viewer?.id) {
              await authenticatedFetch(ApiEndpoint.logout, {
                method: 'POST',
                credentials: 'same-origin',
                headers: new Headers({ 'Content-Type': 'application/json' }),
              });

              actions.push(signOutDone());
            }

            return null;
          };

          return logout();
        };

        return from(getPromise()).pipe(
          mergeMap((response) => of(...[saveTokenDone(response), ...actions])),
        );
      },
    ),
  );

saveSessionEpic.epicName = 'saveSessionEpic';
