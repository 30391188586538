import { IOffer } from '@bridebook/models/source/models/Suppliers/Offers.types';
import { mapSerializeTimestamps } from '@bridebook/toolbox/src/serialize-timestamps';
import { OffersActionTypes } from 'lib/offers/action-types';
import { OfferTypes } from 'lib/offers/constants';
import { SupplierSpecialOfferForm } from 'lib/offers/types';

export const setSpecialOffersType = (type: OfferTypes) => ({
  type: OffersActionTypes.SET_SPECIAL_OFFERS_TYPE,
  payload: { type },
});

export const getInitialForm = () => ({
  type: OffersActionTypes.GET_INITIAL_FORM,
});

export const setInitialForm = (initialForm: SupplierSpecialOfferForm) => ({
  type: OffersActionTypes.SET_INITIAL_FORM,
  payload: { initialForm },
});

export const toggleInitialised = (isInitialised: boolean) => ({
  type: OffersActionTypes.TOGGLE_INITIALISED,
  payload: isInitialised,
});

export const onFirestoreOffers = (offers: Record<string, IOffer>) => ({
  type: OffersActionTypes.ON_FIRESTORE_OFFERS,
  payload: mapSerializeTimestamps(offers),
});
