import { i18n } from 'i18next';
import { getI18n } from 'react-i18next';
import { Locales } from '../constants';

// This function should not be used in SSR as it does not use the i18n instance
// cloned in the server, so it will only be in sync when used in the client.
//
// If it needs to be used in the server too, the i18n instance should be
// retrieved from the react context and passed as an argument.
//
// For example:
// const { i18n } = useTranslation();
// const locale = getLocale(i18n)
export const getLocale = (i18nInstance?: i18n): Locales => {
  const language = i18nInstance?.language || getI18n()?.language;

  switch (language) {
    case 'de':
    case 'de-DE':
    case 'de-AT':
    case 'de-CH':
      return Locales.DE;
    case 'fr-FR':
      return Locales.FR;
    case 'en-GB':
      return Locales.GB;
    case 'ie':
    case 'en-IE':
      return Locales.IE;
    case 'pl-PL':
      return Locales.PL;
    case 'en':
    case 'en-US':
    default:
      return Locales.US;
  }
};
