import moment from 'moment';
import { merge } from 'ramda';
import type { IDatePickerUI } from '@bridebook/toolbox/src/datepicker/types';
import DateModel from 'lib/app/datepicker-datemodel';

/**
 * Function `mapToDatepickerObject`
 * take exact date string of a format `YYYY-DD-MM` and return Datepicker object
 *
 * @function mapToDatepickerObject
 * @param {String}
 * @returns {Object} - DatePickerDateModel
 */
const mapToDatepickerObject = (exactDate: string): IDatePickerUI => {
  if (exactDate) {
    const momentDate = moment(exactDate);
    return merge(DateModel, {
      day: momentDate.format('D'),
      month: momentDate.toDate().getMonth().toString(),
      year: momentDate.format('YYYY'),
    });
  }
  return merge({}, DateModel);
};

export default mapToDatepickerObject;
