import type { TrackEventBodyCMS } from '@bridebook/toolbox/src/types';
import { ApiEndpoint } from 'lib/api/api-endpoints';
import type { IApplicationState } from 'lib/types';
import getServerSideAnalyticsProps from './get-server-side-analytics-props';

export interface IServerSideTrack {
  state: IApplicationState;
  event: string;
  identifyProps?: Record<string, any>;
  specificEventProps?: Record<string, any>;
  category?: string;
}

const serverSideTrack = ({
  state,
  event,
  identifyProps,
  specificEventProps,
  category,
}: IServerSideTrack): Promise<any> => {
  const body: TrackEventBodyCMS = {
    event,
    identifyProps,
    specificEventProps,
    genericProps: getServerSideAnalyticsProps(state),
    source: 'cms',
    category,
  };

  return fetch(ApiEndpoint.analytics.track, {
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then((response) => {
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    return response;
  });
};

export default serverSideTrack;
