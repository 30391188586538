import { Action, State } from 'lib/types';
import { toUrlQuery } from 'lib/utils';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';
import { IRouteChangeComplete } from './types';
import { getPathnameFromUrl } from './utils/get-router-pathname';

const appAnalytics = (
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
): void => {
  switch (action.type) {
    case 'ROUTE_CHANGE_COMPLETE': {
      const { host } = getState().app.device;
      const { url, query, previousPath } = action.payload as IRouteChangeComplete['payload'];
      const prevPathname = previousPath ? getPathnameFromUrl(previousPath) : '';
      const pathname = getPathnameFromUrl(url);
      if (pathname !== prevPathname) {
        bridebookAnalytics.page(pathname, {
          path: pathname,
          name: pathname,
          title: pathname,
          referrer: toUrlQuery(query),
          url: `https://${host}${url}`,
        });
      }
      break;
    }
    default:
      break;
  }
};
export default appAnalytics;
