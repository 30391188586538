import { createSlice } from '@reduxjs/toolkit';

export type BadDebtState = {
  popupShown: boolean;
};

const initialState: BadDebtState = {
  popupShown: false,
};

const deviceSlice = createSlice({
  name: 'badDebt',
  initialState,
  reducers: {
    toggleBadDebtPopup(state) {
      state.popupShown = !state.popupShown;
    },
  },
});

export const { toggleBadDebtPopup } = deviceSlice.actions;
export default deviceSlice.reducer;
