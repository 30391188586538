import { reverse } from 'ramda';
import {
  IProgressTrackerConfig,
  ISupplierWeddingManager,
  ProgressValuesType,
} from 'lib/enquiries/types';
import { getProgressTrackerConfig } from 'lib/enquiries/utils/get-progress-tracker-config';

/**
 * Returns the current progress of an enquiry
 */
export const getEnquiryProgress = (enquiry: ISupplierWeddingManager): IProgressTrackerConfig => {
  const config = getProgressTrackerConfig();
  const currentProgress = reverse(config)
    .map((p) => (enquiry?.progress?.includes(p?.id as ProgressValuesType) ? p : null))
    .filter(Boolean);

  return currentProgress[0] || (config.find((t) => t?.id === 'unread') as IProgressTrackerConfig);
};
