import { Form as FormType } from './types';

const Form: FormType = {
  disabled: false,
  error: null,
  firstName: '',
  lastName: '',
  phone: '',
  profilePhoto: null,
  uploadInProgress: false,
};

export default Form;
