import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { supplierPropertiesGeneric } from 'lib/analytics-utils';
import serverSideTrack from 'lib/analytics-utils/server-side-track';
import { appError } from 'lib/app/actions';
import {
  SettingsAnalyticsActions,
  SubmitLinkedUserAccountSuccessAnalyticsType,
} from 'lib/settings/analytics/action-types';
import { IEpicDeps } from 'lib/types';
import { CriticalCMSEvents } from '../../../analyticsTypes';

const submitLinkedUserAnalyticsEpic = (
  action$: Observable<SubmitLinkedUserAccountSuccessAnalyticsType>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(SettingsAnalyticsActions.SUBMIT_LINKED_USER_ACCOUNT_SUCCESS_ANALYTICS),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const getPromise = async (): Promise<any> => {
        const { invite, formFields } = payload;
        const {
          supplier: { supplier },
        } = state;

        if (!supplier) {
          throw new Error('submitLinkedUserAnalyticsEpic: no supplier');
        }

        return serverSideTrack({
          event: CriticalCMSEvents.INVITED_COLLABORATOR_TO_SUPPLIER_PROFILE,
          state,
          identifyProps: {
            supplierId: supplier.id,
            supplierCategory: supplier.type[0],
            supplierName: supplier.name,
          },
          specificEventProps: {
            category: 'Supplier linked accounts',
            location: state.app.pathname,
            ...supplierPropertiesGeneric(state),
            collaboratorInvite: invite,
            collaboratorPermissions: formFields.permissionLevel,
            collaboratorEmail: formFields.email,
          },
        });
      };

      return from(getPromise()).pipe(
        map(() => ({
          type: SettingsAnalyticsActions.SUBMIT_LINKED_USER_ACCOUNT_SUCCESS_ANALYTICS_SUCCESS,
        })),
        catchError((error) => [appError({ error, feature: 'submitLinkedUserAnalyticsEpic' })]),
      );
    }),
  );

export default submitLinkedUserAnalyticsEpic;
