import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { ApiEndpoint } from 'lib/api/api-endpoints';

/**
 * If a venue is on Free Tier and has Pay-Per-Enquiry (PPE) enabled
 * and an enquiry has been sent during PPE enabled, then the enquiry is locked.
 * User has to spend a credit to reveal the enquiry.
 * The endpoint spends a credit if the enquiry is locked and returns details about the enquiry.
 */

type RevealEnquiryParams = {
  enquiry: string;
  supplier?: string;
};

export const revealEnquiry = (params: RevealEnquiryParams) =>
  authenticatedFetch(ApiEndpoint.revealEnquiry, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(params),
  }).then((res) => {
    if (res.status !== 200) {
      throw new Error('Failed to reveal enquiry.');
    }

    return res.json();
  });
