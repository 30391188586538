import Router from 'next/router';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { ignoreElements, mergeMap, tap } from 'rxjs/operators';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { AuthActionTypes, ISignOutAction } from 'lib/auth/action-types';
import { IEpic, IEpicDeps } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';
import { signOutDone } from '../actions';

export const signOutEpic: IEpic<ISignOutAction, any> = (action$, { firebaseAuth }: IEpicDeps) =>
  action$.pipe(
    ofType(AuthActionTypes.SIGN_OUT),
    mergeMap(() => {
      const getPromise = async () => {
        await firebaseAuth().signOut();
        /** Reset Sentry context */
        SentryMinimal().setUser(null);
      };

      return from(getPromise()).pipe(mergeMap(() => of(signOutDone())));
    }),
  );

signOutEpic.epicName = 'signOutEpic';

export const signOutDoneEpic: IEpic = (action$) =>
  action$.pipe(
    ofType(AuthActionTypes.SIGN_OUT_SUCCESS),
    tap(() => {
      Router.push(UrlHelper.auth.login);
    }),
    ignoreElements(),
  );

signOutDoneEpic.epicName = 'signOutDoneEpic';
