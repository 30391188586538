import { RelationsActionTypes } from 'lib/relations/action-types';
import { ISupplierRelation } from 'lib/relations/types';

export const fetchSupplierRelations = () => ({
  type: RelationsActionTypes.FETCH_SUPPLIER_RELATIONS,
});

export const fetchSupplierRelationsSuccess = ({
  supplierRelations,
}: {
  supplierRelations: ISupplierRelation[];
}) => ({
  type: RelationsActionTypes.FETCH_SUPPLIER_RELATIONS_SUCCESS,
  payload: { supplierRelations },
});
