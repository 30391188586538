import { IFair } from 'types';
import { ContentTranslationsScope } from 'lib/content-translations/types';
import { RecommendationsActions } from '../action-types';

export const saveWeddingFair = (weddingFair: IFair, scope: ContentTranslationsScope) => ({
  type: RecommendationsActions.SAVE_WEDDING_FAIR,
  payload: { weddingFair, scope },
});

export const saveWeddingFairError = (message: string) => ({
  type: RecommendationsActions.SAVE_WEDDING_FAIR_ERROR,
  payload: { message },
});
