import { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { getSupplierLocale } from 'lib/app/selectors';
import { getSupplierId, getSupplierTier, getSupplierType } from 'lib/supplier/selectors';
import { getSupplierCountryCode, getSupplierName } from 'lib/supplier/selectors/contact';
import { Action, IApplicationState, State } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';
import { GroupAnalyticsActions } from './action-types';

const commonAnalyticsProperties = (state: IApplicationState) => {
  const countryCode = getSupplierCountryCode(state);
  const countryName = countryCode ? Gazetteer.getCountryName(countryCode) : undefined;
  return {
    category: 'CMS',
    countryCode,
    countryName,
    locale: getSupplierLocale(state),
    supplierId: getSupplierId(state),
    supplierName: getSupplierName(state),
    supplierTier: getSupplierTier(state),
    supplierCategory: getSupplierType(state),
  };
};

export default function groupDashboardAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const { track } = bridebookAnalytics.getMethods('CMS');
  const state = getState();

  switch (action.type) {
    case GroupAnalyticsActions.clickedToHideMember: {
      const { clickedSupplierId } = action.payload;

      track({
        ...commonAnalyticsProperties(state),
        event: 'Clicked to hide group member from the group dashboard',
        clickedSupplierId,
      });
      break;
    }

    case GroupAnalyticsActions.clickedToShowMember: {
      const { clickedSupplierId } = action.payload;
      track({
        ...commonAnalyticsProperties(state),
        event: 'Clicked to show group member on the group dashboard',
        clickedSupplierId,
      });
      break;
    }

    case GroupAnalyticsActions.clickedToSortGroupDashboard: {
      const { sortColumn } = action.payload;
      track({
        ...commonAnalyticsProperties(state),
        event: 'Clicked to sort group dashboard',
        sortColumn,
      });
      break;
    }

    case GroupAnalyticsActions.viewedDashboardInCMS: {
      track({
        ...commonAnalyticsProperties(state),
        event: 'Viewed dashboard in CMS',
      });
      break;
    }
    default:
      break;
  }
}
