import type { IFollowUp } from '@bridebook/models/source/models/Suppliers/FollowUps.types';
import { progressFilters } from '@bridebook/toolbox/src/inbox/api/utils/transforms';
import type { IEnquiryWeddingSummary } from '@bridebook/toolbox/src/supplier-wedding-manager';
import { toggleCoupleCollaboration } from 'lib/couples/actions';
import type { ISupplierWeddingManager, ProgressValuesType } from 'lib/enquiries/types';
import { updateEnquiryProgress } from 'lib/enquiries/utils';
import type { Action, IDeps } from 'lib/types';
import { EnquiriesActionTypes } from './action-types';
import type { TEnquiryWidgetOption } from './enquiry-widget-settings-default';
import type { IEnquiryEdit } from './types';

export const fetchEnquiryStart = () => ({
  type: EnquiriesActionTypes.FETCH_ENQUIRY_START,
});

export const fetchEnquiry = (eid: string) => ({
  type: EnquiriesActionTypes.FETCH_ENQUIRY,
  payload: { eid },
});

export const changedEnquiryStatusAnalytics = (
  status: string,
  enquiry: ISupplierWeddingManager,
  location: string = 'interior',
  method: string = 'enquiryStatus',
) => ({
  type: EnquiriesActionTypes.CHANGED_ENQUIRY_STATUS_ON_CMS_ANALYTICS,
  payload: { enquiry, status, location, method },
});

export const setEnquiryProgress =
  (
    progress: ProgressValuesType,
    enquiry: ISupplierWeddingManager,
    location: string,
    value: boolean,
    method: string = 'enquiryStatus',
  ) =>
  ({ dispatch }: IDeps) => {
    const newProgress = updateEnquiryProgress(value, progress, enquiry);

    if (progress === 'booked') {
      dispatch(changedEnquiryStatusAnalytics(progress, enquiry, location, method));
      dispatch(
        toggleCoupleCollaboration({
          booking: { userId: enquiry.user, profileId: enquiry.wedding },
          isCollaborating: true,
        }),
      );
    } else {
      dispatch({
        type: EnquiriesActionTypes.ENQUIRY_PROGRESS_UPDATE_ANALYTICS,
        payload: { enquiry, progress, location },
      });
    }
    return {
      type: EnquiriesActionTypes.SET_ENQUIRY_PROGRESS,
      payload: { id: enquiry.id, progress, location, value, newProgress },
    };
  };

export const setEnquiryStatus =
  (
    status: string,
    enquiry: ISupplierWeddingManager,
    location: string,
    value?: boolean,
    noTracking: boolean = false,
  ) =>
  ({ dispatch }: IDeps) => {
    if (!noTracking) {
      dispatch(changedEnquiryStatusAnalytics(status, enquiry, location));
    }

    return {
      type: EnquiriesActionTypes.SET_ENQUIRY_STATUS,
      payload: { id: enquiry.id, status, location, value },
    };
  };

export const fetchEnquirySuccess =
  ({
    supplierWedding,
    next = null,
    weddingSummary,
  }: {
    supplierWedding: ISupplierWeddingManager;
    weddingSummary: IEnquiryWeddingSummary;
    next: string | null;
  }) =>
  ({ dispatch }: IDeps) => {
    dispatch({
      type: EnquiriesActionTypes.ENQUIRY_READ_ANALYTICS,
      payload: {
        enquiry: { ...supplierWedding, progress: [...(supplierWedding.progress || []), 'read'] },
      },
    });

    return {
      type: EnquiriesActionTypes.FETCH_ENQUIRY_SUCCESS,
      payload: { supplierWedding, next, weddingSummary },
    };
  };

export const exportCsv =
  () =>
  ({ getState }: IDeps): Action => {
    const id = getState().supplier.supplier?.id;
    window.open(`/api/enquiries/exportCSV/${id}`, '_blank');
    return { type: EnquiriesActionTypes.EXPORT_ENQUIRIES_CSV };
  };

export const addedNoteToEnquiryAnalytics = (payload: { noteType: 'comment' | 'file' }) => ({
  type: EnquiriesActionTypes.ADDED_NOTE_TO_ENQUIRY_ANALYTICS,
  payload,
});

export const updateNotesCount = (payload: { enquiryId: string; count: number }) => ({
  type: EnquiriesActionTypes.UPDATE_NOTES_COUNT,
  payload,
});

export const updateNotesCountSuccess = (count: number) => ({
  type: EnquiriesActionTypes.UPDATE_NOTES_COUNT_SUCCESS,
  payload: count,
});

export const updateEnquiryOwner = (payload: {
  enquiry: ISupplierWeddingManager;
  ownerId: string | null;
  supplierId: string;
}) => ({
  type: EnquiriesActionTypes.UPDATE_OWNER,
  payload,
});

export const selectedEnquiryOwnerAnalytics = (payload: {
  enquiry: ISupplierWeddingManager;
  ownerId: string | null;
}) => ({
  type: EnquiriesActionTypes.SELECTED_ENQUIRY_OWNER_ANALYTICS,
  payload,
});

export const setOwnerFilter = (payload: { ownerFilter: string | null | undefined }) => ({
  type: EnquiriesActionTypes.SET_OWNER_FILTER,
  payload,
});

export const switchedEnquiryViewAnalytics = () => ({
  type: EnquiriesActionTypes.SWITCHED_ENQUIRY_VIEW_ANALYTICS,
});

export const updateEnquiryWidgetSettings = ({
  name,
  value,
}: {
  name: TEnquiryWidgetOption;
  value: boolean;
}) => ({
  type: EnquiriesActionTypes.UPDATE_ENQUIRY_WIDGET_SETTINGS,
  payload: { name, value },
});

export const editEnquiryInfo = (data: IEnquiryEdit) => ({
  type: EnquiriesActionTypes.EDIT_ENQUIRY_INFO,
  payload: data,
});

export const editEnquiryInfoSuccess = (data: ISupplierWeddingManager) => ({
  type: EnquiriesActionTypes.EDIT_ENQUIRY_INFO_SUCCESS,
  payload: data,
});

export const clickedEnquiredCarouselAnalytics = (payload: { otherVenueSupplierId: string }) => ({
  type: EnquiriesActionTypes.CLICKED_ENQUIRED_CAROUSEL_ANALYTICS,
  payload,
});

export const scheduleFollowUpMessage = (followUpMessage: IFollowUp) => ({
  type: EnquiriesActionTypes.SCHEDULE_FOLLOW_UP_MESSAGE,
  payload: followUpMessage,
});

export const deleteScheduledFollowUp = () => ({
  type: EnquiriesActionTypes.DELETE_SCHEDULED_FOLLOW_UP,
});

export const updateEnquiryScheduledFollowUp = (payload: {
  followUpAt?: number;
  followUps?: string[];
}) => ({
  type: EnquiriesActionTypes.UPDATE_ENQUIRY_SCHEDULED_FOLLOW_UP,
  payload,
});

export const enquiryClickAnalytics = (payload: {
  enquiry?: ISupplierWeddingManager;
  sourceLocation: 'enquiries' | 'bookings';
}) => ({
  type: EnquiriesActionTypes.ENQUIRY_CLICK_ANALYTICS,
  payload,
});

export const clickedTypeformAnalytics = () => ({
  type: EnquiriesActionTypes.CLICKED_TYPEFORM,
});

export const setEnquiriesPage = (page: number) => ({
  type: EnquiriesActionTypes.SET_ENQUIRIES_PAGE,
  payload: page,
});

export const setEnquiriesStatus = (status: keyof typeof progressFilters) => ({
  type: EnquiriesActionTypes.SET_ENQUIRIES_STATUS,
  payload: status,
});

export const setEnquiriesOwner = (ownerId: string | null | undefined) => ({
  type: EnquiriesActionTypes.SET_ENQUIRIES_OWNER,
  payload: ownerId,
});
