import { IBadge } from '@bridebook/models/source/models/Suppliers/Badges.types';
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { BadgesState } from 'lib/badges/types';

const initialState: BadgesState = [];

const initBadgesListener = createAction<string>('initBadgesListener');
const stopBadgesListener = createAction('stopBadgesListener');

const badgesSlice = createSlice({
  name: 'badges',
  initialState,
  reducers: {
    updateBadges(_, action: PayloadAction<IBadge[]>) {
      return action.payload.sort(
        (firstBadge, secondBadge) => (secondBadge?.period?.y || 0) - (firstBadge?.period?.y || 0),
      );
    },
  },
});

export const { updateBadges } = badgesSlice.actions;
export { initBadgesListener, stopBadgesListener };
export default badgesSlice.reducer;
