import buildGetListedError from 'lib/get-listed/utils/build-get-listed-error';
import { IGetListedPayloadArg } from '../types';

export const getGetListedPayload = ({ response, fields }: IGetListedPayloadArg): object => {
  if (response.valid === false && response.publicId && response.slug && response.reason) {
    const { publicId, slug, reason } = response;
    const error = buildGetListedError({
      urlId: publicId,
      // @ts-expect-error TODO: requires fixing
      slug,
      reason,
      name: fields.name,
    });

    throw error;
  }

  return response;
};
