import produce from 'immer';
import { SupplierUsers } from '@bridebook/models';
import { ISupplierUser } from '@bridebook/models/source/models/SupplierUsers.types';
import { IUser as ISupplierACLUser } from '@bridebook/models/source/models/Suppliers/Users.types';
import { IPhoto } from '@bridebook/ui';
import {
  AccessControlActionTypes,
  ISwitchSupplierAction,
  IUpdateAccessControlSuccessAction,
  IUpdateExtendedActiveSupplierACLAction,
} from 'lib/access-control/action-types';
import { IACLUserExtended } from 'lib/access-control/types';
import { AdminActionTypes, ISelectSupplierAction } from 'lib/admin/action-types';
import { AuthActionTypes, IOnAuthAction } from 'lib/auth/action-types';
import { Action, IReducersImmer } from 'lib/types';
import { IOnUserListenerAction, UserActionTypes } from 'lib/users/action-types';
import { Providers } from 'lib/users/providers';
import { ProvidersType } from 'lib/users/types';
import { mergeClean } from 'lib/utils';
import Form from './form';
import { Form as FormType } from './types';

export interface UsersState {
  form: FormType;
  viewer: ISupplierUser | null;
  viewerLoaded: boolean;
  redirected: boolean;
  token: string;
  uploadInProgress: boolean;
  providers: ProvidersType;
  userAccessControl: string[] | null;
  activeSupplierAccessControl: ISupplierACLUser | null;
  extendedSupplierACL: Record<string, IACLUserExtended> | null;
}

const initialState: UsersState = {
  form: { ...Form },
  viewer: null,
  viewerLoaded: false,
  userAccessControl: null,
  activeSupplierAccessControl: null,
  providers: Providers,
  redirected: false,
  token: '',
  uploadInProgress: false,
  extendedSupplierACL: null,
};

const resetState = () => initialState;

const reducers: IReducersImmer<UsersState> = (draft: UsersState) => ({
  [AuthActionTypes.ON_AUTH]: (action: IOnAuthAction) => {
    const { user } = action.payload;

    if (user) {
      /**
       * After an hour of idleness, this action is triggered, because
       * firebaseAuth().onIdTokenChanged callback is called in appStartedEpic. Without this check,
       * the User object would be overwritten with the default one (the one without useActions for
       * instance) which causes some issues within the app (for example IntroJS tours might get
       * triggered even though they shouldn't).
       */
      if (user.id !== draft.viewer?.id) {
        draft.viewer = user as any as ISupplierUser;
      }
    } else {
      draft.userAccessControl = null;
    }
  },

  [AccessControlActionTypes.SWITCH_SUPPLIER]: resetState,

  [AccessControlActionTypes.UPDATE_ACCESS_CONTROL_SUCCESS]: (
    action: IUpdateAccessControlSuccessAction,
  ) => {
    const { activeSupplierAccessControl, userAccessControl } = action.payload;

    draft.userAccessControl = userAccessControl;
    draft.activeSupplierAccessControl = activeSupplierAccessControl;
  },

  [AuthActionTypes.SIGN_OUT_SUCCESS]: () => {
    draft.viewer = null;
    draft.userAccessControl = null;
    draft.activeSupplierAccessControl = null;
  },

  [AdminActionTypes.ADMIN_SELECT_SUPPLIER]: (action: ISelectSupplierAction) => {
    const { activeSupplierAccessControl, extendedSupplierACL, isAdmin } = action.payload;

    if (isAdmin && activeSupplierAccessControl) {
      draft.userAccessControl = [activeSupplierAccessControl.id];
      draft.activeSupplierAccessControl = activeSupplierAccessControl;
      draft.extendedSupplierACL = extendedSupplierACL;
      return;
    }
  },

  [UserActionTypes.ON_USER_LISTENER]: (action: IOnUserListenerAction) => {
    const user = action.payload;

    if (user) {
      draft.viewer = SupplierUsers.new('_', { ...user });
      const firstName = user.name?.[0];
      const lastName = user.name?.[1];
      const phone = user.contacts?.phone;
      const profilePhoto = user.photo?.path;
      draft.viewerLoaded = true;
      draft.form = mergeClean(Form, { firstName, lastName, profilePhoto, phone }) as FormType;
    }
  },

  SAVE_USER_SETTINGS_START: () => {
    draft.form.error = null;
  },

  RESET_SETTINGS_ERROR: () => {
    draft.form.error = null;
  },

  [UserActionTypes.SET_FORM_FIELD]: (action: {
    payload: { name: 'firstName' | 'lastName'; value: string };
  }) => {
    draft.form[action.payload.name] = action.payload.value;
  },

  [UserActionTypes.UPLOAD_PROFILE_PHOTO]: (action: { payload: IPhoto | null }) => {
    draft.form.profilePhoto = action.payload;
    draft.form.error = null;
  },

  [AccessControlActionTypes.SWITCH_SUPPLIER]: (action: ISwitchSupplierAction) => {
    const { activeSupplierAccessControl } = action.payload;
    draft.activeSupplierAccessControl = activeSupplierAccessControl;
  },

  [AccessControlActionTypes.EXTEND_ACCESS_CONTROL_SUCCESS]: (
    action: IUpdateExtendedActiveSupplierACLAction,
  ) => {
    const { activeSupplierAccessControl, extendedSupplierACL } = action.payload;
    draft.extendedSupplierACL = extendedSupplierACL;
    draft.activeSupplierAccessControl = activeSupplierAccessControl;
  },
});

const reducer = (state: UsersState = initialState, action: Action): UsersState => {
  try {
    return produce(state, (draft) => reducers(draft, state)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
