import { ValidationError } from '@bridebook/toolbox/src';

const checkIfEmailExistsInPerms = <T extends { email?: string }>(users: T[], email: string) => {
  const emailExists = users.find((user) => user.email === email);
  if (emailExists) {
    const error = {
      message: 'User already added as a collaborator with this email address',
      prop: 'email',
    };

    throw new ValidationError(error.message, error.prop);
  }
};

export default checkIfEmailExistsInPerms;
