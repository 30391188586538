import {
  addPhoto,
  deletePhotos,
  fetchPhotosSuccess,
  initPhotosListener,
  setPhotoPriority,
  setThumbnail,
} from './actions';

export enum PhotosActionTypes {
  FETCH_PHOTOS_SUCCESS = '[Photos] Fetch photos success',
  ADD_PHOTO = '[Photos] Add photo',
  ADD_PHOTO_SUCCESS = '[Photos] Add photo success',
  DELETE_PHOTO = '[Photos] Delete photo',
  DELETE_PHOTOS = '[Photos] Delete photos',
  DELETE_PHOTO_SUCCESS = '[Photos] Delete photo success',
  SET_PHOTO_ORDER = '[Photos] Set photo order',
  SET_THUMBNAIL = '[Photos] Set thumbnail',
  SET_ORDER_AFTER_PHOTO_ADD = '[Photos] Set order after photo add',
  SET_GALLERY_UNSAVED = '[Photos] Set gallery changed unsaved',
  INIT_PHOTOS_LISTENER = '[Photos] Init photos listener',
}

export type ISetPhotoOrder = ReturnType<typeof setPhotoPriority>;
export type IAddPhoto = ReturnType<typeof addPhoto>;
export type ISetThumbnail = ReturnType<typeof setThumbnail>;
export type IDeletePhotos = ReturnType<typeof deletePhotos>;
export type FetchPhotosSuccessAction = ReturnType<typeof fetchPhotosSuccess>;
export type IInitPhotosListenerAction = ReturnType<typeof initPhotosListener>;
