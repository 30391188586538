import { groupBy, prop, sortBy } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { FetchBookingsSuccessAction, YourCouplesActionTypes } from 'lib/couples/action-types';
import { processBookingsByYear } from 'lib/couples/actions/process-bookings-by-year';
import { ICoupleBooking } from 'lib/couples/types';

export const processBookingYearsEpic = (action$: Observable<FetchBookingsSuccessAction>) =>
  action$.pipe(
    ofType(YourCouplesActionTypes.FETCH_COUPLES_BOOKINGS_SUCCESS),
    mergeMap(({ payload }) => {
      if (!payload) return of();

      const { bookings } = payload;

      const byYear = groupBy((booking: ICoupleBooking) => {
        const year = new Date(booking.weddingDate).getUTCFullYear();
        return year.toString();
      });

      return of(processBookingsByYear(byYear(sortBy(prop('weddingDate'))(bookings))));
    }),
  );
