import { orderBy } from 'firebase/firestore';
import { values } from 'ramda';
import { ofType } from 'redux-observable';
import { map, pluck, switchMap, takeUntil } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { AuthActionTypes } from 'lib/auth/action-types';
import { mapCleanTimestamps } from 'lib/utils';
import type { IEpic } from '../types';
import { IInitVideosListenerAction, VideosActionTypes } from './action-types';
import { fetchVideosSuccess } from './actions';

export const initVideosListener: IEpic<IInitVideosListenerAction> = (action$) =>
  action$.pipe(
    ofType(VideosActionTypes.INIT_VIDEOS_LISTENER),
    pluck('payload'),
    switchMap(({ supplierId }) =>
      Suppliers._.getById(supplierId)
        .Videos.query([orderBy('createdAt', 'asc')])
        .observe()
        .pipe(
          map((data) => fetchVideosSuccess(mapCleanTimestamps(values(data)))),
          takeUntil(action$.pipe(ofType(AuthActionTypes.SIGN_OUT_SUCCESS))),
        ),
    ),
  );
