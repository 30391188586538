import {
  getListed,
  getListedError,
  getListedValidateStep1,
  getListedValidateStep1Error,
  onGetListedFormFieldChange,
  setGetListedLocation,
} from 'lib/get-listed/actions';

export enum GetListedActionTypes {
  ON_GET_LISTED_FORM_FIELD_CHANGE = '[Get Listed] Form field change',
  GET_LISTED_VALIDATE_STEP1_START = '[Get Listed] Validate first step of get listed fields Start',
  GET_LISTED_VALIDATE_STEP1_ERROR = '[Get Listed] Validate first step of get listed fields Error',
  GET_LISTED_VALIDATE_STEP1_SUCCESS = '[Get Listed] Validate first step of get listed fields Success',
  GET_LISTED_START = '[Get Listed] Get listed start',
  GET_LISTED_ERROR = '[Get Listed] Get listed Error',
  GET_LISTED_SUCCESS = '[Get Listed] Get listed Success',
  SET_GET_LISTED_LOCATION = '[Get Listed] Set location object returned from google autocomplete',
  SCROLL_TO_GET_LISTED_ANALYTICS = '[Analytics] [Get Listed] Scroll to get listed form',
  GET_LISTED_VALIDATE_STEP1_ERROR_ANALYTICS = '[Analytics] [Get Listed] Validate first step of get listed fields Error',
  GET_LISTED_VALIDATE_STEP1_SUCCESS_ANALYTICS = '[Analytics] [Get Listed] Validate first step of get listed fields Success',
  GET_LISTED_SUCCESS_ANALYTICS = '[Analytics] [Get Listed] Get listed Success',
  GET_LISTED_ERROR_ANALYTICS = '[Analytics] [Get Listed] Get listed Error',
  RESET_GET_LISTED = '[Get Listed] Reset Get listed',
}

export type IOnGetListedFormFieldChangeAction = ReturnType<typeof onGetListedFormFieldChange>;
export type IGetListedStartAction = ReturnType<typeof getListed>;
export type IGetListedValidateStep1ErrorAction = ReturnType<
  ReturnType<typeof getListedValidateStep1Error>
>;
export type IGetListedErrorAction = ReturnType<ReturnType<typeof getListedError>>;
export type ISetGetListedLocationAction = ReturnType<typeof setGetListedLocation>;
export type IGetListedValidateStep1Action = ReturnType<typeof getListedValidateStep1>;
