import produce from 'immer';
import { ValidationError } from '@bridebook/toolbox/src';
import { NotificationTypes } from 'lib/ui/types';
import { Action, IReducersImmer } from '../types';
import {
  EditRWAction,
  RWActions,
  SaveRWErrorAction,
  UpdateRWFormAction,
  UpdateRWListAction,
} from './action-types';
import { RealWedding, RealWeddingForm } from './types';

export interface IRealWeddingsState {
  editing: boolean;
  error: ValidationError | null;
  form: RealWeddingForm;
  list: RealWedding[];
  notification: NotificationTypes['mode'];
  notificationText: string;
  unsaved: boolean;
}

const initialState: IRealWeddingsState = {
  editing: false,
  error: null,
  form: {
    isLoading: false,
  },
  list: [],
  notification: null,
  notificationText: '',
  unsaved: false,
};

const reducers: IReducersImmer<IRealWeddingsState> = (draft) => ({
  [RWActions.UPDATE_LIST]: (action: UpdateRWListAction) => {
    draft.list = action.payload || [];
  },

  [RWActions.SAVE_SUCCESS]: () => {
    draft.unsaved = false;
    draft.editing = false;
    draft.form = {
      isLoading: false,
    };
  },

  [RWActions.CLEAR]: () => {
    draft.editing = false;
    draft.unsaved = false;
    draft.form = {
      isLoading: false,
    };
  },

  [RWActions.SAVE_ERROR]: (action: SaveRWErrorAction) => {
    draft.error = action.payload;
    draft.notification = 'error';
    draft.notificationText = action.payload.message;
    draft.form.isLoading = false;
  },

  [RWActions.RESET_NOTIFICATION]: () => {
    draft.notification = null;
    draft.notificationText = '';
  },

  [RWActions.EDIT]: (action: EditRWAction) => {
    draft.form = action.payload;
    draft.unsaved = true;
    draft.editing = true;
  },

  [RWActions.UPDATE_FORM]: (action: UpdateRWFormAction) => {
    if (draft.error) {
      draft.error = null;
    }

    if (draft.notification) {
      draft.notification = null;
      draft.notificationText = '';
    }

    Object.assign(draft.form, action.payload);

    draft.unsaved = true;
  },
});

const reducer = (state = initialState, action: Action) => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (e) {
    return state;
  }
};

export default reducer;
