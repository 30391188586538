import { onBadgesChange, setLinkedAccountData, toggleInviteCollabPopup } from './actions';

export enum SettingsActions {
  RESET_SETTINGS = '[Settings] Reset settings',
  SET_FORM_FIELD = '[Settings] Set form field',
  CHANGE_EMAIL = '[Settings] Change email',
  ON_BADGES_CHANGE = '[Settings] On badges change',
  TOGGLE_INVITE_COLLAB_POPUP = '[Settings] Toggle invite collab popup',
  SET_LINKED_ACCOUNT_DATA = '[Settings] Set linked account data',
  SUBMIT_LINKED_USER_ACCOUNT_ERROR = 'SUBMIT_LINKED_USER_ACCOUNT_ERROR',
  SUBMIT_LINKED_USER_ACCOUNT_SUCCESS = 'SUBMIT_LINKED_USER_ACCOUNT_SUCCESS',
  RESET_LINK_SUCCESS_MESSAGE = '[Settings] Reset link success message',
  LINKED_USER_ERROR_RESET = '[Settings] Linked user error reset',
  UPLOAD_PROFILE_PHOTO = '[Settings] Upload profile photo',
  UPDATE_USER_ROLE = '[Settings] Update user role',
  UPDATE_USER_ROLE_SUCCESS = '[Settings] Update user role success',
  START_UPDATE_USER_ROLE = '[Settings] Start update user role',
}

export type OnBadgeChangeType = ReturnType<typeof onBadgesChange>;

export type ToggleInviteCollabPopupType = ReturnType<ReturnType<typeof toggleInviteCollabPopup>>;

export type SetLinkedAccountDataType = ReturnType<ReturnType<typeof setLinkedAccountData>>;
