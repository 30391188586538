import { getI18n } from 'react-i18next';
import { IStatisticsHighchartsConfig } from '../types';
import getChartView from './chart-view';

const getHistoricalRankingsCompetitors = (): IStatisticsHighchartsConfig =>
  getChartView({
    seriesName: getI18n().t('marketInsights:competitorSearchRankings.title'),
    yAxisReversed: true,
    yAxisMin: 1,
    xAxisTitleEnabled: false,
    yAxisTitleEnabled: false,
  });

export default getHistoricalRankingsCompetitors;
