import { extractSupplier } from '@bridebook/toolbox/src';
import { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { Middleware } from '@reduxjs/toolkit';
import { AnalyticsEvents } from 'lib/analytics/events';
import { FailedAction } from 'lib/analytics/failed-action';
import { FailedReason } from 'lib/analytics/failed-reason';
import { getPreviousPath } from 'lib/app/selectors';
import { env } from 'lib/env';
import { IApplicationState, IDeps } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';

type Handler<Payload extends object | undefined> = [Payload] extends [undefined] // If Payload is `undefined`
  ? () => void
  : Record<string, never> extends Payload // If all properties of Payload are optional
  ? (payload?: Payload & Record<string, never>) => void
  : (payload: Payload) => void;

export class AnalyticsService {
  #getState: (() => IApplicationState) | undefined = undefined;
  #bridebookAnalytics: CmsAnalyticsContext | undefined = undefined;

  applyAsReduxMiddleWare(): Middleware<never, IApplicationState> {
    return (store) => {
      this.#getState = store.getState;
      return (next) => (action) => next(action);
    };
  }

  setDependencies(getState: () => IApplicationState, bridebookAnalytics: CmsAnalyticsContext) {
    if (typeof this.#getState === 'undefined') {
      this.log('getState dependency set');
      this.#getState = getState;
    }

    if (typeof this.#bridebookAnalytics === 'undefined') {
      this.log('bridebookAnalytics dependency set');
      this.#bridebookAnalytics = bridebookAnalytics;
    }
  }

  createErrorHandler = (eventName: AnalyticsEvents) => {
    this.log(`Created "${eventName}" error handler`);
    return <
        ErrorPayload extends { failedReason: FailedReason; failedAction: FailedAction },
        Properties extends object = object,
      >(
        getProperties: (state: IApplicationState) => Properties,
      ) =>
      (payload: ErrorPayload) => {
        if (!this.#getState) {
          throw new Error('[AnalyticsService] Dependencies not set');
        }

        const state = this.#getState();
        const genericProperties = this.genericProperties(state);
        this.track(eventName, {
          ...genericProperties,
          ...getProperties(state),
          ...payload,
        });
      };
  };

  createHandlers<
    Properties extends object,
    CreatePayload extends object | undefined = undefined,
    DeletePayload extends object | undefined = CreatePayload,
    EditPayload extends object | undefined = CreatePayload,
  >(
    eventName: AnalyticsEvents,
    getProperties: (state: IApplicationState, actionType: string) => Properties,
  ) {
    this.log(`Created handlers for "${eventName}" event`);
    return {
      added: this.createHandler<Properties, CreatePayload>('added', eventName, getProperties),
      deleted: this.createHandler<Properties, DeletePayload>('deleted', eventName, getProperties),
      edited: this.createHandler<Properties, EditPayload>('edited', eventName, getProperties),
    };
  }

  createHandler<Properties extends object | never, Payload extends object | undefined>(
    actionType: string,
    eventName: AnalyticsEvents,
    getProperties?: (state: IApplicationState, actionType: string) => Properties,
  ) {
    const handler = (payload: Payload) => {
      if (!this.#getState) {
        throw new Error('[AnalyticsService] Dependencies not set');
      }

      const state = this.#getState();
      const genericProperties = this.genericProperties(state);
      this.track(eventName, {
        ...genericProperties,
        ...(getProperties?.(state, actionType) || {}),
        ...(payload || {}),
        actionType,
      });
    };
    return handler as Handler<Payload>;
  }

  private track(event: string, properties: object) {
    if (typeof this.#bridebookAnalytics === 'undefined') {
      throw new Error('[AnalyticsService] Dependencies not set');
    }

    const { track } = this.#bridebookAnalytics.getMethods('CMS');
    this.log({ event, ...properties });
    track({ event, ...properties });
  }

  private genericProperties = (state: IApplicationState) => {
    const supplier = state.supplier.supplier || state.auth.collaboratorInvite.inviteSupplier;
    if (!supplier) return {};

    const {
      email: supplierEmail,
      county: supplierCounty,
      category: supplierCategory,
      id: supplierId,
      name: supplierName,
      countryCode,
    } = extractSupplier(supplier);

    const previousPath = getPreviousPath(state);
    const { supplierAdminObject } = state.admin;
    const countryName = countryCode ? Gazetteer.getCountryName(countryCode) : undefined;

    return {
      countryCode,
      countryName,
      supplierCategory,
      supplierCounty,
      supplierEmail,
      supplierId,
      supplierTier: supplierAdminObject?.premium ? supplierAdminObject.premium.tier || 0 : 0,
      supplierName,
      previousPath,
    };
  };

  private log(msg: any) {
    if (!env.LIVE && typeof window !== 'undefined') {
      const style = 'font-style: italic;';
      // eslint-disable-next-line no-console
      console.groupCollapsed(`%cAnalytics service logger`, style);
      // eslint-disable-next-line no-console
      console.log(msg);
      // eslint-disable-next-line no-console
      console.trace();
      // eslint-disable-next-line no-console
      console.groupEnd();
    }
  }
}

type IAnalyticsServiceMiddleware = (
  bridebookAnalytics: CmsAnalyticsContext,
  analyticsService: AnalyticsService,
) => Middleware<IDeps, IApplicationState>;

export const AnalyticsServiceMiddleware: IAnalyticsServiceMiddleware =
  (bridebookAnalytics, analyticsService) =>
  ({ getState }) => {
    analyticsService.setDependencies(getState, bridebookAnalytics);
    return (next) => (action) => next(action);
  };
