import { isObject } from 'remeda';
import { DotNotation } from '../type-utils';

/**
 * Represents a flattened object where keys are in dot notation.
 */
export type FlattenedObject<T> = {
  [K in DotNotation<T>]: K extends `${infer P}.${infer R}`
    ? R extends DotNotation<T[P & keyof T]>
      ? FlattenedObject<T[P & keyof T]>[R]
      : never
    : T[K & keyof T];
};

/**
 * Flattens a nested object into a flat object where keys are in dot notation.
 */
export const flattenObject = <T extends object>(
  obj: T,
  parentKey: string = '',
  result: Record<string, any> = {},
): FlattenedObject<T> => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      const value = obj[key];
      if (isObject(value)) {
        flattenObject(value, newKey, result);
      } else {
        result[newKey] = value;
      }
    }
  }
  return result as FlattenedObject<T>;
};
