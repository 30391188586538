import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { type Wedding, Weddings } from '../Weddings';
import type { IFile } from './Files.types';

@Identifiable
@Timestampable
@Untrackable
export class File extends AbstractDocument<IFile> {
  readonly collections = {};
}

export class Files extends AbstractCollection<File, IFile> {
  static path = 'files';

  constructor(document: Wedding) {
    super(document.collection(Files.path), File);
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }
}
