import { splitEvery } from 'ramda';
import { ofType } from 'redux-observable';
import { type Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import type { IPhoto } from '@bridebook/models/source/models/Suppliers/Photos.types';
import type { IEpicDeps } from 'lib/types';
import { appError } from '../../app/actions';
import { type ISetPhotoOrder, PhotosActionTypes } from '../action-types';

const BATCH_SIZE = 250;

// Split photos in smaller batches to avoid Firestore limit of 500 operations
export const doBatchPhotoUpdate = async (photos: IPhoto[], id: string, batchNum: number) => {
  const supplierPhotos = Suppliers._.getById(id).Photos;

  supplierPhotos.begin();

  for (const [i, photo] of photos.entries()) {
    await supplierPhotos.getById(photo.id).set({
      order: batchNum * BATCH_SIZE + i,
    });
  }

  return supplierPhotos.commit();
};

export const setOrderInBatches = (photos: IPhoto[], id: string) => {
  const batches = splitEvery(BATCH_SIZE, photos);
  const combined = batches.map((batch, i) => doBatchPhotoUpdate(batch, id, i));
  return Promise.all([combined]);
};

export const setPhotoOrderEpic = (action$: Observable<ISetPhotoOrder>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(PhotosActionTypes.SET_PHOTO_ORDER),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const activeSupplier = state.users.activeSupplierAccessControl;

      const getPromise = async () => {
        if (!activeSupplier) throw new Error('setPhotoOrderEpic: missing activeSupplier');
        const { id } = activeSupplier;

        return setOrderInBatches(payload, id);
      };

      return from(getPromise()).pipe(
        mergeMap(() => of({ type: 'SET_PHOTO_ORDER_SUCCESS' })),
        catchError((error) => of(appError({ error, feature: 'Photos' }))),
      );
    }),
  );
