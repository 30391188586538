import {
  deleteMessageTemplate,
  initTemplatesListener,
  saveMessageTemplate,
  templateListSaved,
} from 'lib/message-templates/actions';

export enum MessageTemplatesAction {
  DeleteTemplate = '[Message templates] Delete template',
  InitTemplatesListener = '[Message templates] Initialize message templates',
  TemplateDeleted = '[Message templates] Template deleted',
  TemplateListSaved = '[Message templates] Template list saved',
  TemplateSaved = '[Message templates] Template saved',
  SaveTemplate = '[Message templates] Save template',
  SaveTemplateError = '[Message templates] Save template error',
}

export type ISaveMessageTemplate = ReturnType<typeof saveMessageTemplate>;
export type IInitializeMessageTemplates = typeof initTemplatesListener;
export type ITemplateListSaved = ReturnType<typeof templateListSaved>;
export type IDeleteMessageTemplate = ReturnType<typeof deleteMessageTemplate>;
