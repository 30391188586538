import { getI18n } from 'react-i18next';
import { IStatisticsHighchartsConfig } from '../types';
import getChartView from './chart-view';

const BookedSupplier = (): IStatisticsHighchartsConfig => {
  const i18n = getI18n();
  return getChartView({
    seriesName: i18n.t('enquiryGraphs:config.bookedSupplier.seriesName'),
    yAxisTitle: i18n.t('enquiryGraphs:config.bookedSupplier.yAxisTitle'),
    allowDecimals: false,
  });
};

export default BookedSupplier;
