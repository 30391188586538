import { OfferDiscountTypes } from 'lib/offers/constants';
import { ISupplierSpecialOfferBBDiscount, SupplierSpecialOfferForm } from 'lib/offers/types';

export enum SupplierScores {
  awardsScore = 'awardsScore',
  contactScore = 'contactScore',
  endorsementScore = 'endorsementScore',
  photoScore = 'photoScore',
  popularityLastUpdated = 'popularityLastUpdated',
  popularityScore = 'popularityScore',
  profileScore = 'profileScore',
  recsScore = 'recsScore',
  responseLastUpdated = 'responseLastUpdated',
  responseScore = 'responseScore',
}

export const supplierSpecialOffersFormDefault: SupplierSpecialOfferForm = {
  createdAt: 0,
  title: '',
  details: '',
  discountValue: 0,
  // @ts-ignore - this requires refactoring from RTDB to Firestore types
  discountType: OfferDiscountTypes.setPrice,
  expiryDate: 0,
};

export const supplierSpecialOffersLateAvailabilityFormDefault: SupplierSpecialOfferForm = {
  ...supplierSpecialOffersFormDefault,
  lateDates: undefined,
};

export const supplierSpecialOffersBBDiscount: ISupplierSpecialOfferBBDiscount = {
  createdAt: 0,
  title: 'Bridebook Discount',
  discountValue: 0,
  discountType: OfferDiscountTypes.percentOff,
  expiryDate: 0,
};

export const ManualCheck = {
  star: false,
  poop: false,
  toBeDeleted: false,
  dateManualCheck: 0,
  initials: '',
  completed: false,
};

export const socialNetworks = {
  facebook: '',
  pinterest: '',
  twitter: '',
  instagram: '',
  googlePlus: '',
  youtube: '',
  vimeo: '',
  blog: '',
  linkedin: '',
};

export const premiumSchema = {
  ppb: false,
  tier: 1,
  tierCreatedAt: 0,
  tierUpdatedAt: 0,
  ppbUpdatedAt: 0,
};

export const targetingSchema = {
  targetingBudget: {
    gte: null,
    lte: null,
  },
  targetingYear: null,
  targetingSeason: null,
  targetingWeekday: null,
};
