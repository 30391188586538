import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, ignoreElements, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { IVideo } from '@bridebook/models/source/models/Suppliers/Videos.types';
import { ValidationError } from '@bridebook/toolbox/src';
import { FailedAction } from 'lib/analytics/failed-action';
import { FailedReason } from 'lib/analytics/failed-reason';
import { appError } from 'lib/app/actions';
import { getPhotos } from 'lib/photos/selectors';
import { IEpicDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';
import validate from 'lib/validate';
import { AddVideoAction, VideosActionTypes } from 'lib/videos/action-types';
import { getVideoTourProps, parseVideoURL } from 'lib/videos/utils';

export const addVideoEpic = (
  action$: Observable<AddVideoAction>,
  { state$, bbanalytics }: IEpicDeps,
) =>
  action$.pipe(
    ofType(VideosActionTypes.ADD_VIDEO),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const activeSupplier = state.supplier.supplier;
      const photos = getPhotos(state);

      if (!activeSupplier) return of();
      const { url, videoType = 'video' } = payload;
      const isVideoTour = videoType === 'videoTour';
      const list = state.videos.list;
      const cleanURL = parseVideoURL(url, isVideoTour);

      const getPromise = async (): Promise<IVideo['id']> => {
        await validate({ url: cleanURL })
          .prop('url')
          .video(() => getI18n().t('videos:inputText.addVideo.incorrectUrl.validation')).promise;

        const newVideo = await getVideoTourProps(cleanURL, list, photos);

        // Duplicate video validation
        if (list.find((v) => v.sourceId === newVideo.id)) {
          throw new ValidationError(
            isVideoTour
              ? getI18n().t('videoTours:addVideo.alreadyExists')
              : getI18n().t('videos:inputText.addVideo.alreadyExists.validation'),
            'url',
          );
        }

        await Suppliers._.getById(activeSupplier.id).Videos.push().create({
          source: newVideo.type,
          sourceId: newVideo.id,
          thumbnail: newVideo.thumbnail_url,
          label: newVideo.title,
          type: videoType,
        });

        return newVideo.id;
      };

      return from(getPromise()).pipe(
        tap((id) => !isVideoTour && bbanalytics.supplier.videos.added({ videoId: id })),
        catchError((error) => {
          const errorActions: (ReturnType<typeof appError> | ReturnType<typeof toggleSnackbar>)[] =
            [
              appError({ error, feature: isVideoTour ? 'Video Tours' : 'Videos' }),
              toggleSnackbar(
                'alert',
                error instanceof ValidationError
                  ? error.message
                  : getI18n().t('videos:inputText.addVideo.notFound.validation'),
              ),
            ];

          !isVideoTour &&
            bbanalytics.error({
              failedAction: FailedAction.AddVideo,
              failedReason: FailedReason.FailedToAddVideo,
            });

          return of(...errorActions);
        }),
        ignoreElements(),
      );
    }),
  );
