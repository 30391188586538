import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { appError } from '../../app/actions';
import { IEpicDeps } from '../../types';
import { DeleteWeddingFairAction, RecommendationsActions } from '../action-types';
import deletedWeddingFairAnalytics from '../analytics/deleted-wedding-fair-analytics';

export const deleteWeddingFairEpic = (
  action$: Observable<DeleteWeddingFairAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(RecommendationsActions.DELETE_WEDDING_FAIR),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { fair },
        },
        state,
      ]) => {
        const supplierId = state.supplier.supplier?.id;
        if (!supplierId || !fair.id) return of();

        return from(Suppliers._.getById(supplierId).Fairs.getById(fair.id).delete()).pipe(
          mergeMap(() => of(deletedWeddingFairAnalytics(fair))),
          catchError((error) => of(appError({ error, feature: 'WeddingFairs.delete' }))),
        );
      },
    ),
  );
