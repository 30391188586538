import { OffersActionTypes } from 'lib/offers/action-types';
import { OfferDiscountTypes } from '../constants';

export const setTitle = (name: string) => ({
  type: OffersActionTypes.SET_TITLE,
  payload: name,
});

export const setDiscount = (discount: string | number) => ({
  type: OffersActionTypes.SET_DISCOUNT,
  payload: discount,
});

export const setDiscountType = (type: OfferDiscountTypes) => ({
  type: OffersActionTypes.SET_DISCOUNT_TYPE,
  payload: type,
});

export const setExpiryDate = (expiryDate: number) => ({
  type: OffersActionTypes.SET_EXPIRY_DATE,
  payload: expiryDate,
});

export const setLateAvailabilityDate = (newDate: number | null, index: number) => ({
  type: OffersActionTypes.SET_LATE_AVAILABILITY,
  payload: { newDate, index },
});

export const removeLateAvailabilityDate = (index: number, hasUnlimitedDates: boolean) => ({
  type: OffersActionTypes.REMOVE_LATE_AVAILABILITY,
  payload: { index, hasUnlimitedDates },
});

export const setDescription = (description: string) => ({
  type: OffersActionTypes.SET_DESCRIPTION,
  payload: description,
});
