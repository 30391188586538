import {
  checkedAddonAvailabilityAnalytics,
  clickedToViewUpgradeOptionsAnalytics,
  interactedWithSelfServeAnalytics,
  paymentSuccessAnalytics,
  updatedSupplierPremiumStatusAnalytics,
} from 'lib/premium/analytics/actions';

export enum PremiumAnalyticsActionTypes {
  HUBSPOT_COMPLETED = '[Analytics] Supplier completed register interest form',
  UPDATED_SUPPLIER_PREMIUM_STATUS = '[Analytics] Updated supplier premium status',
  CHECKED_ADDON_AVAILABILITY = '[Analytics] Checked addon availability',
  INTERACTED_WITH_SELF_SERVE = '[Analytics] Interacted with self-serve upgrades',
  PAYMENT_SUCCESS = '[Payment][Analytics] Completed payment',
  CLICKED_TO_VIEW_UPGRADE_OPTIONS = '[Analytics] Clicked to view upgrade options',
}

export type IUpdatedSupplierPremiumStatusAnalyticsAction = ReturnType<
  typeof updatedSupplierPremiumStatusAnalytics
>;

export type CheckedAddonAvailabilityAnalyticsAction = ReturnType<
  typeof checkedAddonAvailabilityAnalytics
>;

export type InteractedWithSelfServeAnalyticsAction = ReturnType<
  typeof interactedWithSelfServeAnalytics
>;

export type PaymentSuccessAnalyticsAction = ReturnType<typeof paymentSuccessAnalytics>;

export type ClickedToViewUpgradeOptionsAnalyticsAction = ReturnType<
  typeof clickedToViewUpgradeOptionsAnalytics
>;
