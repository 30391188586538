import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { appError } from '../../app/actions';
import { IEpicDeps } from '../../types';
import { EnquiriesActionTypes, IUpdateNotesCount } from '../action-types';
import { updateNotesCountSuccess } from '../actions';

export const updateNotesCountEpic = (
  action$: Observable<IUpdateNotesCount>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.UPDATE_NOTES_COUNT),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const {
        supplier: {
          supplier: { id: supplierId },
        },
      } = state;
      const { enquiryId, count } = payload || {};

      const getPromise = async () => {
        if (!enquiryId) throw new Error('Enquiry not passed');

        const supplier = Suppliers._.getById(supplierId);
        await supplier.Weddings.getById(enquiryId).set({
          notesCount: count || 0,
        });
        return count || 0;
      };

      return from(getPromise()).pipe(
        mergeMap((count) => of(updateNotesCountSuccess(count))),
        catchError((error: Error) =>
          of(appError({ error, feature: EnquiriesActionTypes.UPDATE_NOTES_COUNT })),
        ),
      );
    }),
  );
