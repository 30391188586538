import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { IRevealEnquiryAction, PayPerEnquiryActionTypes } from 'lib/pay-per-enquiry/action-types';
import { revealEnquiryFailure, revealEnquirySuccess } from 'lib/pay-per-enquiry/actions';
import { IEpicDeps } from 'lib/types';
import { revealEnquiry } from '../../api/clients/revealEnquiry';

const revealEnquiryEpic = (action$: Observable<IRevealEnquiryAction>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(PayPerEnquiryActionTypes.REVEAL_ENQUIRY),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { enquiryId },
        },
        state,
      ]) => {
        const getPromise = async () =>
          revealEnquiry({
            enquiry: enquiryId,
            supplier: state.supplier.supplier?.id,
          });

        return from(getPromise()).pipe(
          mergeMap((data) => of(revealEnquirySuccess(data))),
          catchError((error: Error) => of(revealEnquiryFailure(error))),
        );
      },
    ),
  );

export default revealEnquiryEpic;
