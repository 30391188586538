import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { ITargeting } from 'lib/supplier/types';
import { BUDGET_MAX, GUEST_MAX, GUEST_MIN } from 'lib/targeting/utils/constants';

type TTargetingBudget = Required<ISupplier>['targeting']['budget'];
type TTargetingDate = Required<Required<ISupplier>['targeting']>['date'];
type TTargetingGuest = Required<ISupplier>['targeting']['guestsNumber'];

/**
 * Map targeting budget from local state to database format
 * @param budget
 */
const mapTargetingBudget = (budget: ITargeting['targetingBudget']): TTargetingBudget => {
  if (!budget || !Array.isArray(budget)) {
    return null;
  }

  const lowRange = budget[0];
  const topRange = budget[1] >= BUDGET_MAX ? 99000 : budget[1];

  if (lowRange === 0 && topRange === 99000) {
    return null;
  }

  return [lowRange, topRange];
};

const mapTargetingGuests = (guest: ITargeting['targetingGuests']): TTargetingGuest => {
  if (!Array.isArray(guest)) {
    return null;
  }

  const lowRange = guest[0];
  const topRange = guest[1] >= GUEST_MAX ? GUEST_MAX : guest[1];

  if (lowRange === GUEST_MIN && topRange === GUEST_MAX) {
    return null;
  }
  return [lowRange, topRange];
};

/**
 * Map targeting date from local state to database format
 * @param values
 */
const mapTargetingDate = ({
  targetingWeekday,
  targetingSeason,
  targetingYear,
}: ITargeting): TTargetingDate => {
  const getDay = (): TTargetingDate['d'] => {
    if (targetingWeekday === 'Mon-Thurs') return ['mon', 'tue', 'wed', 'thu'];
    if (targetingWeekday === 'Friday') return 'fri';
    if (targetingWeekday === 'Saturday') return 'sat';
    if (targetingWeekday === 'Sunday') return 'sun';
    return null;
  };

  const getSeason = (): TTargetingDate['m'] => {
    if (targetingSeason === 'all') return null;
    return targetingSeason || null;
  };

  const getYear = (): TTargetingDate['y'] => {
    if (targetingYear === 'all') return null;
    return targetingYear || null;
  };

  return {
    d: getDay(),
    m: getSeason(),
    y: getYear(),
  };
};

/**
 * Map radio button values to acceptable values for targeting object
 * @param radioValues
 */
export const mapLocalToDatabaseFormat = (radioValues: ITargeting): ISupplier['targeting'] => {
  const budget = mapTargetingBudget(radioValues.targetingBudget);
  const guestsNumber = mapTargetingGuests(radioValues.targetingGuests);
  const date = mapTargetingDate(radioValues);

  return {
    budget,
    guestsNumber,
    date,
  };
};
