import {
  deleteAllSupplierRelations,
  deleteAllSupplierRelationsSuccess,
  fetchSupplierRelations,
  fetchSupplierRelationsSuccess,
  updateSupplierRelations,
  updateSupplierRelationsSuccess,
} from 'lib/relations/actions';

export enum RelationsActionTypes {
  FETCH_SUPPLIER_RELATIONS = '[Relations] Fetch supplier relations',
  FETCH_SUPPLIER_RELATIONS_SUCCESS = '[Relations] Fetch supplier relations success',
  UPDATE_SUPPLIER_RELATIONS = '[Relations] Update supplier relations',
  UPDATE_SUPPLIER_RELATIONS_SUCCESS = '[Relations] Update supplier relations success',
  DELETE_ALL_SUPPLIER_RELATIONS = '[Relations] Delete all supplier relations',
  DELETE_ALL_SUPPLIER_RELATIONS_SUCCESS = '[Relations] Delete all supplier relations success',
}

export type IFetchSupplierRelationsAction = ReturnType<typeof fetchSupplierRelations>;
export type IFetchSupplierRelationsSuccessAction = ReturnType<typeof fetchSupplierRelationsSuccess>;

export type IUpdateSupplierRelationsAction = ReturnType<typeof updateSupplierRelations>;
export type IUpdateSupplierRelationsSuccessAction = ReturnType<
  typeof updateSupplierRelationsSuccess
>;

export type IDeleteAllSupplierRelationsAction = ReturnType<typeof deleteAllSupplierRelations>;
export type IDeleteAllSupplierRelationsSuccessAction = ReturnType<
  typeof deleteAllSupplierRelationsSuccess
>;
