export type NavigationMethod =
  | 'mobileHomeTab'
  | 'mobileHeaderFull'
  | 'mobileHeaderReduced'
  | 'desktopHeaderFull'
  | 'desktopHeaderReduced';

export type NavigationDestination =
  | 'home'
  | 'search'
  | 'checklist'
  | 'shortlist'
  | 'budget'
  | 'guestlist'
  | 'articles'
  | 'settings'
  | 'logout';

export type TilesCarouselLocation = 'home';

export type TilesCarouselAction = 'left' | 'right';

export interface NavPropArg {
  method: NavigationMethod;
  destination: NavigationDestination;
}

export interface CarouselPropArg {
  action: TilesCarouselAction;
  location: TilesCarouselLocation;
}

export enum CriticalCMSEvents {
  REGISTERED_AS_SUPPLIER = 'Registered as Supplier',
  COLLABORATED_ON_SUPPLIER_PROFILE = 'Collaborated on supplier profile',
  INVITED_COLLABORATOR_TO_SUPPLIER_PROFILE = 'Invited collaborator to supplier profile',
  LOGGED_IN_AS_SUPPLIER = 'Logged In as Supplier',
  SUPPLIER_REGISTRATION_FAILED = 'Supplier registration failed',
}
