import { IFilterLabelProps } from '@bridebook/analytics';
import { IPhoto } from '@bridebook/models/source/models/Suppliers/Photos.types';
import { identifySupplier, supplierPropertiesGeneric } from 'lib/analytics-utils';
import { Action, IApplicationState } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';
import { Meta } from './types';

const filterLabelProps: IFilterLabelProps = (props) => {
  const newProps = { ...props };
  delete newProps.supplierPhotoUrl;
  return newProps;
};

export function supplierPhotoPropertiesGeneric(supplierPhotoUrl: string) {
  return {
    supplierPhotoUrl,
  };
}

export default function photosAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => IApplicationState,
) {
  const { payload } = action;
  const { track } = bridebookAnalytics.getMethods('CMS', filterLabelProps);
  const {
    supplier: { supplier },
  } = getState();

  switch (action.type) {
    case 'ADDED_USER_PHOTO_ON_CMS': {
      const { imgixURL } = payload || {};
      track({
        event: 'Added user photo on CMS',
        ...supplierPropertiesGeneric(getState()),
        ...supplierPhotoPropertiesGeneric(imgixURL),
      });
      break;
    }
    case 'EDITED_USER_PHOTO_ON_CMS': {
      const { imgixURL } = payload || {};
      track({
        event: 'Edited user photo on CMS',
        ...supplierPropertiesGeneric(getState()),
        ...supplierPhotoPropertiesGeneric(imgixURL),
      });
      break;
    }

    case 'DELETED_USER_PHOTO_ON_CMS': {
      const { imgixURL } = payload || {};
      track({
        event: 'Deleted user photo on CMS',
        ...supplierPropertiesGeneric(getState()),
        ...supplierPhotoPropertiesGeneric(imgixURL),
      });
      break;
    }

    case 'ADD_PHOTOS_SUCCESS_ANALYTICS': {
      const { noOfSupplierPhotos } = payload || {};
      identifySupplier({ supplier, props: { noOfSupplierPhotos } });
      break;
    }
  }
}

export const addPhotosSuccessAnalytics = (photos: IPhoto[], s3photos: Pick<Meta, 'imgixURL'>[]) => {
  const getMatch = (photo: IPhoto) => photo.path;
  const notStockPhotos = photos.filter(
    (photo: IPhoto) => !getMatch(photo).match(/(stock|initial)/g),
  );
  const noOfSupplierPhotos = notStockPhotos.length + s3photos.length;
  return {
    type: 'ADD_PHOTOS_SUCCESS_ANALYTICS',
    payload: { noOfSupplierPhotos },
  };
};

export const addUserPhotoSuccessAnalytics = (photo: string) => ({
  type: 'ADDED_USER_PHOTO_ON_CMS',
  payload: { imgixURL: photo },
});

export const editedUserPhotoSuccessAnalytics = (photo: string) => ({
  type: 'EDITED_USER_PHOTO_ON_CMS',
  payload: { imgixURL: photo },
});

export const deletedUserPhotoSuccessAnalytics = (photo: string) => ({
  type: 'DELETED_USER_PHOTO_ON_CMS',
  payload: { imgixURL: photo },
});
