import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { ISupplier_Translations } from '@bridebook/models/source/models/Suppliers.types';
import { appError } from 'lib/app/actions';
import { getScopedContentTranslations } from 'lib/content-translations/selectors';
import { clearTempContentTranslations } from 'lib/content-translations/slice';
import { ISaveFormSection, SupplierActionTypes } from 'lib/supplier/action-types';
import { saveFormSectionSuccess } from 'lib/supplier/actions';
import { FormSections } from 'lib/supplier/types';
import { getHandleSaveSupplierDataPromise } from 'lib/supplier/utils/get-save-all-supplier-data-promise';
import { IEpic, IEpicDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';

/**
 * Epic that updates supplier fields in database, when a form section is saved.
 **/
export const saveFormSectionEpic: IEpic<ISaveFormSection, any> = (
  action$: Observable<ISaveFormSection>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(SupplierActionTypes.SAVE_FORM_SECTION),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { formSection, scope, translationPaths } = action.payload;
      const _translations = getScopedContentTranslations<ISupplier_Translations>(
        state,
        scope,
        translationPaths,
      );
      const {
        supplier: { supplier, formSectionsUpdates },
      } = state;

      const updateData = formSectionsUpdates?.[formSection] || {};
      if (!supplier) return of();

      const getPromise = async () => {
        // If there is no data to update, return success action
        if (!Object.keys(updateData)) return;

        return formSection === FormSections.Contact
          ? getHandleSaveSupplierDataPromise({
              formData: updateData,
              supplier,
              _translations,
            })
          : Suppliers._.getById(supplier.id).set({
              ...updateData,
              ...(_translations && { _translations }),
            });
      };

      return from(getPromise()).pipe(
        mergeMap(() => [
          toggleSnackbar('success', getI18n().t('common:savedChanges.success')),
          saveFormSectionSuccess(formSection),
          clearTempContentTranslations(scope),
        ]),
        catchError((error) =>
          of(
            toggleSnackbar('alert', getI18n().t('common:savedChanges.error')),
            appError({ error, feature: `Supplier form section: ${formSection}` }),
          ),
        ),
      );
    }),
  );
