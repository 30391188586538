import { ofType } from 'redux-observable';
import { merge } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  pluck,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { AccessControlActionTypes, ISwitchSupplierAction } from 'lib/access-control/action-types';
import { initAchievementsListener } from 'lib/achievements/actions';
import { initSupplierAdminListener } from 'lib/admin/actions';
import { AuthActionTypes } from 'lib/auth/action-types';
import { initPhotosListener } from 'lib/photos/actions';
import { initQuestionsListener } from 'lib/questions/actions';
import { initRecommendationsListener } from 'lib/recommendations/actions/listener-actions';
import { fetchCountries, updateSupplier } from 'lib/supplier/actions';
import { IEpic } from 'lib/types';
import { initVideosListener } from 'lib/videos/actions';

export const initSupplierListener: IEpic<ISwitchSupplierAction, any> = (action$) =>
  action$.pipe(
    ofType(AccessControlActionTypes.SWITCH_SUPPLIER),
    pluck('payload'),
    distinctUntilChanged(
      (
        { activeSupplierAccessControl: prevActiveSupplierAccessControl },
        { activeSupplierAccessControl: newActiveSupplierAccessControl },
      ) => prevActiveSupplierAccessControl?.id === newActiveSupplierAccessControl?.id,
    ),
    filter(hasActiveSupplierAccessControl),
    switchMap(({ activeSupplierAccessControl: { id: supplierId } }) => {
      const supplier$ = Suppliers._.getById(supplierId)
        .observe()
        .pipe(
          filter((data) => !!data),
          takeUntil(action$.pipe(ofType(AuthActionTypes.SIGN_OUT_SUCCESS))),
        );
      return merge(
        supplier$.pipe(map(updateSupplier)),
        supplier$.pipe(
          first(),
          switchMap(() => [
            fetchCountries(),
            initPhotosListener(supplierId),
            initVideosListener(supplierId),
            initQuestionsListener(supplierId),
            initAchievementsListener(supplierId),
            initRecommendationsListener(supplierId),
            initSupplierAdminListener(supplierId),
          ]),
        ),
      );
    }),
  );

// hasActiveSupplier typeguard
const hasActiveSupplierAccessControl = (accessControl: {
  activeSupplierAccessControl: any;
}): accessControl is { activeSupplierAccessControl: { id: string } } =>
  !!accessControl.activeSupplierAccessControl && !!accessControl.activeSupplierAccessControl.id;
