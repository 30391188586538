import type { ISupplier } from '@bridebook/models/source/models/RTDB/Suppliers.types';
import type { ISupplierBeauty } from '@bridebook/models/source/models/RTDB/Suppliers/Beauty.types';
import type { ISupplierCake } from '@bridebook/models/source/models/RTDB/Suppliers/Cakes.types';
import type { ISupplierCatering } from '@bridebook/models/source/models/RTDB/Suppliers/Catering.types';
import type { ISupplierDecoration } from '@bridebook/models/source/models/RTDB/Suppliers/Decoration.types';
import type { ISupplierDress } from '@bridebook/models/source/models/RTDB/Suppliers/Dress.types';
import type { ISupplierEntertainment } from '@bridebook/models/source/models/RTDB/Suppliers/Entertainment.types';
import type { ISupplierFlorist } from '@bridebook/models/source/models/RTDB/Suppliers/Florist.types';
import type { ISupplierJewellery } from '@bridebook/models/source/models/RTDB/Suppliers/Jewellery.types';
import type { ISupplierMarquee } from '@bridebook/models/source/models/RTDB/Suppliers/Marquee.types';
import type { ISupplierMenswear } from '@bridebook/models/source/models/RTDB/Suppliers/Menswear.types';
import type { ISupplierMusic } from '@bridebook/models/source/models/RTDB/Suppliers/Music.types';
import type { ISupplierPhoto } from '@bridebook/models/source/models/RTDB/Suppliers/Photo.types';
import type { ISupplierPlanner } from '@bridebook/models/source/models/RTDB/Suppliers/Planners.types';
import type { ISupplierStationery } from '@bridebook/models/source/models/RTDB/Suppliers/Stationery.types';
import type { ISupplierTransport } from '@bridebook/models/source/models/RTDB/Suppliers/Transport.types';
import type { ISupplierVenue } from '@bridebook/models/source/models/RTDB/Suppliers/Venue.types';
import type { ISupplierVideo } from '@bridebook/models/source/models/RTDB/Suppliers/Video.types';
import type { Slug } from '@bridebook/toolbox/src/types';

type SupplierDefaults = {
  beauty: ISupplierBeauty;
  cakes: ISupplierCake;
  catering: ISupplierCatering;
  decoration: ISupplierDecoration;
  dress: ISupplierDress;
  entertainment: ISupplierEntertainment;
  florist: ISupplierFlorist;
  group: never;
  jewellery: ISupplierJewellery;
  marquee: ISupplierMarquee;
  menswear: ISupplierMenswear;
  music: ISupplierMusic;
  photo: ISupplierPhoto;
  planners: ISupplierPlanner;
  stationery: ISupplierStationery;
  transport: ISupplierTransport;
  venue: ISupplierVenue;
  video: ISupplierVideo;
};

const createSupplier = (payload: Partial<ISupplier | { slug: Slug | '' }>) => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const schemas = require('./supplier.defaults.json') as SupplierDefaults;
  const supplier = schemas[payload.slug as Slug];
  return { ...supplier, ...payload };
};

export default createSupplier;
