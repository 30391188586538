export enum MessageTemplatesAnalyticsActions {
  AddTemplateClicked = '[Analytics] Add template clicked',
  MessageTemplateAdded = '[Analytics] Message template added',
  MessageTemplateEdited = '[Analytics] Message template edited',
  MessageTemplateDeleted = '[Analytics] Message template deleted',
  MessageTemplateFileAttached = '[Analytics] Message template file attached',
  MessageTemplateFileUploadFailed = '[Analytics] Message template file upload failed',
  MessageTemplateFileRemoved = '[Analytics] Message template file removed',
  MessageTemplateUsed = '[Analytics] Message template used',
}
