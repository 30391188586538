import { ValueOf } from 'lib/app/types';
import {
  getListedErrorAnalytics,
  getListedSuccessAnalytics,
  getListedValidateStep1ErrorAnalytics,
  getListedValidateStep1SuccessAnalytics,
} from 'lib/get-listed/analytics/actions';
import { BridebookError, IDeps } from 'lib/types';
import { GetListedActionTypes } from '../action-types';
import {
  IAccountLocation,
  IGetListedAPIResponse,
  IGetListedFields,
  IGetListedValidateAPIResponse,
} from '../types';

const FORM_FIELD_MAX_LENGTH = 100;

export interface IGetListedTarget {
  name: keyof IGetListedFields;
  value: ValueOf<IGetListedFields>;
}

export const onGetListedFormFieldChange = ({
  target: { name, value },
}: {
  target: IGetListedTarget;
}) => {
  if (typeof value === 'string') {
    value = value.slice(0, FORM_FIELD_MAX_LENGTH);
  }
  return {
    type: GetListedActionTypes.ON_GET_LISTED_FORM_FIELD_CHANGE,
    payload: { name, value },
  };
};

export const setGetListedLocation = (location: Partial<IAccountLocation>) => ({
  type: GetListedActionTypes.SET_GET_LISTED_LOCATION,
  payload: location,
});

export const getListed = (fields: IGetListedFields) => ({
  type: GetListedActionTypes.GET_LISTED_START,
  payload: fields,
});

export const getListedError =
  ({ error, fields }: { error: BridebookError; fields: IGetListedFields }) =>
  ({ dispatch }: IDeps) => {
    dispatch(getListedErrorAnalytics({ error, fields }));

    return {
      type: GetListedActionTypes.GET_LISTED_ERROR,
      payload: { error },
    };
  };

export const getListedSuccess =
  (payload: IGetListedAPIResponse) =>
  ({ dispatch }: IDeps) => {
    dispatch(getListedSuccessAnalytics(payload));

    return { type: GetListedActionTypes.GET_LISTED_SUCCESS, payload };
  };

export const resetGetListedForm = () => () => ({ type: GetListedActionTypes.RESET_GET_LISTED });

export const getListedValidateStep1 = (fields: IGetListedFields) => ({
  type: GetListedActionTypes.GET_LISTED_VALIDATE_STEP1_START,
  payload: fields,
});

export const getListedValidateStep1Success =
  ({ fields, response }: { fields: IGetListedFields; response: IGetListedValidateAPIResponse }) =>
  ({ dispatch }: IDeps) => {
    dispatch(getListedValidateStep1SuccessAnalytics(fields));

    return { type: GetListedActionTypes.GET_LISTED_VALIDATE_STEP1_SUCCESS, payload: response };
  };

export const getListedValidateStep1Error =
  ({ error, fields }: { fields: IGetListedFields; error: BridebookError }) =>
  ({ dispatch }: IDeps) => {
    dispatch(getListedValidateStep1ErrorAnalytics({ fields, error }));

    return {
      type: GetListedActionTypes.GET_LISTED_VALIDATE_STEP1_ERROR,
      payload: { error },
    };
  };
