import { supplierPropertiesGeneric } from 'lib/analytics-utils';
import {
  CouplesActivityAnalyticsActions,
  ISwitchedCouplesActivityViewAnalytics,
} from 'lib/couples-activity/analytics/action-types';
import { Action, State } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';

export default function couplesActivityAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const { track } = bridebookAnalytics.getMethods('CMS');
  const state = getState();

  switch (action.type) {
    case CouplesActivityAnalyticsActions.SWITCHED_COUPLES_ACTIVITY_VIEW: {
      const status = action.payload as ISwitchedCouplesActivityViewAnalytics['payload'];

      track({
        ...supplierPropertiesGeneric(state),
        toggle: status || 'all',
        event: 'Switched couples activity log view on CMS',
      });
      break;
    }
  }
}
