import { RelationsActionTypes } from 'lib/relations/action-types';

export const deleteAllSupplierRelations = () => ({
  type: RelationsActionTypes.DELETE_ALL_SUPPLIER_RELATIONS,
});

export const deleteAllSupplierRelationsSuccess = () => ({
  type: RelationsActionTypes.DELETE_ALL_SUPPLIER_RELATIONS_SUCCESS,
  payload: {},
});
