import { IVideo } from '@bridebook/models/source/models/Suppliers/Videos.types';
import { VideosActionTypes } from 'lib/videos/action-types';

export const playVideo = (video: IVideo) => ({
  type: VideosActionTypes.PLAY_VIDEO,
  payload: video,
});

export const deleteVideo = (video: IVideo, videoType?: 'videoTour' | 'video') => ({
  type: VideosActionTypes.DELETE_VIDEO,
  payload: { video, videoType },
});

export const clearVideo = () => ({
  type: VideosActionTypes.CLEAR_VIDEO,
});

export const addVideo = (url: string, videoType?: 'videoTour' | 'video') => ({
  type: VideosActionTypes.ADD_VIDEO,
  payload: { url, videoType },
});

export const fetchVideosSuccess = (videos: IVideo[]) => ({
  type: VideosActionTypes.FETCH_VIDEOS_SUCCESS,
  payload: { videos },
});

export const initVideosListener = (supplierId: string) => ({
  type: VideosActionTypes.INIT_VIDEOS_LISTENER,
  payload: { supplierId },
});
