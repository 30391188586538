import { SettingsAnalyticsActions } from 'lib/settings/analytics/action-types';
import { IFormFields } from 'lib/settings/types';

interface ISubmitLinkedUserAccountSuccessAnalytics {
  invite: string;
  formFields: IFormFields;
}

export const submitLinkedUserAccountSuccessAnalytics = (
  payload: ISubmitLinkedUserAccountSuccessAnalytics,
) => ({
  type: SettingsAnalyticsActions.SUBMIT_LINKED_USER_ACCOUNT_SUCCESS_ANALYTICS,
  payload,
});
