import { getSession } from '@bridebook/toolbox/src/api/auth/with-session';
import { PageInitialProps } from 'lib/types';

export const getAdmin = async (
  req: PageInitialProps['ctx']['req'],
  res: PageInitialProps['ctx']['res'],
  store: PageInitialProps['store'],
): Promise<boolean> => {
  const isAdmin = store.getState().auth.isAdmin;
  let serverAdmin = false;
  if (!process.browser) {
    const session = await getSession(req, res);
    serverAdmin = session && session.user ? session.isAdmin : false;
  }
  return isAdmin || serverAdmin;
};

export const getUser = async (
  req: PageInitialProps['ctx']['req'],
  res: PageInitialProps['ctx']['res'],
  store: PageInitialProps['store'],
): Promise<string | null> => {
  const { viewer } = store.getState().users;
  const clientUser = viewer ? viewer.id : null;
  let serverUser = null;
  if (!process.browser) {
    const session = await getSession(req, res);
    serverUser = session && session.user ? session.user.id : null;
  }
  return clientUser || serverUser;
};
