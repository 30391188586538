import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { appError } from 'lib/app/actions';
import { fetchSupplierScoreError, fetchSupplierScoreSuccess } from 'lib/statistics/actions';
import { IEpicDeps } from 'lib/types';
import { StatisticsActionTypes } from '../action-types';

export const fetchSupplierScoreEpic = (action$: Observable<any>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(StatisticsActionTypes.FETCH_SUPPLIER_SCORE),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const getPromise = async () => Suppliers._.getById(state.supplier.supplier?.id).getScore();

      return from(getPromise()).pipe(
        map((payload) => fetchSupplierScoreSuccess(payload)),
        catchError((error) => [
          fetchSupplierScoreError(),
          appError({ error, epic: 'fetchSupplierScoreEpic' }),
        ]),
      );
    }),
  );
