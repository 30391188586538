import { RecommendationsActions } from '../action-types';

export const initRecommendationsListener = (supplierId: string) => ({
  type: RecommendationsActions.INIT_RECOMMENDATIONS_LISTENER,
  payload: supplierId,
});

export const startWeddingFairsListener = () => ({
  type: RecommendationsActions.INIT_WEDDING_FAIRS_LISTENER,
});

export const stopWeddingFairsListener = () => ({
  type: RecommendationsActions.STOP_WEDDING_FAIRS_LISTENER,
});
