import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { authenticatedPOST } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { GetFilesParams, GetFilesResponse } from '@bridebook/toolbox/src/booking-files/utils';
import { ApiEndpoint } from 'lib/api/api-endpoints';
import { appError } from 'lib/app/actions';
import { YourCouplesActionTypes } from 'lib/couples/action-types';
import { fetchFilesSuccess } from 'lib/couples/actions';
import { Action, IEpicDeps } from 'lib/types';

export const fetchFilesEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(YourCouplesActionTypes.FETCH_FILES),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const {
        supplier,
        couples: { openedBookingId: weddingId },
      } = state;

      const supplierId = supplier.supplier?.id;

      if (!supplierId || !weddingId) return of();

      const getPromise = async () => {
        const response = await authenticatedPOST<GetFilesParams, GetFilesResponse>(
          ApiEndpoint.collaboration.getFiles,
          {
            body: { supplierId, weddingId, owner: 'supplier' },
          },
        );

        return response.files;
      };

      return from(getPromise()).pipe(
        mergeMap((files) => of(fetchFilesSuccess({ files }))),
        catchError((error) => of(appError({ error, feature: YourCouplesActionTypes.FETCH_FILES }))),
      );
    }),
  );
