import uuid from 'uuid-random';
import { IFilterLabelProps } from '@bridebook/analytics';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { extractSupplier } from '@bridebook/toolbox/src';
import { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import serverSideTrack from 'lib/analytics-utils/server-side-track';
import { GetListedActionTypes } from 'lib/get-listed/action-types';
import { Action, IApplicationState } from 'lib/types';
import { matchDomains } from 'lib/utils';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';

const filterLabelProps: IFilterLabelProps = (props) => {
  const newProps = { ...props };
  delete newProps.getListedSupplierPhone;
  delete newProps.getListedSupplierEmail;
  delete newProps.getListedSupplierAddress;
  delete newProps.getListedSupplierTown;
  delete newProps.getListedSupplierCounty;
  delete newProps.getListedSupplierPostcode;
  delete newProps.getListedSupplierInviteLink;
  return newProps;
};

const getListedSupplierPropertiesGeneric = (
  supplier: Partial<ISupplier>,
  invite?: string | null,
) => {
  const {
    id: getListedSupplierId,
    type: getListedSupplierCategory,
    name: getListedSupplierName,
    website: getListedSupplierWebsite,
    phone: getListedSupplierPhone,
    email: getListedSupplierEmail,
    address: getListedSupplierAddress,
    town: getListedSupplierTown,
    county: getListedSupplierCounty,
    postcode: getListedSupplierPostcode,
  } = extractSupplier(supplier);
  return {
    getListedSupplierCategory,
    getListedSupplierId,
    getListedSupplierName,
    getListedSupplierWebsite,
    getListedSupplierPhone,
    getListedSupplierEmail,
    getListedSupplierAddress,
    getListedSupplierTown,
    getListedSupplierCounty,
    getListedSupplierPostcode,
    getListedSupplierInviteLink: invite,
  };
};

export default function authAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => IApplicationState,
) {
  const payload = action.payload || {};

  const { track } = bridebookAnalytics.getMethods('Get Listed', filterLabelProps);

  switch (action.type) {
    case GetListedActionTypes.GET_LISTED_VALIDATE_STEP1_SUCCESS_ANALYTICS: {
      const { fields } = payload || {};
      const { email, website, phone, name, countryCode } = fields;
      const trackProps: any = getListedSupplierPropertiesGeneric({
        contacts: { email, website, phone },
        name,
      });
      if (!matchDomains(website, email)) {
        trackProps.completedGetListedFlag = 'email/website domain mismatch';
      }
      track({
        event: 'Completed Get Listed step 1',
        ...trackProps,
        countryCode,
      });
      break;
    }

    case GetListedActionTypes.GET_LISTED_VALIDATE_STEP1_ERROR_ANALYTICS: {
      const { fields, error } = payload;
      const { email, website, phone, name, countryCode } = fields;

      track({
        event: 'Failed Get Listed step 1',
        reasonFailedGetListed: error.reason || error.message,
        ...getListedSupplierPropertiesGeneric({
          contacts: { email, website, phone },
          name,
        }),
        countryCode,
      });
      break;
    }

    case GetListedActionTypes.GET_LISTED_ERROR_ANALYTICS: {
      const { fields, error } = payload;
      const {
        email,
        website,
        phone,
        slug,
        name,
        address,
        postcode,
        town,
        county,
        countryCode,
        lat,
        lng,
      } = fields;

      track({
        event: 'Failed Get Listed step 2',
        reasonFailedGetListed: error.reason || error.message,
        ...getListedSupplierPropertiesGeneric({
          contacts: { email, website, phone },
          address: {
            street: [address],
            postalCode: postcode,
            city: town,
            adminArea: [county],
            country: countryCode,
          },
          coordinates: { latitude: lat, longitude: lng },
          type: [slug],
          name,
        }),
        countryCode,
      });
      break;
    }

    case GetListedActionTypes.GET_LISTED_SUCCESS_ANALYTICS: {
      const { supplier, invite } = payload;
      const { contacts } = supplier as ISupplier;
      const email = contacts?.email;
      const website = contacts?.website;

      let anonymousId = bridebookAnalytics?.analytics.user
        ? bridebookAnalytics.analytics.user().anonymousId()
        : '';

      if (!anonymousId && bridebookAnalytics?.analytics) {
        anonymousId = uuid();
        bridebookAnalytics.analytics.setAnonymousId(anonymousId);
      }

      const trackProps: any = getListedSupplierPropertiesGeneric(supplier, invite);
      if (!matchDomains(website, email)) {
        trackProps.completedGetListedFlag = 'email/website domain mismatch';
      }

      const countryCode = supplier.l10n.country;
      const countryName = countryCode ? Gazetteer.getCountryName(countryCode) : undefined;

      serverSideTrack({
        event: 'Completed Get Listed step 2',
        state: getState(),
        identifyProps: {
          anonymousId,
        },
        specificEventProps: {
          location: getState().app.pathname,
          category: 'Get Listed',
          countryCode,
          countryName,
          ...trackProps,
        },
      });

      break;
    }

    case GetListedActionTypes.SCROLL_TO_GET_LISTED_ANALYTICS: {
      track({
        event: 'Clicked create account link on CMS landing page',
        category: 'CMS',
      });
      break;
    }

    default:
      break;
  }
}
