import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { RelationsActionTypes } from 'lib/relations/action-types';

export const updateSupplierRelations = (supplierRelations: Pick<ISupplier, 'id'>[]) => ({
  type: RelationsActionTypes.UPDATE_SUPPLIER_RELATIONS,
  payload: { supplierRelations },
});

export const updateSupplierRelationsSuccess = ({
  supplierRelations,
}: {
  supplierRelations: Pick<ISupplier, 'id'>[];
}) => ({
  type: RelationsActionTypes.UPDATE_SUPPLIER_RELATIONS_SUCCESS,
  payload: { supplierRelations },
});
