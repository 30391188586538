import { appError } from 'lib/app/actions';
import { fetchEnquirySuccess } from 'lib/enquiries/actions';
import { PayPerEnquiryActionTypes } from 'lib/pay-per-enquiry/action-types';
import {
  closedBuyEnquiriesPopupAnalytics,
  triggeredBuyEnquiriesPopupAnalytics,
} from 'lib/pay-per-enquiry/analytics/actions';
import { IPayPerEnquiryState, TPricingItem } from 'lib/pay-per-enquiry/types';
import { IDeps, TTouchPointLocation } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';

export const toggleBuyEnquiriesPopup =
  (show: boolean, touchpointLocation?: TTouchPointLocation) =>
  ({ dispatch }: IDeps) => {
    show
      ? dispatch(triggeredBuyEnquiriesPopupAnalytics({ touchpointLocation }))
      : dispatch(closedBuyEnquiriesPopupAnalytics());

    return {
      type: PayPerEnquiryActionTypes.TOGGLE_BUY_ENQUIRIES_POPUP,
      payload: show,
    };
  };

export const togglePaymentCallbackPopup = (
  payload: Required<IPayPerEnquiryState>['paymentCallbackPopup'],
) => ({
  type: PayPerEnquiryActionTypes.TOGGLE_PAYMENT_CALLBACK_POPUP,
  payload,
});

export const toggleRevealEnquiryPopup = (
  payload: Required<IPayPerEnquiryState>['revealEnquiryPopup'],
) => ({
  type: PayPerEnquiryActionTypes.TOGGLE_REVEAL_ENQUIRY_POPUP,
  payload,
});

export const selectPrice = (price: TPricingItem) => ({
  type: PayPerEnquiryActionTypes.SELECT_PRICE,
  payload: price,
});

export const fetchPricing = () => ({
  type: PayPerEnquiryActionTypes.FETCH_PRICING,
});

export const revealEnquiry = (payload: { enquiryId: string }) => ({
  type: PayPerEnquiryActionTypes.REVEAL_ENQUIRY,
  payload,
});

export const revealEnquirySuccess =
  (data) =>
  ({ dispatch }: IDeps) => {
    dispatch(toggleRevealEnquiryPopup({ show: false }));
    dispatch(fetchEnquirySuccess(data));

    return {
      type: PayPerEnquiryActionTypes.REVEAL_ENQUIRY_SUCCESS,
    };
  };

export const revealEnquiryFailure =
  (error: Error) =>
  ({ dispatch }: IDeps) => {
    dispatch(appError({ error, feature: 'PayPerEnquiry' }));
    dispatch(
      toggleSnackbar('alert', 'Something went wrong, please try again. No credit was used.'),
    );
    dispatch(toggleRevealEnquiryPopup({ show: false }));

    return {
      type: PayPerEnquiryActionTypes.REVEAL_ENQUIRY_FAILURE,
    };
  };

export const initPricing = (pricing: TPricingItem[]) => () => ({
  type: PayPerEnquiryActionTypes.INIT_PRICING,
  payload: pricing,
});

export const initBalanceListener = () => ({
  type: PayPerEnquiryActionTypes.INIT_BALANCE_LISTENER,
});

export const stopBalanceListener = () => ({
  type: PayPerEnquiryActionTypes.STOP_BALANCE_LISTENER,
});

export const onFirestoreBalance = (balance: number) => ({
  type: PayPerEnquiryActionTypes.ON_FIRESTORE_BALANCE,
  payload: balance,
});
