import Router from 'next/router';
import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { clearTempContentTranslations } from 'lib/content-translations/slice';
import { OffersActionTypes } from 'lib/offers/action-types';
import { deleteSpecialOfferSuccess, showError } from 'lib/offers/actions';
import { IEpicDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';
import { UrlHelper } from 'lib/url-helper';
import { getOffers } from '../selectors/offers';

export const deleteSpecialOfferEpic = (
  action$: Observable<any>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType(OffersActionTypes.DELETE_SPECIAL_OFFER),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const { type } = state.offers;
      const specialOffers = getOffers(state);

      const activeSupplier = state.users.activeSupplierAccessControl;
      if (!activeSupplier?.id) return of();

      const getPromise = async (): Promise<any> => {
        const remove = async () => {
          const offer = specialOffers[type as string];
          if (!offer || !offer.id) {
            throw new Error('Cannot delete offer - missing offer data!');
          }

          await Suppliers._.getById(activeSupplier.id).Offers.getById(offer.id).delete();

          // TODO: handle Counters
          // await firebase.update({
          //   [`counters/suppliers/${slug}/${id}/specialOffers/lateAvailability/redeemCount`]: null,
          // });

          return Router.push(UrlHelper.grow.specialOffers.home);
        };

        return remove();
      };

      return from(getPromise()).pipe(
        mergeMap(() =>
          of(
            deleteSpecialOfferSuccess(),
            toggleSnackbar('success', getI18n().t('offers:offerDeleted')),
            clearTempContentTranslations('offers'),
          ),
        ),
        catchError((error) =>
          of(showError(error.message, error.prop), toggleSnackbar('alert', error.message)),
        ),
      );
    }),
  );
