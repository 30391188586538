import type { User as FirebaseUser } from 'firebase/auth';
import type { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { getUserAccessControl } from 'lib/access-control/utils/get-user-access-control';
import type { IDeps } from 'lib/types';
import createUserFirebase from 'lib/users/utils/create-user-firebase';

export const triggerAuthAnalytics =
  (firebaseUser: FirebaseUser, supplier?: ISupplier | null) =>
  ({ dispatch }: IDeps) => {
    const getPromise = async () => {
      const user = createUserFirebase(firebaseUser);
      const userAccessControl = await getUserAccessControl(user.id);
      if (!userAccessControl || userAccessControl.length < 1) {
        dispatch({
          type: 'REGISTRATION_SUCCESS_ANALYTICS',
          payload: {
            user,
            countryCode: supplier?.l10n.country,
            isFirstUser: supplier ? !supplier.users?.length : undefined,
          },
        });
      } else {
        dispatch({
          type: 'LOG_IN_SUCCESS_ANALYTICS',
          payload: { user },
        });
      }
    };

    return {
      type: 'TRIGGER_AUTH_ANALYTICS',
      payload: {
        promise: getPromise(),
      },
    };
  };

export const claimedSupplierProfileAnalytics = ({
  supplierId,
  user,
}: {
  supplierId: string;
  user: { id: string };
}) => ({
  type: 'CLAIMED_SUPPLIER_PROFILE',
  payload: { supplierId, user },
});
