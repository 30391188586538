import gazetteer, { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { TidyInboxStatus } from 'lib/api/supplier/tidy-inbox/types';
import { getPreviousPath, getSupplierLocale } from 'lib/app/selectors';
import { getSupplierId, getSupplierTier, getSupplierType } from 'lib/supplier/selectors';
import { getSupplierCountryCode, getSupplierName } from 'lib/supplier/selectors/contact';
import { Action, IApplicationState, State } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';
import { IEmailsProcessingFinished, TidyYourInboxAnalyticsActions } from './action-types';

const commonAnalyticsProperties = (state: IApplicationState) => () => {
  const supplierCountry = state.supplier.supplier?.l10n.country;
  const market = supplierCountry ? gazetteer.getMarketByCountry(supplierCountry) : null;
  const currency = market?.currency;
  const countryCode = getSupplierCountryCode(state);
  const countryName = countryCode ? Gazetteer.getCountryName(countryCode) : undefined;

  return {
    category: 'CMS',
    countryCode,
    countryName,
    locale: getSupplierLocale(state),
    currency,
    previousPath: getPreviousPath(state),
    supplierId: getSupplierId(state),
    supplierName: getSupplierName(state),
    supplierTier: getSupplierTier(state),
    supplierCategory: getSupplierType(state),
  };
};

export default function tidyYourInboxAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const { track } = bridebookAnalytics.getMethods('CMS');
  const state = getState();

  switch (action.type) {
    case TidyYourInboxAnalyticsActions.TidyYourInboxClicked: {
      track({
        ...commonAnalyticsProperties(state),
        event: 'Clicked tidy your inbox on CMS',
      });
      break;
    }

    case TidyYourInboxAnalyticsActions.EmailsProcessingFinished: {
      const payload = action.payload as IEmailsProcessingFinished['payload'];

      const totalEmailsUploaded =
        payload[TidyInboxStatus.MarkedAsBooked].length +
        payload[TidyInboxStatus.AlreadyBooked].length +
        payload[TidyInboxStatus.Error].length +
        payload[TidyInboxStatus.Invalid].length +
        payload[TidyInboxStatus.NotMatched].length;

      track({
        ...commonAnalyticsProperties(state),
        event: 'Clicked upload on tidy your inbox on CMS',
        emailsUploaded: totalEmailsUploaded,
        enquiriesMarkedAsBooked: payload[TidyInboxStatus.MarkedAsBooked].length,
        enquiriesAlreadyBooked: payload[TidyInboxStatus.AlreadyBooked].length,
        enquiriesErroredWhenMarkingAsBooked: payload[TidyInboxStatus.Error].length,
        invalidEmails: payload[TidyInboxStatus.Invalid].length,
        noMatchEmails: payload[TidyInboxStatus.NotMatched].length,
      });
      break;
    }

    case TidyYourInboxAnalyticsActions.UploadMoreClicked: {
      track({
        ...commonAnalyticsProperties(state),
        event: 'Clicked upload more on tidy your inbox on CMS',
      });
      break;
    }

    default:
      break;
  }
}
