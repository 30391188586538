import { createSlice } from '@reduxjs/toolkit';
import { ROIState } from 'lib/roi/types';

const initialState: ROIState = {
  calculator: {
    show: false,
  },
  report: {
    isLoading: false,
  },
};

const roiSlice = createSlice({
  name: 'roi',
  initialState,
  reducers: {
    toggleROICalculator(state) {
      state.calculator.show = !state.calculator.show;
    },
    toggleLoadingROIReport(state) {
      state.report.isLoading = !state.report.isLoading;
    },
  },
});

export const { toggleROICalculator, toggleLoadingROIReport } = roiSlice.actions;
export default roiSlice.reducer;
