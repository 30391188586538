import type { IAddressComponents } from '@bridebook/toolbox/src/types';

export const addressHasLine1 = (line1: string, address: IAddressComponents): boolean => {
  if (!line1) return false;
  let tempAddress: string = line1;
  if (address.locality) tempAddress = tempAddress.replace(address.locality, '');
  if (address.postal_code) {
    tempAddress = tempAddress.replace(address.postal_code, '');
  }
  if (address.country) tempAddress = tempAddress.replace(address.country, '');
  tempAddress = tempAddress.replace('UK', '');
  tempAddress = tempAddress.replace(/,/g, '');
  tempAddress = tempAddress.replace(/ /g, '');
  return tempAddress.length > 2;
};
