import {
  fetchCountries,
  saveFormError,
  saveFormSection,
  saveFormSectionSuccess,
  setFormSectionUnsaved,
  updateFormSectionCheckboxGroup,
  updateFormSectionField,
  updateSupplier,
  updateSupplierData,
} from './actions';

export enum SupplierActionTypes {
  SAVE_SUPPLIER = '[Supplier] Save Supplier',
  UPDATE_SUPPLIER = '[Supplier] Update Supplier',
  FETCH_COUNTRIES = '[Supplier] Fetch Countries',
  FETCH_COUNTRIES_SUCCESS = '[Supplier] Fetch Countries Success',
  DISCARD_FORM_CHANGES = '[Supplier] Discard form changes',
  UPDATE_SUPPLIER_DATA = '[Supplier] Update supplier data',
  ADD_FIELD_TO_LIST = '[Supplier] Add field to list',
  // Form section field update
  SET_FORM_SECTION_UNSAVED = '[Supplier] Set form section unsaved',
  UPDATE_FORM_SECTION_FIELD = '[Supplier] Update form section field',
  UPDATE_FORM_SECTION_CHECKBOX_GROUP = '[Supplier] Update form section checkbox group',
  // Form sections save
  SAVE_FORM_SECTION = '[Supplier] Save form section',
  SAVE_FORM_SECTION_SUCCESS = '[Supplier] Save form section success',
  SAVE_FORM_ERROR = '[Supplier] Save form error',
}

export type IUpdateSupplierAction = ReturnType<typeof updateSupplier>;
export type IUpdateSupplierDataAction = ReturnType<typeof updateSupplierData>;
export type IFetchCountries = ReturnType<typeof fetchCountries>;
export type IUpdateFormSectionField = ReturnType<typeof updateFormSectionField>;
export type IUpdateFormSectionCheckboxGroup = ReturnType<typeof updateFormSectionCheckboxGroup>;
export type ISaveFormSectionSuccess = ReturnType<typeof saveFormSectionSuccess>;
export type ISaveFormSection = ReturnType<typeof saveFormSection>;
export type SetFormSectionUnsavedAction = ReturnType<typeof setFormSectionUnsaved>;
export type ISaveFormError = ReturnType<typeof saveFormError>;
