import { extractSupplier } from '@bridebook/toolbox/src';
import { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { supplierPropertiesGeneric } from 'lib/analytics-utils';
import { usedNavigationAnalytics } from 'lib/navigation/actions/analytics';
import { Action, IApplicationState, State } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';

export default function navigationAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const payload = action.payload || {};

  const { track } = bridebookAnalytics.getMethods('CMS');
  const state = getState();
  const genericProps = supplierPropertiesGeneric(state);

  const getAdditionalSupplierData = (state: IApplicationState) => {
    const { supplier } = state.supplier;

    if (!supplier) return;

    const { countryCode, email: supplierEmail, county: supplierCounty } = extractSupplier(supplier);

    return {
      supplierEmail,
      supplierCounty,
      countryCode,
      countryName: countryCode && Gazetteer.getCountryName(countryCode),
    };
  };

  switch (action.type) {
    case usedNavigationAnalytics.type: {
      const { viewer } = state.users;
      track({
        event: action.type,
        ...payload,
        ...genericProps,
        ...getAdditionalSupplierData(state),
        id: viewer?.id,
      });
      break;
    }
  }
}
