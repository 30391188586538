import { createSelector } from 'reselect';
import {
  countriesWithRW,
  countriesWithSupplierRecommendations,
} from '@bridebook/toolbox/src/i18n/features';
import { getIsDE, getIsUK } from 'lib/app/selectors';
import { getSupplierCountryCode, getSupplierType } from 'lib/supplier/selectors';
import { IApplicationState } from 'lib/types';

export const getRWList = (state: IApplicationState) => state.realWeddings.list;

export const getIsRWUnsaved = (state: IApplicationState) => state.realWeddings.unsaved;
export const getIsRWEditing = (state: IApplicationState) => state.realWeddings.editing;
export const getRWError = (state: IApplicationState) => state.realWeddings.error;

const getRWForm = (state: IApplicationState) => state.realWeddings.form;

export const getIsRWLoading = createSelector([getRWForm], (form) => form?.isLoading);
export const getRWFormConsent = createSelector([getRWForm], (form) => form?.consent);

export const getIsRealWeddingAvailable = createSelector(
  [getSupplierCountryCode, getSupplierType],
  (countryCode, supplierType) =>
    countriesWithRW.includes(countryCode) && ['venue', 'photo'].includes(supplierType as string),
);

export const getCanLinkSupplier = createSelector([getIsUK, getIsDE], (isUK, isDE) => isUK || isDE);

export const getIsRecommendationsAvailable = createSelector(
  [getSupplierCountryCode],
  (countryCode) => countriesWithSupplierRecommendations.includes(countryCode),
);
