import { pathOr } from 'ramda';
import { applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import promiseMiddleware from 'redux-promise-middleware';
import createSentryMiddleware from 'redux-sentry-middleware';
import { Middleware } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/nextjs';
import { IApplicationState, IEpicDeps } from 'lib/types';
import { IConfigureDeps } from './configure-deps';
import configureEpics from './configure-epics';

// Redux logger for Node.js.
const nodeLogger = () => (next: any) => (action: any) => {
  const { type, ...props } = action;
  const propsAsShortString = JSON.stringify(props).slice(0, 60);
  // eslint-disable-next-line
  console.log(`action ${type}, ${propsAsShortString}...`);
  return next(action);
};

const injectMiddleware =
  (deps: any) =>
  ({ dispatch, getState }: any) =>
  (next: any) =>
  (action: any) => {
    const injectAction = () => {
      const nextAction =
        typeof action === 'function' ? action({ ...deps, dispatch, getState }) : action;
      if (nextAction?.type === 'NOOP') {
        // do nothing
      } else {
        next(nextAction);
      }
    };

    try {
      return injectAction();
    } catch (e) {
      if (process.env.NODE_ENV === 'production') {
        Sentry.captureException(e);
      } else {
        // eslint-disable-next-line no-console
        console.error(
          'Actions must be plain objects. Use custom middleware for async actions',
          action,
          e,
        );
      }
    }
  };

const createReduxMiddleware = (platformMiddlewares: Middleware[], deps: IConfigureDeps) => {
  const rootEpic = configureEpics(deps);
  const epicMiddleware = createEpicMiddleware<any, any, IApplicationState, IEpicDeps>();
  const middleware = [
    injectMiddleware(deps),
    ...platformMiddlewares,
    epicMiddleware,
    promiseMiddleware({ promiseTypeSuffixes: ['START', 'SUCCESS', 'ERROR'] }),
  ];

  if (process.browser) {
    const getUserContext = (state: IApplicationState) => {
      const { viewer } = state.users;
      const { supplier } = state.supplier;

      return {
        email: pathOr('', ['email'], viewer),
        id: pathOr('', ['id'], viewer),
        supplierId: pathOr('', ['id'], supplier),
        supplierSlug: pathOr('', ['slug'], supplier),
      };
    };

    const stateTransformer = (state: IApplicationState) => ({
      userAccessControl: state.users.userAccessControl,
      viewer: state.users.viewer,
    });

    middleware.push(
      // @ts-expect-error
      createSentryMiddleware(Sentry, {
        getUserContext,
        stateTransformer,
      }),
    );
  }

  // Logger must be the last middleware in chain.
  if (process.env.NODE_ENV !== 'production') {
    const isServer = !process.browser;
    const logger = isServer ? nodeLogger : createLogger({ collapsed: true, diff: true });
    middleware.push(logger);
  }

  const appliedMiddleware = applyMiddleware(...middleware);

  if (process.browser) {
    return { middleware: appliedMiddleware, rootEpic, epicMiddleware, deps };
  } else {
    return { middleware: appliedMiddleware, rootEpic: {}, deps };
  }
};

export default createReduxMiddleware;
