import { SearchFields as SearchFieldsType } from './types';

const SearchFields: SearchFieldsType = {
  category: 'venue',
  area: '',
  swlat: 0,
  swlon: 0,
  nelat: 0,
  nelon: 0,
};

export default SearchFields;
