import { PartialRecursive } from '@bridebook/models/source/abstract/_';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { friendlyUrl } from '@bridebook/toolbox';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import {
  IGetListedAPIResponse,
  IGetListedFields,
  IGetListedValidateAPIResponse,
} from 'lib/get-listed/types';

export const createRequestedAccount = async (
  fields: IGetListedFields,
): Promise<IGetListedAPIResponse | IGetListedValidateAPIResponse> => {
  const countryCode = fields.countryCode || CountryCodes.GB;
  const urlSlug = friendlyUrl({ name: fields.name, town: fields.town, county: fields.county });
  const supplierFields: PartialRecursive<ISupplier> = {
    contacts: {
      email: fields.email,
      phone: fields.phone,
      website: fields.website,
    },
    name: fields.name,
    type: [fields.slug],
    address: {
      street: [fields.address],
      postalCode: fields.postcode,
      city: fields.town,
      country: countryCode,
      adminArea: [fields.county, fields.countryName],
    },
    l10n: {
      country: countryCode,
      currency: gazetteer.getMarketByCountry(countryCode).currency,
    },
    coordinates: { latitude: fields.lat, longitude: fields.lng },
    counters: { photos: 0, reviews: 0, testimonials: 0, videos: 0, videoTours: 0 },
    seo: { urlSlug },
  };
  const response = await fetch(`/api/supplier/create`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ supplierFields, test: false }),
  });

  if (!response.ok || response.status !== 200) {
    throw new Error(`Something went wrong ${response.statusText}`);
  }

  return await response.json();
};
