import Router from 'next/router';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';
import {
  IDeleteMessageTemplate,
  ISaveMessageTemplate,
  MessageTemplatesAction,
} from 'lib/message-templates/action-types';
import { UrlHelper } from 'lib/url-helper';

export const redirectToTemplatesSettings = (
  action$: Observable<ISaveMessageTemplate | IDeleteMessageTemplate>,
) =>
  action$.pipe(
    ofType(MessageTemplatesAction.TemplateSaved, MessageTemplatesAction.TemplateDeleted),
    tap(() => Router.replace(UrlHelper.couples.enquirySettings)),
    ignoreElements(),
  );
