import { Timestamp, WithFieldValue, serverTimestamp } from 'firebase/firestore';
import { omit } from 'ramda';
import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import uuid from 'uuid-random';
import { Suppliers } from '@bridebook/models';
import { Fairs } from '@bridebook/models/source/models/Suppliers/Fairs';
import { IFair, IFair_Translations } from '@bridebook/models/source/models/Suppliers/Fairs.types';
import { getScopedContentTranslations } from 'lib/content-translations/selectors';
import { clearTempContentTranslations } from 'lib/content-translations/slice';
import { toggleSnackbar } from 'lib/ui/actions';
import { appError } from '../../app/actions';
import { IEpicDeps } from '../../types';
import validate from '../../validate';
import { RecommendationsActions, SaveWeddingFairAction } from '../action-types';
import { saveWeddingFairError } from '../actions/save-wedding-fair';
import failedToSaveFairAnalytics from '../analytics/failed-to-save-fair-analytics';
import saveWeddingFairSuccessAnalytics from '../analytics/save-wedding-fair-success-analytics';

export const saveWeddingFairEpic = (
  action$: Observable<SaveWeddingFairAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(RecommendationsActions.SAVE_WEDDING_FAIR),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const { weddingFair, scope } = payload;
      const supplierId = state.supplier.supplier?.id;
      if (!supplierId) return of();

      const isEdit = !!weddingFair.id;
      const supplierRef = Suppliers._.getById(supplierId);
      const { dates, name, description } = weddingFair;
      const _translations = getScopedContentTranslations<IFair_Translations>(state, scope);
      const fair: WithFieldValue<IFair> = Fairs.new('_', {
        dates: dates ? [Timestamp.fromDate(new Date(dates[0]))] : [],
        description,
        name,
        updatedAt: serverTimestamp(),
        ...(_translations && { _translations }),
      });

      const getPromise = async () => {
        await validate(weddingFair)
          .prop('name')
          .required()
          .prop('dates')
          .required()
          .prop('description')
          .required().promise;

        if (isEdit && weddingFair.id) {
          fair.id = weddingFair.id;

          await supplierRef.Fairs.getById(weddingFair.id).set(omit(['createdAt'], fair));
        } else {
          const id = uuid();
          fair.id = id;
          fair.createdAt = serverTimestamp();

          await supplierRef.Fairs.push(id).create(fair);
        }

        return { fair };
      };

      return from(getPromise()).pipe(
        mergeMap(({ fair }) =>
          of(
            saveWeddingFairSuccessAnalytics(fair, isEdit),
            toggleSnackbar('success', getI18n().t('weddingFairs:successFairSave')),
            clearTempContentTranslations(scope),
          ),
        ),
        catchError((error) => [
          appError({ error, feature: 'WeddingFairs' }),
          saveWeddingFairError(error.message),
          failedToSaveFairAnalytics({ fair, err: error, isEdit }),
          clearTempContentTranslations(scope),
        ]),
      );
    }),
  );
