import { PremiumTiers } from '@bridebook/toolbox';

/**
 * Returns the maximum number of additional counties a supplier can have based on their tier.
 * This can be overwritten by purchasing additional counties in Chargebee!
 */
export const getAdditionalCountiesLimit = ({
  supplierTier = PremiumTiers.Tier_0,
  isVenue,
}: {
  supplierTier?: PremiumTiers;
  isVenue?: boolean;
}) => {
  // Limits for venues
  if (isVenue) {
    if (supplierTier >= PremiumTiers.Tier_3) {
      return 5;
    }

    return 0;
  }

  // Limits for suppliers
  if (supplierTier >= PremiumTiers.Tier_2) {
    return 2;
  }

  return 0;
};
