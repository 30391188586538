import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { ISupplierWeddingManager } from 'lib/enquiries/types';
import { isEnquiryPayable, isRevealed } from 'lib/enquiries/utils';
import { isPaidTier } from 'lib/premium/utils/tiers';
import { getSupplierAdmin } from 'lib/supplier/selectors';
import { IApplicationState } from 'lib/types';

const balance = (state: IApplicationState) => state.payPerEnquiry.balance;
const enquiry = (state: IApplicationState) => state.enquiries.enquiry;

/**
 * Returns true if supplier can see components related to Pay-Per-Enquiry
 */
export const getIsPayPerEnquiryAvailable = createSelector([getSupplierAdmin], (supplierAdmin) => {
  if (!supplierAdmin) return false;
  return !isPaidTier(supplierAdmin.premium?.tier) && Boolean(supplierAdmin.payPerEnquiry);
});

/**
 * Use only if you have enquiry set in state
 */
export const getIsLocked = createSelector(
  [getIsPayPerEnquiryAvailable, enquiry],
  (isPayPerEnquiryAvailable, enquiry) =>
    isPayPerEnquiryAvailable && enquiry && isEnquiryPayable(enquiry) && !isRevealed(enquiry),
);

export const getIsEnquiryLocked = createCachedSelector(
  getIsPayPerEnquiryAvailable,
  (_: IApplicationState, enquiry: ISupplierWeddingManager) => enquiry,
  (isPayPerEnquiryAvailable, enquiry) =>
    isPayPerEnquiryAvailable && isEnquiryPayable(enquiry) && !isRevealed(enquiry),
)((_: IApplicationState, enquiry) => enquiry.id);

export const getEnquiryBalanceNumber = createSelector([balance], (balance) => balance);
