import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { isDefined } from '@bridebook/toolbox/src/utils/type-guards';
import { appError } from 'lib/app/actions';
import { FetchSearchRankingAction, StatisticsActionTypes } from 'lib/statistics/action-types';
import { fetchSearchRankingSuccess } from 'lib/statistics/actions/fetch';
import { fetchSearchRanking } from 'lib/statistics/utils';
import {
  getCustomArea,
  getSupplierCountry,
  getSupplierCountryCode,
  getSupplierCounty,
  getSupplierType,
} from 'lib/supplier/selectors';
import { IEpicDeps } from 'lib/types';

export const fetchSearchRankingEpic = (
  action$: Observable<FetchSearchRankingAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(StatisticsActionTypes.FETCH_SEARCH_RANKING),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const getPromise = async () => {
        const supplierId = state.supplier.supplier?.id as string;
        const obj = {
          supplierCounty: getSupplierCounty(state) as string,
          supplierCustomArea: getCustomArea(state),
          supplierCountry: getSupplierCountry(state) as string,
          supplierId,
          countryCode: getSupplierCountryCode(state),
          type: getSupplierType(state),
          additionalAreas: state.supplier?.supplier?.additionalAreas?.filter(isDefined),
        };
        return fetchSearchRanking(obj);
      };

      return from(getPromise()).pipe(
        map((data) => fetchSearchRankingSuccess(data)),
        catchError((error: Error) => [
          { type: StatisticsActionTypes.FETCH_SEARCH_RANKING_ERROR },
          appError({ error, feature: 'fetchSearchRankingEpic' }),
        ]),
      );
    }),
    catchError((error) => of(appError({ error, feature: 'fetchSearchRankingEpic' }))),
  );
