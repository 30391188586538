import {
  addedAdditionalLocationAnalytics,
  removedAdditionalLocationAnalytics,
} from 'lib/supplier/analytics/actions';

export enum SupplierAnalyticsActionTypes {
  ADDED_ADDITIONAL_LOCATION = '[Analytics][Supplier] Added additional location',
  REMOVED_ADDITIONAL_LOCATION = '[Analytics][Supplier] Removed additional location',
}

export type AddedAdditionalLocationAnalyticsAction = ReturnType<
  typeof addedAdditionalLocationAnalytics
>;

export type RemovedAdditionalLocationAnalyticsAction = ReturnType<
  typeof removedAdditionalLocationAnalytics
>;
