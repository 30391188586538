import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { PremiumTiers } from '@bridebook/models/source/models/Suppliers/Admin/admin.types';
import { getAdditionalCountiesLimit } from 'lib/multi-county/get-additional-counties-limit';

/**
 * Returns current number of slots for additional counties for a given tier and supplier type.
 */
export const getAdditionalCountiesSlots = ({
  isVenue,
  supplierTier,
  additionalAreasSlots = 0,
}: {
  isVenue: boolean;
  additionalAreasSlots: ISupplier['additionalAreasSlots'];
  supplierTier?: PremiumTiers;
}) => {
  const maxSlotsPerTier = getAdditionalCountiesLimit({
    supplierTier,
    isVenue,
  });

  return isVenue
    ? // Slots for venues are controlled by the number of purchased items on Chargebee.
      additionalAreasSlots
    : // Slots for suppliers are by default based on the tier OR can be overwritten by purchasing items on Chargebee.
      Math.max(additionalAreasSlots, maxSlotsPerTier);
};
