import { Timestamp, serverTimestamp } from 'firebase/firestore';
import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from, merge, of } from 'rxjs';
import { catchError, mergeMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import uuid from 'uuid-random';
import { Countries, Weddings } from '@bridebook/models';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { IWork } from '@bridebook/models/source/models/Suppliers/Works.types';
import { ValidationError } from '@bridebook/toolbox';
import { appError } from 'lib/app/actions';
import { Action, IEpicDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';
import { imgixBaseURL } from 'lib/utils';
import validate from 'lib/validate';
import { DeleteRWAction, RWActions, SaveRWAction } from './action-types';
import {
  clearRW,
  deleteRWError,
  deleteRWSuccess,
  saveRWError,
  saveRWSuccess,
  updateRWForm,
  updateRWList,
} from './actions';
import { getCanLinkSupplier } from './selectors';
import { RealWedding, RealWeddingForm } from './types';

const getDefaultWorkProps = () => ({
  createdAt: serverTimestamp() as Timestamp,
  active: true,
});

const validateRealWedding = (realWedding: RealWeddingForm) => {
  const i18n = getI18n();

  let validation = validate(realWedding)
    .prop('name')
    .required(() => i18n.t('realWeddings:validation.weddingName'))
    .prop('couple')
    .required(() => i18n.t('realWeddings:validation.coupleNames'))
    .prop('url')
    .required(() => i18n.t('realWeddings:validation.blockedUrl'))
    .urLink();

  validation.promise = validation.promise.then(async () => {
    const { competitors } = await Countries._.getById('*').get();
    const isInvalid = (competitors || []).some((competitorUrl) =>
      realWedding.url?.includes(competitorUrl),
    );

    if (isInvalid) {
      throw new ValidationError(getI18n().t('realWeddings:validation.blockedUrl'), 'url');
    }
  });

  validation = validation.prop('photos').custom(() => {
    if (realWedding.photos?.length) return;

    throw new ValidationError(getI18n().t('realWeddings:validation.photos'), 'photos');
  });

  return validation.promise;
};

const feature = 'Real Weddings';

const createRW = async (
  supplier: ISupplier,
  realWeddingForm: RealWeddingForm,
  canLinkSupplier: boolean,
) => {
  const weddingId = uuid();
  const { id: supplierId, l10n } = supplier;
  const supplierType = supplier.type[0];
  const newWedding = Weddings.new('_', {
    id: weddingId,
    l10n,
    createdBy: supplierId,
    users: [],
  });

  const { linkedSupplier, photos, isLoading, ...realWedding } = realWeddingForm as RealWedding & {
    isLoading: RealWeddingForm['isLoading'];
    photos: NonNullable<RealWeddingForm['photos']>;
    linkedSupplier: RealWeddingForm['linkedSupplier'];
  };

  const weddingEntity = await Weddings._.push(weddingId).create(newWedding);
  const supplierEntity = Suppliers._.getById(supplierId);

  const photoId = uuid();

  await weddingEntity.Photos.push(photoId).create({
    approved: false, // This is approved by couple
    createdAt: serverTimestamp(),
    id: photoId,
    order: 0,
    path: photos[0].path,
  });

  const newWork: IWork = {
    ...realWedding,
    ...getDefaultWorkProps(),
    photos: [photoId],
    role: supplierType,
    supplier: supplierId,
    createdBy: supplierId,
  };

  await supplierEntity.Works.push(weddingId).create(newWork);

  if (canLinkSupplier && linkedSupplier) {
    const otherSupplierEntity = Suppliers._.getById(linkedSupplier.id);
    await otherSupplierEntity.Works.push(weddingId).create({
      ...newWork,
      // This is important, it means that the work will not appear in the CMS
      // page of the linked supplier
      consent: false,
      supplier: linkedSupplier.id,
      role: linkedSupplier.role,
    });
  }

  return { weddingId, work: newWork, linkedSupplier };
};

const editRW = async (supplier: ISupplier, realWeddingForm: RealWeddingForm) => {
  const { id: supplierId } = supplier;

  const { linkedSupplier, photos, isLoading, ...realWedding } = realWeddingForm as RealWedding & {
    isLoading: RealWeddingForm['isLoading'];
    photos: RealWeddingForm['photos'];
    linkedSupplier: NonNullable<RealWeddingForm['linkedSupplier']>;
  };

  const supplierEntity = Suppliers._.getById(supplierId);

  const currentWork = await supplierEntity.Works.getById(realWedding.id).get();

  const photo = { ...(photos[0] as { id: string; path: string }) };
  if (!photo.id) photo.id = uuid();

  if (currentWork.photos[0] !== photo.id) {
    const weddingEntity = Weddings._.getById(realWedding.id);
    await weddingEntity.Photos.push(photo.id).create({
      approved: false,
      id: photo.id,
      createdAt: serverTimestamp(),
      order: 0,
      path: photo.path,
    });
  }

  const updatedData = {
    ...realWedding,
    photos: [photo.id],
  } as RealWedding;
  await supplierEntity.Works.getById(realWedding.id).update(updatedData);

  return { work: updatedData, linkedSupplier };
};

export const saveRWEpic = (action$: Observable<SaveRWAction>, { state$, bbanalytics }: IEpicDeps) =>
  action$.pipe(
    ofType(RWActions.SAVE),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const realWedding = state.realWeddings.form;
      const supplier = state.supplier.supplier;

      if (realWedding.isLoading) return of();

      if (!realWedding) {
        return of(appError({ error: new Error('Missing real wedding'), feature }));
      }

      const editing = Boolean(realWedding.id);
      const supplierId = state.supplier.supplier?.id;
      if (!supplierId) {
        return of(appError({ error: new Error('Missing supplier id'), feature }));
      }

      const successActions = [
        saveRWSuccess(),
        toggleSnackbar('success', getI18n().t('realWeddings:save.success')),
      ];

      const getPromise = async () => {
        const canLinkSupplier = getCanLinkSupplier(state);
        await validateRealWedding(realWedding);

        if (editing) {
          const {
            work: { id, photos, url, createdBy },
            linkedSupplier,
          } = await editRW(supplier as ISupplier, realWedding);
          bbanalytics.supplier.realWeddings.edited({
            realWeddingId: id,
            realWeddingPhotoUrl: photos.length ? `${imgixBaseURL}/${photos[0]}` : '',
            realWeddingUrl: url,
            weddingCreator: createdBy,
            taggedSupplierId: linkedSupplier?.id,
          });
        } else {
          const { work, linkedSupplier } = await createRW(
            supplier as ISupplier,
            realWedding,
            canLinkSupplier,
          );
          bbanalytics.supplier.realWeddings.added({
            realWeddingId: work.id,
            realWeddingPhotoUrl: work.photos.length ? `${imgixBaseURL}/${work.photos[0]}` : '',
            realWeddingUrl: work.url,
            weddingCreator: work.createdBy,
            taggedSupplierId: linkedSupplier?.id,
          });

          if (realWedding.linkedSupplier) {
            bbanalytics.supplier.realWeddings.tagged({
              taggedSupplierId: realWedding.linkedSupplier.id,
            });
          }
        }
      };

      return merge(
        of(updateRWForm({ isLoading: true })),
        from(getPromise()).pipe(
          mergeMap(() => of(...successActions)),
          catchError((error: Error) => {
            const errorActions: Action[] = [];

            if (error instanceof ValidationError) {
              errorActions.push(saveRWError(error));
            } else {
              errorActions.push(appError({ error, feature }), updateRWForm({ isLoading: false }));
            }

            return of(...errorActions);
          }),
        ),
      );
    }),
  );

export const initRWListenerEpic = (action$: Observable<SaveRWAction>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(RWActions.INIT_LISTENER),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const supplierId: string | undefined = state.supplier.supplier?.id;
      if (!supplierId) return of();

      const realWeddings$ = Suppliers._.getById(supplierId).Works.query().observe();

      return realWeddings$.pipe(
        mergeMap((data) => {
          const works = Object.values(data).filter((w) => w.consent && w.active);

          return of(updateRWList(works));
        }),
        takeUntil(action$.pipe(ofType(RWActions.STOP_LISTENER))),
      );
    }),
  );

export const deleteRWEpic = (
  action$: Observable<DeleteRWAction>,
  { state$, bbanalytics }: IEpicDeps,
) =>
  action$.pipe(
    ofType(RWActions.DELETE),
    withLatestFrom(state$),
    mergeMap(([{ payload: realWedding }, state]) => {
      const supplierId = state.supplier.supplier?.id;
      if (!supplierId) return of();
      const successActions = [deleteRWSuccess()];
      const realWeddingForm = state.realWeddings.form;

      // If it is editing the same real wedding as the one being deleted, reset
      // the form
      if (realWedding.id === realWeddingForm.id) {
        successActions.push(clearRW());
      }

      const promise = Suppliers._.getById(supplierId).Works.getById(realWedding.id).update({
        active: false,
      });

      return from(promise).pipe(
        tap(() =>
          bbanalytics.supplier.realWeddings.deleted({
            realWeddingId: realWedding.id,
            realWeddingPhotoUrl: realWedding.photos.length
              ? `${imgixBaseURL}/${realWedding.photos[0]}`
              : '',
            realWeddingUrl: realWedding.url,
            weddingCreator: realWedding.createdBy,
            taggedSupplierId: realWeddingForm.linkedSupplier?.id,
          }),
        ),
        mergeMap(() => of(...successActions)),
        catchError((error: Error) => [appError({ error, feature }), deleteRWError(error)]),
      );
    }),
  );
