import i18next from 'i18next';
import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, merge, of } from 'rxjs';
import { filter, ignoreElements, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AuthActionTypes } from 'lib/auth/action-types';
import { Action } from 'lib/types';
import { IOnUserListenerAction, UserActionTypes } from 'lib/users/action-types';
import { onLanguageChanged } from '../actions';

export const onLanguageChangedEpic = (action$: Observable<Action>): Observable<any> => {
  const listenForLanguageChange$ = () =>
    new Observable((observer) => {
      i18next.on('languageChanged', (lng) => {
        observer.next(onLanguageChanged(lng));
      });
    });

  return merge(
    action$.pipe(
      filter((action) => action.type === 'APP_STARTED'),
      switchMap(() => listenForLanguageChange$()),
    ),
    // When logging out of cms we are resetting the Redux state to the initial state via
    // `resetStateOnSignOut`. This also triggers the action of `APP_START`, which in
    // turn creates a subscriber to the i18n `languageChange` event. In order not to
    // duplicate handlers, we are unsubscribing from the event when logging out.
    action$.pipe(
      filter((action) => action.type === AuthActionTypes.SIGN_OUT_SUCCESS),
      tap(() => i18next.off('languageChanged')),
      ignoreElements(),
    ),
  );
};

export const changeToUserLanguageEpic = (action$: Observable<IOnUserListenerAction>) =>
  action$.pipe(
    ofType(UserActionTypes.ON_USER_LISTENER),
    mergeMap(({ payload: user }) => {
      if (user?.l10n?.locale && getI18n().language !== user.l10n?.locale) {
        getI18n().changeLanguage(user.l10n.locale);
      }

      return of({ type: 'CHANGED_TO_USER_LANGUAGE', payload: user?.l10n?.locale || '' });
    }),
  );
