import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import { FetchHistoricRankingsAction, StatisticsActionTypes } from 'lib/statistics/action-types';
import { fetchHistoricRankingsSuccess } from 'lib/statistics/actions/fetch';
import { fetchHistoricalRankings } from 'lib/statistics/utils';
import { IEpicDeps } from 'lib/types';

export const getHistoricRankingsEpic = (
  action$: Observable<FetchHistoricRankingsAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(StatisticsActionTypes.FETCH_HISTORIC_RANKINGS),
    withLatestFrom(state$),
    mergeMap(([_, state]) =>
      from(
        fetchHistoricalRankings(
          [state.supplier.supplier?.id || ''],
          state.supplier.supplier?.address?.adminArea?.[0] || '',
        ),
      ).pipe(
        map((data) => fetchHistoricRankingsSuccess(data)),
        catchError((error: Error) => [
          { type: StatisticsActionTypes.FETCH_HISTORIC_RANKINGS_ERROR },
          appError({ error, feature: 'fetchHistoricRankings' }),
        ]),
      ),
    ),
  );
