import { AdminActionTypes } from 'lib/admin/action-types';

export const unlinkSupplierUserSuccess = (userId: string) => ({
  type: AdminActionTypes.UNLINK_USER_SUCCESS,
  payload: userId,
});

export const unlinkSupplierUser = (userId: string, removeAuth: boolean = false) => ({
  type: AdminActionTypes.UNLINK_USER,
  payload: { userId, removeAuth },
});
