import uuid from 'uuid-random';
import { IApplicationState } from 'lib/types';

interface IServerSideTrackProps {
  userId: string;
  anonymousId: string;
  isAnonymous: boolean;
  sessionId: string;
  previousPath: string;
  pageTitle: string;
  pathname: string;
}

/**
 * Get props required for server side analytics
 */
export const getServerSideAnalyticsProps = (state: IApplicationState): IServerSideTrackProps => {
  const {
    users: { viewer },
    app: {
      previousPath,
      pathname,
      device: { sessionId },
    },
  } = state;

  const pageTitle: string = window.document.title || '';

  return {
    userId: viewer?.id || '',
    anonymousId: (analytics && analytics.user && analytics.user().anonymousId()) || uuid(),
    isAnonymous: Boolean(viewer && viewer.isAnonymous),
    sessionId: sessionId || '',
    previousPath,
    pageTitle,
    pathname,
  };
};

export default getServerSideAnalyticsProps;
