import { ICollaboratorInviteType } from 'lib/auth/types';
import mapFBErrorToValidationError from 'lib/auth/utils/map-fb-error-to-validation-error';

/**
 * Checks if user has access to supplier
 * @method verifyPermissions
 * @param {string} email
 * @param {object} invite - if this is part of invite accepting flow
 * @returns {boolean} - returns boolean or throws error to be displayed to end user
 */
export const verifyPermissions = async (
  invite: ICollaboratorInviteType | null,
  email?: string | null,
) => {
  if (email) {
    const permissionError = mapFBErrorToValidationError('auth/no-permissions');
    const res = await fetch(`/api/access-control/verify-permissions`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, invite: invite?.invite || null }),
    });

    if (res.status === 200) {
      const response = await res.json();

      if (!response.valid) {
        const error = mapFBErrorToValidationError(response.errorId);
        throw error;
      }
    } else {
      throw permissionError;
    }
  }

  return true;
};
