import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeAll, mergeMap, withLatestFrom } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import { fetchStatisticsValueSuccess } from 'lib/statistics/actions';
import { StatisticsEndpoint } from 'lib/statistics/types';
import { fetchStatisticsValue, getCompetitorsData } from 'lib/statistics/utils';
import {
  getSupplierCountry,
  getSupplierCountryCode,
  getSupplierCounty,
} from 'lib/supplier/selectors';
import { IEpicDeps } from 'lib/types';
import { StatisticsActionTypes } from '../action-types';

// TODO: split to separate epics
export const fetchStatisticsAsyncEpic = (action$: Observable<any>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(
      StatisticsActionTypes.FETCH_STATISTICS_MARKET,
      StatisticsActionTypes.FETCH_STATISTICS_REACH,
    ),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const supplierId = state.supplier.supplier?.id!;
      const supplierCounty = getSupplierCounty(state)!;
      const supplierCountry = getSupplierCountry(state)!;
      const countryCodeISO = getSupplierCountryCode(state)!;

      if (!supplierId || !supplierCountry || !supplierCounty) return of();

      const promises: Promise<any>[] = [];

      let endpoints: StatisticsEndpoint[] = [];

      switch (action.type) {
        case StatisticsActionTypes.FETCH_STATISTICS_MARKET:
          endpoints = ['couple-budgets', 'competitor-list-shortlists'];
          break;
        case StatisticsActionTypes.FETCH_STATISTICS_REACH:
          endpoints = ['keyword-insights'];
          break;
        default:
      }

      endpoints.forEach((endpoint) => {
        const promise = fetchStatisticsValue(supplierId, endpoint).then(
          async (data: { name: string; value: any }) => {
            if (data.name === 'competitorList') {
              const value = await getCompetitorsData({
                supplierId,
                supplierCounty,
                supplierCountry,
                competitorList: data.value,
                countryCode: countryCodeISO,
              });

              return { name: 'competitorsData', value };
            }

            return data;
          },
        );

        promises.push(promise);
      });

      return from(promises).pipe(
        mergeAll(),
        map((payload) => fetchStatisticsValueSuccess(payload)),
        catchError((error) =>
          of(
            { type: StatisticsActionTypes.FETCH_STATISTICS_VALUE_ERROR },
            appError({ error, feature: 'fetchStatisticsAsyncEpic' }),
          ),
        ),
      );
    }),
  );
