import { pathOr } from 'ramda';
import { switchSupplier } from 'lib/access-control/actions';
import { AuthActionTypes } from 'lib/auth/action-types';
import { IDeps } from 'lib/types';
import { onUserListener } from 'lib/users/actions/user-listener';
import { getUsersViewer } from 'lib/users/selectors';

export const signOut =
  () =>
  ({ dispatch, getState }: IDeps) => {
    const email = pathOr('', ['contacts', 'email'], getUsersViewer(getState()));
    dispatch({ type: 'LOGOUT_ANALYTICS', payload: email });
    return {
      type: AuthActionTypes.SIGN_OUT,
    };
  };

export const signOutDone =
  () =>
  ({ dispatch }: IDeps) => {
    dispatch(onUserListener(null));
    dispatch(switchSupplier({ activeSupplierAccessControl: null }));

    return {
      type: AuthActionTypes.SIGN_OUT_SUCCESS,
    };
  };
