import { PremiumTiers } from 'lib/premium/constants';

const getVenueSearchAppearancesMultiplier = (tier: PremiumTiers) => {
  const multipliers = {
    [PremiumTiers.Unverified]: 0.5,
    [PremiumTiers.Tier_0]: 1,
    [PremiumTiers.Tier_1]: 1.7,
    [PremiumTiers.Tier_2]: 3.2,
    [PremiumTiers.Tier_2_5]: 3.2,
    [PremiumTiers.Tier_3]: 5.7,
    [PremiumTiers.Tier_4]: 8.2,
  };

  return multipliers[tier];
};

const calculateVenueSearchAppearancesValue =
  (supplierTier: PremiumTiers, graphTier: PremiumTiers) => (value: number) => {
    const calculated =
      value *
      (getVenueSearchAppearancesMultiplier(graphTier) /
        getVenueSearchAppearancesMultiplier(supplierTier));
    return Math.floor(calculated);
  };

const supplierSearchAppearancesMultipliers = (tier: PremiumTiers) => {
  const multipliers = {
    [PremiumTiers.Unverified]: 0.5,
    [PremiumTiers.Tier_0]: 1,
    [PremiumTiers.Tier_1]: 6.2,
    [PremiumTiers.Tier_2]: 15.2,
  };

  return multipliers[tier as keyof typeof multipliers] || 1;
};

const calculateSupplierSearchAppearancesValue =
  (supplierTier: PremiumTiers, graphTier: PremiumTiers) => (value: number) => {
    const calculated =
      value *
      (supplierSearchAppearancesMultipliers(graphTier) /
        supplierSearchAppearancesMultipliers(supplierTier));
    return Math.floor(calculated);
  };

export { calculateVenueSearchAppearancesValue, calculateSupplierSearchAppearancesValue };
