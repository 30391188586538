import { User as FirebaseUser } from 'firebase/auth';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { appError } from 'lib/app/actions';
import { AuthActionTypes } from 'lib/auth/action-types';
import { triggerAuthAnalytics } from 'lib/auth/analytics/actions';
import getFirebaseMessages from 'lib/auth/firebase-messages';
import { AuthBridebookError, IAuthFields } from 'lib/auth/types';
import mapFBErrorToValidationError from 'lib/auth/utils/map-fb-error-to-validation-error';
import { IDeps, IIntlMessageDescriptor } from 'lib/types';
import { createFirebaseUserAfterSignUp } from 'lib/users/actions/create-firebase-user-after-sign-up';
import createUserFirebase from 'lib/users/utils/create-user-firebase';

export const signInWithPassword = (fields: IAuthFields) => ({
  type: AuthActionTypes.SIGN_IN_WITH_PASSWORD,
  payload: { fields },
});

export const signInError =
  (error: AuthBridebookError, fields: IAuthFields) =>
  ({ dispatch, getState }: IDeps) => {
    const oneOfFirebaseErrors: IIntlMessageDescriptor = getFirebaseMessages()[error.code];

    if (oneOfFirebaseErrors) {
      error = mapFBErrorToValidationError(error.code);
    }
    dispatch(appError({ error, feature: 'Auth' }));

    const { collaboratorInvite } = getState().auth;
    if (collaboratorInvite?.invite?.id) {
      dispatch({
        type: 'FAILED_TO_CLAIM_SUPPLIER_PROFILE',
        payload: {
          supplierId: collaboratorInvite?.invite?.id,
          error,
        },
      });
    }

    dispatch({
      type: 'LOG_IN_ERROR_ANALYTICS',
      payload: {
        fields,
        error,
      },
    });

    return {
      type: AuthActionTypes.SIGN_IN_ERROR,
      payload: { error },
    };
  };

export const signInSignupSuccess =
  (
    firebaseUser: FirebaseUser | null,
    type: AuthActionTypes.SIGN_UP_SUCCESS | AuthActionTypes.SIGN_IN_SUCCESS,
  ) =>
  ({ dispatch, getState }: IDeps) => {
    const { collaboratorInvite } = getState().auth;

    if (firebaseUser && firebaseUser.uid) {
      /** Set Sentry context */
      SentryMinimal().setUser({ id: firebaseUser.uid });

      dispatch(triggerAuthAnalytics(firebaseUser, collaboratorInvite?.inviteSupplier));
      dispatch(createFirebaseUserAfterSignUp(firebaseUser, collaboratorInvite?.inviteSupplier));
    }

    return {
      type,
      payload: {
        user: createUserFirebase(firebaseUser),
      },
    };
  };
