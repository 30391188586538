import { isEmpty, keys } from 'ramda';
import { formatWeeklyDate, get12WeeksRange } from 'lib/statistics/utils';

const buildHistoricalRankingsCompetitorsCategories = (
  seriesData: Record<string, any>[],
): string[] => {
  const singleSupplierRanking = seriesData.find(
    (d) => d.historicalRankings && !isEmpty(d.historicalRankings),
  );

  if (singleSupplierRanking) {
    const real = keys(singleSupplierRanking.historicalRankings) as string[];
    return formatWeeklyDate(real);
  } else {
    const mock = get12WeeksRange();
    return formatWeeklyDate(mock);
  }
};

export default buildHistoricalRankingsCompetitorsCategories;
