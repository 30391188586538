import { getI18n } from 'react-i18next';
import { IIntlMessageDescriptor } from 'lib/types';

const getMessages = (): {
  [k: string]: IIntlMessageDescriptor;
} => {
  const i18n = getI18n();
  return {
    'auth/email-already-in-use': {
      defaultMessage: i18n.t('auth/email-already-in-use'),
      id: 'firebase.error.EMAIL_TAKEN',
    },
    'auth/invalid-email': {
      defaultMessage: i18n.t('auth/invalid-email'),
      id: 'firebase.error.INVALID_EMAIL',
    },
    'auth/user-not-found': {
      defaultMessage: i18n.t('auth/user-not-found'),
      id: 'firebase.error.INVALID_USER',
    },
    'auth/wrong-password': {
      defaultMessage: i18n.t('auth/wrong-password'),
      id: 'firebase.error.INVALID_PASSWORD',
    },
    'auth/network-request-failed': {
      defaultMessage: i18n.t('auth/network-request-failed'),
      id: 'firebase.error.auth/network-request-failed',
    },
    'auth/too-many-requests': {
      defaultMessage: i18n.t('auth/too-many-requests'),
      id: 'firebase.error.auth/too-many-requests',
    },
    'auth/user-token-expired': {
      defaultMessage: i18n.t('auth/user-token-expired'),
      id: 'firebase.error.auth/user-token-expired',
    },
    'auth/invalid-action-code': {
      defaultMessage: i18n.t('auth/invalid-action-code'),
      id: 'firebase.error.auth/invalid-action-code',
    },
    'auth/expired-action-code': {
      defaultMessage: i18n.t('auth/expired-action-code'),
      id: 'firebase.error.auth/expired-action-code',
    },
    'auth/no-permissions': {
      defaultMessage: i18n.t('auth/no-permissions'),
      id: 'auth/no-permissions',
    },
    'auth/existing-supplier': {
      defaultMessage: i18n.t('auth/existing-supplier'),
      id: 'existing-supplier',
    },
    'auth/deactivated': {
      defaultMessage: i18n.t('auth/deactivated'),
      id: 'deactivated',
    },
    'auth/account-exists-with-different-credential': {
      defaultMessage: i18n.t('auth/account-exists-with-different-credential'),
      id: 'account-exists-with-different-credential',
    },
  };
};

export default getMessages;
