import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import { FetchReviewsRatingAction, StatisticsActionTypes } from 'lib/statistics/action-types';
import { fetchReviewsRatingSuccess } from 'lib/statistics/actions/fetch';
import { fetchReviewsRating } from 'lib/statistics/utils';
import { IEpicDeps } from 'lib/types';

export const fetchReviewsRatingEpic = (
  action$: Observable<FetchReviewsRatingAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(StatisticsActionTypes.FETCH_REVIEWS_RATING),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const getPromise = async () => {
        const supplierId = state.supplier.supplier?.id;

        return fetchReviewsRating(supplierId);
      };

      return from(getPromise()).pipe(
        map((data) => fetchReviewsRatingSuccess(data)),
        catchError((error: Error) => [
          { type: StatisticsActionTypes.FETCH_REVIEWS_RATING_ERROR },
          appError({ error, feature: 'fetchReviewsRatingEpic' }),
        ]),
      );
    }),
    catchError((error) => of(appError({ error, feature: 'fetchReviewsRatingEpic' }))),
  );
