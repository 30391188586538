import { Photo } from '../photos/types';

export enum SortOptions {
  mostRecent = 'mostRecent',
  oldest = 'oldest',
  lowToHigh = 'lowToHigh',
  highToLow = 'highToLow',
}

export interface Review {
  createdAt: number;
  email: string;
  from: string;
  id: string;
  message: string;
  name: string;
  photo?: Photo;
  stars: string;
  title: string;
  weddingDate: string;
}
