import { StatisticsActionTypes } from '../action-types';

export const statisticsClearAll = () => ({
  type: StatisticsActionTypes.STATISTICS_CLEAR_ALL,
});

export const clickedReportAnalytics = (name: string) => ({
  type: 'CLICKED_REPORT_ANALYTICS',
  payload: { name },
});
