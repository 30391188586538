import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, ignoreElements, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { appError } from 'lib/app/actions';
import { IEpicDeps } from 'lib/types';
import { DeleteVideoAction, VideosActionTypes } from 'lib/videos/action-types';

export const deleteVideoEpic = (
  action$: Observable<DeleteVideoAction>,
  { state$, bbanalytics }: IEpicDeps,
) =>
  action$.pipe(
    ofType(VideosActionTypes.DELETE_VIDEO),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const { video, videoType } = payload;
      const activeSupplier = state.supplier.supplier;
      const id = video.sourceId;
      const type = video.source;

      if (!activeSupplier || !id || !type) return of();
      const isVideoTour = videoType === 'videoTour';

      const promise = Suppliers._.getById(activeSupplier.id).Videos.getById(video.id).delete();

      return from(promise).pipe(
        tap(() => !isVideoTour && bbanalytics.supplier.videos.deleted({ videoId: id })),
        catchError((error) =>
          of(appError({ error, feature: isVideoTour ? 'Video Tours' : 'Videos' })),
        ),
        ignoreElements(),
      );
    }),
  );
