import { Action } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';

export default function searchAnalytics(action: Action, bridebookAnalytics: CmsAnalyticsContext) {
  const payload = action.payload || {};

  const { track } = bridebookAnalytics.getMethods('CMS');

  switch (action.type) {
    case 'PERFORMED_SUPPLIER_SEARCH_BY_LOACTION_ANALYTICS': {
      track({
        event: 'Performed supplier search by location',
        pageCategoryWhenPerformedSupplierSearch: '/',
        searchedLocation: payload.area,
        searchedSupplierCategory: payload.category,
        boundingBoxSWlat: payload.swlat,
        boundingBoxSWlon: payload.swlon,
        boundingBoxNElat: payload.nelat,
        boundingBoxNElon: payload.nelon,
      });
      break;
    }

    case 'PERFORMED_SUPPLIER_SEARCH_BY_NAME_ANALYTICS': {
      track({
        event: 'Performed supplier search by name',
        pageCategoryWhenPerformedSupplierSearch: '/',
        searchedSupplierId: payload.sid,
        searchedSupplierName: payload.name,
        searchedSupplierCategory: payload.slug,
      });
      break;
    }
  }
}
