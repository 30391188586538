import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { AnalyticsServiceMiddleware } from 'lib/analytics/analytics-service';
import configureDeps from 'lib/configure-deps';
import { CustomPageContext, IApplicationState } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';
import AnalyticsMiddleware from './analyticsMiddleware';
import createReduxMiddleware from './create-redux-middleware';
import createReduxReducer from './create-redux-reducer';
import { env } from './env';

const analytics = null;

const createReduxStore = (initialState: IApplicationState, ctx: CustomPageContext) => {
  const deps = configureDeps();
  const state =
    !initialState.app && !process.browser ? { app: { device: ctx.req.device } } : initialState;

  const sessionId = state.app?.device ? state.app.device.sessionId : null;

  const bridebookAnalytics = new CmsAnalyticsContext(analytics, {
    sessionId,
    appVersion: env.PLATFORM_VERSION,
  });

  const analyticsMiddleware = AnalyticsMiddleware(bridebookAnalytics);
  const analyticsServiceMiddleware = AnalyticsServiceMiddleware(
    bridebookAnalytics,
    deps.bbanalytics._,
  );

  const reducer = createReduxReducer(initialState);
  const { middleware, rootEpic, epicMiddleware } = createReduxMiddleware(
    [analyticsMiddleware, analyticsServiceMiddleware],
    deps,
  );
  const store = createStore<IApplicationState>(
    reducer,
    initialState,
    composeWithDevTools(middleware),
  );

  deps.bbanalytics._.setDependencies(store.getState, bridebookAnalytics);

  if (process.browser) {
    epicMiddleware?.run(rootEpic);
    return { store, rootEpic, bridebookAnalytics };
  } else {
    return { store, bridebookAnalytics };
  }
};

export default createReduxStore;
