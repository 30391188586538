import {
  type Query,
  collectionGroup,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { Relations } from '@bridebook/models/source/models/Suppliers/Relations';
import { IRelation } from '@bridebook/models/source/models/Suppliers/Relations.types';
import { appError } from 'lib/app/actions';
import {
  IDeleteAllSupplierRelationsAction,
  RelationsActionTypes,
} from 'lib/relations/action-types';
import { deleteAllSupplierRelationsSuccess } from 'lib/relations/actions';
import { IEpicDeps } from 'lib/types';

export const deleteAllSupplierRelationsEpic = (
  action$: Observable<IDeleteAllSupplierRelationsAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(RelationsActionTypes.DELETE_ALL_SUPPLIER_RELATIONS),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const activeSupplier = state.users.activeSupplierAccessControl;
      if (!activeSupplier?.id) return of();
      const getPromise = async () => {
        const relationsQuery = query(
          collectionGroup(getFirestore(), Relations.path) as Query<IRelation>,
          where('id', '==', activeSupplier.id),
        );

        const relationEntities = await getDocs(relationsQuery);

        await Promise.all(
          relationEntities.docs.map((relationEntity) => {
            const supplierId = relationEntity.ref.parent?.parent?.id;
            if (supplierId) {
              const supplier = Suppliers._.getById(supplierId);
              const relations = new Relations(supplier);
              const relation = relations.getById(activeSupplier.id);
              return relation.delete();
            }
          }),
        );
        return;
      };

      return from(getPromise()).pipe(
        map(() => deleteAllSupplierRelationsSuccess()),
        catchError((error) =>
          of(appError({ error, feature: RelationsActionTypes.DELETE_ALL_SUPPLIER_RELATIONS })),
        ),
      );
    }),
  );
