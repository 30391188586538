export default function resolveInputType(input: {
  name?: string;
  value?: any;
  checked?: boolean;
}): boolean | number | string {
  let type: string | null = '';
  if (input instanceof HTMLInputElement) {
    type = input.getAttribute('type');
  }
  switch (type) {
    case 'checkbox':
      return input.checked as boolean;
    case 'number': {
      const val = parseInt(input.value, 10);
      return isNaN(val) ? 0 : Math.abs(val);
    }
    default:
      return input.value || '';
  }
}
