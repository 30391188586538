import { ISupplierAdmin } from '@bridebook/models/source/models/Suppliers/Admin/admin.types';
import { ISupplierAdminChargebee } from '@bridebook/models/source/models/Suppliers/Admin/chargebee.types';
import { ISupplierAdminScore } from '@bridebook/models/source/models/Suppliers/Admin/score.types';
import { ISupplierAdminStripe } from '@bridebook/models/source/models/Suppliers/Admin/stripe.types';
import { AdminActionTypes } from 'lib/admin/action-types';

export const onSupplierAdminFetch = ({
  admin,
  score,
  stripe,
  chargebee,
}: {
  admin: ISupplierAdmin;
  score: ISupplierAdminScore;
  stripe: ISupplierAdminStripe;
  chargebee?: ISupplierAdminChargebee;
}) => ({
  type: AdminActionTypes.ON_SUPPLIER_ADMIN_FETCH,
  payload: { admin, score, stripe, chargebee },
});
