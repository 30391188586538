import { values } from 'ramda';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import type {
  IQuestion,
  IQuestion_Translations,
} from '@bridebook/models/source/models/Suppliers/Questions.types';
import {
  getContentTranslationsForQuestions,
  getScopedContentTranslations,
} from 'lib/content-translations/selectors';
import { clearTempContentTranslations } from 'lib/content-translations/slice';
import type { ContentTranslationsForCollection } from 'lib/content-translations/types';
import type { IEpic } from 'lib/types';
import { appError } from '../../app/actions';
import { QuestionsActionTypes, type SaveFirestoreQuestionsAction } from '../action-types';
import type { IQuestionOrDraft } from '../types';

const getQuestionsToUpdate = (
  reduxQuestions: IQuestionOrDraft[],
  firebaseQuestions: IQuestion[],
  translationsToUpdate: ContentTranslationsForCollection<IQuestion_Translations>,
): IQuestionOrDraft[] => {
  const differentAnswers: IQuestionOrDraft[] = [];

  reduxQuestions.forEach((reduxQuestion) => {
    const matchingFirebaseQuestion = firebaseQuestions.find(
      (firebaseQuestion) => firebaseQuestion.id === reduxQuestion.id,
    );

    const matchingTranslationToUpdate = translationsToUpdate.some(
      ({ id }) => reduxQuestion.id === id,
    );

    if (
      !matchingFirebaseQuestion ||
      matchingFirebaseQuestion.answer !== reduxQuestion.answer ||
      matchingTranslationToUpdate
    ) {
      differentAnswers.push(reduxQuestion);
    }
  });

  return differentAnswers;
};

export const saveFirestoreQuestionsEpic: IEpic<SaveFirestoreQuestionsAction, any> = (
  action$,
  { state$ },
) =>
  action$.pipe(
    ofType(QuestionsActionTypes.SAVE_FIRESTORE_QUESTIONS),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const getPromise = async (): Promise<any> => {
        const {
          questions: { questions },
        } = state;
        const activeSupplier = state.users.activeSupplierAccessControl;
        if (!activeSupplier?.id) return of();
        const supplierQuestionsRef = Suppliers._.getById(activeSupplier.id).Questions;
        const fbQuestions = await supplierQuestionsRef.query().get();

        const translationsToUpdate = getContentTranslationsForQuestions(state);
        const questionsToUpdate = getQuestionsToUpdate(
          questions,
          values(fbQuestions),
          translationsToUpdate,
        );

        if (!questionsToUpdate.length) return of();

        supplierQuestionsRef.begin();

        for (const question of questionsToUpdate) {
          const { id, answer } = question;
          const hasAnswer = answer.trim() !== '';
          const _translations = getScopedContentTranslations<IQuestion_Translations>(
            state,
            `questions.${id}`,
            ['answer'],
          );

          const supplierQuestion = id
            ? supplierQuestionsRef.getById(id)
            : supplierQuestionsRef.push();

          if (hasAnswer) {
            const data = {
              ...question,
              ...(_translations && { _translations }),
            };

            if (id) {
              await supplierQuestion.set(data);
            } else {
              await supplierQuestion.create(data);
            }
          } else if (id) {
            await supplierQuestion.delete(false);
          } else {
            return of();
          }
        }

        return supplierQuestionsRef.commit();
      };

      return from(getPromise()).pipe(
        mergeMap(() => [
          {
            type: QuestionsActionTypes.SAVE_FIRESTORE_QUESTIONS_SUCCESS,
          },
          clearTempContentTranslations('questions'),
        ]),
        catchError((error) => [
          { type: QuestionsActionTypes.SAVE_FIRESTORE_QUESTIONS_ERROR },
          appError({ error, feature: 'Questions' }),
        ]),
      );
    }),
  );
