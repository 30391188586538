import { getI18n } from 'react-i18next';
import { AuthActionTypes } from 'lib/auth/action-types';
import getMessages from 'lib/auth/messages';
import { AuthBridebookError } from 'lib/auth/types';
import mapFBErrorToValidationError from 'lib/auth/utils/map-fb-error-to-validation-error';
import { IDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';

export const resetPassword = (fields: { email: string }) => ({
  type: AuthActionTypes.RESET_PASSWORD,
  payload: { fields },
});

export const resetPasswordSuccess =
  (fields: { email: string }) =>
  ({ dispatch }: IDeps) => {
    dispatch({
      type: 'FIREBASE_RESET_PASSWORD_SUCCESS_ANALYTICS',
      payload: fields,
    });
    dispatch(
      toggleSnackbar(
        'success',
        getI18n().t('common:passwordEmailSent.success', {
          userEmail: fields.email,
        }),
      ),
    );

    return {
      type: AuthActionTypes.RESET_PASSWORD_SUCCESS,
    };
  };

export const resetPasswordError =
  (fields: { email: string }, error: AuthBridebookError) =>
  ({ dispatch }: IDeps) => {
    const throwError = getMessages()[error.code] ? mapFBErrorToValidationError(error.code) : error;
    dispatch({
      type: 'FIREBASE_RESET_PASSWORD_ERROR_ANALYTICS',
      payload: { fields, error: throwError },
    });

    return {
      type: AuthActionTypes.RESET_PASSWORD_ERROR,
      payload: { error: throwError },
    };
  };
