import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { IBrochure } from '@bridebook/models/source/models/Suppliers/Brochures.types';
import { IFeedback } from '@bridebook/models/source/models/Suppliers/Feedback.types';
import { IRecommendation } from '@bridebook/models/source/models/Suppliers/Recommendations.types';
import { IVideo } from '@bridebook/models/source/models/Suppliers/Videos.types';
import { IWork } from '@bridebook/models/source/models/Suppliers/Works.types';
import type { PackagesSections } from '@bridebook/toolbox/src/map-packages-to-sections';
import { AnalyticsService } from 'lib/analytics/analytics-service';
import { AnalyticsEvents } from 'lib/analytics/events';
import { FormSections } from 'lib/supplier/types';

enum Section {
  Photos = 'photos',
  Pricing = 'pricing',
  ProfileDetails = 'profileDetails',
  Testimonials = 'testimonials',
  SupplierNetwork = 'supplierNetwork',
  Videos = 'videos',
  AboutMe = 'aboutMe',
  RealWeddings = 'realWeddings',
  Reviews = 'reviews',
}

type Properties = { updatedSection: Section };

export const analyticsSupplierService = (analytics: AnalyticsService) => {
  const event = AnalyticsEvents.SupplierUpdatedTheirProfile;

  const getCommonProperties = (section: Section) => () => ({
    updatedSection: section,
  });

  const getHandlers = <
    CreatePayload extends object | undefined = undefined,
    DeletePayload extends object | undefined = CreatePayload,
    EditPayload extends object | undefined = CreatePayload,
  >(
    section: Section,
  ) =>
    analytics.createHandlers<Properties, CreatePayload, DeletePayload, EditPayload>(
      event,
      getCommonProperties(section),
    );

  return {
    photos: getHandlers<IPhotos>(Section.Photos),
    pricing: getHandlers<IPricing>(Section.Pricing),
    profileDetails: getHandlers<IProfileDetails>(Section.ProfileDetails),
    testimonials: getHandlers<ITestimonials>(Section.Testimonials),
    supplierNetwork: getHandlers<ISupplierNetwork>(Section.SupplierNetwork),
    videos: getHandlers<IVideos>(Section.Videos),
    aboutMe: getHandlers<IAboutMe>(Section.AboutMe),
    realWeddings: {
      ...getHandlers<IRealWeddings>(Section.RealWeddings),
      tagged: analytics.createHandler<Properties, { taggedSupplierId: string }>(
        'tagged',
        event,
        getCommonProperties(Section.RealWeddings),
      ),
    },
    reviews: {
      ...getHandlers<IReviews>(Section.Reviews),
      responded: analytics.createHandler<Properties, IReviews>(
        'responded',
        event,
        getCommonProperties(Section.Reviews),
      ),
    },
  };
};

interface IReviews {
  reviewId: IFeedback['id'];
  supplierProfileURL: string;
}

interface IRealWeddings {
  realWeddingId: IWork['id'];
  weddingCreator: IWork['createdBy'];
  realWeddingUrl: IWork['url'];
  realWeddingPhotoUrl: IWork['photos'][0];
  taggedSupplierId: IWork['supplier'] | undefined;
}

interface IAboutMe {
  supplierProfileURL: string;
}

interface IVideos {
  videoId: IVideo['id'];
}

interface ISupplierNetwork {
  recommendedSupplierId: IRecommendation['id'];
  recommendedSupplierCategory: ISupplier['type'][0];
}

interface ITestimonials {
  supplierTestimonialId: IFeedback['id'];
}

interface IProfileDetails {
  updatedSubsection: FormSections;
}

interface IPricing {
  updatedSubsection: PackagesSections | FormSections;
  supplierBrochureId?: IBrochure['id'];
}

interface IPhotos {
  photoId: string;
}
