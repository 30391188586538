import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthActionTypes, IChangeAuthEmailAction } from 'lib/auth/action-types';
import { AuthBridebookError } from 'lib/auth/types';
import authValidate from 'lib/auth/utils/auth-validate';
import { IEpic } from 'lib/types';
import { updateUserField } from 'lib/users/actions/update-user';
import { changeAuthEmailError, changeAuthEmailSuccess } from '../actions';

export const changeAuthEmailEpic: IEpic<IChangeAuthEmailAction, any> = (
  action$,
  { firebaseAuth },
) =>
  action$.pipe(
    ofType(AuthActionTypes.CHANGE_AUTH_EMAIL),
    mergeMap(({ payload: { fields, oldEmail } }) => {
      const getPromise = async () => {
        await authValidate(fields);
        const credential = firebaseAuth.EmailAuthProvider.credential(
          oldEmail as string,
          fields.password,
        );
        const currentUser = firebaseAuth().currentUser;

        if (!currentUser) {
          throw new Error('firebaseAuth().currentUser is null');
        }

        await currentUser.reauthenticateWithCredential(credential);
        const response = await currentUser.updateEmail(fields.email);
        return { fields, response };
      };

      return from(getPromise()).pipe(
        mergeMap(({ fields }) =>
          of(updateUserField('contacts', { email: fields.email }), changeAuthEmailSuccess(fields)),
        ),
        catchError((error: AuthBridebookError) => of(changeAuthEmailError(fields, error))),
      );
    }),
  );
