import produce from 'immer';
import { addressHasLine1 } from 'lib/get-listed/utils/address-has-line-1';
import { Action, IReducersImmer } from 'lib/types';
import {
  GetListedActionTypes,
  IGetListedErrorAction,
  IGetListedValidateStep1ErrorAction,
  IOnGetListedFormFieldChangeAction,
  ISetGetListedLocationAction,
} from './action-types';
import GetListedForm from './get-listed-form';
import { IGetListedState } from './types';

const initialState: IGetListedState = {
  getListedForm: GetListedForm,
  getListedFormSuccess: false,
};

const reducers: IReducersImmer<IGetListedState> = (draft) => ({
  [GetListedActionTypes.ON_GET_LISTED_FORM_FIELD_CHANGE]: (
    action: IOnGetListedFormFieldChangeAction,
  ) => {
    const { name, value } = action.payload;
    draft.getListedForm.fields[name] = value;
    draft.getListedForm.error = null;
    if (name === 'address') {
      draft.getListedForm.addressSetFromGeoCode = false;
    }
  },

  [GetListedActionTypes.GET_LISTED_VALIDATE_STEP1_ERROR]: (
    action: IGetListedValidateStep1ErrorAction,
  ) => {
    const { error } = action.payload;
    draft.getListedForm.error = error;
    draft.getListedForm.disabled = false;
  },

  [GetListedActionTypes.GET_LISTED_VALIDATE_STEP1_SUCCESS]: () => {
    draft.getListedForm.step = 2;
    draft.getListedForm.error = null;
  },

  [GetListedActionTypes.GET_LISTED_START]: () => {
    draft.getListedForm.disabled = true;
    draft.getListedForm.error = null;
  },

  [GetListedActionTypes.GET_LISTED_SUCCESS]: () => {
    draft.getListedForm = GetListedForm;
    draft.getListedFormSuccess = true;
    draft.getListedForm.error = null;
  },

  [GetListedActionTypes.GET_LISTED_ERROR]: (action: IGetListedErrorAction) => {
    const { error } = action.payload;
    draft.getListedForm.error = error;
    draft.getListedForm.disabled = false;
  },

  [GetListedActionTypes.RESET_GET_LISTED]: () => {
    draft.getListedForm = GetListedForm;
    draft.getListedFormSuccess = false;
  },

  [GetListedActionTypes.SET_GET_LISTED_LOCATION]: (action: ISetGetListedLocationAction) => {
    const {
      locality,
      postal_code: postcode,
      postal_town: postalTown,
      lat,
      lng,
      address,
      administrative_area_level_2: aaLevel2,
      administrative_area_level_1: aaLevel1,
    } = action.payload || {};

    const substrUntil = address?.indexOf(',') || address?.length;
    const line1 = address?.slice(0, substrUntil);
    const addressLine1 = addressHasLine1(line1 ?? '', action.payload) ? line1 : '';
    const finalPostcode = postcode || '';
    const finalTown = locality || postalTown || '';
    const finalCounty = aaLevel2 || '';

    draft.getListedForm.addressSetFromGeoCode = true;
    draft.getListedForm.fields.address = addressLine1 ?? '';
    draft.getListedForm.fields.town = finalTown;
    draft.getListedForm.fields.county = finalCounty;
    draft.getListedForm.fields.countryName = aaLevel1 || '';
    draft.getListedForm.fields.postcode = finalPostcode;
    draft.getListedForm.fields.lat = lat || 0;
    draft.getListedForm.fields.lng = lng || 0;

    draft.getListedForm.postcodeSetFromGeoCode = !!finalPostcode;
    draft.getListedForm.townSetFromGeoCode = !!finalTown;
    draft.getListedForm.countySetFromGeoCode = !!finalCounty;
  },
});

/*
	This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: IGetListedState = initialState, action: Action): IGetListedState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
