import {
  marketingBannerClickedAnalytics,
  marketingBannerClosedAnalytics,
} from 'lib/ui/analytics/actions';

export enum UIAnalyticsActionTypes {
  MARKETING_BANNER_CLICKED = '[Analytics] [UI] Marketing banner clicked',
  MARKETING_BANNER_CLOSED = '[Analytics] [UI] Marketing banner closed',
}

export type MarketingBannerClickedAction = ReturnType<typeof marketingBannerClickedAnalytics>;
export type MarketingBannerClosedAction = ReturnType<typeof marketingBannerClosedAnalytics>;
