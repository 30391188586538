import { ofType } from 'redux-observable';
import { type Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { appError } from 'lib/app/actions';
import { YourCouplesActionTypes } from 'lib/couples/action-types';
import type { Action, IEpicDeps } from 'lib/types';
import { fetchInviteMessageSuccess } from '../actions/contact-couple';

export const fetchInviteMessage = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(YourCouplesActionTypes.VIEW_INVITE_MESSAGE),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const supplierId = state.supplier?.supplier?.id;
      if (!supplierId) return of();
      const { weddingId } = action.payload;

      const getPromise = async () =>
        (await Suppliers._.getById(supplierId).Weddings.getByWeddingId(weddingId))?.inviteMessage ??
        null;

      return from(getPromise()).pipe(
        mergeMap((payload) => {
          if (!payload) return of();
          return of(fetchInviteMessageSuccess(payload));
        }),
        catchError((error) => of(appError({ error, feature: 'Contact Couples' }))),
      );
    }),
  );
