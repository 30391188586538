import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, mergeMap, pluck } from 'rxjs/operators';
import { authenticatedPOST } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import type { CollaboratorsPerSupplier, RequestPayload } from 'pages/api/nonce/fetch-collaborators';
import { AccessControlActionTypes, ISwitchSupplierAction } from 'lib/access-control/action-types';
import { fetchCollaboratorsDataSuccess } from 'lib/access-control/actions';
import { appError } from 'lib/app/actions';
import { IEpic } from 'lib/types';

export const fetchCollaboratorsData: IEpic<ISwitchSupplierAction, any> = (action$) =>
  action$.pipe(
    ofType(AccessControlActionTypes.SWITCH_SUPPLIER),
    pluck('payload'),
    mergeMap(({ activeSupplierAccessControl }) => {
      if (!activeSupplierAccessControl?.id || activeSupplierAccessControl.role === 'viewer') {
        return of();
      }

      const getPromise = async () => {
        const response = await authenticatedPOST<RequestPayload, CollaboratorsPerSupplier[]>(
          `/api/nonce/fetch-collaborators`,
          {
            body: {
              supplierId: activeSupplierAccessControl.id,
            },
          },
        );

        return response;
      };

      return from(getPromise()).pipe(
        mergeMap((collaborators) => {
          if (collaborators) {
            return of(fetchCollaboratorsDataSuccess(collaborators));
          }

          return of();
        }),
        catchError((error: Error) =>
          of(appError({ error, feature: 'Access Control fetch collaborators data' })),
        ),
      );
    }),
    catchError((error: Error) =>
      of(appError({ error, feature: 'Access Control fetch collaborators data' })),
    ),
  );
