import type {
  ISupplierSpecialOffersGeneric,
  ISupplierSpecialOffersLateAvailability,
} from '@bridebook/models/source/models/RTDB/Suppliers.types';
import type { Slug } from '@bridebook/toolbox/src/types';
import { TrackFunction } from 'lib/analytics-utils';
import { OfferDiscountTypes, OfferTypes } from 'lib/offers/constants';
import { getOffersForm, getOffersType } from 'lib/offers/selectors/offers';
import { PremiumTiers } from 'lib/premium/constants';
import {
  getSupplierId,
  getSupplierName,
  getSupplierTier,
  getSupplierType,
} from 'lib/supplier/selectors';
import { Action, IApplicationState } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';
import { IOffersAnalyticsActionTypes, ISaveSpecialOfferAnalyticsAction } from './action-types';

interface ISpecialOffersDetailsGeneric {
  specialOfferType: OfferTypes;
  specialOfferTitle: string;
  specialOfferDetails: string;
  specialOfferDeadline: number;
  specialOfferDates: Array<number>;
  specialOfferDiscountType?: OfferDiscountTypes;
  supplierTier: PremiumTiers;
  supplierCategory?: Slug;
  supplierId: string;
  supplierName: string;
}

const getSpecialOffersDetailsGeneric = (state: IApplicationState): ISpecialOffersDetailsGeneric => {
  const form = getOffersForm(state);
  const type = getOffersType(state);
  const supplierCategory = getSupplierType(state);
  const supplierTier = getSupplierTier(state);
  const supplierId = getSupplierId(state) || '';
  const supplierName = getSupplierName(state) || '';

  return {
    specialOfferType: type as OfferTypes,
    specialOfferTitle: form.title || '',
    specialOfferDetails: form.details || '',
    specialOfferDeadline: (form as ISupplierSpecialOffersGeneric).expiryDate || 0,
    specialOfferDates: (form as ISupplierSpecialOffersLateAvailability).lateDates || [],
    // @ts-ignore - this requires refactoring from RTDB to Firestore types
    specialOfferDiscountType: form.discountType,
    supplierCategory,
    supplierTier,
    supplierId,
    supplierName,
  };
};

const trackCalls = (track: TrackFunction, state: IApplicationState): { [k: string]: any } => ({
  [IOffersAnalyticsActionTypes.SAVE_SPECIAL_OFFER_ANALYTICS]: (
    action: ISaveSpecialOfferAnalyticsAction,
  ) => {
    const form = getOffersForm(state);
    const type = getOffersType(state);

    const { previousOffer } = action.payload;

    const isUpdate = !!previousOffer;
    const expiryDateUpdated =
      isUpdate &&
      (previousOffer as ISupplierSpecialOffersGeneric).expiryDate !==
        (form as ISupplierSpecialOffersGeneric).expiryDate;

    if (type) {
      track({
        event: isUpdate ? 'Supplier updated special offer' : 'Supplier added special offer',
        ...getSpecialOffersDetailsGeneric(state),
        expiryDateUpdated,
      });
    }
  },

  [IOffersAnalyticsActionTypes.DELETE_SPECIAL_OFFER_ANALYTICS]: () => {
    const type = getOffersType(state);

    if (type) {
      track({
        event: 'Supplier deleted special offer',
        ...getSpecialOffersDetailsGeneric(state),
      });
    }
  },
});

export default function offersAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => IApplicationState,
) {
  const { track } = bridebookAnalytics.getMethods('CMS');

  try {
    trackCalls(track, getState())[action.type](action);
  } catch {
    // do nothing
  }
}
