import { serverTimestamp } from 'firebase/firestore';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { appError } from 'lib/app/actions';
import { SupplierStatusActionTypes } from 'lib/supplier-status/action-types';
import {
  addSupplierStatusAnalytics,
  editSupplierStatusAnalytics,
  removeSupplierStatusAnalytics,
  updateSupplierStatusSuccess,
} from 'lib/supplier-status/actions';
import { Action, IEpicDeps } from 'lib/types';

export const supplierStatusUpdateEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(SupplierStatusActionTypes.UPDATE_SUPPLIER_STATUS),
    withLatestFrom(state$),
    mergeMap(([{ payload: statusText }, state]) => {
      const {
        supplier: { supplier },
      } = state;
      if (!supplier) {
        return of();
      }
      const status = supplier?.status;
      const createdAt = status?.createdAt;
      const message = status?.message;
      const supplierId = supplier?.id;
      const isPreviousUpdate = message && message.length > 0;
      const isRemoved = isPreviousUpdate && statusText.length === 0;

      const statusUpdateAnalytics = () => {
        if (isRemoved) {
          return removeSupplierStatusAnalytics();
        }
        if (isPreviousUpdate) {
          return editSupplierStatusAnalytics();
        }
        return addSupplierStatusAnalytics();
      };

      const supplierEntity = Suppliers._.getById(supplierId);
      const timestamp = serverTimestamp();

      const updatedStatus = {
        createdAt: isPreviousUpdate ? createdAt : timestamp,
        message: statusText,
        updatedAt: timestamp,
      };

      return from(supplierEntity.set({ status: updatedStatus }, true)).pipe(
        mergeMap(() => of(updateSupplierStatusSuccess(), statusUpdateAnalytics())),
        catchError((error) =>
          of(appError({ error, feature: SupplierStatusActionTypes.UPDATE_SUPPLIER_STATUS })),
        ),
      );
    }),
  );
