import { SupplierUsers, Suppliers } from '@bridebook/models';
import type { IUser as ISupplierACLUser } from '@bridebook/models/source/models/Suppliers/Users.types';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';

export async function getUserAccessControl(uid?: string) {
  try {
    if (!uid) {
      throw new Error('AccessControl. uid is missing!');
    }

    const user = await SupplierUsers._.getById(uid).get();
    const suppliers = user?.suppliers || [];

    if (suppliers.length < 1) {
      return null;
    }

    const promises: Promise<ISupplierACLUser | null>[] = [];

    for (const id of suppliers) {
      const promise = async () => {
        const supplier = Suppliers._.getById(id);
        const supplierData = await supplier.get();

        if (!supplierData?.id) {
          return null;
        }

        const acl = await supplier.Users.getById(uid).get();

        return {
          ...acl,
          id: supplierData.id,
        } as ISupplierACLUser;
      };

      promises.push(promise());
    }

    return (await Promise.all(promises)).filter((value) => value != null) as ISupplierACLUser[];
  } catch (error) {
    if (error instanceof Error) {
      SentryMinimal().captureException(error);
    }

    throw error;
  }
}
