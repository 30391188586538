import isThisYear from 'date-fns/isThisYear';
import isToday from 'date-fns/isToday';
import Router from 'next/router';
import { isEmpty } from 'ramda';
import { getI18n } from 'react-i18next';
import {
  authenticatedFetch,
  authenticatedGET,
} from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { getDayOrdinal } from '@bridebook/toolbox/src/datepicker/get-day-ordinal';
import type { IDatePickerUI } from '@bridebook/toolbox/src/datepicker/types';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { EnquiryWidgetLocales } from '@bridebook/toolbox/src/i18n';
import type { IGetWeddingsResponse } from '@bridebook/toolbox/src/inbox/api/utils/types';
import type { IGetWeddingResponse } from 'pages/api/inbox/wedding/[...params]';
import getDatepickerTitle, {
  type IGetDatePickerTitleLabels,
} from 'lib/datepicker/utils/get-datepicker-title';
import mapToDatepickerObject from 'lib/datepicker/utils/map-to-datepicker-object';
import mapToExactDate from 'lib/datepicker/utils/map-to-exact-date';
import { getLocale } from 'lib/i18n/utils/get-locale';
import { UrlHelper } from 'lib/url-helper';
import { trimString } from 'lib/utils';
import { formatI18nDate } from 'lib/utils/date-fns';
import type { ISupplierWeddingManager, TEnquirySource } from '../types';

const defaultLocale = gazetteer.getMarketByCountry(CountryCodes.GB).locale;
export const getCreatedAtString = (ts: number, locale = defaultLocale): string => {
  let dateString: string;

  if (isToday(ts)) {
    dateString = formatI18nDate(ts, locale.includes('en-') ? 'h:mma' : 'H:mm', locale);
  } else if (isThisYear(ts) && locale.includes('en-')) {
    // This may not be necessary to pass `getDayOrdinal` but adding it in case
    // the implementation changes
    dateString = formatI18nDate(ts, `MMMM ${getDayOrdinal(locale)}`, locale);
  } else if (ts === 0) {
    dateString = '-';
  } else {
    dateString = formatI18nDate(ts, 'd/M/yyyy', locale, true);
  }

  return dateString;
};

export const getPartnersNames = (
  partnerName1: string,
  partnerName2: string,
  long = false,
): string => {
  const partner1 = trimString(partnerName1);
  const partner2 = trimString(partnerName2);
  const partner2String = partner2
    ? partner1
      ? ` ${long ? getI18n().t('common:and') : '&'} ${partner2}`
      : partner2
    : '';

  return `${partner1}${partner2String}`;
};

export const getPartnersNamesInitials = (partnerName1: string, partnerName2: string) =>
  `${partnerName1[0].toUpperCase()}&${partnerName2[0].toUpperCase()}`;

export const getWeddingDateTimestamp = (enquiry: ISupplierWeddingManager): number => {
  const datepickerSource = !isEmpty(enquiry.weddingDateDatePicker)
    ? mapToExactDate(enquiry.weddingDateDatePicker) || null
    : null;

  const source = enquiry.weddingDate ? Number(enquiry.weddingDate) : datepickerSource;

  return source ? new Date(source).valueOf() : 0;
};

export const getWeddingDateString = (
  enquiry: ISupplierWeddingManager,
  locale = defaultLocale,
  labels?: Partial<IGetDatePickerTitleLabels>,
): string | null => {
  const isUK = locale === defaultLocale;
  const datepickerSource = !isEmpty(enquiry.weddingDateDatePicker)
    ? enquiry.weddingDateDatePicker
    : null;

  const weddingDateSource = enquiry.weddingDate ? mapToDatepickerObject(enquiry.weddingDate) : null;
  const source = isUK ? datepickerSource || weddingDateSource : datepickerSource;
  return source
    ? getDatepickerTitle({ datePickerUI: source as IDatePickerUI, locale, labels })
    : null;
};

/**
 * Returns string representation of ownerFilter used in analytics and API call
 * @param ownerFilter
 */
export const getOwnerFilterValue = (ownerFilter: string | null | undefined) =>
  ownerFilter || (ownerFilter === null ? 'none' : 'all');

interface FetchEnquiriesPromiseType {
  id: string;
  page: number;
  status: string;
  ownerFilter: string;
}

export const fetchEnquiriesPromise = ({
  id,
  page,
  status = 'inprogress',
  ownerFilter = 'all',
}: FetchEnquiriesPromiseType): Promise<IGetWeddingsResponse> =>
  authenticatedFetch(`/api/inbox/weddings/${id}/${page}/${status}/${ownerFilter}`).then((res) =>
    res.json(),
  );

interface FetchEnquiryPromiseType {
  id: string;
  eid: string;
  status: string;
}

export const fetchEnquiryPromise = async ({ id, eid, status }: FetchEnquiryPromiseType) => {
  try {
    // Await required to catch errors
    return await authenticatedGET<IGetWeddingResponse>(`/api/inbox/wedding/${id}/${eid}/${status}`);
  } catch (error) {
    await Router.replace(UrlHelper.couples.enquiries.manager);
  }
};

export const getEnquirySourceTitle = ({ source }: { source: TEnquirySource }) => {
  switch (source) {
    case 'widget':
      return 'Bridebook widget';
    case 'concierge':
      return 'Bridebook Concierge';
    default:
      return 'Bridebook';
  }
};

export const isRevealed = (enquiry: ISupplierWeddingManager) =>
  enquiry?.flags?.revealed || enquiry?.source === 'widget';

/**
 * True if first enquiry was sent while supplier Pay Per Enquiry enabled
 */
export const isEnquiryPayable = (enquiry: ISupplierWeddingManager) =>
  enquiry?.flags?.isPayPerEnquiry;

export const getEnquiryWidgetLocale = () => {
  const locale = getLocale() as string;
  const widgetLocales = Object.values(EnquiryWidgetLocales) as string[];

  // When using GB, the enquiry widget URL also accepts no locale, e.g.
  // https://enquiry.bridebook.com
  if (widgetLocales.includes(locale) && locale !== EnquiryWidgetLocales.GB) {
    return locale;
  }

  return '';
};

// These countries have the phone hidden for free VP
const countriesWithHiddenPhone: CountryCodes[] = [
  CountryCodes.GB,
  CountryCodes.DE,
  CountryCodes.PL,
];

// These countries have the email hidden for free VP
const countriesWithHiddenEmail: CountryCodes[] = [CountryCodes.FR];
const countriesVenueWithHiddenEmail: CountryCodes[] = [CountryCodes.DE];

// These supplier type for DE have the phone hidden for free VP
const supplierTypeWithHiddenPhone = ['venue'];

export const getShouldPayToSeePhone = (countryCode: CountryCodes) =>
  countriesWithHiddenPhone.includes(countryCode);

export const getShouldPayToSeeEmail = (countryCode: CountryCodes) =>
  countriesWithHiddenEmail.includes(countryCode);
export const getVenuesShouldPayToSeeEmail = (isVenue: boolean, countryCode: CountryCodes) =>
  isVenue && countriesVenueWithHiddenEmail.includes(countryCode);
// UK suppliers for free VP have all contact details hidden for DE only venues have hidden all contact details rest of the
// suppliers have only email hidden
export const getShouldSpecificSupplierTypePayToSeePhone = (isUK: boolean, supplierType?: string) =>
  isUK ? isUK : supplierTypeWithHiddenPhone.includes(supplierType || '');

export { getEnquiryProgress } from './get-enquiry-progress';
export { mapProgressArrayToAnalyticsObject } from './map-progress-array-to-analytics-object';
export { getProgressTrackerConfig } from './get-progress-tracker-config';
export { updateEnquiryProgress } from './update-enquiry-progress';
