import { getI18n } from 'react-i18next';
import { IBadge } from '@bridebook/models/source/models/Suppliers/Badges.types';
import { ValidationError } from '@bridebook/toolbox/src';
import { CollaboratorsPerSupplier } from 'pages/api/nonce/fetch-collaborators';
import { Badges } from 'lib/settings/types';
import { IDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';
import { SettingsActions } from './action-types';
import { IFormFields } from './types';
import { submitLinkedAccount } from './utils';

export function resetSettings() {
  return {
    type: SettingsActions.RESET_SETTINGS,
  };
}

export function startChangingEmail() {
  return {
    type: SettingsActions.CHANGE_EMAIL,
  };
}

export function onBadgesChange(value: string) {
  return {
    type: SettingsActions.ON_BADGES_CHANGE,
    payload: value,
  };
}

export function linkedUserErrorReset() {
  return {
    type: SettingsActions.LINKED_USER_ERROR_RESET,
  };
}

export function toggleInviteCollabPopup(extraInfo?: {
  supplierName: string;
  supplierId: string;
  supplierType: string;
}) {
  return ({ getState, dispatch }: IDeps) => {
    dispatch(linkedUserErrorReset());
    const displayPopup = getState().settings.showInviteCollabPopup;
    if (!displayPopup) {
      const path = getState().app.pathname;
      dispatch(clickedInviteCollaboratorAnalytics({ location: path }));
    }

    return {
      type: SettingsActions.TOGGLE_INVITE_COLLAB_POPUP,
      payload: extraInfo ? { display: !displayPopup, extraInfo } : !displayPopup,
    };
  };
}

export const clickedInviteCollaboratorAnalytics = (payload: { location: string }) => ({
  type: 'CLICKED_INVITE_COLLABORATOR_ANALYTICS',
  payload,
});

export function resetLinkSuccessMessage() {
  return {
    type: SettingsActions.RESET_LINK_SUCCESS_MESSAGE,
  };
}

export function submitLinkedUserAccount(formFields: IFormFields) {
  return (deps: IDeps) => {
    const getPromise = async () => {
      try {
        await submitLinkedAccount(deps, formFields);
        const {
          supplier: { supplier },
          settings: {
            linkedAccountForm: { email, permissionLevel, supplierName },
          },
        } = deps.getState();
        deps.dispatch(
          toggleSnackbar(
            'success',
            supplierName || (supplier && supplier.name)
              ? getI18n().t('profileSettings:inviteCollaboratorSuccess', {
                  email,
                  permissionLevel,
                  supplierName: supplierName || (supplier && supplier.name),
                  context: 'hasSupplierName',
                })
              : getI18n().t('profileSettings:inviteCollaboratorSuccess', {
                  email,
                  permissionLevel,
                }),
          ),
        );
      } catch (error) {
        deps.dispatch(toggleSnackbar('alert', error.message));
        deps.dispatch({
          type: 'SUBMIT_LINKED_USER_ACCOUNT_ERROR_ANALYTICS',
          payload: {
            collaboratorInvite: null,
            collaboratorPermissions: formFields.permissionLevel,
            collaboratorEmail: formFields.email,
            failedToInviteCollaboratorReason: error.message,
          },
        });
        throw new ValidationError(error.message, error.prop);
      }
    };

    return {
      type: 'SUBMIT_LINKED_USER_ACCOUNT',
      payload: {
        promise: getPromise(),
      },
    };
  };
}

export function setLinkedAccountData(name: string, value: string) {
  return ({ dispatch }: IDeps) => {
    dispatch(linkedUserErrorReset());
    return {
      type: SettingsActions.SET_LINKED_ACCOUNT_DATA,
      payload: {
        name,
        value,
      },
    };
  };
}

export function copiedSupplierBadgeCodeAnalytics(
  supplierBadgeType: Badges | IBadge['type'],
  supplierBadgeName?: string,
) {
  return {
    type: 'COPIED_SUPPLIER_BADGE_CODE_ANALYTICS',
    payload: { supplierBadgeType, supplierBadgeName },
  };
}

export const triggerEnquiry =
  () =>
  ({ dispatch }: IDeps) => {
    dispatch({
      type: 'CONTACT_SUPPLIER_ANALYTICS',
    });

    return {
      type: 'CONTACT_SUPPLIER',
    };
  };

export function resetSettingError() {
  return {
    type: 'RESET_SETTINGS_ERROR',
  };
}

export const startUpdateUserRole = () => ({
  type: SettingsActions.START_UPDATE_USER_ROLE,
});

export const updateUserRole = (id: string, role: string, supplierId: string) => ({
  type: SettingsActions.UPDATE_USER_ROLE,
  payload: { id, role, supplierId },
});

export const updatedUserRoleSuccess = (data: CollaboratorsPerSupplier[]) => ({
  type: SettingsActions.UPDATE_USER_ROLE_SUCCESS,
  payload: data,
});
