import { IUser } from '@bridebook/models/source/models/Suppliers/Users.types';
import { AccessControlActionTypes } from 'lib/access-control/action-types';
import { IACLUserExtended } from 'lib/access-control/types';
import { Action, ActionCreatorWithDeps, IDeps } from 'lib/types';

export const onUserAccessControlListener = (userAccessControl: Record<string, IUser>) => ({
  type: AccessControlActionTypes.ON_USER_ACCESS_CONTROL_LISTENER,
  payload: userAccessControl,
});

export const onSupplierAccessControlListener = (acl: Record<string, IUser>) => ({
  type: AccessControlActionTypes.ON_SUPPLIER_ACCESS_CONTROL_LISTENER,
  payload: acl,
});

export const updateAccessControlSuccess: ActionCreatorWithDeps<Action> =
  ({
    activeSupplierAccessControl,
    userAccessControl,
    isAdmin,
  }: {
    activeSupplierAccessControl: IUser;
    userAccessControl: Record<string, IACLUserExtended>;
    isAdmin: boolean;
  }) =>
  ({ dispatch }: IDeps) => {
    dispatch({ type: AccessControlActionTypes.SET_ADMIN, payload: isAdmin });
    dispatch(switchSupplier({ activeSupplierAccessControl, isAdmin }));

    return {
      type: AccessControlActionTypes.UPDATE_ACCESS_CONTROL_SUCCESS,
      payload: { userAccessControl, activeSupplierAccessControl },
    };
  };

export const switchPermission = (permission: IACLUserExtended) => ({
  type: AccessControlActionTypes.SWITCH_PERMISSION_START,
  payload: permission.id,
});

export const switchSupplier = ({
  activeSupplierAccessControl,
  isAdmin,
}: {
  activeSupplierAccessControl: IUser | null;
  isAdmin?: boolean;
}) => ({
  type: AccessControlActionTypes.SWITCH_SUPPLIER,
  payload: { activeSupplierAccessControl, isAdmin },
});

export const updateExtendedActiveSupplierACL = ({
  extendedSupplierACL,
  activeSupplierAccessControl,
}: {
  extendedSupplierACL: Record<string, IACLUserExtended>;
  activeSupplierAccessControl: IUser | null;
}) => ({
  type: AccessControlActionTypes.EXTEND_ACCESS_CONTROL_SUCCESS,
  payload: { extendedSupplierACL, activeSupplierAccessControl },
});
