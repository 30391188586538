import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { getUrlCoupleSideLocale } from '@bridebook/toolbox/src/getSupplierUrl';
import { AuthActionTypes, IResetPasswordAction } from 'lib/auth/action-types';
import { AuthBridebookError } from 'lib/auth/types';
import { findCountryCodeByLocale } from 'lib/i18n/utils';
import { IEpic } from 'lib/types';
import { resetPasswordError, resetPasswordSuccess } from '../actions';

export const resetPasswordEpic: IEpic<IResetPasswordAction, any> = (
  action$,
  { firebaseAuth, validate, state$ },
) =>
  action$.pipe(
    ofType(AuthActionTypes.RESET_PASSWORD),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { fields },
        },
        state,
      ]) => {
        const getPromise = async () => {
          const { i18n } = state;
          await validate(fields).prop('email').required().email().promise;

          const countryCode = findCountryCodeByLocale(i18n.language);
          const defaultAuth = firebaseAuth();
          defaultAuth.languageCode = getUrlCoupleSideLocale(countryCode);

          const response = await defaultAuth.sendPasswordResetEmail(fields.email, {
            url: `${window.location.origin}`,
          });

          return { response, fields };
        };

        return from(getPromise()).pipe(
          mergeMap(({ fields }) => of(resetPasswordSuccess(fields))),
          catchError((error: AuthBridebookError) => of(resetPasswordError(fields, error))),
        );
      },
    ),
  );
