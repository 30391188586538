import produce from 'immer';
import { defaultLocale } from '@bridebook/toolbox/src/gazetteer';
import { Action, IReducersImmer } from 'lib/types';
import { I18nActionTypes } from './action-types';
import { II18nState } from './types';

const initialState: II18nState = {
  language: defaultLocale,
};

const reducers: IReducersImmer<II18nState> = (draft) => ({
  [I18nActionTypes.ON_CHANGED_LANGUAGE]: (action: { payload: string }) => {
    draft.language = action.payload;
  },
});

/*
	This is a wrapper function which runs a proper reducer from the object above.
*/

const reducer = (state: II18nState = initialState, action: Action): II18nState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
