import {
  onFirestoreOffers,
  removeLateAvailabilityDate,
  saveSpecialOffer,
  saveSupplierDiscount,
  setDescription,
  setDiscount,
  setDiscountType,
  setExpiryDate,
  setInitialForm,
  setLateAvailabilityDate,
  setSpecialOffersType,
  setTitle,
  showError,
  toggleInitialised,
} from 'lib/offers/actions';

export enum OffersActionTypes {
  SET_TITLE = '[Offers] Set title',
  SET_DISCOUNT = '[Offers] Set discount',
  SET_DISCOUNT_TYPE = '[Offers] Set discount type',
  SET_EXPIRY_DATE = '[Offers] Set expiry date',
  SET_LATE_AVAILABILITY = '[Offers] Set late availability date',
  REMOVE_LATE_AVAILABILITY = '[Offers] Remove late availability date',
  SET_DESCRIPTION = '[Offers] Set description',
  SHOW_ERROR = '[Offers] Show error',
  SET_SPECIAL_OFFERS_TYPE = '[Offers] Set special offers type',
  SAVE_SPECIAL_OFFER = '[Offers] Save special offer',
  SAVE_SPECIAL_OFFER_SUCCESS = '[Offers] Save special offer success',
  DELETE_SPECIAL_OFFER = '[Offers] Delete special offer',
  DELETE_SPECIAL_OFFER_SUCCESS = '[Offers] Delete special offer success',
  GET_INITIAL_FORM = '[Offers] Get initial form',
  SET_INITIAL_FORM = '[Offers] Set initial form',
  SAVE_SUPPLIER_DISCOUNT = '[Offers] Save supplier discount',
  SAVE_SUPPLIER_DISCOUNT_SUCCESS = '[Offers] Save supplier discount success',
  TOGGLE_INITIALISED = '[Offers] Toggle form initialised',
  INIT_OFFERS_LISTENER = '[Offers] Init offers listener',
  STOP_OFFERS_LISTENER = '[Offers] Stop offers listener',
  ON_FIRESTORE_OFFERS = '[Offers] On Firestore offers',
}

export type IShowErrorAction = ReturnType<typeof showError>;
export type ISetTitleAction = ReturnType<typeof setTitle>;
export type ISetDiscountAction = ReturnType<typeof setDiscount>;
export type ISetLateAvailabilityDateAction = ReturnType<typeof setLateAvailabilityDate>;
export type IRemoveLateAvailabilityDateAction = ReturnType<typeof removeLateAvailabilityDate>;
export type ISetExpiryDateAction = ReturnType<typeof setExpiryDate>;
export type ISetDescriptionAction = ReturnType<typeof setDescription>;
export type ISetSpecialOffersTypeAction = ReturnType<typeof setSpecialOffersType>;
export type ISaveSpecialOfferAction = ReturnType<typeof saveSpecialOffer>;
export type ISetInitialFormAction = ReturnType<typeof setInitialForm>;
export type IToggleInitialisedAction = ReturnType<typeof toggleInitialised>;
export type ISaveSupplierDiscountAction = ReturnType<typeof saveSupplierDiscount>;
export type ISetDiscountTypeAction = ReturnType<typeof setDiscountType>;
export type IOnFirestoreOffersAction = ReturnType<typeof onFirestoreOffers>;
