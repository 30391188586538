import produce from 'immer';
import { IToggleHubspotPopupAction, PremiumActionTypes } from 'lib/premium/action-types';
import { Action, IReducersImmer } from 'lib/types';
import { IPremiumState } from './types';

const initialState: IPremiumState = {
  hubspotPopupVisible: false,
};

const reducers: IReducersImmer<IPremiumState> = (draft) => ({
  [PremiumActionTypes.TOGGLE_HUBSPOT_POPUP]: (action: IToggleHubspotPopupAction) => {
    draft.hubspotPopupVisible = action.payload;
  },
});

/*
 This is a wrapper function which runs a proper reducer from the object above.
 */

const reducer = (state: IPremiumState = initialState, action: Action): IPremiumState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
