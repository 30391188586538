import { submitLinkedUserAccountSuccessAnalytics } from 'lib/settings/analytics/actions/analytics';

export enum SettingsAnalyticsActions {
  SUBMIT_LINKED_USER_ACCOUNT_SUCCESS_ANALYTICS = '[Settings] Submit linked user account success analytics',
  SUBMIT_LINKED_USER_ACCOUNT_SUCCESS_ANALYTICS_SUCCESS = '[Settings] Submit linked user account success analytics success',
}

export type SubmitLinkedUserAccountSuccessAnalyticsType = ReturnType<
  typeof submitLinkedUserAccountSuccessAnalytics
>;
