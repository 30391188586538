import Router from 'next/router';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { appError } from 'lib/app/actions';
import { ToggleCoupleCollaborationAction, YourCouplesActionTypes } from 'lib/couples/action-types';
import { fetchBooking } from 'lib/couples/actions';
import { IEpicDeps } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';

export const toggleCoupleCollaborationEpic = (
  action$: Observable<ToggleCoupleCollaborationAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(YourCouplesActionTypes.TOGGLE_COUPLE_COLLABORATION),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { booking, isCollaborating } = action.payload;
      const {
        supplier: { supplier },
      } = state;

      const { profileId, userId } = booking;
      const supplierId = supplier?.id;

      const promise = authenticatedFetch(`/api/couple-collaboration/toggle`, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({
          profileId,
          userId,
          collaborating: isCollaborating,
          supplierId,
        }),
      }).then((res) => {
        if (res.status !== 200) {
          throw new Error('Error toggling supplier collaborating');
        }

        return res.json();
      });

      // Fetch the booking to refresh if it was toggled on that booking's page
      const {
        asPath,
        query: { id },
      } = Router;
      const shouldFetchBooking = asPath === UrlHelper.couples.bookings.details(String(id));

      return from(promise).pipe(
        mergeMap(() => (shouldFetchBooking ? of(fetchBooking()) : of())),
        catchError((error) => of(appError({ error, feature: 'Shortlist' }))),
      );
    }),
  );
