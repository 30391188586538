import { AppsActionTypes } from 'lib/apps/action-types';
import { AppsInstagram } from 'lib/apps/types';

export const initAppsListener = (payload: { supplierId: string }) => ({
  type: AppsActionTypes.INIT_LISTENER,
  payload,
});

export const stopAppsListener = () => ({
  type: AppsActionTypes.STOP_LISTENER,
});

export const setInstagramData = (payload?: AppsInstagram) => ({
  type: AppsActionTypes.SET_INSTAGRAM_DATA,
  payload,
});
