import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, ignoreElements, mapTo, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { IBrochure } from '@bridebook/models/source/models/Suppliers/Brochures.types';
import { FormSections } from 'lib/supplier/types';
import { IEpicDeps } from 'lib/types';
import { appError } from '../../app/actions';
import { IChangeBrochureTitleAction, PricingActionTypes } from '../action-types';

export const changeBrochureTitleEpic = (
  action$: Observable<IChangeBrochureTitleAction>,
  { state$, bbanalytics }: IEpicDeps,
) =>
  action$.pipe(
    ofType(PricingActionTypes.CHANGE_BROCHURE_TITLE),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { brochure } = action.payload;

      const activeSupplier = state.users.activeSupplierAccessControl;
      if (!activeSupplier?.id) return of();

      const getPromise = async () => {
        const save = async () => {
          const id = (brochure as IBrochure).id;
          const brochureRef = Suppliers._.getById(activeSupplier?.id).Brochures.getById(id);
          return brochureRef.set(brochure as IBrochure);
        };

        return save();
      };

      return from(getPromise()).pipe(
        tap(() =>
          bbanalytics.supplier.pricing.edited({
            updatedSubsection: FormSections.Brochures,
            supplierBrochureId: (brochure as IBrochure).id,
          }),
        ),
        mapTo({
          type: PricingActionTypes.CHANGE_BROCHURE_TITLE_SUCCESS,
        }),
        catchError((error) =>
          of(
            { type: PricingActionTypes.CHANGE_BROCHURE_TITLE_ERROR },
            appError({ error, feature: 'Pricing' }),
          ),
        ),
        ignoreElements(),
      );
    }),
  );
