import { lensPath, set } from 'ramda';
import { FocusEvent } from 'react';
import { PackagesSections } from '@bridebook/toolbox/src/map-packages-to-sections';
import { IDeps } from 'lib/types';
import resolveInputType from 'lib/utils/resolve-input-type';

interface IResolvePackageInput {
  name: string;
  value: any;
}

/**
 * resolve input name and value from UI based on input name
 * main purpose for this utility is to handle Package array min/max properties.
 * @param packageSection - name of the packageSection
 * @param event - input event
 * @param getState - get redux state
 */
export const resolvePackageInput = (
  packageSection: PackagesSections,
  event: FocusEvent<HTMLInputElement>,
  getState: IDeps['getState'],
): IResolvePackageInput => {
  const { [packageSection]: data } = getState().pricing.packagesSections;

  const name = event.target.name;
  const value = resolveInputType(event.target);
  const path = name.split('.');
  if (path.length < 2) {
    return { name, value };
  }

  const [prop, type] = path;

  let resolvedValue: any;
  switch (type) {
    case 'min':
      resolvedValue = set(lensPath([0]), value, data?.[prop] || [null, null]); // do not overwrite 'max' value
      break;
    case 'max':
      resolvedValue = [data?.[prop]?.[0] || null, value]; // do not overwrite 'min' value or generate it if not defined
      break;
    case 'fixed':
    default:
      resolvedValue = value;
  }

  return { name: prop, value: resolvedValue };
};
