import { Timestamp, deleteField } from 'firebase/firestore';
import Router from 'next/router';
import { isNil, omit, pathOr } from 'ramda';
import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import type {
  ISupplierSpecialOffersGeneric,
  ISupplierSpecialOffersLateAvailability,
} from '@bridebook/models/source/models/RTDB/Suppliers.types';
import { Offers } from '@bridebook/models/source/models/Suppliers/Offers';
import {
  IOffer_DiscountType,
  IOffer_Translations,
} from '@bridebook/models/source/models/Suppliers/Offers.types';
import { ValidationError } from '@bridebook/toolbox/src';
import { appError } from 'lib/app/actions';
import { getScopedContentTranslations } from 'lib/content-translations/selectors';
import { clearTempContentTranslations } from 'lib/content-translations/slice';
import {
  ISaveSpecialOfferAction,
  ISaveSupplierDiscountAction,
  OffersActionTypes,
} from 'lib/offers/action-types';
import { saveSpecialOfferSuccess, showError } from 'lib/offers/actions';
import { BBDiscountOptions, OfferDiscountTypes, OfferTypes } from 'lib/offers/constants';
import { isNoneDiscount, isOtherDiscount } from 'lib/offers/data/bb-discount-options';
import {
  discountStringToExactValue,
  getFirestoreDiscountType,
  getMaxLateAvailabilityDates,
  offerTimestampsToDatepickerObjects,
} from 'lib/offers/utils';
import { supplierSpecialOffersBBDiscount } from 'lib/supplier/schemas/generic';
import { getIsVenue, getSupplierTier } from 'lib/supplier/selectors';
import { IApplicationState, IEpicDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';
import { UrlHelper } from 'lib/url-helper';
import { cleanTimestamps } from 'lib/utils';
import { getOffers } from '../selectors/offers';
import { SupplierSpecialOfferForm } from '../types';

const validateSpecialOfferFormBase = async (
  form: SupplierSpecialOfferForm,
  validate: (fields: object) => any,
) => {
  const i18n = getI18n();
  try {
    await validate(form).prop('title').required().promise;
  } catch (error) {
    throw new ValidationError(i18n.t('offers:validationError'), error.prop);
  }

  if (form.discountType === OfferDiscountTypes.percentOff) {
    const isValid = form.discountValue && form.discountValue > 0 && form.discountValue < 100;

    if (!isValid) {
      throw new ValidationError(i18n.t('offers:dealValueEmpty'), 'discountValue');
    }
  } else if (form.discountType !== OfferDiscountTypes.other) {
    if (!form.discountValue || form.discountValue < 1) {
      throw new ValidationError(i18n.t('offers:dealValueEmpty'), 'discountValue');
    }
  }
};

const validateLateAvailabilityOffer = async (
  form: SupplierSpecialOfferForm,
  validate: (fields: object) => Promise<any>,
  state: IApplicationState,
) => {
  await validateSpecialOfferFormBase(form, validate);

  const i18n = getI18n();
  const supplierTier = getSupplierTier(state);
  const isVenue = getIsVenue(state);

  const lateDates = form.lateDates?.filter((date: number) => !isNil(date));

  const { enabledDates } = getMaxLateAvailabilityDates(supplierTier, isVenue);

  if (!lateDates || !lateDates.filter(Boolean).length) {
    throw new ValidationError(i18n.t('offers:offer.lateAvailability'), 'lateDates');
  }

  if (typeof enabledDates === 'number' && lateDates.length > enabledDates) {
    throw new ValidationError(
      i18n.t('offers:offer.lateAvailability.maxDatesError', { maxDates: enabledDates }),
      'lateDates',
    );
  }
};

const validateSpecialOffer = async (
  form: SupplierSpecialOfferForm,
  validate: (fields: object) => Promise<any>,
) => {
  await validateSpecialOfferFormBase(form, validate);
  const i18n = getI18n();
  if (!form.expiryDate || form.expiryDate < Date.now()) {
    throw new ValidationError(i18n.t('offers:offer.expirtDate'), 'expiryDate');
  }
};

export const saveSpecialOfferEpic = (
  action$: Observable<ISaveSpecialOfferAction>,
  { state$, validate }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType(OffersActionTypes.SAVE_SPECIAL_OFFER),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { fromProfile, scope },
        },
        state,
      ]) => {
        const { form, type } = state.offers;
        const specialOffers = getOffers(state);
        const previousOffer = pathOr(null, [type as string], specialOffers);

        const activeSupplier = state.users.activeSupplierAccessControl;
        if (!activeSupplier?.id) return of();

        const getPromise = async () => {
          if (type === OfferTypes.lateAvailability) {
            await validateLateAvailabilityOffer(form, validate, state);
            // bbDiscount offers should not be validated here
          } else if (type !== OfferTypes.bbDiscount) {
            await validateSpecialOffer(form, validate);
          }

          const supplierRef = Suppliers._.getById(activeSupplier?.id);
          const _translations = getScopedContentTranslations<IOffer_Translations>(state, scope);
          let updateObject: Record<string, any> = {
            ...(previousOffer || {}),
            title: form.title,
            discount: form.discountValue,
            type,
            active: true,
            ...(_translations && { _translations }),
          };

          if (type === OfferTypes.lateAvailability) {
            const lateDates = [
              ...((form as ISupplierSpecialOffersLateAvailability).lateDates || []),
            ].filter((date) => !isNil(date));
            updateObject.dates = offerTimestampsToDatepickerObjects(
              lateDates.sort((a, b) => a - b),
            );
          } else if ((form as ISupplierSpecialOffersGeneric).expiryDate) {
            updateObject.expiration = Timestamp.fromMillis(
              (form as ISupplierSpecialOffersGeneric).expiryDate!,
            );
          }

          if ((form as ISupplierSpecialOffersGeneric).details) {
            updateObject.details = (form as ISupplierSpecialOffersGeneric).details;
          }

          // if bbDiscount and discountOther omit discountType
          if (updateObject.type === OfferTypes.bbDiscount) {
            if (typeof form.discountValue === 'string') {
              updateObject = omit(['discountType'], updateObject);
            } else {
              // fallback following issues with migration script
              updateObject.discountType = OfferDiscountTypes.percentOff;
            }
          } else {
            updateObject.discountType = getFirestoreDiscountType(
              form.discountType as OfferDiscountTypes,
            );
          }

          if (updateObject.id) {
            await supplierRef.Offers.getById(updateObject.id).set({
              ...cleanTimestamps(updateObject),
              discountType: updateObject.discountType || deleteField(),
            });
          } else {
            await supplierRef.Offers.push().create(Offers.new('_', updateObject));
          }

          if (!fromProfile) {
            await Router.push(UrlHelper.grow.specialOffers.home);
          }
        };
        const i18n = getI18n();
        return from(getPromise()).pipe(
          mergeMap(() =>
            fromProfile
              ? [saveSpecialOfferSuccess(previousOffer, false), clearTempContentTranslations(scope)]
              : [
                  saveSpecialOfferSuccess(previousOffer),
                  toggleSnackbar('success', i18n.t('offers:offerSaved')),
                  clearTempContentTranslations(scope),
                ],
          ),
          catchError((error) =>
            fromProfile
              ? of(appError({ error, feature: 'SpecialOffers' }))
              : of(showError(error.message, error.prop), toggleSnackbar('alert', error.message)),
          ),
        );
      },
    ),
  );

export const saveSupplierDiscountEpic = (
  action$: Observable<ISaveSupplierDiscountAction>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType(OffersActionTypes.SAVE_SUPPLIER_DISCOUNT),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const { discount, expiryDate, otherDiscount, fromProfile } = payload;

      const activeSupplier = state.users.activeSupplierAccessControl;
      if (!activeSupplier?.id) return of();

      const getPromise = async (): Promise<any> => {
        const isNone = isNoneDiscount(discount);
        const isOther = isOtherDiscount(discount);
        const i18n = getI18n();
        if (!isNone && (!expiryDate || expiryDate < Date.now())) {
          throw new ValidationError(i18n.t('offers:expiryDate'), 'expiryDate');
        }

        const supplierRef = Suppliers._.getById(activeSupplier.id);
        const { bbDiscount } = getOffers(state);

        if (isNone) {
          if (bbDiscount?.id) {
            // delete if discount set to none
            return supplierRef.Offers.getById(bbDiscount.id).delete();
          }
        } else {
          let updateObject = {
            ...(bbDiscount || {}),
            discount: isOther
              ? otherDiscount || ''
              : discountStringToExactValue(discount as BBDiscountOptions),
            type: OfferTypes.bbDiscount,
            title: supplierSpecialOffersBBDiscount.title,
            active: true,
          };

          if (isOther) {
            updateObject = omit(['discountType'], updateObject);
          } else {
            updateObject.discountType = IOffer_DiscountType.percentOff;
          }

          if (expiryDate) {
            updateObject.expiration = Timestamp.fromMillis(expiryDate);
          }

          if (updateObject.id) {
            await supplierRef.Offers.getById(updateObject.id).set({
              ...cleanTimestamps(updateObject),
              discountType: updateObject.discountType || deleteField(),
            });
          } else {
            await supplierRef.Offers.push().create(Offers.new('_', updateObject));
          }
        }
      };
      const i18n = getI18n();
      return from(getPromise()).pipe(
        mergeMap(() =>
          fromProfile
            ? of({ type: OffersActionTypes.SAVE_SUPPLIER_DISCOUNT_SUCCESS })
            : of(
                { type: OffersActionTypes.SAVE_SUPPLIER_DISCOUNT_SUCCESS },
                toggleSnackbar('success', i18n.t('offers:discountSaved')),
              ),
        ),
        catchError((error) =>
          of(showError(error.message, error.prop), toggleSnackbar('alert', error.message)),
        ),
      );
    }),
  );
