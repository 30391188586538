import { GeoPoint } from 'firebase/firestore';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';

export default async function getSupplierCoordinates(
  supplier: ISupplier,
): Promise<GeoPoint | null> {
  const address = supplier.address?.street?.[0];
  const postcode = supplier.address?.postalCode;
  const county = supplier.address?.adminArea?.[0];
  const country = supplier.address?.adminArea?.[1];
  const city = supplier.address?.city;

  const geo = await fetch(`/api/search/geocode`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      address: [address, postcode, city, county, country].filter(Boolean).join(', '),
    }),
  }).then((res) => res.json());

  if (geo.status === 'OK') {
    const location = geo.results[0].geometry.location;
    return new GeoPoint(location.lat, location.lng);
  }

  return null;
}
