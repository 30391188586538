import { CustomNameValueEvent } from '@bridebook/ui';
import { AdminActionTypes } from 'lib/admin/action-types';
import resolveInputType from 'lib/utils/resolve-input-type';

export const markSupplierAsDelisted = (event: CustomNameValueEvent) => {
  const value = resolveInputType(event.target);

  return {
    type: AdminActionTypes.MARK_SUPPLIER_AS_DELISTED_START,
    payload: value,
  };
};
