import { getI18n } from 'react-i18next';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { countriesWithSearchAppearancesPredictions } from '@bridebook/toolbox/src/i18n/features';
import type { Slug } from '@bridebook/toolbox/src/types';
import { PremiumTiers } from 'lib/premium/constants';
import { getPremiumTierNames, getSupplierPremiumTierNames } from 'lib/premium/utils/tiers';
import { IStatisticsHighchartsConfig } from 'lib/statistics/types';
import {
  calculateSupplierSearchAppearancesValue,
  calculateVenueSearchAppearancesValue,
} from 'lib/statistics/utils';

const getVenueChartColor = (tier: PremiumTiers) => {
  const colors = {
    [PremiumTiers.Tier_4]: '#FDBD5D',
    [PremiumTiers.Tier_3]: '#FDBD5D', // gold
    [PremiumTiers.Tier_2_5]: '#FDBD5D', // gold
    [PremiumTiers.Tier_2]: '#4D52E3', // blue
    [PremiumTiers.Tier_1]: '#4D52E3', // blue
    [PremiumTiers.Tier_0]: '#BABFC5', // grey
    [PremiumTiers.Unverified]: '#BABFC5', // grey
  };

  return colors[tier];
};

const getSupplierChartColor = (tier: PremiumTiers) => {
  const colors = {
    [PremiumTiers.Tier_2]: '#FDBD5D', // gold
    [PremiumTiers.Tier_1]: '#4D52E3', // blue
    [PremiumTiers.Tier_0]: '#BABFC5', // grey
    [PremiumTiers.Unverified]: '#BABFC5', // grey
  };

  return colors[tier as keyof typeof colors] || '#BABFC5';
};

const buildSearchAppearancesSeries = ({
  Config,
  seriesData,
  supplierTier,
  supplierType,
  supplierCountryCode,
}: {
  Config: IStatisticsHighchartsConfig;
  seriesData: number[];
  supplierTier?: PremiumTiers;
  supplierType?: Slug;
  supplierCountryCode: CountryCodes;
}) => {
  if (typeof supplierTier !== 'number' || !supplierType) {
    return null;
  }

  const isVenue = supplierType === 'venue';

  return [
    ...(isVenue
      ? getExpectedChartsForVenue({
          supplierTier,
          seriesData,
          Config,
          supplierCountryCode,
        })
      : getExpectedChartsForSupplier({ supplierTier, seriesData, Config, supplierCountryCode })),
  ].filter(Boolean); // filter out false values
};

export default buildSearchAppearancesSeries;

interface IGenerateSearchAppearancesInput {
  supplierTier: PremiumTiers;
  seriesData: number[];
  Config: IStatisticsHighchartsConfig;
  supplierCountryCode: CountryCodes;
}

const getExpectedChartsForVenue = ({
  supplierTier,
  seriesData,
  Config,
  supplierCountryCode,
}: IGenerateSearchAppearancesInput) => {
  const i18n = getI18n();

  return [
    {
      ...Config.series[0],
      name: i18n.t('enquiryGraphs:charts.legend.searchAppearances'),
      color: getVenueChartColor(supplierTier),
      dashStyle: 'solid',
      data: seriesData,
      currentStats: true,
    },
    ...generateVenueSearchAppearancesPredictions({
      supplierTier,
      seriesData,
      Config,
      supplierCountryCode,
    }),
  ];
};

const getExpectedChartsForSupplier = ({
  supplierTier,
  seriesData,
  Config,
  supplierCountryCode,
}: IGenerateSearchAppearancesInput) => {
  const i18n = getI18n();

  return [
    {
      ...Config.series[0],
      name: i18n.t('enquiryGraphs:charts.legend.searchAppearances'),
      color: getSupplierChartColor(supplierTier),
      dashStyle: 'solid',
      data: seriesData,
      currentStats: true,
    },
    ...generateSupplierSearchAppearancesPredictions({
      supplierTier,
      seriesData,
      Config,
      supplierCountryCode,
    }),
  ];
};

const generateVenueSearchAppearancesPredictions = ({
  supplierTier,
  seriesData,
  Config,
  supplierCountryCode,
}: IGenerateSearchAppearancesInput) => {
  const tierNames = getPremiumTierNames();
  const i18n = getI18n();

  const withPredictions = countriesWithSearchAppearancesPredictions.includes(supplierCountryCode);

  return withPredictions
    ? [
        ![PremiumTiers.Tier_0, PremiumTiers.Unverified].includes(supplierTier) && {
          ...Config.series[0],
          name: i18n.t('enquiryGraphs:charts.legend.expected', {
            tierName: tierNames[PremiumTiers.Tier_0],
          }),
          color: getVenueChartColor(PremiumTiers.Tier_0),
          dashStyle: 'dash',
          data: seriesData.map(
            calculateVenueSearchAppearancesValue(supplierTier, PremiumTiers.Tier_0),
          ),
          currentStats: false,
        },
        ![PremiumTiers.Tier_1, PremiumTiers.Tier_2, PremiumTiers.Tier_2_5].includes(
          supplierTier,
        ) && {
          ...Config.series[0],
          name: i18n.t('enquiryGraphs:charts.legend.expected', {
            tierName: tierNames[PremiumTiers.Tier_2_5],
          }),
          color: getVenueChartColor(PremiumTiers.Tier_2),
          dashStyle: 'dash',
          data: seriesData.map(
            calculateVenueSearchAppearancesValue(supplierTier, PremiumTiers.Tier_2),
          ),
          currentStats: false,
        },
        supplierTier !== PremiumTiers.Tier_3 && {
          ...Config.series[0],
          name: i18n.t('enquiryGraphs:charts.legend.expected', {
            tierName: tierNames[PremiumTiers.Tier_3],
          }),
          color: getVenueChartColor(PremiumTiers.Tier_3),
          dashStyle: 'dash',
          data: seriesData.map(
            calculateVenueSearchAppearancesValue(supplierTier, PremiumTiers.Tier_3),
          ),
          currentStats: false,
        },
      ]
    : [];
};

const generateSupplierSearchAppearancesPredictions = ({
  supplierTier,
  seriesData,
  Config,
  supplierCountryCode,
}: IGenerateSearchAppearancesInput) => {
  const tierNames = getSupplierPremiumTierNames();
  const i18n = getI18n();

  const withPredictions = countriesWithSearchAppearancesPredictions.includes(supplierCountryCode);

  return withPredictions
    ? [
        ![PremiumTiers.Tier_0, PremiumTiers.Unverified].includes(supplierTier) && {
          ...Config.series[0],
          name: i18n.t('enquiryGraphs:charts.legend.expected', {
            tierName: tierNames[PremiumTiers.Tier_0],
          }),
          color: getSupplierChartColor(PremiumTiers.Tier_0),
          dashStyle: 'dash',
          data: seriesData.map(
            calculateSupplierSearchAppearancesValue(supplierTier, PremiumTiers.Tier_0),
          ),
          currentStats: false,
        },
        supplierTier !== PremiumTiers.Tier_1 && {
          ...Config.series[0],
          name: i18n.t('enquiryGraphs:charts.legend.expected', {
            tierName: tierNames[PremiumTiers.Tier_1],
          }),
          color: getSupplierChartColor(PremiumTiers.Tier_1),
          dashStyle: 'dash',
          data: seriesData.map(
            calculateSupplierSearchAppearancesValue(supplierTier, PremiumTiers.Tier_1),
          ),
          currentStats: false,
        },
        supplierTier !== PremiumTiers.Tier_2 && {
          ...Config.series[0],
          name: i18n.t('enquiryGraphs:charts.legend.expected', {
            tierName: tierNames[PremiumTiers.Tier_2],
          }),
          color: getSupplierChartColor(PremiumTiers.Tier_2),
          dashStyle: 'dash',
          data: seriesData.map(
            calculateSupplierSearchAppearancesValue(supplierTier, PremiumTiers.Tier_2),
          ),
          currentStats: false,
        },
      ]
    : [];
};
