import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { appError } from '../../app/actions';
import { IEpicDeps } from '../../types';
import { IDeleteSupplierPackageAction, PricingActionTypes } from '../action-types';

export const deletePricingPackageEpic = (
  action$: Observable<IDeleteSupplierPackageAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(PricingActionTypes.DELETE_SUPPLIER_PACKAGE),
    withLatestFrom(state$),
    mergeMap(([{ payload: pricingPackage }, state]) => {
      const supplierId = state.supplier.supplier?.id;
      if (!supplierId || !pricingPackage.id) return of();

      return from(
        Suppliers._.getById(supplierId).Packages.getById(pricingPackage.id).delete(),
      ).pipe(
        mergeMap(() => of()), // add analytics one we have it
        catchError((error) => of(appError({ error, feature: 'PricingPackage.delete' }))),
      );
    }),
  );
