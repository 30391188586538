import gazetteer, { CountryCodes, Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { ApiEndpoint } from 'lib/api/api-endpoints';

/**
 * Returns a country code matching the given locale
 * @param locale
 */
export const findCountryCodeByLocale = (locale: string) =>
  gazetteer
    .getMarkets()
    // Find matching Country by both full locale ('de-DE') or partial locale ('de')
    .filter((details) => details.locale === locale || details.locale.split('-')[0] === locale)
    .map((details) => details.country)[0];

export const getCountryNameByCountryCode = (countryCode: CountryCodes) =>
  countryCode === CountryCodes.GB ? 'UK' : Gazetteer.getCountryName(countryCode);

export const setClientLocaleCookie = async (locale: string): Promise<void> => {
  await fetch(ApiEndpoint.setLocale, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      locale,
    }),
  });
};
