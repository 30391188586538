import { isEmpty } from 'ramda';
import { getI18n } from 'react-i18next';
import { COMPETITORS_CHARTS_COLORS } from 'lib/statistics/constants';
import { IStatisticsHighchartsConfig } from 'lib/statistics/types';

const buildHistoricalRankingsCompetitorsSeries = (
  Config: IStatisticsHighchartsConfig,
  seriesData: Record<string, any>[],
) =>
  seriesData
    .map((d, i) =>
      d.historicalRankings && !isEmpty(d.historicalRankings)
        ? {
            ...Config.series[0],
            name: i === 0 ? getI18n().t('marketInsights:yourPosition') : d.name,
            color: COMPETITORS_CHARTS_COLORS[i],
            dashStyle: i === 0 ? 'solid' : 'dash',
            data: Object.values(d.historicalRankings),
          }
        : null,
    )
    .filter(Boolean);

export default buildHistoricalRankingsCompetitorsSeries;
