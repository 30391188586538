import { IPhoto } from '@bridebook/models/source/models/Suppliers/Photos.types';
import { IVideo } from '@bridebook/models/source/models/Suppliers/Videos.types';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { parseVideo } from '@bridebook/toolbox/src/validation/parse-video';
import { ApiEndpoint } from 'lib/api/api-endpoints';
import { imgixBaseURL } from 'lib/utils';

export const getVideoData = (url: string) => {
  const videoObj = parseVideo(url);
  return authenticatedFetch(ApiEndpoint.videoMeta, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin',
    body: JSON.stringify({
      vid: videoObj.id,
      type: videoObj.type,
      url,
    }),
  });
};

export const adjustSizes = (thumb: string, type?: string) => {
  if (!thumb) return null;
  const isYoutube = type === 'youtube';
  let yPath = thumb.replace('hqdefault.jpg', '0.jpg');
  yPath = yPath.replace('default.jpg', '0.jpg');
  return isYoutube
    ? yPath
    : thumb.replace('200x150.jpg', '480x360.jpg').replace('http://', 'https://');
};

export const getVideoIframeUrl = (video: IVideo) => {
  switch (video.source) {
    case 'youtube':
      return `https://youtube.com/embed/${video.sourceId}?autoplay=1`;
    case 'vimeo':
      return `https://player.vimeo.com/video/${video.sourceId}?autoplay=1`;
    case 'matterport':
    case 'googlemaps':
      return video.sourceId;
    default:
      return '';
  }
};

export const getTourThumbnail = (list: IVideo[], photos: IPhoto[]) => {
  const totalTours = list.filter(
    (v) => v.type === 'videoTour' && (v.source === 'matterport' || v.source === 'googlemaps'),
  ).length;

  return `${imgixBaseURL}/${
    photos[totalTours]?.path || 'default'
  }?auto=compress,format&w=480&h=360&fit=crop&bri=-10`;
};

export const getVideoTourProps = async (url: string, list: IVideo[], photos: IPhoto[]) => {
  const thumbnail = getTourThumbnail(list, photos);
  const commonProps = {
    id: url,
    thumbnail_url: thumbnail,
    title: '',
  };

  // Matterport 360 tour
  if (url.includes('matterport')) {
    return {
      ...commonProps,
      type: 'matterport',
    };
  }
  // Google Street View 360 tour
  if (url.includes('google')) {
    return {
      ...commonProps,
      type: 'googlemaps',
    };
  }

  const response = await getVideoData(url);
  if (!response || response.status !== 200) {
    throw await response.json();
  }

  return response.json();
};

export const parseVideoURL = (url: string, isVideoTour: boolean) => {
  if (isVideoTour) {
    try {
      // If input is iframe, extract src from it
      const parser = new DOMParser();
      const parsedIframe = parser.parseFromString(url, 'text/html');
      const iframe = parsedIframe.getElementsByTagName('iframe');
      const src = iframe[0].src;
      return src;
    } catch (e) {}
  }
  return url;
};
