import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { authenticatedPOST } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import type { RequestPayload, ResponsePayload } from 'pages/api/nonce/invite/create';
import { AdminActionTypes } from 'lib/admin/action-types';
import { generateSupplierInviteSuccess } from 'lib/admin/actions';
import { appError } from 'lib/app/actions';
import { env } from 'lib/env';
import { IEpicDeps } from 'lib/types';

const generateInviteUrl = (id: string, nonce: string, supplierId: string) => {
  const countryCode = supplierId.slice(0, 2);
  const cmsUrl = countryCode === CountryCodes.GB ? env.CMS_URL : env.CMS_URL_I18N;
  return `${cmsUrl}/?id=${id}&nonce=${nonce}`;
};

export const generateSupplierInviteEpic = (action$: any, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(AdminActionTypes.GENERATE_INVITE),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const getPromise = async () => {
        const {
          supplier: { supplier },
        } = state;

        const response = await authenticatedPOST<RequestPayload, ResponsePayload>(
          `/api/nonce/invite/create`,
          {
            body: {
              role: 'admin',
              supplierId: supplier.id,
            },
          },
          'Failed to fetch invite',
        );

        const { nonceId, nonceSecret } = response;

        return generateInviteUrl(nonceId, nonceSecret, supplier.id);
      };

      return from(getPromise()).pipe(
        mergeMap((invite) => of(generateSupplierInviteSuccess(invite))),
        catchError((error: Error) =>
          of(appError({ error, feature: 'generateSupplierInviteEpic' })),
        ),
      );
    }),
  );
