import { assocPath, merge } from 'ramda';
import { Action } from 'lib/types';
import { mergeClean } from 'lib/utils';
import createSupplier from '../supplier/suppliers';
import SearchFields from './searchFields';
import { SearchFields as SearchFieldsType } from './types';

export interface SearchState {
  supplier: object & { slug: string };
  fields: SearchFieldsType;
}

const initialState: SearchState = {
  supplier: createSupplier({ slug: '' }),
  fields: merge({}, SearchFields),
};

export default function searchReducer(state: SearchState = initialState, action: Action) {
  state = mergeClean(initialState, state);

  switch (action.type) {
    // Reset filters if searching for new category
    case 'CREATE_SUPPLIER': {
      if (action.payload.category === state.supplier.slug) return state;
      return {
        ...state,
        supplier: createSupplier({ slug: action.payload.category }),
      };
    }

    case 'SET_SEARCHBAR_FORM_FIELD':
      return assocPath(['fields', 'area'], action.payload.value)(state);

    case 'UPDATE_SEARCH_CATEGORY':
      return assocPath(['fields', 'category'], action.payload)(state);

    case 'SAVE_SEARCH_FIELDS':
      return assocPath(['fields'], mergeClean(SearchFields, action.payload))(state);

    default:
      return state;
  }
}
