import { keys } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { enrichSuppliers } from '@bridebook/toolbox/src/enrich-suppliers/enrich-suppliers';
import { appError } from 'lib/app/actions';
import { fetchUkHistoricalRankings } from 'lib/statistics/utils';
import { IEpicDeps } from '../../types';
import { StatisticsActionTypes } from '../action-types';
import { fetchUkCompetitorsRankingsSuccess } from '../actions';
import { IUKRankings } from '../types';

const fetchUkCompetitorsRankingsEpic = (action$: Observable<any>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(StatisticsActionTypes.FETCH_UK_COMPETITORS_RANKINGS),
    withLatestFrom(state$),
    filter(([, state]) => Boolean(state.supplier?.supplier?.id)),
    mergeMap(([, state]) => {
      const getPromise = async () => {
        if (!state.supplier?.supplier?.id) {
          return {};
        }

        const hr = await fetchUkHistoricalRankings(state.supplier.supplier.id);
        const suppliers = await enrichSuppliers<{ id: string }>(keys(hr).map((id) => ({ id })));

        return suppliers.reduce((acc, { id, name }) => {
          acc[id] = { name, data: hr[id] };
          return acc;
        }, {} as IUKRankings);
      };

      return from(getPromise()).pipe(
        mergeMap((rankings) => of(fetchUkCompetitorsRankingsSuccess(rankings))),
        catchError((error) => of(appError({ error, feature: 'fetchUkCompetitorsRankingsEpic' }))),
      );
    }),
  );

export default fetchUkCompetitorsRankingsEpic;
