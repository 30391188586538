import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { IApplicationState, IEpicDeps } from 'lib/types';
import { OffersActionTypes } from './action-types';
import { onFirestoreOffers } from './actions';

export const initOffersListener = (action$: Observable<any>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(OffersActionTypes.INIT_OFFERS_LISTENER),
    withLatestFrom(state$),
    mergeMap(([_, state]: [any, IApplicationState]) => {
      const activeSupplier = state.users.activeSupplierAccessControl;
      if (!activeSupplier?.id) return of();

      return Suppliers._.getById(activeSupplier.id)
        .Offers.query()
        .observe()
        .pipe(
          mergeMap((data) => {
            return data ? of(onFirestoreOffers(data)) : of();
          }),
          takeUntil(action$.pipe(ofType(OffersActionTypes.STOP_OFFERS_LISTENER))),
        );
    }),
  );
