import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { showError } from 'lib/offers/actions';
import { savedSupplierInfoOnCmsTargetingAnalytics } from 'lib/supplier/actions';
import { TargetingActionTypes, UpdateTargetingAction } from 'lib/targeting/action-types';
import { mapLocalToDatabaseFormat } from 'lib/targeting/utils';
import { IEpicDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';

export const updateTargetingEpic = (
  action$: Observable<UpdateTargetingAction>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType(TargetingActionTypes.UPDATE_TARGETING),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const activeSupplier = state.users.activeSupplierAccessControl;
      if (!activeSupplier?.id) return of();

      const targeting = mapLocalToDatabaseFormat(payload);

      const getPromise = async (): Promise<any> => {
        const supplierEntity = Suppliers._.getById(activeSupplier.id);
        await supplierEntity.set({
          targeting,
        });
      };

      return from(getPromise()).pipe(
        mergeMap(() =>
          of(
            { type: TargetingActionTypes.UPDATE_TARGETING_SUCCESS },
            savedSupplierInfoOnCmsTargetingAnalytics(payload),
            toggleSnackbar('success', getI18n().t('targeting:coupleTargeting.saved')),
          ),
        ),
        catchError((error) =>
          of(showError(error.message, error.prop), toggleSnackbar('alert', error.message)),
        ),
      );
    }),
  );
