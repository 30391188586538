import { ISupplier, ISupplierDetailVenue } from '@bridebook/models/source/models/Suppliers.types';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { countriesExcludedTypes } from '@bridebook/toolbox/src/supplier/constants';

/**
 * Removes some venue types from the response returned by countries, which are
 * not used in some countries
 */
export const excludeVenueTypes = (country: CountryCodes, data: Partial<ISupplier>) => {
  const venueDetailsObj = data?.typeDetails?.venue;

  if (!venueDetailsObj || !countriesExcludedTypes[country]) return data;

  const countryExcludedTypes = countriesExcludedTypes[country];

  return {
    ...data,
    typeDetails: {
      ...data.typeDetails,
      venue: Object.keys(venueDetailsObj).reduce((venueDetails, prop) => {
        const currentData = venueDetailsObj[prop as keyof ISupplierDetailVenue];

        if (!Array.isArray(currentData)) {
          // @ts-expect-error : Can't use string to index object
          venueDetails[prop as keyof typeof venueDetails] = currentData;
        } else {
          // @ts-expect-error : Can't use string to index object
          venueDetails[prop] = currentData.filter(
            (item: unknown) => typeof item !== 'string' || !countryExcludedTypes.has(item),
          );
        }

        return venueDetails;
      }, {} as ISupplierDetailVenue),
    },
  };
};
