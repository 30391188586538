import {
  getBeautyFaq,
  getCakesFaq,
  getCateringFaq,
  getDecorationFaq,
  getDressFaq,
  getEntertainmentFaq,
  getFloristFaq,
  getJewelleryFaq,
  getMarqueeFaq,
  getMenswearFaq,
  getMusicFaq,
  getPhotoFaq,
  getPlannersFaq,
  getStationeryFaq,
  getTransportFaq,
  getVenueFaq,
  getVideoFaq,
} from '../data/questions';
import { IFaqObject, IQuestionDraft, IQuestionOrDraft } from '../types';

const questionsByCategory = (): Record<string, Record<string, string>> => ({
  beauty: getBeautyFaq(),
  cakes: getCakesFaq(),
  catering: getCateringFaq(),
  decoration: getDecorationFaq(),
  dress: getDressFaq(),
  entertainment: getEntertainmentFaq(),
  florist: getFloristFaq(),
  jewellery: getJewelleryFaq(),
  marquee: getMarqueeFaq(),
  menswear: getMenswearFaq(),
  music: getMusicFaq(),
  photo: getPhotoFaq(),
  planners: getPlannersFaq(),
  stationery: getStationeryFaq(),
  transport: getTransportFaq(),
  venue: getVenueFaq(),
  video: getVideoFaq(),
});

export const getFaqQuestionsBySupplierType = (supplierType: string) =>
  questionsByCategory()[supplierType.toLowerCase()];

/**
 * For a given question id returns an array provided by a supplier or an empty string
 * @param answers
 * @param questionId
 */
export const findAnswerByQuestionId = (answers: IQuestionOrDraft[], questionId: string) =>
  answers.find((i) => i.question === questionId)?.answer || '';

const findQuestionId = (answers: IQuestionOrDraft[], questionId: string) =>
  answers.find((i) => i.question === questionId)?.id;

/**
 * Compiles FAQ questions together with answers in array for use in UI
 * @param answers - array of answers
 * @param questions - corresponding hard-coded questions array
 */
export const getFaq = (
  answers: IQuestionOrDraft[],
  questions: Record<string, string>,
): IFaqObject[] =>
  Object.entries(questions).map(([questionId, question]) => {
    const answer = findAnswerByQuestionId(answers, questionId);
    const id = findQuestionId(answers, questionId);
    return {
      id,
      answer,
      question,
      questionId,
    };
  });

/**
 * Creates Question draft object to build Question object
 * @param questionId - question identifier like "venueFaqQuestion1"
 * @param answer - FAQ answer as string
 */
export const createQuestionDraft = ({
  questionId,
  answer,
}: {
  questionId: string;
  answer: string;
}): IQuestionDraft => ({
  question: questionId,
  answer,
});
