import { selectSupplier } from 'lib/search/actions';
import {
  enableDemoMode,
  fetchSupplierInvitesSuccess,
  generateSupplierInviteSuccess,
  initSupplierAdminListener,
  onSupplierAdminFetch,
  updateAdminPremiumObject,
} from './actions';

export enum AdminActionTypes {
  MARK_SUPPLIER_AS_DELETED_START = '[Admin] Mark supplier as deleted start',
  MARK_SUPPLIER_AS_DELISTED_START = '[Admin] Mark supplier as delisted start',
  FETCH_SUPPLIER_INVITES = '[Admin] Fetch supplier invites',
  FETCH_SUPPLIER_INVITES_SUCCESS = '[Admin] Fetch supplier invites success',
  GENERATE_INVITE = '[Admin] Generate invite start',
  GENERATE_INVITE_SUCCESS = '[Admin] Generate invite success',
  UNLINK_USER = '[Admin] Unlink user start',
  UNLINK_USER_SUCCESS = '[Admin] Unlink user success',
  ON_SUPPLIER_ADMIN_FETCH = '[Admin] On supplier admin object fetch',
  ADMIN_SELECT_SUPPLIER = '[Admin] Admin Select supplier ACL',
  UPDATE_SUPPLIER_ADMIN_PREMIUM = '[Admin] Update supplier admin premium',
  ENABLE_DEMO_MODE = '[Admin] Enable demo mode',
  DISABLE_DEMO_MODE = '[Admin] Disable demo mode',
  UPDATE_SUPPLIER_ADMIN_SUCCESS = '[Admin] Update supplier admin success',
  UPDATE_SUPPLIER_ADMIN_FAILED = '[Admin] Cannot update supplier admin',
  INIT_SUPPLIER_ADMIN_LISTENER = '[Admin] Init supplier admin listener',
}

export type IFetchSupplierInvitesSuccessAction = ReturnType<typeof fetchSupplierInvitesSuccess>;
export type IGenerateSupplierInviteSuccess = ReturnType<typeof generateSupplierInviteSuccess>;
export type ISelectSupplierAction = ReturnType<ReturnType<typeof selectSupplier>>;
export type OnSupplierAdminFetchAction = ReturnType<typeof onSupplierAdminFetch>;
export type IUpdateAdminPremiumObjectAction = ReturnType<
  ReturnType<typeof updateAdminPremiumObject>
>;

export type IEnableDemoMode = ReturnType<typeof enableDemoMode>;
export type IInitSupplierAdminListener = ReturnType<typeof initSupplierAdminListener>;
