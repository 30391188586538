import { AnalyticsService } from 'lib/analytics/analytics-service';
import { AnalyticsEvents, SwipeDirection } from 'lib/analytics/events';

export interface IWidgetInteracted {
  actionLocation: 'dashboard' | 'performance' | 'enquiries';
  actionSection:
    | 'enquiriesWidget'
    | 'bookingsWidget'
    | 'ROIWidget'
    | 'profileScoreWidget'
    | 'searchPositionWidget'
    | 'enquiryManagerWidget'
    | 'profileTasksWidget'
    | 'lostLeadsWidget'
    | 'dynamicTaskWidget'
    | 'competitorPerformanceWidget'
    | 'couplesInsightWidget'
    | 'searchPerformanceWidget';
  element: string;
  elementLabel?: string;
}

enum WidgetActionType {
  Clicked = 'clicked',
  Hovered = 'hovered',
  Swiped = 'swiped',
}

export const analyticsWidgetService = (analytics: AnalyticsService) => {
  const event = AnalyticsEvents.SupplierInteractedWithWidget;

  return {
    clicked: analytics.createHandler<never, IWidgetInteracted>(WidgetActionType.Clicked, event),
    hovered: analytics.createHandler<never, IWidgetInteracted>(WidgetActionType.Hovered, event),
    swiped: analytics.createHandler<never, IWidgetInteracted & { swipeDirection: SwipeDirection }>(
      WidgetActionType.Swiped,
      event,
    ),
    enquiryClicked: analytics.createHandler<never, IWidgetInteracted & { weddingId: string }>(
      WidgetActionType.Clicked,
      event,
    ),
    dynamicTaskClicked: analytics.createHandler<never, IWidgetInteracted & { actionTask: string }>(
      WidgetActionType.Clicked,
      event,
    ),
  };
};
