import { ofType } from 'redux-observable';
import { Observable, from, merge } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  pluck,
  switchMap,
} from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { appError } from 'lib/app/actions';
import {
  FollowUpMessagesAction,
  IInitializeFollowUpMessages,
} from 'lib/follow-up-messages/action-types';
import { followUpMessageListSaved } from 'lib/follow-up-messages/actions';
import { IApplicationState, IEpicDeps } from 'lib/types';

/**
 * Dispatches action with follow-up messages list in payload, initializes follow-up messages listener.
 */
export const initFollowUpMessagesListenerEpic = (
  action$: Observable<IInitializeFollowUpMessages>,
  { state$ }: IEpicDeps,
) => {
  const supplierId$ = state$.pipe(
    pluck<IApplicationState, ISupplier>('supplier', 'supplier'),
    filter((supplier) => !!supplier?.id),
    map<ISupplier, string>((supplier) => supplier.id),
    distinctUntilChanged(),
  );

  return supplierId$.pipe(
    switchMap((supplierId) =>
      action$.pipe(
        ofType(FollowUpMessagesAction.InitFollowUpMessagesListener),
        first(),
        switchMap(() => {
          const getPromise = async () => {
            const supplierRef = Suppliers._.getById(supplierId);
            const followUpMessages = await supplierRef.FollowUps.query().get();
            return Object.values(followUpMessages);
          };

          const followUpMessages$ = Suppliers._.getById(supplierId)
            .FollowUps.query()
            .observe()
            .pipe(map((followUpMessages) => Object.values(followUpMessages)));

          return merge(from(getPromise()), followUpMessages$).pipe(
            map(followUpMessageListSaved),
            catchError((error) => [appError({ error, feature: 'Follow-up Messages' })]),
          );
        }),
      ),
    ),
  );
};
