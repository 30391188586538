import { YourCouplesActionTypes } from 'lib/couples/action-types';
import { ICoupleBooking, ICoupleBookingDetails } from 'lib/couples/types';

export const fetchBookings = () => ({
  type: YourCouplesActionTypes.FETCH_COUPLES_BOOKINGS_START,
});

export const fetchBookingsSuccess = (bookingsResponse?: {
  bookings: ICoupleBooking[];
  stats: { total: number };
}) => ({
  type: YourCouplesActionTypes.FETCH_COUPLES_BOOKINGS_SUCCESS,
  payload: bookingsResponse,
});

export const fetchBookingSuccess = (bookingsResponse: ICoupleBookingDetails) => ({
  type: YourCouplesActionTypes.FETCH_SINGLE_BOOKINGS_SUCCESS,
  payload: bookingsResponse,
});

export const fetchBooking = () => ({
  type: YourCouplesActionTypes.FETCH_SINGLE_BOOKING_START,
});

export const clearBooking = () => ({
  type: YourCouplesActionTypes.CLEAR_SINGLE_BOOKING,
});
