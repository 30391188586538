import { deleteField } from 'firebase/firestore';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { selectedEnquiryOwnerAnalytics } from 'lib/enquiries/actions';
import { appError } from '../../app/actions';
import { EnquiriesActionTypes, IUpdateEnquiryOwner } from '../action-types';

export const updateOwnerEpic = (action$: Observable<IUpdateEnquiryOwner>) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.UPDATE_OWNER),
    mergeMap(({ payload }) => {
      const { enquiry, ownerId, supplierId } = payload || {};

      const getPromise = async () => {
        if (!enquiry) throw new Error('Enquiry/Owner not passed');

        const supplier = Suppliers._.getById(supplierId);
        return supplier.Weddings.getById(enquiry.id).set({
          owner: ownerId || deleteField(),
        });
      };

      return from(getPromise()).pipe(
        mergeMap(() => of(selectedEnquiryOwnerAnalytics({ enquiry, ownerId }))),
        catchError((error: Error) => of(appError({ error, feature: 'UPDATE_OWNER' }))),
      );
    }),
  );
