import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IQuestion } from './Questions.types';

@Identifiable
@Timestampable
@Untrackable
export class Question extends AbstractDocument<IQuestion> {
  readonly collections = {};

  readonly translatable = (data: IQuestion): IQuestion['_translations'][string] => ({
    answer: data.answer,
  });
}

export class Questions extends AbstractCollection<Question, IQuestion> {
  static path = 'questions';

  constructor(document: Supplier) {
    super(document.collection(Questions.path), Question);
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }
}
