/**
 * Gets pathname from url string (Router.asPath)
 * Useful when we want pathname without query or hash
 * but still keeping /{id} (which Router.pathname removes)
 *
 * e.g.
 * input: couples/enquiries/enquiry-id?query=true#hash
 * output: couples/enquiries/enquiry-id
 */
export const getPathnameFromUrl = (routerAsPath: string) =>
  routerAsPath.split('?')[0].split('#')[0];
