import {
  followUpMessageAddedAnalytics,
  followUpMessageDeletedAnalytics,
  followUpMessageEditedAnalytics,
  followUpScheduledAnalytics,
  scheduledFollowUpDeletedAnalytics,
} from 'lib/follow-up-messages/analytics/actions';

export enum FollowUpMessagesAnalyticsActions {
  AddFollowUpMessageClicked = '[Analytics] Add follow-up message clicked',
  FollowUpMessageAdded = '[Analytics] Follow-up message added',
  FollowUpMessageEdited = '[Analytics] Follow-up message edited',
  FollowUpMessageDeleted = '[Analytics] Follow-up message deleted',
  FollowUpMessageScheduled = '[Analytics] Follow-up message scheduled',
  ScheduledFollowUpDeleted = '[Analytics] Scheduled follow-up deleted',
}

export type IFollowUpMessageAddedAnalytics = ReturnType<typeof followUpMessageAddedAnalytics>;
export type IFollowUpMessageEditedAnalytics = ReturnType<typeof followUpMessageEditedAnalytics>;
export type IFollowUpMessageDeletedAnalytics = ReturnType<typeof followUpMessageDeletedAnalytics>;
export type IFollowUpMessageScheduledAnalytics = ReturnType<typeof followUpScheduledAnalytics>;
export type IScheduledFollowUpDeletedAnalytics = ReturnType<
  typeof scheduledFollowUpDeletedAnalytics
>;
