import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { appError } from '../../app/actions';
import { IEpicDeps } from '../../types';
import { DeleteFeedbackAction, FeedbackActions } from '../action-types';
import { deleteFeedbackError, deleteFeedbackSuccess } from '../actions/delete-feedback';

const deleteFeedbackEpic = (
  action$: Observable<DeleteFeedbackAction>,
  { state$, bbanalytics }: IEpicDeps,
) =>
  action$.pipe(
    ofType(FeedbackActions.DELETE_FEEDBACK),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { feedback },
        },
        state,
      ]) => {
        const supplierId = state.supplier.supplier?.id;
        if (!supplierId) return of();
        const promise = Suppliers._.getById(supplierId).Feedback.getById(feedback.id).delete();

        return from(promise).pipe(
          mergeMap(() => {
            bbanalytics.supplier.testimonials.deleted({ supplierTestimonialId: feedback.id });
            return of(deleteFeedbackSuccess());
          }),
          catchError((error: Error) => [
            appError({ error, feature: 'Feedback' }),
            deleteFeedbackError(error),
          ]),
        );
      },
    ),
  );

export default deleteFeedbackEpic;
