import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { IEpicDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';
import { appError } from '../../app/actions';
import { IDeletePhotos, PhotosActionTypes } from '../action-types';

export const deletePhotosEpic = (
  action$: Observable<IDeletePhotos>,
  { state$, bbanalytics }: IEpicDeps,
) =>
  action$.pipe(
    ofType(PhotosActionTypes.DELETE_PHOTOS),
    withLatestFrom(state$),
    mergeMap(([{ payload: photosToDelete }, state]) => {
      const activeSupplier = state.users.activeSupplierAccessControl;
      const i18n = getI18n();

      if (!activeSupplier) throw new Error('addPhotoOrderEpic: missing activeSupplier');

      const { id } = activeSupplier;

      const supplier = Suppliers._.getById(id);

      const getPromise = async () => {
        if (!activeSupplier) throw new Error('addPhotoOrderEpic: missing activeSupplier');

        photosToDelete.forEach((id) =>
          supplier.Photos.getById(id)
            .delete()
            .then(() => bbanalytics.supplier.photos.deleted({ photoId: id })),
        );
      };

      return from(getPromise()).pipe(
        mergeMap(() =>
          of(toggleSnackbar('success', i18n.t('photoVideo:photosDeletedSuccessfully'))),
        ),
        catchError((error) => of(appError({ error, feature: 'Photos.Delete' }))),
      );
    }),
  );
