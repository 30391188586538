import type { IDatePickerUI } from '@bridebook/toolbox/src/datepicker/types';

const DateRecord: IDatePickerUI = {
  day: '',
  weekDay: '',
  dayUndecided: false,
  month: '',
  monthUndecided: false,
  season: '',
  year: '',
  yearUndecided: false,
};

export default DateRecord;
