import { participatedInAbTestingAnalytics } from '@bridebook/toolbox/src/ab-testing/lib/actions/actions';
import { getParticipatedInAbTestPayload } from '@bridebook/toolbox/src/ab-testing/lib/utils/utils';
import { Action } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';

export default function abTestingAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
) {
  const { track } = bridebookAnalytics.getMethods('CMS');

  if (participatedInAbTestingAnalytics.match(action)) {
    const payload = action.payload;
    const { abTest, variant } = payload;
    track(getParticipatedInAbTestPayload({ abTest, variant }));
  }
}
