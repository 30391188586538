import { IStatisticsHighchartsConfig } from '../types';
import getChartView from './chart-view';

const getHistoricalRankings = (): IStatisticsHighchartsConfig =>
  getChartView({
    seriesName: 'Search position',
    yAxisReversed: true,
    yAxisMin: 1,
    xAxisTitleEnabled: false,
    yAxisTitleEnabled: false,
    legendEnabled: false,
  });

export default getHistoricalRankings;
