import { supplierPropertiesGeneric } from 'lib/analytics-utils';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';
import { Action, State } from '../types';
import { SupplierStatusActionTypes } from './action-types';

export default function supplierStatusAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const { track } = bridebookAnalytics.getMethods('Supplier Status');

  switch (action.type) {
    case SupplierStatusActionTypes.ADD_SUPPLIER_STATUS_ANALYTICS: {
      track({
        event: 'Added supplier status',
        ...supplierPropertiesGeneric(getState()),
      });
      break;
    }
    case SupplierStatusActionTypes.EDIT_SUPPLIER_STATUS_ANALYTICS: {
      track({
        event: 'Edited supplier status',
        ...supplierPropertiesGeneric(getState()),
      });
      break;
    }
    case SupplierStatusActionTypes.REMOVE_SUPPLIER_STATUS_ANALYTICS: {
      track({
        event: 'Removed supplier status',
        ...supplierPropertiesGeneric(getState()),
      });
      break;
    }
  }
}
