import type { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import type { IBrochure } from '@bridebook/models/source/models/Suppliers/Brochures.types';
import type { IUser as ISupplierACLUser } from '@bridebook/models/source/models/Suppliers/Users.types';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { ApiEndpoint } from 'lib/api/api-endpoints';
import { getBrochureFilename, getBrochureTitle } from 'lib/pricing/utils';
import type { IBrochureRTDB } from 'lib/supplier/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';

export type TrackFunction = ReturnType<CmsAnalyticsContext['getMethods']>['track'];

// old cms
export { default as supplierPropertiesGeneric } from './supplierPropertiesGeneric';

interface ISupplierBrochurePropertiesGeneric {
  supplierBrochureFileName: string;
  supplierBrochureTitle: string;
  supplierBrochureSize: string;
}

export const supplierBrochurePropertiesGeneric = (
  brochure: IBrochure | IBrochureRTDB,
  fileBlob: Record<string, any> = {},
): ISupplierBrochurePropertiesGeneric => {
  const fileSize = fileBlob.size;

  return {
    supplierBrochureFileName: getBrochureFilename(brochure),
    supplierBrochureTitle: getBrochureTitle(brochure),
    supplierBrochureSize: fileSize ? (Number(fileSize) / 1024 / 1024).toFixed(2) : '0',
  };
};

export const identifySupplier = ({
  supplier,
  props,
  claimed = false,
}: {
  supplier: ISupplier | ISupplierACLUser;
  props: Record<string, any>;
  claimed?: boolean;
}) =>
  process.browser &&
  authenticatedFetch(
    claimed
      ? ApiEndpoint.analytics.identifySupplierClaimed
      : ApiEndpoint.analytics.identifySupplier,
    {
      body: JSON.stringify({
        pageTitle: window.document.title,
        props,
        supplierId: supplier.id,
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    },
  ).catch((e) => SentryMinimal().captureException(e));
