import { AnalyticsContext, IDefaultProps, IFilterLabelProps } from '@bridebook/analytics';
import * as Sentry from '@sentry/nextjs';
import { CriticalCMSEvents } from 'lib/analyticsTypes';

class CmsAnalyticsContext extends AnalyticsContext {
  private _criticalEvents: string[];

  constructor(analytics: any, defaultProps: IDefaultProps) {
    super(analytics, defaultProps);

    this._criticalEvents = Object.values(CriticalCMSEvents);
  }

  isCriticalEvent(event: string) {
    return this._criticalEvents.includes(event);
  }

  getMethods(category: string, filterLabelProps?: IFilterLabelProps) {
    const methods = super.getMethods(category, filterLabelProps);

    if (process.browser) {
      const { track: parentTrack, identify, identifyWithTrack } = methods;

      const track = (...args: Parameters<typeof parentTrack>) => {
        const [properties, callback] = args;

        return parentTrack(properties, callback).catch((e) => {
          if (this.isCriticalEvent(properties.event)) {
            Sentry.captureException(e);
          }
        });
      };

      return { track, identify, identifyWithTrack: identifyWithTrack.bind({ track, identify }) };
    }

    return methods;
  }
}

export default CmsAnalyticsContext;
