import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { authenticatedPOST } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { CollaboratorsPerSupplier, RequestPayload } from 'pages/api/nonce/fetch-collaborators';
import { updatedUserRoleSuccess } from 'lib/settings/actions';
import { Action, IEpicDeps } from 'lib/types';
import { appError } from '../../app/actions';
import { SettingsActions } from '../action-types';

export const changeUserRoleEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(SettingsActions.UPDATE_USER_ROLE),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { supplier } = state.supplier;

      if (!supplier) return of({});

      const { id, role, supplierId } = action.payload;

      const getPromise = async () => {
        const supplierRef = Suppliers._.getById(supplierId);
        await supplierRef.Users.getById(id).set({ role });
        const response = await authenticatedPOST<RequestPayload, CollaboratorsPerSupplier[]>(
          `/api/nonce/fetch-collaborators`,
          {
            body: {
              supplierId: supplier.id,
            },
          },
        );

        return response;
      };

      return from(getPromise()).pipe(
        map((data) => updatedUserRoleSuccess(data)),
        catchError((error) => of(appError({ error, feature: 'Supplier.UPDATE_USER_ROLE' }))),
      );
    }),
  );
