import { equals } from 'ramda';
import { ActionsObservable, ofType } from 'redux-observable';
import { isObject } from 'remeda';
import { merge } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeAll, pluck } from 'rxjs/operators';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { IFair } from '@bridebook/models/source/models/Suppliers/Fairs.types';
import { IFeedback } from '@bridebook/models/source/models/Suppliers/Feedback.types';
import { IOffer } from '@bridebook/models/source/models/Suppliers/Offers.types';
import { IPackage } from '@bridebook/models/source/models/Suppliers/Packages.types';
import { IQuestion } from '@bridebook/models/source/models/Suppliers/Questions.types';
import { flattenObject } from '@bridebook/toolbox/src/utils/flatten-object';
import { AnyAction } from '@reduxjs/toolkit';
import { saveContentTranslations } from 'lib/content-translations/slice';
import { ContentTranslationsCollectionScope } from 'lib/content-translations/types';
import { FeedbackActions } from 'lib/feedback/action-types';
import { OffersActionTypes } from 'lib/offers/action-types';
import { PricingActionTypes } from 'lib/pricing/action-types';
import { QuestionsActionTypes } from 'lib/questions/action-types';
import { RecommendationsActions } from 'lib/recommendations/action-types';
import { SupplierActionTypes } from 'lib/supplier/action-types';
import type { ActionWithPayload, IEpic } from 'lib/types';

/**
 * This epic listens for some actions that might contain translations and saves them to the store.
 * It listens for the following actions:
 * - `SupplierActionTypes.UPDATE_SUPPLIER` - Save main supplier document translations.
 * - `OffersActionTypes.ON_FIRESTORE_OFFERS` - Save offers translations.
 * - `QuestionsActionTypes.ON_FIRESTORE_QUESTIONS` - Save questions translations.
 * - `RecommendationsActions.UPDATE_WEDDING_FAIRS` - Save fairs translations.
 * - `FeedbackActions.UPDATE_FEEDBACK_LIST` - Save feedback translations.
 * - `PricingActionTypes.ON_FIRESTORE_PACKAGES` - Save packages translations.
 *
 * The translations are saved to the store with dot notation. For example, the main supplier
 * document translations are saved with the scope `supplier`, and the translations for an offer
 * are saved with the scope `offers.${id}`.
 */
export const saveContentTranslationsEpic: IEpic = (action$) =>
  merge(
    // Save main supplier document translations
    action$.pipe(
      ofType(SupplierActionTypes.UPDATE_SUPPLIER),
      pluck<ActionWithPayload<ISupplier>, ISupplier['_translations']>('payload', '_translations'),
      filter(isObject),
      distinctUntilChanged((prev, curr) => equals(prev, curr)),
      map((translations) =>
        saveContentTranslations({ scope: `supplier`, data: flattenObject(translations) }),
      ),
    ),
    // Save offers translations
    createContentTranslationsStreamFor<IOffer>(
      'offers',
      OffersActionTypes.ON_FIRESTORE_OFFERS,
    )(action$),
    // // Save questions translations
    createContentTranslationsStreamFor<IQuestion>(
      'questions',
      QuestionsActionTypes.ON_FIRESTORE_QUESTIONS,
    )(action$),
    // Save fairs
    createContentTranslationsStreamFor<IFair>(
      'fairs',
      RecommendationsActions.UPDATE_WEDDING_FAIRS,
      ['payload', 'fairs'],
    )(action$),
    // Save feedback
    createContentTranslationsStreamFor<IFeedback>(
      'feedback',
      FeedbackActions.UPDATE_FEEDBACK_LIST,
      ['payload', 'feedback'],
    )(action$),
    // Save packages
    createContentTranslationsStreamFor<IPackage>(
      'packages',
      PricingActionTypes.ON_FIRESTORE_PACKAGES,
      ['payload'],
    )(action$),
  );

saveContentTranslationsEpic.epicName = 'saveContentTranslationsEpic';

/**
 * A helper function to create a stream that saves translations for a specific collection.
 * It listens for a specific action type, plucks the translations object from the action payload,
 * and saves the translations to the store. with dot notation.
 * Example:
 *
 * ```ts
 * createTranslationsStreamFor<IOffer>('offers', OffersActionTypes.ON_FIRESTORE_OFFERS)(action$)
 * ```
 *
 * This will listen for `OffersActionTypes.ON_FIRESTORE_OFFERS` action, pluck the translations
 * object from the action payload, and save the translations to the store with the scope
 * `offers.${id}`.
 *
 * @param scope - The scope of the translations collection.
 * @param actionType - The action type that triggers the saving of translations.
 * @param plucks - The path to the translations object in the action payload.
 * @returns A stream that saves translations for a specific collection.
 */
const createContentTranslationsStreamFor =
  <P extends { id: string; _translations?: object }>(
    scope: ContentTranslationsCollectionScope,
    actionType: string,
    plucks: string[] = ['payload'],
  ) =>
  (action$: ActionsObservable<AnyAction>) =>
    action$.pipe(
      ofType(actionType),
      pluck<ActionWithPayload<Record<string, P>>, Record<string, P>>(...plucks),
      distinctUntilChanged((prev, curr) => equals(prev, curr)),
      map((items) => {
        const mappedItems = Array.isArray(items) ? items : Object.values(items);
        return mappedItems
          .map(({ _translations, id }) => ({
            _translations,
            id,
          }))
          .filter(({ _translations }) => isObject(_translations));
      }),
      mergeAll(),
      map(({ _translations, id }) =>
        saveContentTranslations({
          scope: `${scope}.${id}`,
          data: flattenObject(_translations as object),
        }),
      ),
    );
