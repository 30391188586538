import { getI18n } from 'react-i18next';
import { PackagesSections } from '@bridebook/toolbox/src/map-packages-to-sections';

export const packagesDefaults: Record<PackagesSections, Record<string, any>> = {
  [PackagesSections.pricePerHead]: {
    name: 'Price Per Head',
    order: 0,
    guestsType: 'minMax',
    priceType: 'minMax',
    priceMethod: 'perHead',
  },
  [PackagesSections.totalInclusive]: {
    name: 'Total Inclusive',
    order: 1,
    priceMethod: 'fixed',
    priceType: 'minMax',
    guestsType: 'fixed',
  },
  [PackagesSections.venueHire]: {
    name: 'Venue Hire',
    order: 2,
    guestsType: 'guestIndependent',
    priceType: 'minMax',
    priceMethod: 'fixed',
  },
  [PackagesSections.inHouseCatering]: {
    name: 'In House Catering',
    order: 3,
    priceMethod: 'perHead',
    priceType: 'minMax',
    guestsType: 'guestIndependent',
  },
};

export const supplierPackagesNaming = (supplierType: string) => {
  const i18n = getI18n();
  const suppliersWithPackages = {
    beauty: i18n.t('supplierPricing:typicalWeddingDayBeauty'),
    cakes: i18n.t('supplierPricing:typicalWeddingCake'),
    catering: i18n.t('supplierPricing:typicalMenuPerPerson'),
    decoration: i18n.t('supplierPricing:typicalVenueStyling'),
    entertainment: i18n.t('supplierPricing:typicalSet'),
    florist: i18n.t('supplierPricing:typicalWeddingPartyFlowers'),
    marquee: i18n.t('supplierPricing:typicalMarqueeHire'),
    music: i18n.t('supplierPricing:typicalSet'),
    photo: i18n.t('supplierPricing:fullDayPackage'),
    planners: i18n.t('supplierPricing:fullDayPackage'),
    transport: i18n.t('supplierPricing:typicalTransportHire'),
    video: i18n.t('supplierPricing:fullDayPackage'),
  } as Record<string, string>;
  //Typing it to avoid typescript error
  return suppliersWithPackages[supplierType];
};
