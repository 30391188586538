import { createSlice } from '@reduxjs/toolkit';

export type SidebarState = {
  mobile: {
    show: boolean;
    showProfile: boolean;
  };
};

const initialState: SidebarState = {
  mobile: {
    show: false,
    showProfile: false,
  },
};

const deviceSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.mobile.show = !state.mobile.show;
    },
    toggleSidebarProfile(state) {
      state.mobile.showProfile = !state.mobile.showProfile;
    },
    toggleSidebarAll(state) {
      state.mobile.show = !state.mobile.show;
      state.mobile.showProfile = !state.mobile.showProfile;
    },
  },
});

export const { toggleSidebar, toggleSidebarProfile, toggleSidebarAll } = deviceSlice.actions;
export default deviceSlice.reducer;
