import { values } from 'ramda';
import { Social } from '../../types';

enum socialTypes {
  facebook = 'facebook',
  instagram = 'instagram',
  linkedin = 'linkedin',
  pinterest = 'pinterest',
  twitter = 'twitter',
  vimeo = 'vimeo',
  youtube = 'youtube',
}

function getSupplierSocialInputs(social?: Social) {
  return values(socialTypes).map((network) => ({
    name: network,
    title: network.charAt(0).toUpperCase() + network.slice(1),
    value: social?.[network] || '',
  }));
}

export default getSupplierSocialInputs;
