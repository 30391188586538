import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { authenticatedPOST } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import type { RequestPayload, ResponsePayload } from 'pages/api/nonce/remove-access';
import { unlinkSupplierUserSuccess } from 'lib/admin/actions/unlink-user';
import { appError } from '../../app/actions';
import { IEpicDeps } from '../../types';
import { AdminActionTypes } from '../action-types';

export const unlinkSupplierUserEpic = (action$: any, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(AdminActionTypes.UNLINK_USER),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const getPromise = async () => {
        const {
          supplier: { supplier },
        } = state;

        const { userId, removeAuth } = payload || {};

        if (typeof userId !== 'string' || !userId) {
          throw new Error('Cannot unlink user - invalid uid');
        }

        await authenticatedPOST<RequestPayload, ResponsePayload>(`/api/nonce/remove-access`, {
          body: {
            removeAuth,
            supplierId: supplier.id,
            userId,
          },
        });

        return userId;
      };

      return from(getPromise()).pipe(
        mergeMap((userId) => of(unlinkSupplierUserSuccess(userId))),
        catchError((error: Error) => of(appError({ error, feature: 'unlinkSupplierUserEpic' }))),
      );
    }),
  );
