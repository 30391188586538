import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Countries } from '@bridebook/models';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { IEpicDeps } from 'lib/types';
import { appError } from '../../app/actions';
import { IFetchCountries, SupplierActionTypes } from '../action-types';
import { excludeVenueTypes } from '../utils/exclude-venue-types';

export const fetchCountriesEpic = (action$: Observable<IFetchCountries>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(SupplierActionTypes.FETCH_COUNTRIES),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const country = state.supplier?.supplier?.l10n?.country || CountryCodes.GB;

      const promise = Countries._.getById(country).getSupplier();

      return from(promise).pipe(
        map((data) => {
          const parsedData = excludeVenueTypes(country, data?.default);

          // Guarantee that otherVenueType is at the end of the venue types array
          if (parsedData?.typeDetails?.venue?.type) {
            const venueTypes = parsedData.typeDetails.venue.type;
            if (venueTypes && venueTypes.includes('otherVenueType')) {
              parsedData.typeDetails.venue.type = [
                ...venueTypes.filter((type) => type !== 'otherVenueType'),
                'otherVenueType',
              ];
            }
          }

          return { type: SupplierActionTypes.FETCH_COUNTRIES_SUCCESS, payload: parsedData };
        }),
        catchError((error) => of(appError({ error, feature: 'Supplier' }))),
      );
    }),
  );
