import { getI18n } from 'react-i18next';
import { IRadioGroupOption } from '@bridebook/ui/src/components/bbcommon/radio-group-v2/radio-group-v2';

export const getbbDiscountOptions = (): IRadioGroupOption<string>[] => {
  const i18n = getI18n();
  return [
    {
      value: 'bbDiscountNone',
      label: i18n.t('offers:none'),
    },
    {
      value: 'bbDiscount5PerCentDiscount',
      label: i18n.t('offers:discount', { number: 5 }),
    },
    {
      value: 'bbDiscount10PerCentDiscount',
      label: i18n.t('offers:discount', { number: 10 }),
    },
    {
      value: 'bbDiscount20PerCentDiscount',
      label: i18n.t('offers:discount', { number: 20 }),
    },
    {
      value: 'bbDiscountOther',
      label: i18n.t('offers:other'),
    },
  ];
};

export const isNoneDiscount = (val: string) => val === getbbDiscountOptions()[0].value;

export const isOtherDiscount = (val: string) =>
  val === getbbDiscountOptions()[getbbDiscountOptions().length - 1].value;
