import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IAchievement } from './Achievements.types';

@Identifiable
@Timestampable
@Untrackable
export class Achievement extends AbstractDocument<IAchievement> {
  readonly collections = {};

  readonly translatable = (data: IAchievement): IAchievement['_translations'][string] => ({
    name: data.name,
  });
}

export class Achievements extends AbstractCollection<Achievement, IAchievement> {
  static path = 'achievements';

  constructor(document: Supplier) {
    super(document.collection(Achievements.path), Achievement);
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }
}
