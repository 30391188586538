import { AuthForm } from './types';

const Form: AuthForm = {
  disabled: false,
  error: null,
  fields: {
    email: '',
    password: '',
  },
};

export default Form;
