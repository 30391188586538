import { ofType } from 'redux-observable';
import { combineLatest, of } from 'rxjs';
import { catchError, map, pluck, switchMap, takeUntil } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { serializeTimestamps } from '@bridebook/toolbox/src/serialize-timestamps';
import {
  AdminActionTypes,
  IInitSupplierAdminListener,
  OnSupplierAdminFetchAction,
} from 'lib/admin/action-types';
import { onSupplierAdminFetch } from 'lib/admin/actions';
import { appError } from 'lib/app/actions';
import { AuthActionTypes } from 'lib/auth/action-types';
import { IEpic } from 'lib/types';

export const initSupplierAdminListener: IEpic<
  IInitSupplierAdminListener,
  OnSupplierAdminFetchAction | ReturnType<typeof appError>
> = (action$) =>
  action$.pipe(
    ofType(AdminActionTypes.INIT_SUPPLIER_ADMIN_LISTENER),
    pluck('payload'),
    switchMap((id) => {
      const supplierRef = Suppliers._.getById(id);

      const admin$ = supplierRef.Admins.admin.observe().pipe(
        map((admin) => ({
          ...admin,
          actions: admin.actions ? serializeTimestamps(admin.actions) : admin.actions,
        })),
      );
      const score$ = supplierRef.Admins.score.observe();
      const stripe$ = supplierRef.Admins.stripe.observe();
      const chargebee$ = supplierRef.Admins.chargebee.observe();

      return combineLatest([admin$, score$, stripe$, chargebee$]).pipe(
        map(([admin, score, stripe, chargebee]) =>
          onSupplierAdminFetch({
            admin,
            score,
            stripe,
            chargebee,
          }),
        ),
        catchError((error: Error) => of(appError({ error, feature: 'Supplier Admin' }))),
        takeUntil(action$.pipe(ofType(AuthActionTypes.SIGN_OUT_SUCCESS))),
      );
    }),
  );
