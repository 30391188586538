import { format, parseISO, startOfWeek } from 'date-fns';
import { keys } from 'ramda';
import { ChartGranularity } from 'lib/statistics/constants';

const segmentizeTimeseriesData = (
  data: Record<string, number>,
  granularity: ChartGranularity = ChartGranularity.month,
) => {
  const segmentized: Record<string, number> = {};

  // key is date-string e.g. '2020-05-27'
  keys(data).forEach((key: string) => {
    const segmentKey =
      granularity === ChartGranularity.month
        ? format(parseISO(key), 'yyyy-MM')
        : format(startOfWeek(parseISO(key), { weekStartsOn: 1 }), 'yyyy-MM-dd');

    segmentized[segmentKey] = (segmentized[segmentKey] || 0) + data[key];
  });

  return segmentized;
};

export default segmentizeTimeseriesData;
