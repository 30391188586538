import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, mergeMap, pluck } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { appError } from 'lib/app/actions';
import { IDeleteMessageTemplate, MessageTemplatesAction } from 'lib/message-templates/action-types';
import { messageTemplateDeleted, saveMessageTemplateError } from 'lib/message-templates/actions';
import { messageTemplateDeletedAnalytics } from 'lib/message-templates/analytics/actions';
import { IEpicDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';
import { withSupplierId } from 'lib/utils/operators/with-supplier-id';

export const deleteMessageTemplate = (
  action$: Observable<IDeleteMessageTemplate>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(MessageTemplatesAction.DeleteTemplate),
    pluck('payload'),
    withSupplierId(state$),
    mergeMap(([supplierId, template]) => {
      const getPromise = () => {
        const supplierRef = Suppliers._.getById(supplierId);
        return supplierRef.Templates.getById(template.id).delete();
      };

      return from(getPromise()).pipe(
        mergeMap(() => [
          messageTemplateDeletedAnalytics({
            templateTitle: template.title,
            templateId: template.id,
          }),
          messageTemplateDeleted,
          toggleSnackbar('success', getI18n().t('messageTemplates:message.templateDeleted')),
        ]),
        catchError((error) => [
          appError({ error, feature: 'Message Templates' }),
          toggleSnackbar('alert', getI18n().t('messageTemplates:message.deleteTemplateError')),
          saveMessageTemplateError,
        ]),
      );
    }),
  );
