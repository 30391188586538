import { AdminActionTypes } from 'lib/admin/action-types';

export const generateSupplierInvite = () => ({
  type: AdminActionTypes.GENERATE_INVITE,
});

export const generateSupplierInviteSuccess = (invite: string) => ({
  type: AdminActionTypes.GENERATE_INVITE_SUCCESS,
  payload: invite,
});

export const fetchSupplierInvites = () => ({
  type: AdminActionTypes.FETCH_SUPPLIER_INVITES,
});

export const fetchSupplierInvitesSuccess = (inviteUrls: string[]) => ({
  type: AdminActionTypes.FETCH_SUPPLIER_INVITES_SUCCESS,
  payload: inviteUrls,
});
