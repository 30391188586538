import { OffersActionTypes } from 'lib/offers/action-types';
import { deleteSpecialOfferAnalytics } from 'lib/offers/analytics/actions';
import { IDeps } from 'lib/types';

export const deleteSpecialOffer =
  () =>
  ({ dispatch }: IDeps) => {
    dispatch(deleteSpecialOfferAnalytics());

    return {
      type: OffersActionTypes.DELETE_SPECIAL_OFFER,
    };
  };

export const deleteSpecialOfferSuccess = () => ({
  type: OffersActionTypes.DELETE_SPECIAL_OFFER_SUCCESS,
});
