import Router from 'next/router';
import NProgress from 'nprogress';
import { Observable } from 'rxjs';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { ValidationError } from '@bridebook/toolbox/src';
import * as Sentry from '@sentry/nextjs';
import { AppAction, IRouteChange } from 'lib/app/types';
import { IApplicationState, IDeps, IEpicDeps } from 'lib/types';

export const appError = ({
  error,
  feature = '',
}: {
  error: Error;
  feature?: string;
}): AppAction => {
  if (!(error instanceof ValidationError)) {
    Sentry.captureException(error, (scope) => {
      scope.setTag('severity', 'AppError');
      scope.setTag('feature', feature);
      scope.setFingerprint([error.message]);
      return scope;
    });

    // eslint-disable-next-line no-console
    console.error(`AppError feature: ${feature}`);
    // eslint-disable-next-line no-console
    console.error('AppError: ', error);
  }

  return {
    type: 'APP_ERROR',
  };
};

export const routeChangeComplete =
  (payload: IRouteChange) =>
  ({ getState }: IDeps) => {
    const {
      app: { previousPath },
    } = getState();
    return {
      type: 'ROUTE_CHANGE_COMPLETE',
      payload: {
        ...payload,
        previousPath,
      },
    };
  };

export const routeChangeStart = (payload: IRouteChange) => ({
  type: 'ROUTE_CHANGE_START',
  payload,
});

export const updatePathname = (payload: IRouteChange) => () => ({
  type: 'UPDATE_PATHNAME',
  payload,
});

export const resizeWindow = (payload: {
  width: number;
  height: number;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLandscape: boolean;
}): AppAction => ({
  type: 'RESIZE_WINDOW',
  payload,
});

export const windowResizeEpic = (action$: any, { state$ }: IEpicDeps): Observable<any> => {
  const createResizer$ = (state: IApplicationState) =>
    new Observable((observer) => {
      const { isMobileUA, isTabletUA } = state.app.device;

      let resizeTimeout: NodeJS.Timeout | null = null;
      const resizeThrottler = () => {
        if (!resizeTimeout) {
          resizeTimeout = setTimeout(() => {
            resizeTimeout = null;
            const width = window.innerWidth;
            const height = window.innerHeight;
            const isLandscape = width > height;
            const isMobile = width < 641;
            const isTablet = width > 641 && width < 1025;
            const isDesktop = width > 1024;
            const isDesktopDesign = isDesktop || (isTablet && isLandscape);
            const sizes = {
              width,
              height,
              isMobile: isMobileUA && isLandscape ? true : isMobile,
              isTablet: isTabletUA && isLandscape ? true : isTablet,
              isLandscape,
              isDesktop,
              isDesktopDesign,
            };
            observer.next(resizeWindow(sizes));
          }, 500);
        }
      };
      resizeThrottler();
      window.addEventListener('resize', resizeThrottler, false);
    });

  return action$.pipe(
    filter((action: AppAction) => action.type === 'APP_STARTED'),
    withLatestFrom(state$),
    switchMap(([, state]) => createResizer$(state)),
  );
};

export const routingEpic = (action$: any): Observable<any> => {
  const routerObserver$ = () =>
    new Observable((observer) => {
      observer.next(
        updatePathname({
          url: Router.asPath,
          query: Router.query,
        }),
      );
      Router.events.on('routeChangeComplete', () => {
        NProgress.done();
        observer.next(
          routeChangeComplete({
            url: Router.asPath,
            query: Router.query,
          }),
        );
      });
      Router.events.on('routeChangeStart', () => {
        observer.next(
          routeChangeStart({
            url: Router.asPath,
            query: Router.query,
          }),
        );
        NProgress.start();
      });

      Router.events.on('routeChangeError', () => {
        NProgress.done();
      });
    });

  return action$.pipe(
    filter((action: AppAction) => action.type === 'APP_STARTED'),
    switchMap(() => routerObserver$()),
  );
};
