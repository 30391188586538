import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { appError } from '../../app/actions';
import { IEpicDeps } from '../../types';
import { AddFeedbackPhotoAction, FeedbackActions } from '../action-types';
import { addFeedbackPhotoError, setFeedbackPhoto } from '../actions/add-feedback-photo';

const addFeedbackPhotoEpic = (action$: Observable<AddFeedbackPhotoAction>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(FeedbackActions.ADD_FEEDBACK_PHOTO),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { s3file },
        },

        state,
      ]) => {
        //TODO: need to clarify how to calculate portrait property
        const photo = { path: s3file.filename };
        const {
          feedback: { feedback },
        } = state;
        const feedbackId = feedback?.id;
        if (!feedbackId) {
          return of(setFeedbackPhoto(photo));
        }
        const supplierId = state.supplier.supplier?.id;
        if (!supplierId) return of();
        const feedbackRef = Suppliers._.getById(supplierId).Feedback.getById(feedbackId);
        const promise = feedbackRef.set({ photos: [photo] });

        return from(promise).pipe(
          mergeMap(() => of(setFeedbackPhoto(photo))),
          catchError((error: Error) => [
            appError({ error, feature: 'Feedback' }),
            addFeedbackPhotoError(error),
          ]),
        );
      },
    ),
  );

export default addFeedbackPhotoEpic;
