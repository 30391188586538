export enum CouplesActivityActionTypes {
  SET_AVAILABLE_MESSAGES_BALANCE = '[Couples Activity] Set available messages balance',
  INIT_BALANCE_LISTENER = '[Couples Activity] Init balance listener',
  STOP_BALANCE_LISTENER = '[Couples Activity] Stop balance listener',
}

export type ISetAvailableMessagesBalanceAction = ReturnType<
  typeof setAvailableContactCouplesMessagesBalance
>;

export const setAvailableContactCouplesMessagesBalance = (payload: number) => ({
  type: CouplesActivityActionTypes.SET_AVAILABLE_MESSAGES_BALANCE,
  payload,
});

export const initContactCouplesBalanceListener = () => ({
  type: CouplesActivityActionTypes.INIT_BALANCE_LISTENER,
});

export const stopContactCouplesBalanceListener = () => ({
  type: CouplesActivityActionTypes.STOP_BALANCE_LISTENER,
});
