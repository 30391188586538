import { serverTimestamp } from 'firebase/firestore';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, ignoreElements, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { getSupplierUrl } from '@bridebook/toolbox/src';
import { appError } from 'lib/app/actions';
import { Action, IEpicDeps } from 'lib/types';
import { FeedbackActions, SaveReviewResponseAction } from '../action-types';

const saveReviewResponseEpic = (
  action$: Observable<SaveReviewResponseAction>,
  { state$, bbanalytics }: IEpicDeps,
) =>
  action$.pipe(
    ofType(FeedbackActions.SAVE_REVIEW_RESPONSE),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { id, response },
        },
        state,
      ]) => {
        const supplierId = state.supplier.supplier?.id;
        if (!supplierId) return of();
        const supplierRef = Suppliers._.getById(supplierId);

        const getPromise = async () => {
          const feedbackRef = supplierRef.Feedback.getById(id);
          const [messageDocument, supplier] = await Promise.all([
            feedbackRef.get(),
            supplierRef.get(),
          ]);
          const message = messageDocument.response;
          if (message) {
            await feedbackRef.set({
              response: {
                message: response,
                updatedAt: serverTimestamp(),
              },
            });
          } else {
            await feedbackRef.set({
              response: {
                message: response,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
              },
            });
          }

          return supplier;
        };

        return from(getPromise()).pipe(
          tap((supplier) =>
            bbanalytics.supplier.reviews.responded({
              reviewId: id,
              supplierProfileURL: getSupplierUrl(supplier),
            }),
          ),
          catchError((error: Error) => {
            const errorActions: Action[] = [appError({ error, feature: 'Saving review response' })];

            return errorActions;
          }),
          ignoreElements(),
        );
      },
    ),
  );

export default saveReviewResponseEpic;
