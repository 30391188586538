import {
  type Query,
  type QueryDocumentSnapshot,
  collectionGroup,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { Relations } from '@bridebook/models/source/models/Suppliers/Relations';
import { IRelation } from '@bridebook/models/source/models/Suppliers/Relations.types';
import { appError } from 'lib/app/actions';
import { IFetchSupplierRelationsAction, RelationsActionTypes } from 'lib/relations/action-types';
import { fetchSupplierRelationsSuccess } from 'lib/relations/actions';
import { ISupplierRelation } from 'lib/relations/types';
import { IEpicDeps } from 'lib/types';
import { notEmpty } from 'lib/utils/notEmpty';

async function processRelation(relationEntity: QueryDocumentSnapshot<IRelation>) {
  const supplierId = relationEntity.ref.parent?.parent?.id;
  if (supplierId) {
    const supplierEntity = Suppliers._.getById(supplierId);
    const supplier = await supplierEntity.get();
    return {
      id: supplier.id,
      name: supplier.name,
      relation: relationEntity.data(),
    };
  }
  return undefined;
}

export const fetchSupplierRelationsEpic = (
  action$: Observable<IFetchSupplierRelationsAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(RelationsActionTypes.FETCH_SUPPLIER_RELATIONS),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const activeSupplier = state.users.activeSupplierAccessControl;
      if (!activeSupplier?.id) return of();
      const getPromise = async () => {
        const relationsQuery = query(
          collectionGroup(getFirestore(), Relations.path) as Query<IRelation>,
          where('id', '==', activeSupplier.id),
        );

        const relationEntities = await getDocs(relationsQuery);

        const supplierRelationsResponse: (ISupplierRelation | undefined)[] = await Promise.all(
          relationEntities.docs.map((relationEntity) => processRelation(relationEntity)),
        );

        const supplierRelations = supplierRelationsResponse.filter(notEmpty);

        return { supplierRelations };
      };

      return from(getPromise()).pipe(
        map(({ supplierRelations }) =>
          fetchSupplierRelationsSuccess({
            supplierRelations,
          }),
        ),
        catchError((error) =>
          of(appError({ error, feature: RelationsActionTypes.FETCH_SUPPLIER_RELATIONS })),
        ),
      );
    }),
  );
