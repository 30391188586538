import { getI18n } from 'react-i18next';

const getGenericFaqQuestions = (prefix: string) => {
  const i18n = getI18n();
  return {
    [`${prefix}FaqQuestion1`]: i18n.t('supplier:inputTextArea.genericFaqQuestion1.label'),
    [`${prefix}FaqQuestion2`]: i18n.t('supplier:inputTextArea.genericFaqQuestion2.label'),
    [`${prefix}FaqQuestion3`]: i18n.t('supplier:inputTextArea.genericFaqQuestion3.label'),
    [`${prefix}FaqQuestion4`]: i18n.t('supplier:inputTextArea.genericFaqQuestion4.label'),
    [`${prefix}FaqQuestion5`]: i18n.t('supplier:inputTextArea.genericFaqQuestion5.label'),
    [`${prefix}FaqQuestion6`]: i18n.t('supplier:inputTextArea.genericFaqQuestion6.label'),
  };
};

export const getBeautyFaq = () => {
  const i18n = getI18n();
  return {
    ...getGenericFaqQuestions('beauty'),
    beautyFaqQuestion7: i18n.t('supplier:inputTextArea.beautyFaqQuestion7.label'),
    beautyFaqQuestion8: i18n.t('supplier:inputTextArea.beautyFaqQuestion8.label'),
    beautyFaqQuestion9: i18n.t('supplier:inputTextArea.beautyFaqQuestion9.label'),
    beautyFaqQuestion10: i18n.t('supplier:inputTextArea.beautyFaqQuestion10.label'),
  };
};

export const getCakesFaq = () => {
  const i18n = getI18n();
  return {
    ...getGenericFaqQuestions('cake'),
    cakeFaqQuestion7: i18n.t('supplier:inputTextArea.cakeFaqQuestion7.label'),
    cakeFaqQuestion8: i18n.t('supplier:inputTextArea.cakeFaqQuestion8.label'),
    cakeFaqQuestion9: i18n.t('supplier:inputTextArea.cakeFaqQuestion9.label'),
    cakeFaqQuestion10: i18n.t('supplier:inputTextArea.cakeFaqQuestion10.label'),
  };
};

export const getCateringFaq = () => {
  const i18n = getI18n();
  return {
    ...getGenericFaqQuestions('caterer'),
    catererFaqQuestion7: i18n.t('supplier:inputTextArea.catererFaqQuestion7.label'),
    catererFaqQuestion8: i18n.t('supplier:inputTextArea.catererFaqQuestion8.label'),
    catererFaqQuestion9: i18n.t('supplier:inputTextArea.catererFaqQuestion9.label'),
    catererFaqQuestion10: i18n.t('supplier:inputTextArea.catererFaqQuestion10.label'),
  };
};

export const getDecorationFaq = () => {
  const i18n = getI18n();
  return {
    ...getGenericFaqQuestions('decoration'),
    decorationFaqQuestion7: i18n.t('supplier:inputTextArea.decorationFaqQuestion7.label'),
    decorationFaqQuestion8: i18n.t('supplier:inputTextArea.decorationFaqQuestion8.label'),
    decorationFaqQuestion9: i18n.t('supplier:inputTextArea.decorationFaqQuestion9.label'),
    decorationFaqQuestion10: i18n.t('supplier:inputTextArea.decorationFaqQuestion10.label'),
  };
};

export const getDressFaq = () => {
  const i18n = getI18n();
  return {
    ...getGenericFaqQuestions('dress'),
    dressFaqQuestion7: i18n.t('supplier:inputTextArea.dressFaqQuestion7.label'),
    dressFaqQuestion8: i18n.t('supplier:inputTextArea.dressFaqQuestion8.label'),
    dressFaqQuestion9: i18n.t('supplier:inputTextArea.dressFaqQuestion9.label'),
    dressFaqQuestion10: i18n.t('supplier:inputTextArea.dressFaqQuestion10.label'),
  };
};

export const getEntertainmentFaq = () => {
  const i18n = getI18n();
  return {
    ...getGenericFaqQuestions('entertainment'),
    entertainmentFaqQuestion7: i18n.t('supplier:inputTextArea.entertainmentFaqQuestion7.label'),
    entertainmentFaqQuestion8: i18n.t('supplier:inputTextArea.entertainmentFaqQuestion8.label'),
    entertainmentFaqQuestion9: i18n.t('supplier:inputTextArea.entertainmentFaqQuestion9.label'),
    entertainmentFaqQuestion10: i18n.t('supplier:inputTextArea.entertainmentFaqQuestion10.label'),
  };
};

export const getFloristFaq = () => {
  const i18n = getI18n();
  return {
    ...getGenericFaqQuestions('florist'),
    floristFaqQuestion7: i18n.t('supplier:inputTextArea.floristFaqQuestion7.label'),
    floristFaqQuestion8: i18n.t('supplier:inputTextArea.floristFaqQuestion8.label'),
    floristFaqQuestion9: i18n.t('supplier:inputTextArea.floristFaqQuestion9.label'),
    floristFaqQuestion10: i18n.t('supplier:inputTextArea.floristFaqQuestion10.label'),
  };
};

export const getJewelleryFaq = () => {
  const i18n = getI18n();
  return {
    ...getGenericFaqQuestions('jewellery'),
    jewelleryFaqQuestion7: i18n.t('supplier:inputTextArea.jewelleryFaqQuestion7.label'),
    jewelleryFaqQuestion8: i18n.t('supplier:inputTextArea.jewelleryFaqQuestion8.label'),
    jewelleryFaqQuestion9: i18n.t('supplier:inputTextArea.jewelleryFaqQuestion9.label'),
    jewelleryFaqQuestion10: i18n.t('supplier:inputTextArea.jewelleryFaqQuestion10.label'),
  };
};

export const getMarqueeFaq = () => {
  const i18n = getI18n();
  return {
    ...getGenericFaqQuestions('marquee'),
    marqueeFaqQuestion7: i18n.t('supplier:inputTextArea.marqueeFaqQuestion7.label'),
    marqueeFaqQuestion8: i18n.t('supplier:inputTextArea.marqueeFaqQuestion8.label'),
    marqueeFaqQuestion9: i18n.t('supplier:inputTextArea.marqueeFaqQuestion9.label'),
    marqueeFaqQuestion10: i18n.t('supplier:inputTextArea.marqueeFaqQuestion10.label'),
  };
};

export const getMenswearFaq = () => {
  const i18n = getI18n();
  return {
    ...getGenericFaqQuestions('menswear'),
    menswearFaqQuestion7: i18n.t('supplier:inputTextArea.menswearFaqQuestion7.label'),
    menswearFaqQuestion8: i18n.t('supplier:inputTextArea.menswearFaqQuestion8.label'),
    menswearFaqQuestion9: i18n.t('supplier:inputTextArea.menswearFaqQuestion9.label'),
    menswearFaqQuestion10: i18n.t('supplier:inputTextArea.menswearFaqQuestion10.label'),
  };
};

export const getMusicFaq = () => {
  const i18n = getI18n();
  return {
    ...getGenericFaqQuestions('music'),
    musicFaqQuestion7: i18n.t('supplier:inputTextArea.musicFaqQuestion7.label'),
    musicFaqQuestion8: i18n.t('supplier:inputTextArea.musicFaqQuestion8.label'),
    musicFaqQuestion9: i18n.t('supplier:inputTextArea.musicFaqQuestion9.label'),
    musicFaqQuestion10: i18n.t('supplier:inputTextArea.musicFaqQuestion10.label'),
  };
};

export const getPhotoFaq = () => {
  const i18n = getI18n();
  return {
    ...getGenericFaqQuestions('photo'),
    photoFaqQuestion7: i18n.t('supplier:inputTextArea.photoFaqQuestion7.label'),
    photoFaqQuestion8: i18n.t('supplier:inputTextArea.photoFaqQuestion8.label'),
    photoFaqQuestion9: i18n.t('supplier:inputTextArea.photoFaqQuestion9.label'),
    photoFaqQuestion10: i18n.t('supplier:inputTextArea.photoFaqQuestion10.label'),
  };
};

export const getPlannersFaq = () => {
  const i18n = getI18n();
  return {
    ...getGenericFaqQuestions('planners'),
    plannersFaqQuestion7: i18n.t('supplier:inputTextArea.plannersFaqQuestion7.label'),
    plannersFaqQuestion8: i18n.t('supplier:inputTextArea.plannersFaqQuestion8.label'),
    plannersFaqQuestion9: i18n.t('supplier:inputTextArea.plannersFaqQuestion9.label'),
    plannersFaqQuestion10: i18n.t('supplier:inputTextArea.plannersFaqQuestion10.label'),
  };
};

export const getStationeryFaq = () => {
  const i18n = getI18n();
  return {
    ...getGenericFaqQuestions('stationery'),
    stationeryFaqQuestion7: i18n.t('supplier:inputTextArea.stationeryFaqQuestion7.label'),
    stationeryFaqQuestion8: i18n.t('supplier:inputTextArea.stationeryFaqQuestion8.label'),
    stationeryFaqQuestion9: i18n.t('supplier:inputTextArea.stationeryFaqQuestion9.label'),
    stationeryFaqQuestion10: i18n.t('supplier:inputTextArea.stationeryFaqQuestion10.label'),
  };
};

export const getTransportFaq = () => {
  const i18n = getI18n();
  return {
    ...getGenericFaqQuestions('transport'),
    transportFaqQuestion7: i18n.t('supplier:inputTextArea.transportFaqQuestion7.label'),
    transportFaqQuestion8: i18n.t('supplier:inputTextArea.transportFaqQuestion8.label'),
    transportFaqQuestion9: i18n.t('supplier:inputTextArea.transportFaqQuestion9.label'),
    transportFaqQuestion10: i18n.t('supplier:inputTextArea.transportFaqQuestion10.label'),
  };
};

export const getVenueFaq = () => {
  const i18n = getI18n();
  return {
    venueFaqQuestion1: i18n.t('supplier:inputTextArea.bestWedding.label'),
    venueFaqQuestion2: i18n.t('supplier:inputTextArea.numberOnePlanningTip.label'),
    venueFaqQuestion3: i18n.t('supplier:inputTextArea.favouritePersonalTouch.label'),
    venueFaqQuestion4: i18n.t('supplier:inputTextArea.fiveAdjectives.label'),
    venueFaqQuestion5: i18n.t('supplier:inputTextArea.bestPhotoSpot.label'),
    venueFaqQuestion6: i18n.t('supplier:inputTextArea.celebrityGuest.label'),
    venueFaqQuestion7: i18n.t('supplier:inputTextArea.favouriteCanape.label'),
    venueFaqQuestion8: i18n.t('supplier:inputTextArea.favouriteMidnightTreat.label'),
    venueFaqQuestion9: i18n.t('supplier:inputTextArea.uniqueFeatures.label'),
    venueFaqQuestion10: i18n.t('supplier:inputTextArea.proudBusiness.label'),
  };
};

export const getVideoFaq = () => {
  const i18n = getI18n();
  return {
    ...getGenericFaqQuestions('video'),
    videoFaqQuestion7: i18n.t('supplier:inputTextArea.videoFaqQuestion7.label'),
    videoFaqQuestion8: i18n.t('supplier:inputTextArea.videoFaqQuestion8.label'),
    videoFaqQuestion9: i18n.t('supplier:inputTextArea.videoFaqQuestion9.label'),
    videoFaqQuestion10: i18n.t('supplier:inputTextArea.videoFaqQuestion10.label'),
  };
};
