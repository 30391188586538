import { format, setDate, setMonth } from 'date-fns/esm';
import { getI18n } from 'react-i18next';
import { getDayOrdinal } from '@bridebook/toolbox/src/datepicker/get-day-ordinal';
import type { IDatePickerUI, WeekDayUI } from '@bridebook/toolbox/src/datepicker/types';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { getDateFnsLocales } from 'lib/utils/date-fns/format-i18n-date';
import getLocalisedSeason from './get-localised-season-string';

const getLocalisedWeekDay = (weekDay: WeekDayUI | '', locale: string) => {
  if (!weekDay) return '';
  const dateFnsLocale = getDateFnsLocales(locale);

  switch (weekDay) {
    case 'Friday':
      return dateFnsLocale.localize?.day(5);
    case 'Mon-Thurs':
      return `${dateFnsLocale.localize?.day(1, {
        width: 'abbreviated',
      })}-${dateFnsLocale.localize?.day(4, { width: 'abbreviated' })}`;
    case 'Saturday':
      return dateFnsLocale.localize?.day(6);
    case 'Sunday':
      return dateFnsLocale.localize?.day(7);
    default:
      return '';
  }
};

export interface IGetDatePickerTitleLabels {
  unknown: string;
  undecided: string;
  weekDay(weekDay: string): string;
  weekDayMonthYear(weekDay: string, monthOrSeason: string, year: string): string;
  dayOfMonth(day: string, month: string): string;
}
interface IGetDatePickerTitleParams {
  datePickerUI: IDatePickerUI | null;
  dateIsUnknown?: boolean;
  locale: string;
  labels?: Partial<IGetDatePickerTitleLabels>;
}

/**
 * Returns a friendly date string from a datepicker object.
 *
 * @param {Object} datePickerUI - Date picker object.
 * @param {boolean} dateIsUnknown - Indicates if the date is unknown.
 * @param {string} locale - Locale string for localization (default: Countries.getDefault().locale).
 * @param {Object} labels - Custom labels for localization.
 * @returns {string} - Friendly date string.
 */
const getDatepickerTitle = ({
  datePickerUI,
  dateIsUnknown,
  locale = gazetteer.getMarketByCountry(CountryCodes.GB).locale,
  labels = {},
}: IGetDatePickerTitleParams): string => {
  if (!datePickerUI) return '';

  const { year, yearUndecided, month, monthUndecided, season, day, weekDay, dayUndecided } =
    datePickerUI;
  const localisedWeekDay = getLocalisedWeekDay(weekDay, locale);
  const dateFnsLocale = getDateFnsLocales(locale);
  const monthString = month ? `${dateFnsLocale.localize?.month(parseInt(month))} ` : '';
  const localisedSeason = season ? `${getLocalisedSeason(season, locale)} ` : '';

  const hasMonth = month || season;
  const hasDay = day || localisedWeekDay;

  const defaultLabels: IGetDatePickerTitleLabels = {
    unknown: getI18n().t('common:dateUnknown'),
    undecided: getI18n().t('common:dateUndecided'),
    weekDay: (weekDay) =>
      getI18n().t('common:datePicker.title.weekDay', {
        weekDay,
      }),
    weekDayMonthYear: (weekDay, monthOrSeason, year) =>
      getI18n().t('common:datePicker.title.weekDayMonthYear', {
        weekDay,
        monthOrSeason,
        year,
      }),
    dayOfMonth: (day, month) =>
      getI18n().t('common:datePicker.title.dayOfMonth', {
        day,
        month,
      }),
  };

  const mergedLabels = Object.assign(defaultLabels, labels) as IGetDatePickerTitleLabels;

  if (
    dateIsUnknown &&
    !(yearUndecided && monthUndecided && dayUndecided) &&
    (yearUndecided || monthUndecided || dayUndecided) &&
    !hasMonth &&
    !hasDay &&
    !year
  ) {
    return '';
  }

  if (yearUndecided && !hasMonth && !hasDay) {
    return dateIsUnknown ? mergedLabels.unknown : mergedLabels.undecided;
  }

  if (yearUndecided && monthUndecided && localisedWeekDay) {
    return mergedLabels.weekDay(localisedWeekDay);
  }

  if (year && !month && !season && !day && !localisedWeekDay) {
    return `${year}`;
  }

  if ((year || yearUndecided) && localisedWeekDay) {
    return mergedLabels.weekDayMonthYear(localisedWeekDay, monthString || localisedSeason, year);
  }

  if ((year || yearUndecided) && (month || season) && !localisedWeekDay && !day) {
    return `${monthString || localisedSeason}${year}`;
  }

  if (!year && month && day) {
    const fullDate = setDate(setMonth(new Date(), parseFloat(month)), parseFloat(day));
    return mergedLabels.dayOfMonth(
      format(fullDate, getDayOrdinal(locale), {
        locale: dateFnsLocale,
      }),
      monthString.replace(/ /g, ''),
    );
  }

  if (year && month && day) {
    const fullDate = setDate(new Date(parseFloat(year), parseFloat(month), 1), parseFloat(day));
    return format(fullDate, `${getDayOrdinal(locale)} MMM yyyy`, {
      locale: dateFnsLocale,
    });
  }

  return '';
};

export default getDatepickerTitle;
