import { arrayUnion, serverTimestamp } from 'firebase/firestore';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { SupplierUsers, Suppliers } from '@bridebook/models';
import { IUser as ISupplierACLUser } from '@bridebook/models/source/models/Suppliers/Users.types';
import { authenticatedPOST } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import type { RequestPayload, ResponsePayload } from 'pages/api/nonce/invite/consume';
import { AccessControlActionTypes, ISwitchPermissionAction } from 'lib/access-control/action-types';
import {
  switchSupplier,
  updateAccessControlSuccess,
  updateExtendedActiveSupplierACL,
} from 'lib/access-control/actions/update-acl';
import { IACLUserExtended } from 'lib/access-control/types';
import { appError } from 'lib/app/actions';
import { getPathname } from 'lib/app/selectors';
import { claimedSupplierProfileAnalytics } from 'lib/auth/analytics/actions';
import { ICollaboratorInviteType } from 'lib/auth/types';
import { RecentSupplierStorage } from 'lib/auth/utils/admin-recent-supplier-storage';
import { Action, ActionCreatorWithDeps, IEpicDeps } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';
import { IOnUserListenerAction, UserActionTypes } from 'lib/users/action-types';
import { extendUserAccessControl } from '../utils/extend-user-access-control';
import { isUserAnAdmin } from '../utils/is-admin';

type ProcessInviteFlowParams = {
  collaboratorInvite: ICollaboratorInviteType;
  userId: string;
};

/**
 * The whole supplier claiming process essentially consists of 5 steps:
 * 1. Add Firebase Authentication User
 * 2. Add Firebase Auth User to `supplier-users` collection in Firestore
 * 3. Add an ACL document in `supplier/:id/users` collection in Firestore
 * 4. Add supplier id to `supplier-users.suppliers[]` array in user document
 * 5. Add user id to `supplier.users[]` array in the supplier document
 *
 * If we come this far, we already can :check: 1. and 2. :-)
 * Since adding step 3 requires elevated privileges, we have to do this
 * in the backend (in this case a API/CloudFunction) to be able to circumvent
 * Firestore's security rules. Once we have that, we immediately add the
 * supplier to the user object (step 4) and vica verse (step 5). This is
 * the `/api/invite/consume` part of this function.
 *
 * Additionally we set the registration date, if this was a newly claimed
 * supplier profile.
 *
 * Ideally all of the above steps happen atomically, so that the claim either
 * works or does not work, but atm we are having to split this in single
 * steps.
 */
const processInviteFlow = async ({ collaboratorInvite, userId }: ProcessInviteFlowParams) => {
  const { nonceId, nonceSecret } = collaboratorInvite;

  /**
   * This will attempt to consume the nonce, attach the user to the target supplier and make it the
   * active one.
   */
  const response = await authenticatedPOST<RequestPayload, ResponsePayload>(
    `/api/nonce/invite/consume`,
    {
      body: {
        nonceId,
        nonceSecret,
      },
    },
    `Could not consume nonce with ID '${nonceId}'.`,
  );

  const supplierAdmin = Suppliers._.getById(response.supplierId).Admins.admin;
  const supplierAdminData = await supplierAdmin.get();

  if (supplierAdminData?.registrationDate == null) {
    await supplierAdmin.set({
      registrationDate: serverTimestamp(),
    });
  }

  return await SupplierUsers._.getById(userId).getActiveSupplierAccessControl();
};

type UpdateACLParams = {
  activeSupplierAccessControl: ISupplierACLUser | null;
  userId: string;
};

/**
 * ! I believe this is an old RTDB->Firestore suppliers artifact that should be removed.
 */
const updateACL = async ({ activeSupplierAccessControl, userId }: UpdateACLParams) => {
  const supplierUser = SupplierUsers._.getById(userId);
  const supplierUserData = await supplierUser.get();

  if (activeSupplierAccessControl) {
    const activeSupplier = Suppliers._.getById(activeSupplierAccessControl.id);
    const activeSupplierData = await activeSupplier.get();

    /**
     * It's possible that `/suppliers/{supplierId}.users` is being tampered with outside of our
     * knowledge. In an ideal world, this wouldn't happen. To be on the safe side however, we keep
     * this snippet to avoid regressions.
     */
    if (activeSupplierData?.users?.includes(userId) !== true) {
      await activeSupplier.set({
        users: arrayUnion(userId),
      });
    }
  }

  return {
    needToUpdateSupplierState: false,
    userAccessControl: supplierUserData.suppliers ?? [],
  };
};

export const readAccessControl = (
  action$: Observable<IOnUserListenerAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(UserActionTypes.ON_USER_LISTENER),
    withLatestFrom(state$),
    distinctUntilChanged(
      ([{ payload: previousUser }], [{ payload: nextUser }]) =>
        previousUser !== null && nextUser !== null && previousUser.id === nextUser.id,
    ),
    filter(([{ payload: user }]) => Boolean(user && user.id)),
    mergeMap(([{ payload: user }, state]) => {
      if (!user?.id) {
        return of();
      }

      const emitNextActions: (Action | ReturnType<ActionCreatorWithDeps<Action>>)[] = [];

      const getPromise = async () => {
        const { supplier } = state.supplier;
        const { collaboratorInvite } = state.auth;

        const supplierUser = SupplierUsers._.getById(user.id);
        const pathname = getPathname(state);

        let needToUpdateSupplierState = !supplier?.id;
        let activeSupplierAccessControl: ISupplierACLUser | null;
        let extendedSupplierACL: Record<string, IACLUserExtended> | undefined;

        const isAdmin = await isUserAnAdmin(user.id);
        const recentSupplierId = RecentSupplierStorage.get();

        // Admin flow with a recent supplier (without search page)
        if (isAdmin && recentSupplierId && !pathname.startsWith(UrlHelper.admin.search)) {
          activeSupplierAccessControl = {
            id: recentSupplierId,
            primary: true,
            // @ts-ignore
            createdAt: new Date().getTime() / 1000,
            role: 'admin',
          };
          // Extend the ACL with active supplier data (for top bar profile view)
          extendedSupplierACL = await extendUserAccessControl([recentSupplierId], user.id);
        }
        // Collaborator invite flow
        else if (collaboratorInvite?.invite?.id) {
          needToUpdateSupplierState = true;

          activeSupplierAccessControl = await processInviteFlow({
            collaboratorInvite,
            userId: user.id,
          });

          emitNextActions.push(
            claimedSupplierProfileAnalytics({
              supplierId: collaboratorInvite.invite.id,
              user: { id: user.id },
            }),
          );
        }
        // Regular user flow
        else {
          activeSupplierAccessControl = await supplierUser.getActiveSupplierAccessControl();
        }

        // Update ACL if needed with new supplier and return updated user
        let userAccessControl: string[] = [];
        if (!isAdmin) {
          const updatedACL = await updateACL({
            activeSupplierAccessControl,
            userId: user.id,
          });
          userAccessControl = updatedACL.userAccessControl;
          needToUpdateSupplierState =
            updatedACL.needToUpdateSupplierState || needToUpdateSupplierState;
        }

        return {
          activeSupplierAccessControl,
          extendedSupplierACL,
          needToUpdateSupplierState,
          userAccessControl,
          isAdmin,
        };
      };

      return from(getPromise()).pipe(
        mergeMap(
          ({
            needToUpdateSupplierState,
            activeSupplierAccessControl,
            extendedSupplierACL,
            userAccessControl,
            isAdmin,
          }) => {
            if (needToUpdateSupplierState && (activeSupplierAccessControl || isAdmin)) {
              emitNextActions.push(
                updateAccessControlSuccess({
                  userAccessControl,
                  activeSupplierAccessControl,
                  isAdmin,
                }),
              );
            }

            if (extendedSupplierACL) {
              emitNextActions.push(
                updateExtendedActiveSupplierACL({
                  activeSupplierAccessControl,
                  extendedSupplierACL,
                }),
              );
            }

            return of(...emitNextActions);
          },
        ),
        catchError((error: Error) =>
          of(appError({ error, feature: 'Access Control readAccessControl' })),
        ),
      );
    }),
  );

export const switchPermissionEpic = (
  action$: Observable<ISwitchPermissionAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(AccessControlActionTypes.SWITCH_PERMISSION_START),
    withLatestFrom(state$),
    mergeMap(([{ payload: supplierId }, state]) => {
      const userId = state.users.viewer?.id;

      if (!userId) {
        return of();
      }

      const getPromise = async () => {
        const supplierUser = SupplierUsers._.getById(userId);

        await supplierUser.setActiveSupplier(supplierId);

        /**
         * Of course we could use supplierUser.getActiveSupplierAccessControl
         * for fetching the latest ACL for the supplierId we just set above,
         * BUT - `setActiveSupplier` will run in a transaction and even once
         * the await has returned, the data is still not "written do disk".
         * So that subsequent requests to the supplier-user document will
         * receive the "old" active supplier and therefore the previous ACL.
         *
         * Therefore and since we allready have all the information anyway,
         * let's fetch the ACL for the current passed supplier manually and
         * pass this further down the "epic" chain.
         *
         * :sadpepe:
         */
        const [userAcl, isAdmin] = await Promise.all([
          Suppliers._.getById(supplierId).Users.getById(userId).get(),
          isUserAnAdmin(userId),
        ]);

        return {
          activeSupplierAccessControl: {
            ...userAcl,
            id: supplierId,
          },
          isAdmin,
        };
      };

      return from(getPromise()).pipe(
        mergeMap(({ activeSupplierAccessControl, isAdmin }) =>
          of(switchSupplier({ activeSupplierAccessControl, isAdmin }), {
            type: AccessControlActionTypes.SWITCH_PERMISSION_SUCCESS,
          }),
        ),
        catchError((error: Error) =>
          of(appError({ error, feature: 'Access Control switchPermissionEpic' })),
        ),
      );
    }),
  );
