import { SupplierSpecialOffer } from 'lib/offers/types';
import { IOffersAnalyticsActionTypes } from './action-types';

export const saveSpecialOfferAnalytics = (previousOffer: SupplierSpecialOffer | null) => ({
  type: IOffersAnalyticsActionTypes.SAVE_SPECIAL_OFFER_ANALYTICS,
  payload: { previousOffer },
});

export const deleteSpecialOfferAnalytics = () => ({
  type: IOffersAnalyticsActionTypes.DELETE_SPECIAL_OFFER_ANALYTICS,
});
