import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { verifyPermissions } from 'lib/access-control/utils/verify-permissions';
import { AuthActionTypes, ISignUpAction } from 'lib/auth/action-types';
import { signInSignupSuccess, signUpError } from 'lib/auth/actions';
import { AuthBridebookError, AuthProviders } from 'lib/auth/types';
import { checkExistingProviders } from 'lib/auth/utils/auth-provider-utils';
import { validateEmailAndPassword } from 'lib/auth/utils/validate-email-pass';
import { IEpicDeps } from 'lib/types';

export const signUpEpic = (
  action$: Observable<ISignUpAction>,
  { firebaseApp, validate, state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(AuthActionTypes.SIGN_UP),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { fields },
        },
        state,
      ]) => {
        const getPromise = async () => {
          const firebaseAuth = getAuth(firebaseApp);

          const { collaboratorInvite } = state.auth;
          await validateEmailAndPassword(validate, fields);
          await checkExistingProviders(firebaseAuth, fields.email, AuthProviders.PASSWORD);
          await verifyPermissions(collaboratorInvite, fields.email);
          return createUserWithEmailAndPassword(firebaseAuth, fields.email, fields.password);
        };

        return from(getPromise()).pipe(
          mergeMap((userCredential) =>
            of(signInSignupSuccess(userCredential.user, AuthActionTypes.SIGN_UP_SUCCESS)),
          ),
          catchError((error: AuthBridebookError) => of(signUpError(fields, error))),
        );
      },
    ),
  );
