import { getI18n } from 'react-i18next';
import { IRadioGroupOption } from '@bridebook/ui/src/components/bbcommon/radio-group-v2/radio-group-v2';
import { ITargetingMappingDayValues } from 'lib/supplier/types';

interface ITargetingDay extends IRadioGroupOption<string> {
  value: ITargetingMappingDayValues;
}

export const getOptionsDay = (): ITargetingDay[] => {
  const i18n = getI18n();

  return [
    {
      label: i18n.t('common:All'),
      value: 'all',
    },
    {
      label: i18n.t('common:mon-thurs'),
      value: 'Mon-Thurs',
    },
    {
      label: i18n.t('common:friday'),
      value: 'Friday',
    },
    {
      label: i18n.t('common:saturday'),
      value: 'Saturday',
    },
    {
      label: i18n.t('common:sunday'),
      value: 'Sunday',
    },
  ];
};
