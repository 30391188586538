import {
  clearRW,
  deleteRW,
  editRW,
  saveRW,
  saveRWError,
  updateRWForm,
  updateRWList,
} from './actions';

export enum RWActions {
  CLEAR = '[RW] Clear',
  DELETE = '[RW] Delete',
  DELETE_ERROR = '[RW] Delete error',
  DELETE_SUCCESS = '[RW] Delete success',
  EDIT = '[RW] Edit',
  INIT_LISTENER = '[RW] Init listener',
  RESET_NOTIFICATION = '[RW] Reset notification',
  SAVE = '[RW] Save',
  SAVE_ERROR = '[RW] Save error',
  SAVE_SUCCESS = '[RW] Save success',
  STOP_LISTENER = '[RW] Stop listener',
  UPDATE_FORM = '[RW] Update form',
  UPDATE_LIST = '[RW] Update list',
}

export type ClearRWAction = ReturnType<typeof clearRW>;
export type DeleteRWAction = ReturnType<typeof deleteRW>;
export type EditRWAction = ReturnType<typeof editRW>;
export type SaveRWAction = ReturnType<typeof saveRW>;
export type SaveRWErrorAction = ReturnType<typeof saveRWError>;
export type UpdateRWFormAction = ReturnType<typeof updateRWForm>;
export type UpdateRWListAction = ReturnType<typeof updateRWList>;
