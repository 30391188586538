import { type IReport } from '@bridebook/models/source/models/Suppliers/Reports.types';
import { type IReportDefaultValues } from 'lib/roi/hooks/use-ROI-report-default-values';

export const mapROIReportToAnalyticsPayload = (
  {
    numberOfEnquiries,
    estimatedNumberOfBookings,
    grossRevenue,
    totalSubscriptionCost,
    revenueROI,
    grossProfit,
    grossProfitROI,
    reportStartDate,
    reportEndDate,
    id: reportId,
    numberOfIntroductions,
    numberOfViews,
    numberOfShortlists,
    numberOfRedirectsToWeb,
    additionalBookingsEstimate,
    additionalRevenueEstimate,
    countyPosition,
    countryPosition,
    profileScore,
    responseScore,
    isCountyHero,
    additionalAreas,
    isVenueTypeHero,
    isPowerMatchActive,
    isCoupleTargetingActive,
    isCoupleIntelActive,
    isAutoFollowUpsActive,
    isSocialSupportActive,
    isLostLeadsActive,
    lastTierUpdateDate,
    averageRevenuePerWedding,
    enquiryToBookingConversion,
    grossProfitMargin,
  }: IReport,
  reportDefaultValues: IReportDefaultValues,
) => {
  const grossProfitMarginFormValue = (grossProfitMargin * 100).toString();

  return {
    // user input values
    inputAvgRevenue: averageRevenuePerWedding,
    inputAvgRevenueType:
      averageRevenuePerWedding === reportDefaultValues.avgRevenueSliderValueDefault
        ? 'preset'
        : 'manual',
    inputGrossProfitMargin: grossProfitMargin,
    inputGrossProfitMarginType:
      grossProfitMarginFormValue === reportDefaultValues.grossProfitMarginRadioValueDefault
        ? 'preset'
        : 'manual',
    inputEnqToBooking: enquiryToBookingConversion,
    inputEnqToBookingType:
      enquiryToBookingConversion[0] === reportDefaultValues.enquiryToBookingSliderValueDefault
        ? 'preset'
        : 'manual',
    reportInputType: 'modified',
    // report values
    outputNumberOfEnquiries: numberOfEnquiries,
    outputEstimatedNumberOfBookings: estimatedNumberOfBookings,
    outputGrossRevenue: grossRevenue,
    outputTotalSubscriptionCost: totalSubscriptionCost,
    outputRevenueROI: revenueROI,
    outputGrossProfit: grossProfit,
    outputGrossProfitROI: grossProfitROI,
    outputReportStartDate: reportStartDate,
    outputReportEndDate: reportEndDate,
    outputReportId: reportId,
    outputNumberOfIntroductions: numberOfIntroductions,
    outputNumberOfViews: numberOfViews,
    outputNumberOfShortlists: numberOfShortlists,
    outputNumberOfRedirectsToWeb: numberOfRedirectsToWeb,
    outputAdditionalBookingsEstimate: additionalBookingsEstimate,
    outputAdditionalRevenueEstimate: additionalRevenueEstimate,
    outputProfileScore: profileScore,
    outputResponseScore: responseScore,
    outputIsCountyHero: isCountyHero,
    outputMultiCounty: additionalAreas.length > 0,
    outputIsVenueTypeHero: isVenueTypeHero,
    outputIsPowerMatchActive: isPowerMatchActive,
    outputIsCoupleTargetingActive: isCoupleTargetingActive,
    outputIsCoupleIntelActive: isCoupleIntelActive,
    outputIsAutoFollowUpsActive: isAutoFollowUpsActive,
    outputIsSocialSupportActive: isSocialSupportActive,
    outputIsLostLeadsActive: isLostLeadsActive,
    outputLastTierUpdateDate: lastTierUpdateDate,
    outputCountyPosition: countyPosition,
    outputCountryPosition: countryPosition,
  };
};

export type ROIReportAnalytics = ReturnType<typeof mapROIReportToAnalyticsPayload>;
