import {
  addVideo,
  deleteVideo,
  fetchVideosSuccess,
  initVideosListener,
  playVideo,
} from 'lib/videos/actions';

export enum VideosActionTypes {
  INIT_VIDEOS_LISTENER = '[Videos] Init videos listener',
  PLAY_VIDEO = '[Videos] Play video',
  DELETE_VIDEO = '[Videos] Delete video',
  CLEAR_VIDEO = '[Videos] Clear video',
  ADD_VIDEO = '[Videos] Add video',
  FETCH_VIDEOS_SUCCESS = '[Videos] Fetch videos success',
}

export type PlayVideoAction = ReturnType<typeof playVideo>;
export type AddVideoAction = ReturnType<typeof addVideo>;
export type DeleteVideoAction = ReturnType<typeof deleteVideo>;
export type FetchVideosSuccessAction = ReturnType<typeof fetchVideosSuccess>;
export type IInitVideosListenerAction = ReturnType<typeof initVideosListener>;
