import { PointOptionsObject } from 'highcharts';
import { colors } from '@bridebook/ui';

export interface ICoupleBudgets {
  chart: {
    plotBackgroundColor: string;
    plotBorderWidth: number;
    plotShadow: boolean;
    type: string;
    width: number;
  };
  title: {
    text: string;
  };
  tooltip: {
    outside: boolean;
    pointFormat: string;
  };
  plotOptions: {
    pie: {
      allowPointSelect: boolean;
      cursor: string;
      dataLabels: {
        enabled: boolean;
      };
      showInLegend: boolean;
      colors: string[];
    };
  };
  legend: {
    enabled: false;
  };
  series: [
    {
      type: 'pie';
      name: string;
      data: PointOptionsObject[];
    },
  ];
  credits: { enabled: boolean };
  loaded: boolean;
}

export const CoupleBudgets: ICoupleBudgets = {
  chart: {
    plotBackgroundColor: '',
    plotBorderWidth: 0,
    plotShadow: false,
    type: 'pie',
    width: 350,
  },
  title: {
    text: '',
  },
  tooltip: {
    outside: true,
    pointFormat: '<b>{point.percentage:.0f}%</b>',
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
      colors: [
        colors.indigoCrush60,
        colors.teal120,
        colors.blushTangerine120,
        colors.peachRose120,
        colors.purple,
        colors.blushTangerine,
      ],
    },
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      type: 'pie',
      name: 'Budget',
      data: [],
    },
  ],
  credits: { enabled: false },
  loaded: false,
};

export default CoupleBudgets;
