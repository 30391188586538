import type { Tooltip, TooltipFormatterContextObject } from 'highcharts';
import { getI18n } from 'react-i18next';
import { colors } from '@bridebook/ui';

const getResponseScoresChartConfig = () => ({
  chart: {
    type: 'bar',
  },
  title: null,
  subtitle: null,
  xAxis: {
    categories: [],
    title: null,
    gridLineWidth: 1,
    tickWidth: 0,
    labels: {
      style: {
        color: colors.space60,
        fontDefault: 12,
      },
    },
  },
  yAxis: {
    title: null,
    min: 0,
    max: 100,
    labels: {
      overflow: 'justify',
    },
  },
  legend: {
    // hide symbol
    symbolPadding: 0,
    symbolWidth: 0.001,
    symbolHeight: 0.001,
    symbolRadius: 0,
    //
    itemStyle: {
      fontDefault: 14,
      color: colors.space,
    },
  },
  tooltip: {
    formatter(tooltip: Tooltip) {
      if ((this as unknown as TooltipFormatterContextObject).point.y === 0.333333) {
        (this as unknown as TooltipFormatterContextObject).point.y = 0;
      }

      // @ts-expect-error
      return tooltip.defaultFormatter.call(this, tooltip);
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 5,
    },
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      name: getI18n().t('marketInsights:responseScores.chart.seriesName'),
      data: [],
    },
  ],
  loaded: false,
});

export default getResponseScoresChartConfig;
