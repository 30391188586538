import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { getDateFnsLocales } from 'lib/utils/date-fns/format-i18n-date';

const getLocalisedSeason = (season: string, locale: string): string => {
  const isUK = locale === gazetteer.getMarketByCountry(CountryCodes.GB).locale;
  const dateFnsLocale = getDateFnsLocales(locale);
  const getShortMonth = (index: number) =>
    dateFnsLocale.localize.month(index, { width: 'abbreviated' });

  switch (season.toLowerCase()) {
    case 'summer':
      // 'Jul/Aug/Sep'
      return isUK ? 'Summer' : `${getShortMonth(6)}/${getShortMonth(7)}/${getShortMonth(8)}`;
    case 'autumn':
      // 'Oct/Nov/Dec'
      return isUK ? 'Autumn' : `${getShortMonth(9)}/${getShortMonth(10)}/${getShortMonth(11)}`;
    case 'winter':
      // 'Jan/Feb/Mar'
      return isUK ? 'Winter' : `${getShortMonth(0)}/${getShortMonth(1)}/${getShortMonth(2)}`;
    case 'spring':
      // 'Apr/May/Jun'
      return isUK ? 'Spring' : `${getShortMonth(3)}/${getShortMonth(4)}/${getShortMonth(5)}`;
    default:
      throw new Error('There is no such season!!');
  }
};

export default getLocalisedSeason;
