import { User as FirebaseUser } from 'firebase/auth';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { IUser } from 'lib/types';
import { UserActionTypes } from 'lib/users/action-types';
import createUserFirebase from 'lib/users/utils/create-user-firebase';

export const createFirebaseUserAfterSignUp = (
  firebaseUser: FirebaseUser | null,
  inviteSupplier?: ISupplier | null,
) => ({
  type: UserActionTypes.SAVE_USER_AFTER_AUTH,
  payload: {
    user: createUserFirebase(firebaseUser, inviteSupplier),
    inviteSupplier,
  },
});

export const saveUserDone = ({ user }: { user: IUser }) => ({
  type: UserActionTypes.SAVE_USER_SUCCESS,
  payload: user,
});
