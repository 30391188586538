import { ActionsObservable, combineEpics } from 'redux-observable';
import { catchError } from 'rxjs/operators';
import * as Sentry from '@sentry/nextjs';
import { IEpic, IEpicDeps } from 'lib/types';

const isFunction = (item: any) => typeof item === 'function';

export const combineAndAddErrorHandlerToEpics = (
  epics: Record<string, IEpic<any, any>>,
  feature: string,
) => {
  const protectedEpics = Object.values(epics)
    .filter(isFunction)
    .map((epic) => addErrorHandler(epic, feature));
  return combineEpics(...protectedEpics);
};

const addErrorHandler =
  (epic: IEpic<any, any>, feature: string) =>
  (action$: ActionsObservable<any>, dependencies: IEpicDeps) =>
    epic(action$, dependencies).pipe(
      catchError((error, stream) => {
        Sentry.captureException(error, (scope) => {
          scope.setTag('feature', feature);
          scope.setTag('epic', epic.epicName);
          scope.setTag('source', 'addErrorHandler');
          scope.setFingerprint([error.message]);
          return scope;
        });
        return stream;
      }),
    );
