import { produce } from 'immer';
import { IVideo } from '@bridebook/models/source/models/Suppliers/Videos.types';
import {
  FetchVideosSuccessAction,
  PlayVideoAction,
  VideosActionTypes,
} from 'lib/videos/action-types';
import { Action, IReducersImmer } from '../types';

export interface VideosState {
  list: IVideo[];
  currentVideo?: IVideo;
}

const initialState: VideosState = {
  list: [],
};

const reducers: IReducersImmer<VideosState> = (draft: VideosState) => ({
  [VideosActionTypes.FETCH_VIDEOS_SUCCESS]: (action: FetchVideosSuccessAction) => {
    draft.list = action.payload.videos;
  },

  [VideosActionTypes.PLAY_VIDEO]: (action: PlayVideoAction) => {
    draft.currentVideo = action.payload;
  },

  [VideosActionTypes.CLEAR_VIDEO]: () => {
    draft.currentVideo = undefined;
  },
});

const reducer = (state: VideosState = initialState, action: Action): VideosState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
