import { getI18n } from 'react-i18next';
import validator from 'validator';
import { ValidationError } from '@bridebook/toolbox';
import { Market } from '@bridebook/toolbox/src/gazetteer';
import { parseVideo } from '@bridebook/toolbox/src/validation/parse-video';
import { getValidationMessages } from 'lib/utils/validation-msg';

export default class Validation<T extends object> {
  _object: T;
  _prop: keyof T | null;
  // To be used when prop is inside a nested object and error should include nested path
  // E.g. validating prop email that is inside contacts (contacts.email).
  // Input field name will be "contacts.email" and I need error name to be "contacts.email"
  _propPath?: string;
  _validator: ValidatorJS.ValidatorStatic;
  promise: Promise<void>;
  messages = getValidationMessages();

  constructor(object: T) {
    this._object = object;
    this._prop = null;
    this._propPath = '';
    this._validator = validator;
    this.promise = Promise.resolve();
  }

  custom(
    callback: (value: any, prop: string | null, obj: Object) => void,
    { required }: { required?: boolean } = {},
  ): Validation<T> {
    const prop = this._prop;
    const value = this._object[prop] ? String(this._object[prop]) : '';
    const object = this._object;
    const propName = this._propPath || this._prop;
    this.promise = this.promise.then(() => {
      if (required && !this._isEmptyString(value)) return;
      callback(value, propName, object);
    });
    return this;
  }

  _isEmptyString(value: string): boolean {
    return this._validator.isEmpty(value, { ignore_whitespace: true });
  }

  prop(prop: keyof T, propPath?: string): Validation<T> {
    this._prop = prop;
    this._propPath = propPath;
    return this;
  }

  required(getRequiredMessage?: (prop: any, value: string) => string): Validation<T> {
    return this.custom(
      (value: any, prop: string | null) => {
        const msg = getRequiredMessage
          ? getRequiredMessage(prop, value)
          : this.getRequiredMessage(prop);
        throw new ValidationError(msg, prop);
      },
      { required: true },
    );
  }

  getRequiredMessage(prop: string | null): string {
    const message = (this.messages.required as any)[prop || ''] as string;
    return message || getI18n().t('common:validation.required.otherProp', { prop });
  }

  email(): Validation<T> {
    return this.custom((value, prop) => {
      if (this._validator.isEmail(value)) return;
      throw new ValidationError(this.messages.invalid.email, prop);
    });
  }

  simplePassword(): Validation<T> {
    return this.custom((value, prop) => {
      const minLength = 6;
      if (value.length >= minLength) return;
      throw new ValidationError(this.messages.invalid.password(minLength), prop);
    });
  }

  coordinate(): Validation<T> {
    return this.custom((value, prop) => {
      if (String(value) !== '0') return;
      throw new ValidationError(this.messages.invalid.coordinate(prop), prop);
    });
  }

  urLink(): Validation<T> {
    return this.custom((value, prop) => {
      if (value === '' || typeof value === 'undefined') return;
      if (this._validator.isURL(value) && !this._validator.isEmail(value)) {
        return;
      }
      throw new ValidationError(this.messages.invalid.url, prop);
    });
  }

  digits(): Validation<T> {
    return this.custom((value, prop) => {
      if (value === '') return;
      if (this._validator.isNumeric(value)) return;
      throw new ValidationError(this.messages.invalid.url, prop);
    });
  }

  ukphone(): Validation<T> {
    return this.custom((value, prop) => {
      const pattern =
        /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|#)\d{3,4})?$/;
      const isValid = RegExp(pattern).test(value);
      if (isValid) return;
      throw new ValidationError(this.messages.invalid.ukPhone, prop);
    });
  }

  phone(): Validation<T> {
    return this.custom((value, prop) => {
      const pattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
      const isValid = RegExp(pattern).test(value);
      if (isValid) return;
      throw new ValidationError(this.messages.invalid.ukPhone, prop);
    });
  }

  postcode(market: Market, required: boolean = true): Validation<T> {
    return this.custom((value, prop) => {
      if (required !== true && this._isEmptyString(value) === true) {
        return;
      }

      if (market.isValidPostalCode(value) === false) {
        throw new ValidationError(this.messages.invalid.postcode, prop);
      }

      return;
    });
  }

  video(getVideoMessage: (prop: any, value: string) => string) {
    return this.custom((value, prop) => {
      if (value.includes('matterport') || value.includes('google')) return;
      const validUrl = parseVideo(value);

      if (validUrl.type === 'youtube' || validUrl.type === 'vimeo') return;
      const msg = getVideoMessage ? getVideoMessage(prop, value) : this.messages.invalid.video;
      throw new ValidationError(msg, prop);
    });
  }
}
