import { ChangeEvent } from 'react';
import { AuthActionTypes } from 'lib/auth/action-types';
import { AuthBridebookError, AuthProviders, IAuthFields } from 'lib/auth/types';
import mapFBErrorToValidationError from 'lib/auth/utils/map-fb-error-to-validation-error';
import { IDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';

export const changeAuthEmailForm = (e: ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value;
  const name = e.target.name;

  return {
    type: AuthActionTypes.SET_AUTH_EMAIL_FORM_FIELD,
    payload: { name, value },
  };
};

export const changeAuthEmail = (fields: IAuthFields, oldEmail?: string) => ({
  type: AuthActionTypes.CHANGE_AUTH_EMAIL,
  payload: { fields, oldEmail },
});

export const changeAuthEmailSuccess =
  (fields: IAuthFields) =>
  ({ dispatch }: IDeps) => {
    dispatch(toggleSnackbar('success', 'Your login email has been changed.'));

    dispatch({
      type: 'USER_UPDATED_AUTH_DETAILS_SUCCESS_ANALYTICS',
      payload: { provider: AuthProviders.PASSWORD, fields },
    });

    return {
      type: AuthActionTypes.CHANGE_AUTH_EMAIL_SUCCESS,
    };
  };

export const changeAuthEmailError =
  (fields: IAuthFields, error: AuthBridebookError) =>
  ({ dispatch }: IDeps) => {
    const throwError = error.code ? mapFBErrorToValidationError(error.code) : error;

    error.provider = AuthProviders.PASSWORD;
    dispatch(toggleSnackbar('alert', error.message));
    dispatch({
      type: 'USER_UPDATED_AUTH_DETAILS_ERROR_ANALYTICS',
      payload: { provider: AuthProviders.PASSWORD, fields, error: throwError },
    });

    return {
      type: AuthActionTypes.CHANGE_AUTH_EMAIL_ERROR,
      payload: throwError,
    };
  };
