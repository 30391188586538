import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { GroupActionTypes } from './action-types';
import { IGroupConnection } from './types';

export const updateGroupConnections = (groupConnections: ISupplier['id'][]) => ({
  type: GroupActionTypes.UPDATE_GROUP_CONNECTIONS,
  payload: groupConnections,
});

export const fetchGroupConnections = () => ({
  type: GroupActionTypes.FETCH_GROUP_CONNECTIONS,
});

export const fetchGroupConnectionsSuccess = (groupConnections: IGroupConnection[]) => ({
  type: GroupActionTypes.FETCH_GROUP_CONNECTIONS_SUCCESS,
  payload: groupConnections,
});
