import { addYears, getYear } from 'date-fns';
import { getI18n } from 'react-i18next';
import { IRadioGroupOption } from '@bridebook/ui/src/components/bbcommon/radio-group-v2/radio-group-v2';

const minDate = new Date();

const year1 = getYear(minDate);
const year2 = getYear(addYears(minDate, 1));
const year3 = getYear(addYears(minDate, 2));
const year4 = getYear(addYears(minDate, 26));

export const getOptionsYear = (): IRadioGroupOption<string>[] => {
  const i18n = getI18n();

  return [
    {
      label: i18n.t('common:All'),
      value: 'all',
    },
    {
      label: `${year1}`,
      value: String(year1),
    },
    {
      label: `${year2}`,
      value: String(year2),
    },
    {
      label: `${year3}`,
      value: String(year3),
    },
    {
      label: `${year3 + 1}+`,
      value: String(year4),
    },
  ];
};
