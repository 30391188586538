import { serverTimestamp } from 'firebase/firestore';
import { isEmpty, reject } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { SupplierUsers } from '@bridebook/models';
import { PartialRecursive } from '@bridebook/models/source/abstract/_';
import { ISupplierUser } from '@bridebook/models/source/models/SupplierUsers.types';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { appError } from 'lib/app/actions';
import { Action } from 'lib/types';
import { ISaveUserAfterAuthAction, UserActionTypes } from 'lib/users/action-types';
import { saveUserDone } from 'lib/users/actions/create-firebase-user-after-sign-up';

export const saveUserEpic = (action$: Observable<ISaveUserAfterAuthAction>) =>
  action$.pipe(
    ofType(UserActionTypes.SAVE_USER_AFTER_AUTH),
    filter(({ payload: { user } }: ISaveUserAfterAuthAction) => Boolean(user)),
    mergeMap(({ payload: { user } }: ISaveUserAfterAuthAction) => {
      const getPromise = async () => {
        if (!user) throw Error('No user');

        const { id: userId, contacts } = user;

        const existingUserEntity = SupplierUsers._.getById(userId);
        const existingUser = await existingUserEntity.get();

        const isSignup = !existingUser;
        const currentEmail = existingUser?.contacts?.email || '';

        const userContacts = contacts
          ? { ...contacts, email: currentEmail || contacts.email }
          : {
              email: currentEmail,
            };

        const defaultMarket = gazetteer.getMarketByCountry(CountryCodes.GB);
        const updateObj: PartialRecursive<ISupplierUser> = {
          contacts: reject(isEmpty, userContacts),
          id: userId,
          authenticatedAt: serverTimestamp(),
          provider: user.provider,
          l10n: existingUser?.l10n ||
            user?.l10n || {
              locale: defaultMarket.locale,
              currency: defaultMarket.currency,
            },
        };
        if (existingUser) {
          await existingUserEntity.set(updateObj);
        } else {
          await SupplierUsers._.push(userId).create(SupplierUsers.new('_', updateObj));
        }

        return { user, signedUp: isSignup };
      };

      return from(getPromise()).pipe(
        mergeMap(({ user, signedUp }) => {
          const actions: Action[] = [saveUserDone({ user, signedUp })];
          return of(...actions);
        }),
        catchError((error) => of(appError({ error, feature: 'Users saveUserEpic' }))),
      );
    }),
  );
