import {
  onModalToggle,
  setMarketingBanner,
  toggleOverlayPortal,
  toggleSnackbar,
} from 'lib/ui/actions';

export enum UIActionTypes {
  TOGGLE_OVERLAY_PORTAL = '[UI] Toggle overlay portal',
  ON_MODAL_TOGGLE = '[UI] On modal toggle',
  TOGGLE_SNACKBAR = '[UI] Toggle snackbar',
  HIDE_SNACKBAR = '[UI] Hide snackbar',
  SET_MARKETING_BANNER = '[UI] Set mktg banner',
  TOGGLE_ADMIN_PANEL = '[UI] Toggle admin panel',
  CLOSE_ADMIN_PANEL = '[UI] Close admin panel',
}

export type ToggleSnackbarAction = ReturnType<typeof toggleSnackbar>;

export type ToggleOverlayPortalAction = ReturnType<ReturnType<typeof toggleOverlayPortal>>;

export type OnModalToggleAction = ReturnType<typeof onModalToggle>;

export type SetMarketingBannerAction = ReturnType<typeof setMarketingBanner>;
