export {
  IOffer_DiscountType as FirestoreOfferDiscountTypes,
  IOffer_Type as OfferTypes,
} from '@bridebook/models/source/models/Suppliers/Offers.types';

/**
 * Those are old constants from RTDB times which needs refactoring
 * to IOffer_DiscountType and IOffer_Type.
 *
 * @deprecated use IOffer_DiscountType and IOffer_Type instead
 */
export enum OfferDiscountTypes {
  percentOff = 'percentOff',
  poundOff = 'poundOff',
  setPrice = 'setPrice',
  other = 'other',
}

export enum BBDiscountOptions {
  bbDiscount5PerCentDiscount = 'bbDiscount5PerCentDiscount',
  bbDiscount10PerCentDiscount = 'bbDiscount10PerCentDiscount',
  bbDiscount20PerCentDiscount = 'bbDiscount20PerCentDiscount',
  bbDiscountOther = 'bbDiscountOther',
  bbDiscountNone = 'bbDiscountNone',
}
export const LATE_AVAILABILITY_WIDGET_DISPLAY_THRESHOLD = 3;
