import { YourCouplesActionTypes } from 'lib/couples/action-types';

export const viewInviteMessage = (payload: { weddingId: string }) => ({
  type: YourCouplesActionTypes.VIEW_INVITE_MESSAGE,
  payload,
});

export const resetInviteMessage = () => ({
  type: YourCouplesActionTypes.RESET_INVITE_MESSAGE,
});

export const fetchInviteMessageSuccess = (message: string) => ({
  type: YourCouplesActionTypes.FETCH_INVITE_MESSAGE_SUCCESS,
  payload: message,
});
