import Router from 'next/router';
import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';
import {
  FollowUpMessagesAction,
  IDeleteFollowUpMessage,
  ISaveFollowUpMessage,
} from 'lib/follow-up-messages/action-types';
import { UrlHelper } from 'lib/url-helper';

export const redirectToEnquirySettings = (
  action$: Observable<ISaveFollowUpMessage | IDeleteFollowUpMessage>,
) =>
  action$.pipe(
    ofType(
      FollowUpMessagesAction.FollowUpMessageSaved,
      FollowUpMessagesAction.FollowUpMessageDeleted,
    ),
    tap(() => Router.replace(UrlHelper.couples.enquirySettings)),
    ignoreElements(),
  );
