import { User as UserType } from './types';

const User: UserType = {
  firstName: '',
  lastName: '',
  email: '',
  id: '',
  provider: '',
  registrationDate: '',
  password: '',
  profilePhoto: null,
  correspondenceEmail: '',
};

export default User;
