import { uniq, without } from 'ramda';
import { ISupplierWeddingManager, ProgressValuesType } from 'lib/enquiries/types';

export const updateEnquiryProgress = (
  value: boolean,
  progress: ProgressValuesType,
  enquiry: ISupplierWeddingManager,
) =>
  value
    ? uniq([...(enquiry?.progress || []), progress])
    : without([progress], enquiry?.progress || []);
