import { ofType } from 'redux-observable';
import { Observable, from, merge } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  pluck,
  switchMap,
} from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { appError } from 'lib/app/actions';
import {
  IInitializeMessageTemplates,
  MessageTemplatesAction,
} from 'lib/message-templates/action-types';
import { templateListSaved } from 'lib/message-templates/actions';
import { IApplicationState, IEpicDeps } from 'lib/types';

/**
 * Dispatches action with templates list in payload, initializes templates listener.
 */
export const initTemplatesListenerEpic = (
  action$: Observable<IInitializeMessageTemplates>,
  { state$ }: IEpicDeps,
) => {
  const supplierId$ = state$.pipe(
    pluck<IApplicationState, ISupplier>('supplier', 'supplier'),
    filter((supplier) => !!supplier?.id),
    map<ISupplier, string>((supplier) => supplier.id),
    distinctUntilChanged(),
  );

  return supplierId$.pipe(
    switchMap((supplierId) =>
      action$.pipe(
        ofType(MessageTemplatesAction.InitTemplatesListener),
        first(),
        switchMap(() => {
          const getPromise = async () => {
            const supplierRef = Suppliers._.getById(supplierId);
            const templates = await supplierRef.Templates.query().get();
            return Object.values(templates);
          };

          const template$ = Suppliers._.getById(supplierId)
            .Templates.query()
            .observe()
            .pipe(map((templates) => Object.values(templates)));

          return merge(from(getPromise()), template$).pipe(
            map(templateListSaved),
            catchError((error) => [appError({ error, feature: 'Message Templates' })]),
          );
        }),
      ),
    ),
  );
};
