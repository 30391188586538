import { assoc, assocPath, compose } from 'ramda';
import { mergeClean } from 'lib/utils';
import Endorsement from './endorsement';
import { EndorsementAction, Endorsement as EndorsementType } from './types';

export interface EndorsementsState {
  list: Array<EndorsementType>;
  endorsement: EndorsementType;
  notification: string | null;
  notificationText: string | null;
  verified: boolean;
  uploadPhotoSuccess: boolean;
  unsaved: boolean;
  editing: boolean;
  error: object | null;
}

const initialState: EndorsementsState = {
  list: [],
  endorsement: { ...Endorsement },
  notification: null,
  notificationText: null,
  verified: false,
  uploadPhotoSuccess: false,
  unsaved: false,
  editing: false,
  error: null,
};

export default function endorsementsReducer(
  state: EndorsementsState = initialState,
  action: EndorsementAction,
) {
  state = mergeClean(initialState, state);

  switch (action.type) {
    case 'PUSH_ENDORSEMENT':
      return assocPath(['endorsement', 'id'], action.payload)(state);

    case 'SET_ENDORSEMENT_FIELD': {
      const { name, value } = action.payload;
      return compose(assocPath(['endorsement', name], value), assoc('unsaved', true))(state);
    }

    case 'SAVE_ENDORSEMENT_START':
      return { ...state, notification: null, notificationText: null };

    case 'SAVE_ENDORSEMENT_ERROR':
      return {
        ...state,
        notification: 'error',
        error: action.payload,
        notificationText: action.payload.message,
      };

    case 'DELETE_ENDORSEMENT': {
      const { id } = action.payload;
      const list = state.list.filter((item) => item.id === id);

      return { ...state, list };
    }

    case 'EDIT_ENDORSEMENT':
      return {
        ...state,
        endorsement: mergeClean(Endorsement, action.payload),
        unsaved: true,
        editing: true,
      };

    case 'LOAD_UPLOAD_WIDGET_ENDORSEMENTS_SUCCESS':
    case 'UPLOAD_ENDORSEMENT_PHOTO':
      return { ...state, uploadPhotoSuccess: false };

    case 'UPLOAD_ENDORSEMENT_PHOTO_ERROR':
    case 'SET_UPLOADED_ENDORSEMENT_PHOTO_ERROR':
      // todo: log error
      return { ...state, uploadPhotoSuccess: false };

    case 'UPLOAD_ENDORSEMENT_PHOTO_SUCCESS':
    case 'SET_UPLOADED_ENDORSEMENT_PHOTO_SUCCESS':
      // todo: log success
      return compose(
        assocPath(['endorsement', 'photo'], action.payload),
        assoc('uploadPhotoSuccess', true),
      )(state);

    case 'VERIFIED_ENDORSEMENT':
      return { ...state, verified: !!action.payload };

    case 'CLEAR_ENDORSEMENT':
      return {
        ...state,
        endorsement: { ...Endorsement },
        unsaved: false,
        notification: null,
        error: null,
        notificationText: null,
      };

    case 'RESET_ENDORSEMENT_NOTIFICATION':
      return {
        ...state,
        notification: null,
        error: null,
        notificationText: null,
      };
    default:
      return state;
  }
}
