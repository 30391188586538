import produce from 'immer';
import { values } from 'ramda';
import { Action } from 'redux';
import { IQuestionsState } from 'lib/questions/types';
import { createQuestionDraft } from 'lib/questions/utils';
import {
  OnFirestoreQuestionsAction,
  QuestionsActionTypes,
  SetQuestionAnswerAction,
} from './action-types';

const initialState: IQuestionsState = {
  questions: [],
};

const reducers: any = (draft: IQuestionsState) => ({
  [QuestionsActionTypes.ON_FIRESTORE_QUESTIONS]: (action: OnFirestoreQuestionsAction) => {
    draft.questions = values(action.payload);
  },

  [QuestionsActionTypes.SET_QUESTION_ANSWER]: (action: SetQuestionAnswerAction) => {
    const { answer, questionId } = action.payload;
    const arrayIndex = draft.questions.findIndex((q) => q.question === questionId);
    if (arrayIndex > -1) {
      draft.questions[arrayIndex].answer = answer;
    } else {
      draft.questions.push(createQuestionDraft({ questionId, answer }));
    }
  },
});

const reducer = (state: IQuestionsState = initialState, action: Action): IQuestionsState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
