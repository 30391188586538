import gazetteer, { CountryCodes, Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { IApplicationState } from 'lib/types';

const supplierPropertiesGeneric = (state: IApplicationState, localeServer?: string) => {
  const supplier = state.supplier.supplier || state.auth.collaboratorInvite.inviteSupplier;
  if (!supplier) return {};

  const { supplierAdminObject } = state.admin;
  const countryCode = state.supplier.supplier?.l10n?.country || supplier?.l10n?.country;
  const countryName = countryCode ? Gazetteer.getCountryName(countryCode) : undefined;
  const locale =
    localeServer ||
    state.users.viewer?.l10n?.locale ||
    gazetteer.getMarketByCountry(countryCode, CountryCodes.GB).locale;

  const { id: supplierId, type, name: supplierName } = supplier;

  return {
    supplierId,
    supplierCategory: type?.[0],
    supplierName,
    supplierTier: supplierAdminObject?.premium ? supplierAdminObject.premium.tier || 0 : 0,
    countryCode,
    countryName,
    locale,
  };
};

export default supplierPropertiesGeneric;
