import { getI18n } from 'react-i18next';
import { friendlyUrl } from 'lib/utils';
import { IAccountErrorArg, IGetListedError } from '../types';
import buildSupplierUrl from './build-supplier-url';

const buildGetListedError = ({
  urlId,
  id,
  reason,
  slug,
  name,
}: IAccountErrorArg): IGetListedError => {
  const url = buildSupplierUrl({
    id: urlId,
    slug,
    url: friendlyUrl({ name }),
  });
  return {
    id,
    reason,
    url,
    message: getI18n().t('loggedOut:profileExists'),
  };
};

export default buildGetListedError;
