import { createFirebaseUserAfterSignUp } from 'lib/users/actions';
import { onUserListener } from 'lib/users/actions/user-listener';

export enum UserActionTypes {
  SAVE_USER_AFTER_AUTH = '[User] Save user after auth',
  SAVE_USER_SUCCESS = '[User] Save user success',
  ON_USER_LISTENER = '[User] On user listener',
  SET_FORM_FIELD = '[User] Set user details form',
  UPLOAD_PROFILE_PHOTO = '[User] Upload profile photo',
  UPDATE_USER_DETAILS = '[User] Update User details',
}

export type ISaveUserAfterAuthAction = ReturnType<typeof createFirebaseUserAfterSignUp>;

export type IOnUserListenerAction = ReturnType<typeof onUserListener>;
