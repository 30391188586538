import { AchievementsActionTypes } from '../action-types';
import { IAchievementsState } from '../types';

export const addAchievementDraft = (section: keyof IAchievementsState) => ({
  type: AchievementsActionTypes.ADD_ACHIEVEMENT_DRAFT,
  payload: { section },
});

export interface ISetAchievementFieldArgs {
  name: string;
  value: string;
  section: keyof IAchievementsState;
  index: number;
}

export const setAchievementField = (payload: ISetAchievementFieldArgs) => ({
  type: AchievementsActionTypes.SET_ACHIEVEMENT_FIELD,
  payload,
});
