import { deleteField } from 'firebase/firestore';
import differenceWith from 'lodash/differenceWith';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { appError } from 'lib/app/actions';
import { IEpicDeps } from 'lib/types';
import { GroupActionTypes, IUpdateGroupConnections } from '../action-types';

export const updateGroupConnectionsEpic = (
  action$: Observable<IUpdateGroupConnections>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(GroupActionTypes.UPDATE_GROUP_CONNECTIONS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const activeSupplier = state.users.activeSupplierAccessControl;
      if (!activeSupplier?.id) return of({});
      const getPromise = async () => {
        const newGroupConnections = action.payload;

        const currentGroupConnections = state.supplier.supplier?.group?.children || [];

        const addedGroupConnections = differenceWith(
          newGroupConnections,
          currentGroupConnections,
          (value, other) => value == other,
        );

        const deletedGroupConnections = differenceWith(
          currentGroupConnections,
          newGroupConnections,
          (value, other) => value == other,
        );

        if (addedGroupConnections.length > 0) {
          await Promise.all(
            addedGroupConnections.map((addedSupplier) => {
              Suppliers._.getById(addedSupplier).set({
                group: { root: activeSupplier.id, parent: activeSupplier.id, children: [] },
              });
            }),
          );
          Suppliers._.getById(activeSupplier.id).set({
            group: { children: [...currentGroupConnections, ...addedGroupConnections] },
          });
        }

        if (deletedGroupConnections.length > 0) {
          await Promise.all(
            deletedGroupConnections.map((deletedSupplier) => {
              Suppliers._.getById(deletedSupplier).set({ group: deleteField() });
            }),
          );

          Suppliers._.getById(activeSupplier.id).set({
            group: {
              children: currentGroupConnections.filter(
                (currentGroupConnection) =>
                  !deletedGroupConnections.includes(currentGroupConnection),
              ),
            },
          });
        }
      };
      return from(getPromise()).pipe(
        catchError((error) => of(appError({ error, feature: 'Update group connections' }))),
      );
    }),
  );
