import { deleteField } from 'firebase/firestore';
import { Suppliers } from '@bridebook/models';
import { PartialRecursive } from '@bridebook/models/source/abstract/_';
import { Translations } from '@bridebook/models/source/helpers/translate';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { countiesSplitIntoCustomAreas } from '@bridebook/toolbox/src/search-suppliers/custom-areas';
import { isCustomAreaSearchSupported } from '@bridebook/toolbox/src/search-suppliers/is-custom-area-search-supported';
import { getSupplierCoordinates } from 'lib/supplier/utils';
import getSupplierCustomArea from 'lib/supplier/utils/modules/get-custom-area';

export const getHandleSaveSupplierDataPromise = async ({
  formData,
  supplier,
  _translations,
}: {
  formData?: PartialRecursive<ISupplier>;
  supplier: ISupplier;
  _translations?: Translations['_translations'];
}) => {
  const countryCode = formData?.l10n?.country || supplier.l10n?.country;
  const county = formData?.address?.adminArea?.[0] || supplier.address?.adminArea?.[0];

  const address = { ...supplier.address, ...(formData?.address || {}) };
  const coordinates = await getSupplierCoordinates(supplier);

  // Get supplier custom area only if county is split into custom areas
  let supplierCustomArea;
  if (
    isCustomAreaSearchSupported(supplier.type[0]) &&
    countiesSplitIntoCustomAreas[
      countryCode as keyof typeof countiesSplitIntoCustomAreas
    ]?.includes(county)
  ) {
    supplierCustomArea = await getSupplierCustomArea(coordinates);
  }

  const supplierRef = Suppliers._.getById(supplier.id);
  const updateObject: PartialRecursive<ISupplier> = {
    ...(formData || {}),
    address,
    // Continues to save even with empty coordinates
    ...(coordinates && { coordinates }),
  };

  return supplierRef.set({
    ...updateObject,
    customArea: supplierCustomArea ?? deleteField(),
    ...(_translations && { _translations }),
  });
};
