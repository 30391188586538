import {
  bookingClickedAnalytics,
  connectWithCoupleClickedAnalytics,
  deleteFilesSuccess,
  fetchBookingSuccess,
  fetchBookingsSuccess,
  fetchFilesSuccess,
  filterBookings,
  processBookingsByYear,
  sortBookings,
  toggleCoupleCollaboration,
} from 'lib/couples/actions';
import { setOpenedBookingId } from 'lib/couples/actions/booking-details';

export enum YourCouplesActionTypes {
  FETCH_COUPLES_BOOKINGS_START = '[Couples] Fetch your bookings start',
  FETCH_COUPLES_BOOKINGS_SUCCESS = '[Couples] Fetch your bookings success',
  PROCESS_BOOKING_BY_YEAR = '[Couples] Process bookings by year',
  SORT_BOOKINGS = '[Couples] Sort bookings',
  FILTER_BOOKINGS = '[Couples] Filter bookings',
  SET_OPENED_BOOKING_ID = '[Couples] Set opened booking id',
  TOGGLE_COUPLE_COLLABORATION = '[Couples] Toggle couple collaboration',
  FETCH_FILES = '[Couples] Fetch files',
  FETCH_FILES_SUCCESS = '[Couples] Fetch files success',
  FETCH_SINGLE_BOOKING_START = '[Couples] Fetch single booking start',
  FETCH_SINGLE_BOOKINGS_SUCCESS = '[Couples] Fetch single booking success',
  CLEAR_SINGLE_BOOKING = '[Couples] Clear single booking success',
  DELETE_FILES_SUCCESS = '[Couples] Delete files success',
  VIEW_INVITE_MESSAGE = '[Couples] Opened Invite message',
  RESET_INVITE_MESSAGE = '[Couples] Closed Invite message',
  FETCH_INVITE_MESSAGE_SUCCESS = '[Couples] Fetch Invite message success',
  SUPPLIER_OPENED_FORM_ANALYTICS = '[Analytics] [Couples] Supplier opened form',
  SUPPLIER_CLOSED_FORM_ANALYTICS = '[Analytics] [Couples] Supplier close form',
  BOOKING_CLICKED_ANALYTICS = '[Analytics] [Couples] Booking clicked',
  CONNECT_WITH_COUPLE_CLICKED_ANALYTICS = '[Analytics] [Couples] Connect with couple clicked',
}

export type FetchBookingsSuccessAction = ReturnType<typeof fetchBookingsSuccess>;
export type ProcessBookingsByYearAction = ReturnType<typeof processBookingsByYear>;
export type SortBookingsAction = ReturnType<typeof sortBookings>;
export type FilterBookingsAction = ReturnType<typeof filterBookings>;
export type SetOpenedBookingIdAction = ReturnType<typeof setOpenedBookingId>;
export type ToggleCoupleCollaborationAction = ReturnType<typeof toggleCoupleCollaboration>;
export type FetchBookingSuccessAction = ReturnType<typeof fetchBookingSuccess>;
export type FetchFilesSuccessAction = ReturnType<typeof fetchFilesSuccess>;
export type DeleteFilesSuccessAction = ReturnType<typeof deleteFilesSuccess>;
export type IBookingClickedAnalyticsAction = ReturnType<typeof bookingClickedAnalytics>;
export type IConnectWithCoupleClickedAnalyticsAction = ReturnType<
  typeof connectWithCoupleClickedAnalytics
>;
