import { values } from 'ramda';
import { ofType } from 'redux-observable';
import { type Observable, from, of } from 'rxjs';
import { catchError, ignoreElements, mergeMap, withLatestFrom } from 'rxjs/operators';
import type { IEpicDeps } from 'lib/types';
import { appError } from '../../app/actions';
import { type ISetThumbnail, PhotosActionTypes } from '../action-types';
import shiftThumbnail from '../utils/modules/shiftThumbnail';
import { doBatchPhotoUpdate } from './set-photo-order';

export const setThumbnailEpic = (action$: Observable<ISetThumbnail>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(PhotosActionTypes.SET_THUMBNAIL),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const activeSupplier = state.users.activeSupplierAccessControl;
      const getPromise = async () => {
        if (!activeSupplier) throw new Error('setThumbnailEpic: missing activeSupplier');
        const { id } = activeSupplier;

        const setThumb = () =>
          doBatchPhotoUpdate(shiftThumbnail(values(state.photos.photos), payload.id, 'id'), id, 0);

        return setThumb();
      };

      return from(getPromise()).pipe(
        catchError((error) => of(appError({ error, feature: 'Photos' }))),
        ignoreElements(),
      );
    }),
  );
