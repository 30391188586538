import { Timestamp, WithFieldValue, deleteField } from 'firebase/firestore';
import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { IFeedback } from '@bridebook/models/source/models/Suppliers/Feedback.types';
import { FailedAction } from 'lib/analytics/failed-action';
import { FailedReason } from 'lib/analytics/failed-reason';
import { appError } from 'lib/app/actions';
import { getScopedContentTranslations } from 'lib/content-translations/selectors';
import { clearTempContentTranslations } from 'lib/content-translations/slice';
import { Action, IEpicDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';
import { FeedbackActions, SaveFeedbackAction } from '../action-types';
import { saveFeedbackSuccess } from '../actions/save-feedback';
import { IFeedbackSerialized } from '../types';

const saveFeedbackEpic = (
  action$: Observable<SaveFeedbackAction>,
  { state$, bbanalytics }: IEpicDeps,
) =>
  action$.pipe(
    ofType(FeedbackActions.SAVE_FEEDBACK),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { feedback, scope },
        },
        state,
      ]) => {
        const editing = Boolean(feedback.id);
        const supplierId = state.supplier.supplier?.id;
        if (!supplierId) return of();
        const supplierRef = Suppliers._.getById(supplierId);

        const timestampFromDate = (date: any) => Timestamp.fromDate(new Date(date));

        const getPromise = async () => {
          const _translations = getScopedContentTranslations<IFeedback>(state, scope);
          const feedbackToSave: WithFieldValue<IFeedbackSerialized> = {
            ...feedback,
            dateWedding: timestampFromDate(feedback.dateWedding),
            ...(feedback.createdAt && { createdAt: timestampFromDate(feedback.createdAt) }),
            ...(feedback.updatedAt && { updatedAt: timestampFromDate(feedback.updatedAt) }),
            ...(feedback.response && {
              response: {
                ...feedback.response,
                createdAt: timestampFromDate(feedback.response.createdAt),
                ...(feedback.response.updatedAt && {
                  updatedAt: timestampFromDate(feedback.response.updatedAt),
                }),
              },
            }),
            ...(_translations && { _translations }),
          };

          if (feedback.id) {
            const feedbackRef = supplierRef.Feedback.getById(feedback.id);
            if (!feedbackToSave.link) {
              feedbackToSave.link = deleteField();
            }

            await feedbackRef.set(feedbackToSave);
            return feedback.id;
          } else {
            if (!feedbackToSave.link) {
              delete feedbackToSave.link;
            }
            const newFeedback = await supplierRef.Feedback.push().create(
              feedbackToSave as IFeedback,
            );
            return newFeedback.id;
          }
        };

        const snackbarCopy =
          feedback.type === 'testimonial'
            ? editing
              ? getI18n().t('testimonialsReviews:testimonialEdit.success')
              : getI18n().t('testimonialsReviews:testimonialUpload.success')
            : getI18n().t('testimonialsReviews:reviewUpdate.success');

        const successActions: Action[] = [
          saveFeedbackSuccess(feedback),
          clearTempContentTranslations(scope),
          toggleSnackbar('success', snackbarCopy),
        ];

        const isTestimonial = feedback.type === 'testimonial';

        return from(getPromise()).pipe(
          mergeMap((supplierTestimonialId) => {
            isTestimonial && editing
              ? bbanalytics.supplier.testimonials.edited({ supplierTestimonialId })
              : bbanalytics.supplier.testimonials.added({ supplierTestimonialId });
            return of(...successActions);
          }),
          catchError((error: Error) => {
            const errorActions: Action[] = [
              appError({ error, feature: 'Feedback' }),
              clearTempContentTranslations(scope),
            ];

            if (isTestimonial) {
              bbanalytics.error({
                failedAction: editing ? FailedAction.EditTestimonial : FailedAction.AddTestimonial,
                failedReason: editing
                  ? FailedReason.FailedToEditTestimonial
                  : FailedReason.FailedToAddTestimonial,
              });
            }

            return errorActions;
          }),
        );
      },
    ),
  );

export default saveFeedbackEpic;
