import { equals } from 'ramda';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { ITargeting } from 'lib/supplier/types';
import { getOptionsDay } from 'lib/targeting/data/day';
import { getOptionsSeason } from 'lib/targeting/data/season';
import { getOptionsYear } from 'lib/targeting/data/year';

type TTargetingBudget = Required<ISupplier>['targeting']['budget'];
type TTargetingDate = Required<Required<ISupplier>['targeting']>['date'];
type TTargetingGuests = Required<ISupplier>['targeting']['guestsNumber'];

/**
 * Map targeting budget from database format to local state
 * @param budget
 */
const mapInitBudget = (budget?: TTargetingBudget): ITargeting['targetingBudget'] => {
  if (!Array.isArray(budget)) return null;
  const min = budget?.[0] || null;
  const max = budget?.[1] || null;
  const gte = min ? min : 0;
  const lte = max ? max : 20000;

  return [gte, lte];
};

const mapInitGuests = (guests?: TTargetingGuests): ITargeting['targetingGuests'] => {
  if (!Array.isArray(guests)) return null;
  const min = guests?.[0] || null;
  const max = guests?.[1] || null;
  const gte = min ? min : 20;
  const lte = max ? max : 260;

  return [gte, lte];
};

const getInitDay = (day?: TTargetingDate['d']): ITargeting['targetingWeekday'] => {
  const optionsDay = getOptionsDay();

  if (Array.isArray(day) && equals(day, ['mon', 'tue', 'wed', 'thu'])) {
    return optionsDay[1].value;
  }
  if (day === 'fri') return optionsDay[2].value;
  if (day === 'sat') return optionsDay[3].value;
  if (day === 'sun') return optionsDay[4].value;

  return optionsDay[0].value;
};

const getInitSeason = (month?: TTargetingDate['m']): ITargeting['targetingSeason'] => {
  const optionsSeason = getOptionsSeason();
  if (typeof month === 'string' && optionsSeason.some(({ value }) => value === month)) return month;
  return optionsSeason[0].value;
};

const getInitYear = (year?: TTargetingDate['y']): ITargeting['targetingYear'] => {
  const optionsYear = getOptionsYear();

  /* If year that is currently saved in database is less than current year,
   * then select first radio button (which is ALL - '0')
   */
  if (!year || !Number.isInteger(year) || year < Number(optionsYear[1].value)) {
    return 'all';
  }

  /* If year that is currently saved in database is more than 3 years in future,
   * then select last radio button (which is this year + 26)
   */
  if (year && year > Number(optionsYear[3].value)) {
    return Number(optionsYear[4].value);
  }

  return Number(year);
};

/**
 * Map radio button values to acceptable values for targeting object
 */
export const mapDatabaseToLocalFormat = (targeting?: ISupplier['targeting']): ITargeting => {
  const budget = mapInitBudget(targeting?.budget);
  const guestsNumber = mapInitGuests(targeting?.guestsNumber);
  const day = getInitDay(targeting?.date?.d);
  const season = getInitSeason(targeting?.date?.m);
  const year = getInitYear(targeting?.date?.y);

  return {
    targetingBudget: budget,
    targetingGuests: guestsNumber,
    targetingWeekday: day,
    targetingSeason: season,
    targetingYear: year,
  };
};
