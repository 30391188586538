import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import type { Slug } from '@bridebook/toolbox/src/types';
import { IGetListedForm } from './types';

// TODO: need to refactor so fields is Partial<ISupplier> and use up to date Supplier types
const GetListedForm: IGetListedForm = {
  disabled: false,
  error: null,
  addressSetFromGeoCode: false,
  postcodeSetFromGeoCode: false,
  townSetFromGeoCode: false,
  countySetFromGeoCode: false,
  step: 1,
  fields: {
    name: '',
    slug: '' as unknown as Slug,
    email: '',
    phone: '',
    website: '',
    address: '',
    postcode: '',
    town: '',
    county: '',
    countryName: '',
    countryCode: '' as CountryCodes,
    lat: 0,
    lng: 0,
  },
};

export default GetListedForm;
