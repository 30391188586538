import { getI18n } from 'react-i18next';
import { IPhoto } from '@bridebook/models/source/models/Suppliers/Photos.types';
import { IS3File } from '@bridebook/ui';
import { IDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';
import { PhotosActionTypes } from './action-types';

export const fetchPhotosSuccess = (payload: Record<string, IPhoto>) => ({
  type: PhotosActionTypes.FETCH_PHOTOS_SUCCESS,
  payload,
});

export const addPhotoSuccess =
  () =>
  ({ dispatch }: IDeps) => {
    dispatch(toggleSnackbar('success', getI18n().t('common:uploadSuccessful.snackbar')));
    return { type: PhotosActionTypes.ADD_PHOTO_SUCCESS };
  };

export const setPhotoPriority = (photos: IPhoto[]) => ({
  type: PhotosActionTypes.SET_PHOTO_ORDER,
  payload: photos,
});

export const setOrderAfterPhotoAddition = () => ({
  type: PhotosActionTypes.SET_ORDER_AFTER_PHOTO_ADD,
});

export const deletePhotos = (ids: IPhoto['id'][]) => ({
  type: PhotosActionTypes.DELETE_PHOTOS,
  payload: ids,
});

export const setThumbnail = (photo: IPhoto) => ({
  type: PhotosActionTypes.SET_THUMBNAIL,
  payload: photo,
});

export const addPhoto = (s3files: IS3File[]) => ({
  type: PhotosActionTypes.ADD_PHOTO,
  payload: s3files,
});

export const setGalleryChangesUnsaved = (unsaved: boolean) => ({
  type: PhotosActionTypes.SET_GALLERY_UNSAVED,
  payload: unsaved,
});

export const initPhotosListener = (supplierId: string) => ({
  type: PhotosActionTypes.INIT_PHOTOS_LISTENER,
  payload: supplierId,
});
