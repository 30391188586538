import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { OffersActionTypes } from 'lib/offers/action-types';
import { setInitialForm, toggleInitialised } from 'lib/offers/actions';
import { getSpecialOfferInitialForm } from 'lib/offers/utils';
import { IEpicDeps } from 'lib/types';
import { getOffers } from '../selectors/offers';

export const setInitialSpecialOfferEpic = (
  action$: Observable<any>,
  { state$ }: IEpicDeps,
): Observable<any> =>
  action$.pipe(
    ofType(OffersActionTypes.GET_INITIAL_FORM),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const { type } = state.offers;
      if (!type) {
        return of();
      }

      const offers = getOffers(state);
      const initialForm = getSpecialOfferInitialForm(offers, type, state);

      return of(setInitialForm(initialForm), toggleInitialised(true));
    }),
  );
