import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { filter, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { SupplierUsers } from '@bridebook/models';
import { serializeTimestamps } from '@bridebook/toolbox/src/serialize-timestamps';
import { AuthActionTypes, IOnAuthAction } from 'lib/auth/action-types';
import { onUserListener } from 'lib/users/actions/user-listener';
import { cleanTimestamps } from 'lib/utils';

export const initUserListener = (action$: Observable<IOnAuthAction>) =>
  action$.pipe(
    ofType(AuthActionTypes.ON_AUTH),
    mergeMap(({ payload: { user } }) => {
      const userId = user ? user.id : null;

      if (!userId) {
        return of();
      }

      return SupplierUsers._.getById(userId)
        .observe()
        .pipe(
          filter((data) => data && data.createdAt !== null),
          switchMap((data) => {
            if (data === null) {
              return of(onUserListener(data));
            }
            return of(onUserListener(serializeTimestamps(cleanTimestamps(data))));
          }),
          takeUntil(
            action$.pipe(
              filter(
                (action) => action.type === AuthActionTypes.ON_AUTH && !action.payload.firebaseUser,
              ),
            ),
          ),
        );
    }),
  );
