import { original, produce } from 'immer';
import { lensPath, mergeDeepRight, set, union, view, without } from 'ramda';
import { IBrochure } from '@bridebook/models/source/models/Suppliers/Brochures.types';
import { IPackage } from '@bridebook/models/source/models/Suppliers/Packages.types';
import { ISupplierPackage } from '@bridebook/toolbox/src/map-packages-to-sections';
import { Action, IReducersImmer } from '../types';
import {
  IOnFirestoreBrochuresAction,
  IOnFirestorePackagesAction,
  IOnFirestoreSupplierPackagesAction,
  IUpdatePackageCheckboxGroupAction,
  IUpdatePackageFieldAction,
  PricingActionTypes,
  type SavePackageSectionSuccessAction,
  type SetPackageSectionUnsavedAction,
} from './action-types';

export interface IPricingState {
  packagesSections: Record<string, IPackage>;
  // Dirty fields of the pricing packages form
  packagesSectionsUpdates: Partial<Record<string, IPackage>>;
  brochures: IBrochure[];
  supplierPackages: ISupplierPackage[];
  supplierPackagesLoaded: boolean;
}

const initialState: IPricingState = {
  packagesSections: {},
  packagesSectionsUpdates: {},
  brochures: [],
  supplierPackages: [],
  supplierPackagesLoaded: false,
};

const reducers: IReducersImmer<IPricingState> = (draft: IPricingState) => ({
  // Packages
  [PricingActionTypes.ON_FIRESTORE_PACKAGES]: (action: IOnFirestorePackagesAction) => {
    // Merge with dirty fields so that the listener doesn't override them
    draft.packagesSections = mergeDeepRight(action.payload, draft.packagesSectionsUpdates);
  },

  [PricingActionTypes.ON_FIRESTORE_SUPPLIER_PACKAGES]: (
    action: IOnFirestoreSupplierPackagesAction,
  ) => {
    const sortedPackages = Object.values(action.payload).sort(
      (a, b) => a.order - b.order || a.price - b.price,
    );
    draft.supplierPackages = sortedPackages;
    draft.supplierPackagesLoaded = true;
  },

  [PricingActionTypes.UPDATE_PACKAGE_CHECKBOX_GROUP]: (
    action: IUpdatePackageCheckboxGroupAction,
  ) => {
    const {
      payload: { name, value },
    } = action;
    const path = name.split('.');
    const propName = path.pop();
    const lens = lensPath(path);
    const packagesSections = original(draft.packagesSections) || {};
    const packagesSectionsUpdates = original(draft.packagesSectionsUpdates) || {};

    const currentArray = (view(lens, packagesSections) || []) as string[];
    // Depending on the checkbox value, add or remove the prop from the array
    const updatedArray = value
      ? union(currentArray, [propName])
      : without([propName], currentArray);

    // Save to packages since field values read from this object
    draft.packagesSections = set(lens, updatedArray, packagesSections);
    // Keep memory of dirty fields that have been changed to override in listener handler
    draft.packagesSectionsUpdates = set(lens, updatedArray, packagesSectionsUpdates);
  },

  [PricingActionTypes.SET_PACKAGE_SECTION_UNSAVED]: (action: SetPackageSectionUnsavedAction) => {
    const {
      payload: { packageSection },
    } = action;
    draft.packagesSectionsUpdates[packageSection] =
      draft.packagesSectionsUpdates[packageSection] || ({} as IPackage);
  },

  [PricingActionTypes.UPDATE_PACKAGE_FIELD]: (action: IUpdatePackageFieldAction) => {
    const {
      payload: { packageSection, name, value },
    } = action;
    const path = lensPath([packageSection, name]);

    // Save to packages since field values read from this object
    draft.packagesSections = set(path, value, original(draft.packagesSections) || {});
    // Keep memory of dirty fields that have been changed to override in listener handler
    draft.packagesSectionsUpdates = set(path, value, original(draft.packagesSectionsUpdates) || {});
  },

  [PricingActionTypes.SAVE_PACKAGE_SECTION_SUCCESS]: (action: SavePackageSectionSuccessAction) => {
    delete draft.packagesSectionsUpdates[action.payload.packageSection];
  },

  [PricingActionTypes.DISCARD_PACKAGES_FORM_CHANGES]: () => {
    draft.packagesSectionsUpdates = {};
  },

  // Brochures
  [PricingActionTypes.ON_FIRESTORE_BROCHURES]: (action: IOnFirestoreBrochuresAction) => {
    draft.brochures = action.payload;
  },
});

const reducer = (state: IPricingState = initialState, action: Action): IPricingState => {
  try {
    return produce(state, (draft) => reducers(draft, state)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
