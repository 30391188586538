import { RecommendationsActions } from '../action-types';
import { IRecommendationRender } from './update-recommendations-done';

export const deleteRecommendation = (recommendation: IRecommendationRender) => ({
  type: RecommendationsActions.DELETE_RECOMMENDATION,
  payload: { recommendation },
});

export const deleteRecommendationDone = () => ({
  type: RecommendationsActions.DELETE_RECOMMENDATION_DONE,
});
