import { equals } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { SupplierUsers } from '@bridebook/models';
import { extendUserAccessControl } from 'lib/access-control/utils/extend-user-access-control';
import { UserActionTypes } from 'lib/users/action-types';
import { updateExtendedActiveSupplierACL } from '../actions';

export const initUserAccessControlListener = (action$: Observable<any>) =>
  action$.pipe(
    ofType(UserActionTypes.ON_USER_LISTENER),
    distinctUntilChanged(({ payload: previousUser }, { payload: nextUser }) => {
      return equals(
        [...(previousUser?.suppliers || [])].sort(),
        [...(nextUser?.suppliers || [])].sort(),
      );
    }),
    filter(({ payload: user }) => Boolean(user?.id && user.suppliers && user.suppliers.length > 0)),
    mergeMap(({ payload: user }) => {
      const getPromise = async () => {
        const extendedSupplierACL = await extendUserAccessControl(user.suppliers, user.id);
        const activeSupplierAccessControl = await SupplierUsers._.getById(
          user.id,
        ).getActiveSupplierAccessControl();
        return { extendedSupplierACL, activeSupplierAccessControl };
      };

      return from(getPromise()).pipe(
        map(({ extendedSupplierACL, activeSupplierAccessControl }) =>
          updateExtendedActiveSupplierACL({
            extendedSupplierACL,
            activeSupplierAccessControl,
          }),
        ),
        takeUntil(action$.pipe(ofType('STOP_USER_ACCESS_CONTROL_LISTENER'))),
      );
    }),
  );
