import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { mapDateToUI, mapToDatepickerObject, mapToExactDate } from '@bridebook/toolbox/src';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { appError } from 'lib/app/actions';
import { EnquiriesActionTypes, IEditEnquiryInfoAction } from 'lib/enquiries/action-types';
import { editEnquiryInfoSuccess } from 'lib/enquiries/actions';
import { getSupplierCountryCode } from 'lib/supplier/selectors';
import { IEpicDeps } from 'lib/types';

export const editEnquiryInfoEpic = (
  action$: Observable<IEditEnquiryInfoAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.EDIT_ENQUIRY_INFO),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const {
        supplier: {
          supplier: { id: supplierId },
        },
        enquiries: {
          enquiry,
          enquiry: { wedding: weddingId },
        },
      } = state;
      const { email, phone, partnerName1, partnerName2, guestsInitialTarget, weddingDate } =
        payload;

      const formatedDate = weddingDate ? mapToDatepickerObject(weddingDate) : undefined;

      const getPromise = async () => {
        const supplier = Suppliers._.getById(supplierId);
        return supplier.Weddings.getById(weddingId).set({
          contacts: {
            email,
            phone,
          },
          partners: [partnerName1, partnerName2],
          guests: {
            estimate: parseInt(guestsInitialTarget),
          },
          ...(formatedDate && {
            date: formatedDate,
          }),
        });
      };

      return from(getPromise()).pipe(
        mergeMap(() => {
          const uiDate = mapDateToUI(formatedDate);
          const market = gazetteer.getMarketByCountry(
            getSupplierCountryCode(state),
            CountryCodes.GB,
          );

          return of(
            editEnquiryInfoSuccess({
              ...enquiry,
              email,
              phone,
              partnerName1,
              partnerName2,
              guestsInitialTarget,
              ...(formatedDate && {
                weddingDate: mapToExactDate(uiDate, market),
                weddingDateDatePicker: uiDate,
              }),
            }),
          );
        }),
        catchError((error: Error) =>
          of(appError({ error, feature: EnquiriesActionTypes.EDIT_ENQUIRY_INFO })),
        ),
      );
    }),
  );
