import { BridebookError } from 'lib/types';
import { IAuthFields } from './types';

const Fields: IAuthFields = {
  email: '',
  password: '',
};

export interface IChangeAuthEmailForm {
  disabled: boolean;
  error: BridebookError | null;
  fields: IAuthFields;
}

const ChangeAuthEmailForm: IChangeAuthEmailForm = {
  disabled: false,
  error: null,
  fields: Fields,
};

export default ChangeAuthEmailForm;
