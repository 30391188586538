import { colors } from '@bridebook/ui';
import { IStatisticsHighchartsConfig } from '../types';

interface IChartViewArgs {
  xAxisTitle?: string;
  yAxisTitle?: string;
  seriesName: string;
  yAxisReversed?: boolean;
  yAxisMin?: number;
  xAxisTitleEnabled?: boolean;
  yAxisTitleEnabled?: boolean;
  legendEnabled?: boolean;
  height?: number | null | string;
  allowDecimals?: boolean;
  tickInterval?: number;
}

const ChartView = ({
  xAxisTitle = '',
  yAxisTitle = '',
  seriesName,
  yAxisReversed = false,
  yAxisMin = 0,
  xAxisTitleEnabled = true,
  yAxisTitleEnabled = true,
  legendEnabled = true,
  height = null,
  allowDecimals = true,
  tickInterval,
}: IChartViewArgs): IStatisticsHighchartsConfig => ({
  credits: false,
  title: {
    text: '',
  },
  chart: {
    type: 'spline',
    height,
  },
  legend: {
    enabled: legendEnabled,
  },
  xAxis: {
    categories: [],
    title: {
      text: xAxisTitle,
      enabled: xAxisTitleEnabled,
    },
  },
  yAxis: {
    title: {
      text: yAxisTitle,
      enabled: yAxisTitleEnabled,
    },
    reversed: yAxisReversed,
    min: yAxisMin,
    allowDecimals,
    tickInterval,
  },
  series: [
    {
      name: seriesName,
      color: colors.indigoCrush,
      data: [],
    },
  ],
  loaded: false,
});

export default ChartView;
