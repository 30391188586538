import produce from 'immer';
import { AccessControlActionTypes } from 'lib/access-control/action-types';
import { SortOptions } from 'lib/reviews/types';
import { Action, IReducersImmer } from '../types';
import {
  EditFeedbackAction,
  FeedbackActions,
  SetFeedbackFieldAction,
  SetFeedbackPhotoAction,
  SetFeedbackVerifiedAction,
  SetSortReviewsMethodAction,
  UpdateFeedbackAction,
} from './action-types';
import { IFeedbackSerialized } from './types';

const initialState = {
  feedback: null as IFeedbackSerialized | null,
  list: [] as IFeedbackSerialized[],
  unsaved: false,
  editing: false,
  verified: false,
  sortBy: SortOptions.mostRecent,
  feedbackLoaded: false,
};

export type FeedbackState = typeof initialState;

const reducers: IReducersImmer<FeedbackState> = (draft) => ({
  [AccessControlActionTypes.SWITCH_SUPPLIER]: () => initialState,

  [FeedbackActions.UPDATE_FEEDBACK_LIST]: (action: UpdateFeedbackAction) => {
    draft.list = action.payload.feedback;
    draft.feedbackLoaded = true;
  },

  [FeedbackActions.SAVE_FEEDBACK_SUCCESS]: () => {
    draft.unsaved = false;
    draft.verified = false;
    draft.feedback = null;
  },

  [FeedbackActions.CLEAR_MODAL_VALUES]: () => {
    draft.feedback = null;
    draft.unsaved = false;
  },

  [FeedbackActions.EDIT_FEEDBACK]: (action: EditFeedbackAction) => {
    draft.feedback = action.payload.feedback;
    draft.unsaved = true;
    draft.editing = true;
  },

  [FeedbackActions.SET_FEEDBACK_FIELD]: (action: SetFeedbackFieldAction) => {
    const { name, value } = action.payload;

    if (!draft.feedback) {
      // only testimonial can be added
      draft.feedback = { type: 'testimonial' } as IFeedbackSerialized;
    }

    draft.feedback = { ...draft.feedback, [name]: value };

    draft.unsaved = true;
  },

  [FeedbackActions.SET_FEEDBACK_VERIFIED]: (action: SetFeedbackVerifiedAction) => {
    draft.verified = action.payload.verified;
  },
  [FeedbackActions.SET_EDITING]: () => {
    draft.editing = false;
  },

  [FeedbackActions.SET_FEEDBACK_PHOTO]: (action: SetFeedbackPhotoAction) => {
    if (!draft.feedback) {
      // only testimonial can be added
      draft.feedback = { type: 'testimonial' } as IFeedbackSerialized;
    }

    if (!draft.feedback.photos) {
      draft.feedback.photos = [];
    }

    // Only one photo per feedback allowed
    draft.feedback.photos = [action.payload.photo];
  },

  [FeedbackActions.SET_SORT_REVIEWS_METHOD]: (action: SetSortReviewsMethodAction) => {
    draft.sortBy = action.payload;
  },
});

const reducer = (state = initialState, action: Action) => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (e) {
    return state;
  }
};

export default reducer;
