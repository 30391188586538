import produce from 'immer';
import {
  IInitPricingAction,
  IOnFirestoreBalanceAction,
  ISelectPriceAction,
  IToggleBuyEnquiriesPopupAction,
  ITogglePaymentCallbackPopupAction,
  IToggleRevealEnquiryPopupAction,
  PayPerEnquiryActionTypes,
} from 'lib/pay-per-enquiry/action-types';
import { Action, IReducersImmer } from 'lib/types';
import { IPayPerEnquiryState } from './types';

const initialState: IPayPerEnquiryState = {
  buyEnquiriesPopupVisible: false,
};

const reducers: IReducersImmer<IPayPerEnquiryState> = (draft) => ({
  [PayPerEnquiryActionTypes.TOGGLE_BUY_ENQUIRIES_POPUP]: (
    action: IToggleBuyEnquiriesPopupAction,
  ) => {
    draft.buyEnquiriesPopupVisible = action.payload;
  },

  [PayPerEnquiryActionTypes.TOGGLE_PAYMENT_CALLBACK_POPUP]: (
    action: ITogglePaymentCallbackPopupAction,
  ) => {
    draft.paymentCallbackPopup = action.payload;
  },

  [PayPerEnquiryActionTypes.TOGGLE_REVEAL_ENQUIRY_POPUP]: (
    action: IToggleRevealEnquiryPopupAction,
  ) => {
    draft.revealEnquiryPopup = action.payload;
  },

  [PayPerEnquiryActionTypes.SELECT_PRICE]: (action: ISelectPriceAction) => {
    draft.selectedPrice = action.payload;
  },

  [PayPerEnquiryActionTypes.INIT_PRICING]: (action: IInitPricingAction) => {
    draft.pricing = action.payload;
    draft.selectedPrice = action.payload.find((price) => price.recommended);
  },

  [PayPerEnquiryActionTypes.ON_FIRESTORE_BALANCE]: (action: IOnFirestoreBalanceAction) => {
    draft.balance = action.payload;
  },

  ///TODO WIP to push out the changes
  [PayPerEnquiryActionTypes.SELECT_BALANCE]: (action: any) => {
    draft.selectedPrice = action.payload;
  },
});

const reducer = (
  state: IPayPerEnquiryState = initialState,
  action: Action,
): IPayPerEnquiryState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
