import { isEmpty, mergeDeepRight, pickBy } from 'ramda';
import createCachedSelector from 're-reselect';
import { IPackage_Translations } from '@bridebook/models/source/models/Suppliers/Packages.types';
import { IQuestion_Translations } from '@bridebook/models/source/models/Suppliers/Questions.types';
import { DotNotation } from '@bridebook/toolbox/src';
import { dotNotationToObject } from '@bridebook/toolbox/src/utils/dot-notation-to-object';
import {
  ContentTranslationsCollectionScope,
  ContentTranslationsScope,
} from 'lib/content-translations/types';
import { IApplicationState } from 'lib/types';

const getTempContentTranslations = (state: IApplicationState) => state.contentTranslations.temp;
const getOriginalContentTranslations = (state: IApplicationState) =>
  state.contentTranslations.original;

/**
 * Returns the translations object from the store. Overwrites the original translations with the
 * temporary translations if they exist.
 */
export const getContentTranslations = createCachedSelector(
  getOriginalContentTranslations,
  getTempContentTranslations,
  (_: IApplicationState, scope: ContentTranslationsScope) => scope,
  (original, temp, scope) => mergeDeepRight(original[scope] || {}, temp[scope] || {}),
)((_, scope) => scope);

/**
 * Returns the temporary translations for a collection. The scope should be the collection name
 * (e.g. 'questions', 'offers'). The translations are returned as an array of objects with the
 * following shape: { scope: TranslationsScope, translations: Record<string, string>, id: string }.
 */

export const getContentTranslationsForCollection = <P extends object>(
  scope: ContentTranslationsCollectionScope,
) =>
  createCachedSelector(
    getTempContentTranslations,
    getOriginalContentTranslations,
    () => scope,
    (temp, org, scope) =>
      (
        Object.entries(mergeDeepRight(org, temp))
          .filter((data): data is [ContentTranslationsScope, { [key: string]: string }] => {
            const [key, value] = data;
            return key.startsWith(scope) && Boolean(value);
          })
          .map(([key, value]) => ({
            scope,
            id: key.split('.').pop() as string,
            _translations: dotNotationToObject(value),
          })) || []
      ).filter(
        (
          item,
        ): item is {
          scope: ContentTranslationsCollectionScope;
          _translations: { [key: string]: P };
          id: string;
        } => Boolean(item?.id && item?._translations),
      ),
  )(() => scope);

export const getContentTranslationsForQuestions =
  getContentTranslationsForCollection<IQuestion_Translations>('questions');

export const getContentTranslationsForPackages =
  getContentTranslationsForCollection<IPackage_Translations>('packages');

/**
 * Returns the translations for a specific document. The scope should be the document id (e.g.
 *  'offers.123').
 */
export const getScopedContentTranslations = <P extends object>(
  state: IApplicationState,
  scope?: ContentTranslationsScope,
  translationPaths?: DotNotation<P>[],
): Record<string, P> | undefined => {
  if (!scope) return undefined;
  // The scope might point to a collection (questions, offers) or a single document (offer.123). If it's a
  // collection or main document (supplier), the split will return an array with one element, if
  // it's a single document, it will return an array with two elements.
  const data = scope?.split('.');
  const [collection, docId] = data;

  if (docId || collection === 'supplier') {
    // If no translations can be found in the temp translations, use the original translations.
    const storedTranslations =
      state.contentTranslations.temp[scope] || state.contentTranslations.original[scope] || {};

    // Apply paths if provided, eg. ['name', 'description'] will return only the translations for
    // those paths. Otherwise, return all translations for the document.
    const translationsToUpdate = translationPaths
      ? pickBy<Record<string, string>, Record<string, string>>(
          (_: string, key: string) => translationPaths.some((path) => key.includes(path)),
          storedTranslations,
        )
      : storedTranslations;

    const translationsObject = dotNotationToObject<Record<string, P>>(translationsToUpdate);
    return isEmpty(translationsObject) ? undefined : translationsObject;
  }

  return undefined;
};
