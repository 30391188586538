import validate from 'lib/validate';
import { IAuthFields } from '../types';

/**
 * Validate email and password using "validate" library
 * @method authValidate
 * @param {Function} validate fn as passed through dependency middleware
 * @param {Function} getState fn as passed through dependency middleware
 * @param {Object} fields containing email and password properties
 * @returns {Promise}
 */
const authValidate = async (fields: IAuthFields) => {
  try {
    return await validate(fields)
      .prop('email')
      .required()
      .email()
      .prop('password')
      .required()
      .simplePassword().promise;
  } catch (error) {
    throw error;
  }
};

export default authValidate;
