import { getI18n } from 'react-i18next';
import { BrochureUploadFailReason } from 'lib/pricing/types';

export const getBrochureError = (failReason: BrochureUploadFailReason): string => {
  if (!failReason) failReason = '';
  const { t } = getI18n();
  const failReasons = {
    reachedLimit: t('pricing:weddingBrochureLimitReached.validation'),
    wrongFileSize: t('pricing:weddingBrochurePricelistsFileSizeSnackbar.validation'),
    wrongFileType: t('pricing:weddingBrochureWrongFiletype.validation'),
    '': t('pricing:weddingBrochureUnknownError'),
  };
  return failReasons[failReason];
};

export default getBrochureError;
