import {
  fetchCollaboratorsDataSuccess,
  switchPermission,
  switchSupplier,
  updateAccessControlSuccess,
  updateExtendedActiveSupplierACL,
} from 'lib/access-control/actions';

export enum AccessControlActionTypes {
  ON_USER_ACCESS_CONTROL_LISTENER = '[Access Control] On user access control listener',
  ON_SUPPLIER_ACCESS_CONTROL_LISTENER = '[Access Control] On supplier access control listener',
  UPDATE_ACCESS_CONTROL_SUCCESS = '[ACL] Update access control success',
  EXTEND_ACCESS_CONTROL_SUCCESS = '[ACL] Extend access control success',
  FETCH_COLLABORATORS_DATA_SUCCESS = '[ACL] Fetch collaborators data success',
  SWITCH_SUPPLIER = '[ACL] Switch supplier ACL',
  ACCEPT_INVITE_SUCCESS = '[ACL] Accept invite success',
  SET_ADMIN = '[ACL] Set Admin',
  SWITCH_PERMISSION_START = '[ACL] Switch permission start',
  SWITCH_PERMISSION_SUCCESS = '[ACL] Switch permission success',
}

export type IFetchCollaboratorsDataSuccessAction = ReturnType<typeof fetchCollaboratorsDataSuccess>;
export type IUpdateAccessControlSuccessAction = ReturnType<
  ReturnType<typeof updateAccessControlSuccess>
>;
export type ISwitchPermissionAction = ReturnType<typeof switchPermission>;
export type ISwitchSupplierAction = ReturnType<typeof switchSupplier>;
export type IUpdateExtendedActiveSupplierACLAction = ReturnType<
  typeof updateExtendedActiveSupplierACL
>;
