import { values } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, pluck, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { AuthActionTypes } from 'lib/auth/action-types';
import { IEpic, IEpicDeps } from '../types';
import { IInitRecommendationsListenerAction, RecommendationsActions } from './action-types';
import updateRecommendations from './actions/update-recommendations';
import updateWeddingFairs from './actions/update-wedding-fairs';

export const initRecommendationsListener: IEpic<IInitRecommendationsListenerAction> = (
  action$: Observable<any>,
) =>
  action$.pipe(
    ofType(RecommendationsActions.INIT_RECOMMENDATIONS_LISTENER),
    pluck('payload'),
    switchMap((supplierId) =>
      Suppliers._.getById(supplierId)
        .Recommendations.query()
        .observe()
        .pipe(
          mergeMap((data) => of(updateRecommendations(values(data)))),
          takeUntil(action$.pipe(ofType(AuthActionTypes.SIGN_OUT_SUCCESS))),
        ),
    ),
  );

export const initWeddingFairsListener = (action$: Observable<any>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(RecommendationsActions.INIT_WEDDING_FAIRS_LISTENER),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const supplierId = state.users.activeSupplierAccessControl?.id;
      if (!supplierId) return of();

      return Suppliers._.getById(supplierId)
        .Fairs.query()
        .observe()
        .pipe(
          mergeMap((data) => of(updateWeddingFairs(values(data)))),
          takeUntil(action$.pipe(ofType(RecommendationsActions.STOP_WEDDING_FAIRS_LISTENER))),
        );
    }),
  );
