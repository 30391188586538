import { getProgressTrackerConfig } from 'lib/enquiries/utils/get-progress-tracker-config';

/**
 * Convert an array of progress ids to an object with all statuses as keys and boolean values.
 */
export const mapProgressArrayToAnalyticsObject = (progress?: string[]) =>
  getProgressTrackerConfig().reduce((a, v) => {
    a[v.name] = Boolean(progress?.includes(v.id));
    return a;
  }, {} as Record<string, boolean>);
