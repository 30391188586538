import {
  clickedPayForEnquiryCreditsAnalytics,
  clickedTryAgainCallbackPopupAnalytics,
  clickedUnlockEnquiryAnalytics,
  closedBuyEnquiriesCallbackPopupAnalytics,
  triggeredBuyEnquiriesCallbackPopupAnalytics,
  triggeredBuyEnquiriesPopupAnalytics,
  triggeredUnlockEnquiryPopupAnalytics,
} from 'lib/pay-per-enquiry/analytics/actions';

export enum PayPerEnquiryAnalyticsActionTypes {
  TRIGGERED_BUY_ENQUIRIES_POPUP = '[Analytics] Triggered buy enquiries popup',
  CLOSED_BUY_ENQUIRIES_POPUP = '[Analytics] Closed buy enquiries popup',
  CLICKED_PAY_FOR_ENQUIRY_CREDITS = '[Analytics] Clicked pay for enquiry credits',
  TRIGGERED_BUY_ENQUIRIES_CALLBACK_POPUP = '[Analytics] Triggered buy enquiries callback popup',
  CLOSED_BUY_ENQUIRIES_CALLBACK_POPUP = '[Analytics] Closed buy enquiries callback popup',
  CLICKED_TRY_AGAIN_CALLBACK_POPUP = '[Analytics] Clicked try again on callback popup',
  TRIGGERED_UNLOCK_ENQUIRY_POPUP = '[Analytics] Triggered unlock enquiry popup',
  CLICKED_UNLOCK_ENQUIRY = '[Analytics] Clicked unlock enquiry',
  CLOSED_UNLOCK_ENQUIRY_POPUP = '[Analytics] Closed unlock enquiry popup',
}

export type TriggeredBuyEnquiriesPopupAnalyticsAction = ReturnType<
  typeof triggeredBuyEnquiriesPopupAnalytics
>;

export type ClickedPayForEnquiryCreditsAction = ReturnType<
  typeof clickedPayForEnquiryCreditsAnalytics
>;

export type TriggeredBuyEnquiriesCallbackPopupAction = ReturnType<
  typeof triggeredBuyEnquiriesCallbackPopupAnalytics
>;

export type ClosedBuyEnquiriesCallbackPopupAction = ReturnType<
  typeof closedBuyEnquiriesCallbackPopupAnalytics
>;

export type ClickedTryAgainCallbackPopupAction = ReturnType<
  typeof clickedTryAgainCallbackPopupAnalytics
>;

export type ClickedUnlockEnquiryAction = ReturnType<typeof clickedUnlockEnquiryAnalytics>;
export type TriggeredUnlockEnquiryPopupAction = ReturnType<
  typeof triggeredUnlockEnquiryPopupAnalytics
>;
