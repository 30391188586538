import { serverTimestamp } from 'firebase/firestore';
import { Suppliers } from '@bridebook/models';
import { ISupplierAdmin } from '@bridebook/models/source/models/Suppliers/Admin/admin.types';
import { AdminActionTypes } from 'lib/admin/action-types';
import { updatedSupplierPremiumStatusAnalytics } from 'lib/premium/analytics/actions';
import { PremiumTiers } from 'lib/premium/constants';
import { IDeps } from 'lib/types';

export const updateAdminActions =
  <K extends keyof ISupplierAdmin['actions']>(
    name: K,
    value: ISupplierAdmin['actions'][K],
    supplierId: string,
  ) =>
  () => {
    const timestampOrBoolean = value === true ? serverTimestamp() : false;
    Suppliers._.getById(supplierId)
      .Admins.getById('admin')
      .set({ actions: { [name]: timestampOrBoolean } });

    return {
      type: 'UPDATE_SUPPLIER_SCORE_ANALYTICS',
      payload: { name, value },
    };
  };

export const updateAdminPremiumObject =
  <K extends keyof Required<ISupplierAdmin>['premium']>(
    key: K,
    value: Required<ISupplierAdmin>['premium'][K],
    premiumUpdateLocation: 'admin panel' | 'trialExpirationPopup' | 'checkout',
  ) =>
  ({ getState, dispatch }: IDeps) => {
    const activeSupplier = getState().users.activeSupplierAccessControl;
    const { supplierAdminObject } = getState().admin;
    if (activeSupplier?.id) {
      const adminEntity = Suppliers._.getById(activeSupplier.id).Admins.getById('admin');
      const isTier = key === 'tier';
      const active = isTier
        ? value > PremiumTiers.Tier_0
        : supplierAdminObject?.premium?.active || false;

      const updateObject = {
        [key]: value,
        active,
        updatedAt: serverTimestamp(),
      };

      if (!supplierAdminObject?.premium?.createdAt) {
        updateObject.createdAt = serverTimestamp();
      }

      adminEntity.set({
        premium: updateObject,
      });

      if (isTier) {
        dispatch(
          updatedSupplierPremiumStatusAnalytics({
            premium: updateObject,
            premiumUpdateLocation,
          }),
        );
      }
    }

    return {
      type: AdminActionTypes.UPDATE_SUPPLIER_ADMIN_PREMIUM,
      payload: { name: key, value },
    };
  };

export const enableDemoMode = (payload: PremiumTiers) => ({
  type: AdminActionTypes.ENABLE_DEMO_MODE,
  payload,
});

export const disableDemoMode = () => ({ type: AdminActionTypes.DISABLE_DEMO_MODE });
