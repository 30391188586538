import { values } from 'ramda';
import { Epic, combineEpics } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { fetchCollaboratorsData } from 'lib/access-control/epics/fetch-collaborators-data';
import { readAccessControl, switchPermissionEpic } from 'lib/access-control/epics/update-acl';
import { initUserAccessControlListener } from 'lib/access-control/epics/user-access-control-listener';
import { generateSupplierInviteEpic } from 'lib/admin/epics/generate-supplier-invite';
import { markSupplierAsDeletedEpic } from 'lib/admin/epics/mark-supplier-as-deleted';
import { unlinkSupplierUserEpic } from 'lib/admin/epics/unlink-supplier-user';
import { appStartedEpic, redirectOnProfileSwitchEpic } from 'lib/app/epics';
import * as authEpics from 'lib/auth/epics';
import { initBadgesListener } from 'lib/badges/listener';
import { combineAndAddErrorHandlerToEpics } from 'lib/combine-and-add-error-handler-to-epics';
import { IConfigureDeps } from 'lib/configure-deps';
import * as translationsEpics from 'lib/content-translations/epics';
import { initContactCouplesBalanceListener } from 'lib/couples-activity/epics/';
import {
  fetchBookingEpic,
  fetchBookingsEpic,
  fetchFilesEpic,
  fetchInviteMessage,
  processBookingYearsEpic,
  toggleCoupleCollaborationEpic,
} from 'lib/couples/epics';
import * as followUpMessages from 'lib/follow-up-messages/epics';
import * as getListedEpics from 'lib/get-listed/epics';
import * as groupConnectionEpics from 'lib/groups/epics';
import * as i18nEpics from 'lib/i18n/epics';
import * as messageTemplates from 'lib/message-templates/epics';
import clearEnquiryCTAPopupDisplayEpic from 'lib/pay-per-enquiry/epics/clear-enquiry-cta-popup-display-epic';
import fetchPricingEpic from 'lib/pay-per-enquiry/epics/fetch-ppe-pricing-epic';
import revealEnquiryEpic from 'lib/pay-per-enquiry/epics/reveal-enquiry-epic';
import {
  addBrochureEpic,
  changeBrochureTitleEpic,
  deletePricingPackageEpic,
  dropSupplierBrochureEpic,
  savePackageSectionEpic,
} from 'lib/pricing/epics';
import { initBrochuresListener, initPackagesListener } from 'lib/pricing/listener';
import { saveFirestoreQuestionsEpic } from 'lib/questions/epics';
import { initQuestionsListener } from 'lib/questions/listener';
import * as realWeddingsEpics from 'lib/real-weddings/epics';
import * as relationEpics from 'lib/relations/epics';
import { submitLinkedUserAnalyticsEpic } from 'lib/settings/analytics/epics';
import { changeUserRoleEpic } from 'lib/settings/epics/change-user-role-epic';
import fetchPerformanceEpic from 'lib/statistics/epics/fetch-performance';
import { supplierStatusUpdateEpic } from 'lib/supplier-status/epics';
import { fetchCountriesEpic, saveFormSectionEpic, saveSupplierEpic } from 'lib/supplier/epics';
import { initSupplierListener } from 'lib/supplier/listener';
import { updateConciergeOptOutEpic, updateTargetingEpic } from 'lib/targeting/epics';
import { resetPanelEpic } from 'lib/ui/actions';
import * as uiEpics from 'lib/ui/epics';
import { saveUserEpic } from 'lib/users/epics/save-user-after-auth';
import { initUserListener } from 'lib/users/listener';
import { initVideosListener } from 'lib/videos/listener';
import { initAchievementsListener } from './achievements/listener';
import { initSupplierAdminListener } from './admin/epics/init-supplier-admin-listener';
import { routingEpic, windowResizeEpic } from './app/actions';
import { initAppsListener } from './apps/epics/apps-listener';
import {
  deleteScheduledFollowUp,
  editEnquiryInfoEpic,
  fetchEnquiryEpic,
  scheduleFollowUpMessage,
  setEnquiryProgressEpic,
  setEnquiryStatusEpic,
  updateNotesCountEpic,
  updateOwnerEpic,
} from './enquiries/epics';
import addFeedbackPhotoEpic from './feedback/epic/add-feedback-photo-epic';
import deleteFeedbackEpic from './feedback/epic/delete-feedback-epic';
import saveFeedbackEpic from './feedback/epic/save-feedback-epic';
import saveReviewResponseEpic from './feedback/epic/save-review-response-epic';
import feedbackListenerEpic from './feedback/listener';
import {
  deleteSpecialOfferEpic,
  saveSpecialOfferEpic,
  saveSupplierDiscountEpic,
  setInitialSpecialOfferEpic,
} from './offers/epics';
import { initOffersListener } from './offers/listener';
import { initEnquiryBalanceListener } from './pay-per-enquiry/listener';
import {
  addPhotoEpic,
  deletePhotosEpic,
  setPhotoOrderEpic,
  setThumbnailEpic,
} from './photos/epics';
import { setOrderAfterPhotoAdd } from './photos/epics/set-order-after-photo-add';
import { initPhotosListener } from './photos/listener';
import { saveSupplierPackageEpic } from './pricing/epics/save-supplier-package';
import addRecommendationEpic from './recommendations/epics/add-recommendation';
import { deleteRecommendationsEpic } from './recommendations/epics/delete-recommendation';
import { deleteWeddingFairEpic } from './recommendations/epics/delete-wedding-fair';
import { saveWeddingFairEpic } from './recommendations/epics/save-wedding-fair';
import { updateRecommendationsEpic } from './recommendations/epics/update-recommendations';
import { initRecommendationsListener, initWeddingFairsListener } from './recommendations/listeners';
import {
  fetchMonoqueryEpic,
  fetchReviewsRatingEpic,
  fetchSearchRankingEpic,
  fetchStatisticsAsyncEpic,
  fetchSupplierScoreEpic,
  fetchTierChangeHistoryEpic,
  getHistoricRankingsEpic,
  prepareChartsEpic,
} from './statistics/epics';
import fetchCompetitorsEpic from './statistics/epics/fetch-competitors-epic';
import fetchUkCompetitorsRankingsEpic from './statistics/epics/fetch-uk-competitors-rankings-epic';
import { IApplicationState, IEpic, IEpicDeps } from './types';
import { addVideoEpic } from './videos/epics/add-video-epic';
import { deleteVideoEpic } from './videos/epics/delete-video-epic';

type GlobalEpic = IEpic<any, any>;

// Auth feature epics
const authEpicsCombined = combineAndAddErrorHandlerToEpics(authEpics, 'Auth');

// UI features epics
const uiEpicsCombined = combineAndAddErrorHandlerToEpics(uiEpics, 'UI');

// Translations feature epics
const translationsEpicsCombined = combineAndAddErrorHandlerToEpics(
  translationsEpics,
  'Translations',
);

const globalEpics: GlobalEpic[] = values({
  windowResizeEpic,
  routingEpic,
  appStartedEpic,
  redirectOnProfileSwitchEpic,
  // statistics
  fetchStatisticsAsyncEpic,
  fetchSupplierScoreEpic,
  fetchSearchRankingEpic,
  fetchReviewsRatingEpic,
  fetchMonoqueryEpic,
  fetchTierChangeHistoryEpic,
  prepareChartsEpic,
  getHistoricRankingsEpic,
  fetchCompetitorsEpic,
  fetchUkCompetitorsRankingsEpic,
  fetchPerformanceEpic,
  // enquiries
  fetchEnquiryEpic,
  setEnquiryProgressEpic,
  editEnquiryInfoEpic,
  setEnquiryStatusEpic,
  updateNotesCountEpic,
  updateOwnerEpic,
  scheduleFollowUpMessage,
  deleteScheduledFollowUp,
  // emitTimer,
  resetPanelEpic,
  // supplier
  initSupplierListener,
  saveSupplierEpic,
  fetchCountriesEpic,
  saveFormSectionEpic,
  initBadgesListener,
  // pricing
  initPackagesListener,
  savePackageSectionEpic,
  saveSupplierPackageEpic,
  initBrochuresListener,
  addBrochureEpic,
  changeBrochureTitleEpic,
  dropSupplierBrochureEpic,
  deletePricingPackageEpic,
  // recommendations
  saveWeddingFairEpic,
  deleteWeddingFairEpic,
  updateRecommendationsEpic,
  deleteRecommendationsEpic,
  addRecommendationEpic,
  initRecommendationsListener,
  initWeddingFairsListener,
  // offers
  saveSpecialOfferEpic,
  setInitialSpecialOfferEpic,
  deleteSpecialOfferEpic,
  saveSupplierDiscountEpic,
  initOffersListener,
  // targeting
  updateTargetingEpic,
  updateConciergeOptOutEpic,
  // Achievements
  initAchievementsListener,
  // Questions
  initQuestionsListener,
  saveFirestoreQuestionsEpic,
  // feedback
  feedbackListenerEpic,
  deleteFeedbackEpic,
  saveFeedbackEpic,
  addFeedbackPhotoEpic,
  saveReviewResponseEpic,
  // Auth
  authEpicsCombined,
  // Get listed
  ...getListedEpics,
  // User
  saveUserEpic,
  initUserListener,
  // Videos,
  initVideosListener,
  addVideoEpic,
  deleteVideoEpic,
  // Photos
  initPhotosListener,
  setPhotoOrderEpic,
  addPhotoEpic,
  setThumbnailEpic,
  deletePhotosEpic,
  setOrderAfterPhotoAdd,
  // Access Control
  initUserAccessControlListener,
  readAccessControl,
  switchPermissionEpic,
  // extendUserAccessControlEpic,
  fetchCollaboratorsData,
  supplierStatusUpdateEpic,
  // Admin
  generateSupplierInviteEpic,
  markSupplierAsDeletedEpic,
  initSupplierAdminListener,
  unlinkSupplierUserEpic,
  // Your Couples
  fetchBookingsEpic,
  fetchFilesEpic,
  processBookingYearsEpic,
  toggleCoupleCollaborationEpic,
  fetchBookingEpic,
  fetchInviteMessage,
  // Settings Analytics
  submitLinkedUserAnalyticsEpic,
  changeUserRoleEpic,
  // i18n
  ...i18nEpics,
  // UI
  uiEpicsCombined,
  // Pay per enquiry
  initEnquiryBalanceListener,
  fetchPricingEpic,
  revealEnquiryEpic,
  clearEnquiryCTAPopupDisplayEpic,
  // Translations
  translationsEpicsCombined,
  // Relations
  ...relationEpics,
  // Real weddings
  ...realWeddingsEpics,
  // Contact couples
  initContactCouplesBalanceListener,
  // Apps
  initAppsListener,
  ...messageTemplates,
  ...followUpMessages,
  // Groups
  ...groupConnectionEpics,
}).filter((item) => typeof item === 'function');

let epic$: BehaviorSubject<GlobalEpic> | null;

const configureEpics =
  (deps: IConfigureDeps): Epic<any, any, IApplicationState, IEpicDeps> =>
  (action$, state$) => {
    if (!epic$) {
      epic$ = new BehaviorSubject(combineEpics<GlobalEpic>(...globalEpics));
    }

    return epic$.pipe(mergeMap((epic) => epic(action$, { ...deps, state$ })));
  };

export default configureEpics;
