import produce from 'immer';
import {
  CouplesActivityActionTypes,
  ISetAvailableMessagesBalanceAction,
} from 'lib/couples-activity';
import { Action, IReducersImmer } from 'lib/types';
import { ICouplesActivityState } from './types';

const initialState: ICouplesActivityState = {
  messagesBalance: 0,
};

const reducers: IReducersImmer<ICouplesActivityState> = (draft) => ({
  [CouplesActivityActionTypes.SET_AVAILABLE_MESSAGES_BALANCE]: ({
    payload,
  }: ISetAvailableMessagesBalanceAction) => {
    draft.messagesBalance = payload;
  },
});

const reducer = (
  state: ICouplesActivityState = initialState,
  action: Action,
): ICouplesActivityState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
