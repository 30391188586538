import {
  fetchMonoquery,
  fetchMonoquerySuccess,
  fetchReviewsRating,
  fetchReviewsRatingSuccess,
  fetchSearchRanking,
  fetchSearchRankingSuccess,
  fetchSupplierScoreSuccess,
  switchChartGranularity,
  updateReadItems,
} from './actions';
import {
  fetchCompetitorsSuccess,
  fetchHistoricRankings,
  fetchHistoricRankingsSuccess,
  fetchPeerPerformanceSuccess,
  fetchStatisticsValueSuccess,
  fetchTierChangeHistory,
  fetchTierChangeHistorySuccess,
  fetchUkCompetitorsRankingsSuccess,
  prepareCharts,
} from './actions/fetch';

export enum StatisticsActionTypes {
  FETCH_TIER_CHANGE_HISTORY = '[Statistics] Fetch tier change history',
  FETCH_TIER_CHANGE_HISTORY_ERROR = '[Statistics] Fetch tier change history error',
  FETCH_TIER_CHANGE_HISTORY_SUCCESS = '[Statistics] Fetch tier change history success',
  PREPARE_CHARTS = '[Statistics] Prepare charts',

  FETCH_MONOQUERY = '[Statistics] Fetch monoquery',
  FETCH_MONOQUERY_ERROR = '[Statistics] Fetch monoquery error',
  FETCH_MONOQUERY_SUCCESS = '[Statistics] Fetch monoquery success',
  FETCH_REVIEWS_RATING = '[Statistics] Fetch reviews rating',
  FETCH_REVIEWS_RATING_ERROR = '[Statistics] Fetch reviews rating error',
  FETCH_REVIEWS_RATING_SUCCESS = '[Statistics] Fetch reviews rating success',
  FETCH_SEARCH_RANKING = '[Statistics] Fetch search ranking',
  FETCH_SEARCH_RANKING_ERROR = '[Statistics] Fetch search ranking error',
  FETCH_SEARCH_RANKING_SUCCESS = '[Statistics] Fetch search ranking success',
  FETCH_UK_COMPETITORS_RANKINGS = '[Statistics] Fetch UK competitors rankings',
  FETCH_UK_COMPETITORS_RANKINGS_SUCCESS = '[Statistics] Fetch UK competitors rankings success',
  FETCH_STATISTICS_MARKET = '[Statistics] Fetch Market statistics',
  FETCH_STATISTICS_REACH = '[Statistics] Fetch Reach statistics',
  FETCH_STATISTICS_VALUE_ERROR = '[Statistics] Fetch statistics value error',
  FETCH_STATISTICS_VALUE_SUCCESS = '[Statistics] Fetch statistics value success',
  FETCH_COMPETITORS = '[Statistics] Fetch competitors',
  FETCH_COMPETITORS_SUCCESS = '[Statistics] Fetch competitors success',
  FETCH_PEER_PERFORMANCE = '[Statistics] Fetch peer performance',
  FETCH_PEER_PERFORMANCE_SUCCESS = '[Statistics] Fetch peer performance success',
  FETCH_SUPPLIER_SCORE = '[Statistics] Fetch supplier score',
  FETCH_SUPPLIER_SCORE_ERROR = '[Statistics] Fetch supplier score error',
  FETCH_SUPPLIER_SCORE_SUCCESS = '[Statistics] Fetch supplier score success',
  FETCH_HISTORIC_RANKINGS = '[Statistics] Fetch supplier historic search rankings',
  FETCH_HISTORIC_RANKINGS_SUCCESS = '[Statistics] Fetch supplier historic search rankings success',
  FETCH_HISTORIC_RANKINGS_ERROR = '[Statistics] Fetch supplier historic search rankings error',
  STATISTICS_CLEAR_ALL = '[Statistics] Clear all data',
  SWITCH_CHART_GRANULARITY = '[Statistics] Switch chart granularity',
  READ_ITEMS_UPDATED = '[Statistics] Read items updated',
}

export type FetchStatisticsValueSuccessAction = ReturnType<typeof fetchStatisticsValueSuccess>;
export type FetchSupplierScoreSuccessAction = ReturnType<typeof fetchSupplierScoreSuccess>;
export type FetchSearchRankingAction = ReturnType<typeof fetchSearchRanking>;
export type FetchSearchRankingSuccessAction = ReturnType<typeof fetchSearchRankingSuccess>;
export type FetchMonoqueryAction = ReturnType<typeof fetchMonoquery>;
export type FetchMonoquerySuccessAction = ReturnType<ReturnType<typeof fetchMonoquerySuccess>>;
export type FetchTierChangeHistoryAction = ReturnType<typeof fetchTierChangeHistory>;
export type FetchTierChangeHistorySuccessAction = ReturnType<typeof fetchTierChangeHistorySuccess>;
export type PrepareChartsAction = ReturnType<typeof prepareCharts>;

export type FetchReviewsRatingAction = ReturnType<typeof fetchReviewsRating>;
export type FetchReviewsRatingSuccessAction = ReturnType<typeof fetchReviewsRatingSuccess>;
export type FetchHistoricRankingsAction = ReturnType<typeof fetchHistoricRankings>;
export type FetchHistoricRankingsSuccessAction = ReturnType<
  ReturnType<typeof fetchHistoricRankingsSuccess>
>;
export type SwitchChartGranularityAction = ReturnType<ReturnType<typeof switchChartGranularity>>;
export type ReadItemsUpdatedAction = ReturnType<typeof updateReadItems>;
export type FetchUkCompetitorsRankingsSuccessAction = ReturnType<
  typeof fetchUkCompetitorsRankingsSuccess
>;
export type FetchCompetitorsSuccessAction = ReturnType<typeof fetchCompetitorsSuccess>;
export type FetchPeerPerformanceSuccessAction = ReturnType<typeof fetchPeerPerformanceSuccess>;
