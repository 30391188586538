import type { IDatePicker } from './types';

/**
 * Function `mapToDatepickerObject`
 * take exact date string of a format `YYYY-DD-MM` or JS Date object and return IDatePicker object
 *
 * @function mapToDatepickerObject
 * @param {string | Date}
 * @returns {Object} - IDatePicker
 */
const mapToDatepickerObject = (exactDate: string | Date): IDatePicker => {
  const fallback: IDatePicker = {
    d: null,
    m: null,
    y: null,
  };
  try {
    const jsDate = new Date(exactDate);
    if (jsDate instanceof Date && !isNaN(jsDate.getUTCDate())) {
      return {
        d: jsDate.getUTCDate(),
        m: jsDate.getUTCMonth(),
        y: jsDate.getUTCFullYear(),
      };
    }
    return fallback;
  } catch {
    return fallback;
  }
};

export default mapToDatepickerObject;
