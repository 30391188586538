import { ofType } from 'redux-observable';
import { Observable, ObservableInput, of } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { ISupplierProduct } from '@bridebook/models/source/models/Suppliers/Admin/ledger/Transactions.types';
import { appError } from 'lib/app/actions';
import {
  CouplesActivityActionTypes,
  setAvailableContactCouplesMessagesBalance,
} from 'lib/couples-activity';
import { Action, IApplicationState, IEpicDeps } from 'lib/types';

export const initContactCouplesBalanceListener = (
  action$: Observable<any>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(CouplesActivityActionTypes.INIT_BALANCE_LISTENER),
    withLatestFrom<Action, ObservableInput<IApplicationState>>(state$),
    filter(hasActiveSupplier),
    map(toActiveSupplier),
    switchMap((activeSupplierId) => {
      const productId = ISupplierProduct.CONTACT_COUPLES;

      return Suppliers._.getById(activeSupplierId)
        .Admins.ledger.observe()
        .pipe(
          map((ledger) =>
            setAvailableContactCouplesMessagesBalance(ledger?.balance[productId] || 0),
          ),
          takeUntil(action$.pipe(ofType(CouplesActivityActionTypes.STOP_BALANCE_LISTENER))),
          catchError((error: Error) =>
            of(appError({ error, feature: 'Couples Activity Balance' })),
          ),
        );
    }),
  );

const hasActiveSupplier = ([_, state]: [Action, IApplicationState]) =>
  !!state.users.activeSupplierAccessControl?.id;

const toActiveSupplier = ([_, state]: [Action, IApplicationState]) =>
  state.users.activeSupplierAccessControl?.id as string;
