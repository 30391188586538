import { AuthActionTypes } from 'lib/auth/action-types';

export const redirectAfterAuth = (userId: string | null, signedUp?: boolean) => ({
  type: AuthActionTypes.REDIRECT_AFTER_AUTH,
  payload: { userId, signedUp },
});

export const redirectAfterAuthSuccess = () => ({
  type: AuthActionTypes.REDIRECT_AFTER_AUTH_DONE,
});
