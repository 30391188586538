import { getI18n } from 'react-i18next';

const validateLinkedAccount = (validate, formFields) =>
  validate(formFields)
    .prop('email')
    .required(() => getI18n().t('common:validation.required.email'))
    .email()
    .prop('permissionLevel')
    .required();

export default validateLinkedAccount;
