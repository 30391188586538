import { addBusinessDays, addDays } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, mergeMap, pluck, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { appError } from 'lib/app/actions';
import { EnquiriesActionTypes, IScheduleFollowUpMessage } from 'lib/enquiries/action-types';
import { updateEnquiryScheduledFollowUp } from 'lib/enquiries/actions';
import { getEnquiry } from 'lib/enquiries/selectors';
import { followUpScheduledAnalytics } from 'lib/follow-up-messages/analytics/actions';
import { IEpicDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';
import { withSupplierId } from 'lib/utils/operators/with-supplier-id';

export const scheduleFollowUpMessage = (
  action$: Observable<IScheduleFollowUpMessage>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.SCHEDULE_FOLLOW_UP_MESSAGE),
    pluck('payload'),
    withSupplierId(state$),
    withLatestFrom(state$),
    mergeMap(([[supplierId, followUpMessage], state]) => {
      const getPromise = async () => {
        const supplierWeddingId = getEnquiry(state)?.id;

        if (!supplierWeddingId) {
          throw Error('No enquiry found to save scheduled follow-up');
        }

        const scheduledDate = followUpMessage.delay.businessDaysOnly
          ? addBusinessDays(new Date(), followUpMessage.delay.days)
          : addDays(new Date(), followUpMessage.delay.days);

        const followUps = [followUpMessage.id];

        await Suppliers._.getById(supplierId)
          .Weddings.getById(supplierWeddingId)
          .update({
            followUpAt: Timestamp.fromDate(scheduledDate),
            followUps,
          });

        return {
          followUpAt: scheduledDate.getTime(),
          followUps,
          supplierWeddingId,
        };
      };

      return from(getPromise()).pipe(
        mergeMap(({ supplierWeddingId, followUpAt, followUps }) => [
          // optimistic update
          updateEnquiryScheduledFollowUp({ followUpAt, followUps }),
          followUpScheduledAnalytics({
            followUpMessage,
            supplierWeddingId,
          }),
          toggleSnackbar(
            'success',
            getI18n().t('enquiryManager:scheduledFollowUp.scheduleSuccess'),
          ),
        ]),
        catchError((error) => [
          appError({ error, feature: 'Schedule follow-up' }),
          toggleSnackbar('alert', getI18n().t('enquiryManager:scheduledFollowUp.scheduleError')),
        ]),
      );
    }),
  );
