import produce from 'immer';
import { keys, lensPath, set, values } from 'ramda';
import { Action } from 'redux';
import { IAchievement } from '@bridebook/models/source/models/Suppliers/Achievements.types';
import { mapCleanTimestamps } from 'lib/utils';
import {
  AchievementsActionTypes,
  AddAchievementDraftAction,
  OnFirestoreAchievementsAction,
  SetAchievementFieldAction,
} from './action-types';
import { AchievementsTypes } from './constants';
import { IAchievementDraft, IAchievementsState } from './types';

const initialState: IAchievementsState = {
  featuredIn: [],
  industryAwards: [],
  accreditationsAssociations: [],
};

const achievementTypeMap: Record<keyof IAchievementsState, IAchievement['type']> = {
  featuredIn: AchievementsTypes.media,
  industryAwards: AchievementsTypes.award,
  accreditationsAssociations: AchievementsTypes.accreditation,
};

const getAchievementDraft = (section: keyof IAchievementsState): IAchievementDraft => {
  const type = achievementTypeMap[section];

  return { name: '', link: '', type };
};

const reducers: any = (draft: IAchievementsState) => ({
  [AchievementsActionTypes.ON_FIRESTORE_ACHIEVEMENTS]: (action: OnFirestoreAchievementsAction) => {
    const all = mapCleanTimestamps(values(action.payload));
    keys(achievementTypeMap).forEach((section) => {
      const type = achievementTypeMap[section];

      draft[section] = all.filter((record) => record.type === type);
    });
  },

  [AchievementsActionTypes.ADD_ACHIEVEMENT_DRAFT]: (action: AddAchievementDraftAction) => {
    const {
      payload: { section },
    } = action;

    draft[section] = draft[section].concat(getAchievementDraft(section));
  },

  [AchievementsActionTypes.SET_ACHIEVEMENT_FIELD]: (action: SetAchievementFieldAction) => {
    const {
      payload: { section, index, name, value },
    } = action;

    const lens = lensPath([index, name]);

    draft[section] = set(lens, value)(draft[section]);
  },
});

const reducer = (state: IAchievementsState = initialState, action: Action): IAchievementsState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
