import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { appError } from 'lib/app/actions';
import {
  FetchStatisticsValueSuccessAction,
  StatisticsActionTypes,
} from 'lib/statistics/action-types';
import { fetchPeerPerformanceSuccess } from 'lib/statistics/actions';
import { IEpicDeps } from 'lib/types';

const fetchPerformance = (supplierId: string) =>
  authenticatedFetch(`/api/supplier/${supplierId}/statistics/peer-performance`).then((d) =>
    d.json(),
  );

const fetchPerformanceEpic = (
  action$: Observable<FetchStatisticsValueSuccessAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(StatisticsActionTypes.FETCH_PEER_PERFORMANCE),
    withLatestFrom(state$),
    filter(([_, state]) => Boolean(state.supplier?.supplier?.id)),
    mergeMap(([_, state]) =>
      from(fetchPerformance(state.supplier.supplier?.id!)).pipe(
        map(fetchPeerPerformanceSuccess),
        catchError((error) =>
          of(
            { type: StatisticsActionTypes.FETCH_STATISTICS_VALUE_ERROR },
            appError({ error, feature: 'fetchPerformanceEpic' }),
          ),
        ),
      ),
    ),
  );

export default fetchPerformanceEpic;
