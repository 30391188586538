import { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { getSupplierLocale } from 'lib/app/selectors';
import { MessageTemplatesAnalyticsActions } from 'lib/message-templates/analytics/action-types';
import { getSupplierId, getSupplierTier, getSupplierType } from 'lib/supplier/selectors';
import { getSupplierCountryCode, getSupplierName } from 'lib/supplier/selectors/contact';
import { Action, IApplicationState, State } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';

const commonAnalyticsProperties = (state: IApplicationState) => {
  const countryCode = getSupplierCountryCode(state);
  const countryName = countryCode ? Gazetteer.getCountryName(countryCode) : undefined;
  return {
    category: 'CMS',
    countryCode,
    countryName,
    locale: getSupplierLocale(state),
    supplierId: getSupplierId(state),
    supplierName: getSupplierName(state),
    supplierTier: getSupplierTier(state),
    supplierCategory: getSupplierType(state),
  };
};

export default function messageTemplatesAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const { track } = bridebookAnalytics.getMethods('CMS');
  const state = getState();

  switch (action.type) {
    case MessageTemplatesAnalyticsActions.AddTemplateClicked: {
      track({
        ...commonAnalyticsProperties(state),
        event: 'Clicked to add message template in CMS',
      });
      break;
    }

    case MessageTemplatesAnalyticsActions.MessageTemplateAdded: {
      const { templateTitle, templateId } = action.payload;
      track({
        ...commonAnalyticsProperties(state),
        event: 'Added message template in CMS',
        templateTitle,
        templateId,
      });
      break;
    }

    case MessageTemplatesAnalyticsActions.MessageTemplateEdited: {
      const { templateTitle, templateId } = action.payload;
      track({
        ...commonAnalyticsProperties(state),
        event: 'Edited message template in CMS',
        templateTitle,
        templateId,
      });
      break;
    }

    case MessageTemplatesAnalyticsActions.MessageTemplateDeleted: {
      const { templateTitle, templateId } = action.payload;
      track({
        ...commonAnalyticsProperties(state),
        event: 'Deleted message template in CMS',
        templateTitle,
        templateId,
      });
      break;
    }

    case MessageTemplatesAnalyticsActions.MessageTemplateFileAttached: {
      const { templateId } = action.payload;
      track({
        ...commonAnalyticsProperties(state),
        event: 'Attached file to message template in CMS',
        templateId,
      });
      break;
    }

    case MessageTemplatesAnalyticsActions.MessageTemplateFileUploadFailed: {
      const { failureReason, templateId } = action.payload;
      track({
        ...commonAnalyticsProperties(state),
        event: 'Failed to attach file to message template in CMS',
        failureReason,
        templateId,
      });
      break;
    }

    case MessageTemplatesAnalyticsActions.MessageTemplateFileRemoved: {
      const { templateId } = action.payload;
      track({
        ...commonAnalyticsProperties(state),
        event: 'Removed file from message template in CMS',
        templateId,
      });
      break;
    }

    case MessageTemplatesAnalyticsActions.MessageTemplateUsed: {
      const { templateTitle, templateId } = action.payload;
      track({
        ...commonAnalyticsProperties(state),
        event: 'Used message template',
        templateTitle,
        templateId,
      });
      break;
    }

    default:
      break;
  }
}
