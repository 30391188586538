import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { appError } from 'lib/app/actions';
import { PayPerEnquiryActionTypes } from 'lib/pay-per-enquiry/action-types';
import { initPricing } from 'lib/pay-per-enquiry/actions';
import { Action } from 'lib/types';

const fetchPPEPricingEpic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(PayPerEnquiryActionTypes.FETCH_PRICING),
    mergeMap(() => {
      const getPromise = async () =>
        authenticatedFetch(`/api/ppe/fetch-prices`).then((res) => {
          if (res.status !== 200) {
            throw new Error('Failed to fetch pay per enquiry pricing');
          }

          return res.json();
        });

      return from(getPromise()).pipe(
        map((data) => initPricing(data.sort((a, b) => (a.quantity > b.quantity ? 1 : -1)))),
        catchError((error: Error) => of(appError({ error, feature: 'PayPerEnquiry' }))),
      );
    }),
  );

export default fetchPPEPricingEpic;
