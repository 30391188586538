import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../../abstract/Document';
import { type Supplier, Suppliers } from '../Suppliers';
import type { IFile } from './Files.types';

@Identifiable
@Timestampable
@Untrackable
export class File extends AbstractDocument<IFile> {
  readonly collections = {};

  readonly translatable = (data: IFile): IFile['_translations'][string] => ({
    name: data.name,
  });
}

export class Files extends AbstractCollection<File, IFile> {
  static path = 'files';

  constructor(document: Supplier) {
    super(document.collection(Files.path), File);
  }

  get supplier() {
    return Suppliers._.getById(this.reference.parent.id);
  }
}
