import { isNil } from 'ramda';
import { getI18n } from 'react-i18next';
import { PremiumTiers, SupplierProEnabledTiers, VenueProEnabledTiers } from 'lib/premium/constants';

// Note: (NaN, undefined) are not free or paid. Non-venue suppliers do not have tier logic.
export const isFreeTier = (tier?: PremiumTiers) =>
  tier === PremiumTiers.Unverified || tier === PremiumTiers.Tier_0;

export const isPaidTier = (tier?: PremiumTiers) =>
  !!tier &&
  [
    PremiumTiers.Tier_1,
    PremiumTiers.Tier_2,
    PremiumTiers.Tier_2_5,
    PremiumTiers.Tier_3,
    PremiumTiers.Tier_4,
  ].includes(tier);

/**
 * Returns either full (eg. VenuePro Expert) or short (eg. Expert) names of the premium tiers
 */
export const getPremiumTierNames = (fullName = true): Record<PremiumTiers, string> => {
  const i18n = getI18n();

  const prefix = fullName ? 'VenuePro ' : '';

  return {
    [PremiumTiers.Unverified]: i18n.t('common:premiumTiers.venue.unverified'),
    [PremiumTiers.Tier_0]: i18n.t('common:premiumTiers.venue.tier_0'),
    [PremiumTiers.Tier_1]: prefix + i18n.t('common:premiumTiers.venue.tier_1'),
    [PremiumTiers.Tier_2]: prefix + i18n.t('common:premiumTiers.venue.tier_2'),
    [PremiumTiers.Tier_2_5]: prefix + i18n.t('common:premiumTiers.venue.tier_2_5'),
    [PremiumTiers.Tier_3]: prefix + i18n.t('common:premiumTiers.venue.tier_3'),
    [PremiumTiers.Tier_4]: prefix + i18n.t('common:premiumTiers.venue.tier_4'),
  };
};

// todo: should be merged with `getPremiumTierName` with `isVenue` flag
export const getSupplierPremiumTierNames = (
  fullName = true,
): Partial<Record<PremiumTiers, string>> => {
  const i18n = getI18n();
  const prefix = fullName ? 'SupplierPro ' : '';
  return {
    [PremiumTiers.Tier_0]: i18n.t('common:premiumTiers.supplier.tier_0'),
    [PremiumTiers.Tier_1]: prefix + i18n.t('common:premiumTiers.supplier.tier_1'),
    [PremiumTiers.Tier_2]: prefix + i18n.t('common:premiumTiers.supplier.tier_2'),
    [PremiumTiers.Tier_3]: prefix + i18n.t('common:premiumTiers.supplier.tier_3'),
  };
};

export const getPremiumTierName = (tier?: PremiumTiers, fullName: boolean = true): string =>
  isNil(tier) ? 'VenuePro' : getPremiumTierNames(fullName)[tier];

export const getSupplierPremiumTierName = (tier?: PremiumTiers, fullName: boolean = true): string =>
  getSupplierPremiumTierNames(fullName)[tier as PremiumTiers] || 'SupplierPro';

export const getTierName = (
  isVenue: boolean,
  tier?: PremiumTiers,
  fullName: boolean = true,
): string =>
  isVenue ? getPremiumTierName(tier, fullName) : getSupplierPremiumTierName(tier, fullName);

export const getTierListOrdered = (isVenue: boolean): PremiumTiers[] =>
  (isVenue ? VenueProEnabledTiers : SupplierProEnabledTiers).sort();

export const getMaxTier = (isVenue: boolean) => getTierListOrdered(isVenue).slice(-1)[0];

export const getNextTier = (tier: PremiumTiers, isVenue: boolean): PremiumTiers => {
  const tierListOrdered = getTierListOrdered(isVenue);
  const indexOfTier = tierListOrdered.indexOf(tier);
  return tierListOrdered[indexOfTier + 1];
};

/**
 * Returns the highest tier that has a limit of items (so the value is not undefined)
 */
export const getHighestLimitedTier = (
  supplierTier: PremiumTiers,
  isVenue: boolean,
  getMaxItems: (tier: PremiumTiers, isVenue: boolean) => number | undefined,
): { highestTier: PremiumTiers; maxItems: number } => {
  const currentMaxItems = getMaxItems(supplierTier, isVenue) || 0;
  // Reverse the tier list so we can find the highest tier with a limit
  const tierList = getTierListOrdered(isVenue).reverse();
  // Find the first tier with value that is not undefined
  const highestTier = tierList.find((tier) => !isNil(getMaxItems(tier, isVenue))) || supplierTier;

  const maxItems = getMaxItems(highestTier, isVenue) || 0;

  // Return the higher tier only if it has a higher limit
  if (maxItems > currentMaxItems) {
    return {
      highestTier,
      maxItems,
    };
  }

  // Otherwise, the vendor is already at the max limit
  return {
    highestTier: supplierTier,
    maxItems: currentMaxItems,
  };
};

/**
 * Returns the lowest tier that has unlimited items (so the value is undefined)
 */
export const getUnlimitedTier = (
  isVenue: boolean,
  getMaxItems: (tier: PremiumTiers, isVenue: boolean) => number | undefined,
) => {
  // Do not reverse the tier list so we can find the lowest tier with unlimited items
  const tierList = getTierListOrdered(isVenue);
  return tierList.find((tier) => isNil(getMaxItems(tier, isVenue))) || PremiumTiers.Tier_4;
};
