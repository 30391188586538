import { createSelector } from 'reselect';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { IApplicationState } from 'lib/types';

const name = (state: IApplicationState) => state.supplier.supplier?.name;
export const getSupplierName = createSelector([name], (name) => name);

const website = (state: IApplicationState) => state.supplier.supplier?.contacts?.website;
export const getSupplierWebsite = createSelector([website], (website) => website);

const phone = (state: IApplicationState) => state.supplier.supplier?.contacts?.phone;
export const getSupplierPhone = createSelector([phone], (phone) => phone);

const address = (state: IApplicationState) => state.supplier.supplier?.address?.street?.[0];
export const getSupplierAddress = createSelector([address], (address) => address);

const country = (state: IApplicationState) => state.supplier.supplier?.address?.adminArea?.[1];
export const getSupplierCountry = createSelector([country], (country) => country);

const postcode = (state: IApplicationState) => state.supplier.supplier?.address?.postalCode;
export const getSupplierPostcode = createSelector([postcode], (postcode) => postcode);

const city = (state: IApplicationState) => state.supplier.supplier?.address?.city;
export const getSupplierCity = createSelector([city], (city) => city);

export const adminArea = (state: IApplicationState) => state.supplier.supplier?.address?.adminArea;

export const getSupplierCountryCode = (state: IApplicationState) =>
  state.supplier.supplier?.l10n.country || CountryCodes.GB;

export const getSupplierCounty = createSelector(
  [getSupplierCountryCode, adminArea],
  (countryCode, adminAreas) =>
    gazetteer.getMarketByCountry(countryCode).sortAdminAreas(adminAreas || [], 1)[0],
);
export const getSupplierCountryName = createSelector([getSupplierCountryCode], (countryCode) =>
  gazetteer.getMarketByCountry(countryCode)?.getCountryName(),
);

const customArea = (state: IApplicationState) => state.supplier.supplier?.customArea;
export const getCustomArea = createSelector([customArea], (customArea) => customArea);

export const getSupplierSearchRankingArea = createSelector([getSupplierCounty], (county) => county);

const email = (state: IApplicationState) => state.supplier.supplier?.contacts?.email;
export const getSupplierEmail = createSelector([email], (email) => email);

const hideStreet = (state: IApplicationState) => state.supplier.supplier?.flags?.hideStreet;
export const getSupplierHideStreet = createSelector([hideStreet], (hideStreet) => hideStreet);
