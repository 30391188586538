import { PremiumTiers } from '@bridebook/toolbox/src/constants';

// Defines which premium tiers are available for venues
export const VenueProEnabledTiers: PremiumTiers[] = Object.keys(PremiumTiers)
  .map(Number)
  .filter((tier) => !isNaN(tier));

// Defines which premium tiers are available for non-venue suppliers
export const SupplierProEnabledTiers: PremiumTiers[] = [
  PremiumTiers.Tier_0,
  PremiumTiers.Tier_1,
  PremiumTiers.Tier_2,
];

export { PremiumTiers };
