import produce from 'immer';
import { values } from 'ramda';
import type { Action } from 'redux';
import type { ISupplierSpecialOffersGeneric } from '@bridebook/models/source/models/RTDB/Suppliers.types';
import type { IOffer } from '@bridebook/models/source/models/Suppliers/Offers.types';
import {
  IOnFirestoreOffersAction,
  IRemoveLateAvailabilityDateAction,
  ISetDescriptionAction,
  ISetDiscountAction,
  ISetDiscountTypeAction,
  ISetExpiryDateAction,
  ISetInitialFormAction,
  ISetLateAvailabilityDateAction,
  ISetSpecialOffersTypeAction,
  ISetTitleAction,
  IShowErrorAction,
  IToggleInitialisedAction,
  OffersActionTypes,
} from 'lib/offers/action-types';
import type { IOffersState } from 'lib/offers/types';
import { OfferDiscountTypes } from './constants';

const initialState: IOffersState = {
  // @ts-ignore - this requires refactoring from RTDB to Firestore types
  form: { discountType: OfferDiscountTypes.setPrice },
  isInitialised: false,
  error: undefined,
  type: undefined,
  offers: {},
  offersLoaded: false,
};

const reducers: any = (draft: IOffersState) => ({
  [OffersActionTypes.SHOW_ERROR]: (action: IShowErrorAction) => {
    draft.error = action.payload;
  },

  [OffersActionTypes.SET_TITLE]: (action: ISetTitleAction) => {
    draft.form.title = action.payload;
  },

  [OffersActionTypes.SET_DISCOUNT]: (action: ISetDiscountAction) => {
    draft.form.discountValue = action.payload;
  },

  [OffersActionTypes.SET_DISCOUNT_TYPE]: (action: ISetDiscountTypeAction) => {
    // @ts-ignore - this requires refactoring from RTDB to Firestore types
    draft.form.discountType = action.payload;
  },

  [OffersActionTypes.SET_LATE_AVAILABILITY]: (action: ISetLateAvailabilityDateAction) => {
    const { newDate, index } = action.payload;
    const form = draft.form;
    const { lateDates = [] } = form;
    // Null is used to display an empty date input field, but it's not saved in DB
    const newLateDates: Array<number | null> = [...lateDates];
    newLateDates[index] = newDate;
    (form.lateDates as Array<number | null>) = newLateDates;
  },

  [OffersActionTypes.REMOVE_LATE_AVAILABILITY]: (action: IRemoveLateAvailabilityDateAction) => {
    const { index, hasUnlimitedDates } = action.payload;
    const form = draft.form;
    const { lateDates = [] } = form;
    // remove date
    if (hasUnlimitedDates) {
      form.lateDates = [
        ...lateDates.slice(0, index),
        ...lateDates.slice(index + 1, lateDates.length),
      ];
    } else {
      // Null is used to display an empty date input field, but it's not saved in DB
      const newLateDates: Array<number | null> = [...lateDates];
      // keep empty date field visible in form
      newLateDates[index] = null;
      (form.lateDates as Array<number | null>) = newLateDates;
    }
  },

  [OffersActionTypes.SET_EXPIRY_DATE]: (action: ISetExpiryDateAction) => {
    (draft.form as ISupplierSpecialOffersGeneric).expiryDate = action.payload;
  },

  [OffersActionTypes.SET_DESCRIPTION]: (action: ISetDescriptionAction) => {
    draft.form.details = action.payload;
  },

  [OffersActionTypes.SET_SPECIAL_OFFERS_TYPE]: (action: ISetSpecialOffersTypeAction) => {
    draft.type = action.payload.type;
  },

  [OffersActionTypes.SET_INITIAL_FORM]: (action: ISetInitialFormAction) => {
    draft.form = action.payload.initialForm;
    draft.isInitialised = true;
  },

  [OffersActionTypes.TOGGLE_INITIALISED]: (action: IToggleInitialisedAction) => {
    draft.isInitialised = action.payload;
  },

  [OffersActionTypes.SAVE_SPECIAL_OFFER]: () => {
    draft.error = undefined;
  },

  [OffersActionTypes.SAVE_SUPPLIER_DISCOUNT]: () => {
    draft.error = undefined;
  },

  [OffersActionTypes.SAVE_SUPPLIER_DISCOUNT_SUCCESS]: () => {
    draft.error = undefined;
  },

  [OffersActionTypes.ON_FIRESTORE_OFFERS]: (action: IOnFirestoreOffersAction) => {
    const newOffers: Record<string, IOffer> = {};

    // map offers to offer types
    values(action.payload).forEach((offer) => {
      if (offer.type) {
        newOffers[offer.type] = offer;
      }
    });

    draft.offers = newOffers;
    draft.offersLoaded = true;
  },

  [OffersActionTypes.STOP_OFFERS_LISTENER]: () => {
    draft.offers = {};
    draft.offersLoaded = false;
  },
});

/*
  This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: IOffersState = initialState, action: Action): IOffersState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
