import produce from 'immer';
import { Action } from 'redux';
import {
  IFetchSupplierRelationsSuccessAction,
  RelationsActionTypes,
} from 'lib/relations/action-types';
import { ISupplierRelation } from 'lib/relations/types';

export interface IRelationsState {
  list: ISupplierRelation[];
}

const initialState: IRelationsState = {
  list: [],
};

const reducers: any = (draft: IRelationsState) => ({
  [RelationsActionTypes.FETCH_SUPPLIER_RELATIONS_SUCCESS]: (
    action: IFetchSupplierRelationsSuccessAction,
  ) => {
    draft.list = action.payload.supplierRelations;
  },
  [RelationsActionTypes.DELETE_ALL_SUPPLIER_RELATIONS_SUCCESS]: () => {
    draft.list = [];
  },
});

// This is a wrapper function which runs a proper reducer from the object above.

const reducer = (state: IRelationsState = initialState, action: Action): IRelationsState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
