import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, mergeMap, pluck } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { appError } from 'lib/app/actions';
import { ISaveMessageTemplate, MessageTemplatesAction } from 'lib/message-templates/action-types';
import { messageTemplateSaved, saveMessageTemplateError } from 'lib/message-templates/actions';
import {
  messageTemplateAddedAnalytics,
  messageTemplateEditedAnalytics,
} from 'lib/message-templates/analytics/actions';
import { IEpicDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';
import { withSupplierId } from 'lib/utils/operators/with-supplier-id';

export const saveMessageTemplate = (
  action$: Observable<ISaveMessageTemplate>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(MessageTemplatesAction.SaveTemplate),
    pluck('payload'),
    withSupplierId(state$),
    mergeMap(([supplierId, template]) => {
      const getPromise = async () => {
        const supplierRef = Suppliers._.getById(supplierId);

        const templateRef = supplierRef.Templates.getById(template.id);
        const templateExists = Boolean(await templateRef.get());
        const data = {
          title: template.title,
          content: template.content,
          files: template.files,
        };

        if (templateExists) {
          templateRef.set(data);
        } else {
          templateRef.create(data);
        }

        return {
          isEdit: templateExists,
          templateTitle: data.title,
          templateId: template.id,
        };
      };
      return from(getPromise()).pipe(
        mergeMap(({ isEdit, templateTitle, templateId }) => {
          const messageTemplateAnalyticsAction = isEdit
            ? messageTemplateEditedAnalytics
            : messageTemplateAddedAnalytics;

          return [
            messageTemplateAnalyticsAction({ templateTitle, templateId }),
            messageTemplateSaved,
            toggleSnackbar('success', getI18n().t('messageTemplates:message.templateSaved')),
          ];
        }),
        catchError((error) => [
          appError({ error, feature: 'Message Templates' }),
          toggleSnackbar('alert', getI18n().t('messageTemplates:message.saveTemplateError')),
          saveMessageTemplateError,
        ]),
      );
    }),
  );
