import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { appError } from 'lib/app/actions';
import { YourCouplesActionTypes } from 'lib/couples/action-types';
import {
  fetchBookingSuccess,
  fetchBookingsSuccess,
} from 'lib/couples/actions/fetch-couples-bookings';
import { ICoupleBooking, ICoupleBookingDetails } from 'lib/couples/types';
import { Action, IEpicDeps } from 'lib/types';

export const fetchBookingsEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(YourCouplesActionTypes.FETCH_COUPLES_BOOKINGS_START),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const {
        supplier: {
          supplier: { id },
        },
      } = state;

      const promise = authenticatedFetch(`/api/inbox/bookings/${id}`)
        .then((res) => res.json())
        .catch((err) => console.error(err));

      return from(promise).pipe(
        mergeMap(
          (
            bookingsResponse: { bookings: ICoupleBooking[]; stats: { total: number } } | undefined,
          ) => of(fetchBookingsSuccess(bookingsResponse)),
        ),
        catchError((error) =>
          of(appError({ error, feature: YourCouplesActionTypes.FETCH_COUPLES_BOOKINGS_START })),
        ),
      );
    }),
  );

export const fetchBookingEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(YourCouplesActionTypes.FETCH_SINGLE_BOOKING_START),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const {
        supplier: {
          supplier: { id: supplierId },
        },
        couples: { openedBookingId: weddingId },
      } = state;

      const getPromise = async () => {
        const response = await authenticatedFetch(`/api/inbox/booking`, {
          method: 'POST',
          headers: new Headers({ 'Content-Type': 'application/json' }),
          body: JSON.stringify({ supplierId, weddingId }),
        });

        if (!response.ok || response.status !== 200) {
          throw Error(`could not fetch booking`);
        }
        return response.json();
      };

      return from(getPromise()).pipe(
        mergeMap((bookingsResponse: ICoupleBookingDetails) =>
          of(fetchBookingSuccess(bookingsResponse)),
        ),
        catchError((error) =>
          of(appError({ error, feature: YourCouplesActionTypes.FETCH_SINGLE_BOOKING_START })),
        ),
      );
    }),
  );
