import { values } from 'ramda';
import { pipe, prop, sortBy } from 'remeda';
import { createSelector } from 'reselect';
import { IPhoto } from '@bridebook/models/source/models/Suppliers/Photos.types';
import { getIsVenue } from 'lib/supplier/selectors';
import { IApplicationState } from '../types';

export const _photos = (state: IApplicationState) => state.photos.photos;
export const getPhotosList = (state: IApplicationState) => state.photos.list;

/* ############################################################################
 *  Memoized selectors
 * ######################################################################### */

export const getPhotos = createSelector([_photos], (photos) =>
  pipe(values(photos), sortBy(prop<IPhoto, 'order'>('order'))),
);

export const getProfilePhoto = createSelector(
  [getPhotos, getIsVenue],
  (photos, isVenue) =>
    photos?.[0] ||
    ({
      path: `assets/supplier/img-custom-${isVenue ? 'venue' : 'supplier'}.jpg`,
      id: 'profile-photo',
    } as IPhoto),
);
