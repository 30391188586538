import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ValidationError } from '@bridebook/toolbox/src';
import {
  GetListedActionTypes,
  IGetListedStartAction,
  IGetListedValidateStep1Action,
} from 'lib/get-listed/action-types';
import {
  getListedError,
  getListedSuccess,
  getListedValidateStep1Error,
  getListedValidateStep1Success,
} from 'lib/get-listed/actions';
import {
  createRequestedAccount,
  getGetListedPayload,
  validateGetListedFieldsStep1Client,
  validateGetListedFieldsStep1Server,
  validateGetListedFieldsStep2Client,
} from 'lib/get-listed/utils';
import { IEpicDeps } from 'lib/types';
import { IGetListedAPIResponse } from '../types';

export const getListedValidate = (
  action$: Observable<IGetListedValidateStep1Action>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(GetListedActionTypes.GET_LISTED_VALIDATE_STEP1_START),
    withLatestFrom(state$),
    mergeMap(([{ payload: fields }, state]) => {
      const getPromise = async () => {
        await validateGetListedFieldsStep1Client(fields);
        const response = await validateGetListedFieldsStep1Server(fields);

        const payload = getGetListedPayload({
          response,
          fields,
          state,
        });

        return payload;
      };

      return from(getPromise()).pipe(
        mergeMap((response) => of(getListedValidateStep1Success({ fields, response }))),
        catchError((error) => {
          const validationError = error.prop
            ? new ValidationError(error.message, error.prop)
            : error;
          return of(getListedValidateStep1Error({ fields, error: validationError }));
        }),
      );
    }),
  );

export const getListed = (action$: Observable<IGetListedStartAction>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(GetListedActionTypes.GET_LISTED_START),
    withLatestFrom(state$),
    mergeMap(([{ payload: fields }, state]) => {
      const {
        getListed: {
          getListedForm: { addressSetFromGeoCode },
        },
      } = state;

      const getPromise = async () => {
        await validateGetListedFieldsStep2Client(fields, addressSetFromGeoCode);
        const response = await createRequestedAccount(fields);
        if (typeof response.valid !== 'undefined' && !response.valid) {
          getGetListedPayload({
            response,
            fields,
            state,
          });
        }

        return response;
      };

      return from(getPromise()).pipe(
        mergeMap((payload) => of(getListedSuccess(payload as IGetListedAPIResponse))),
        catchError((error) => {
          const validationError = error.prop
            ? new ValidationError(error.message, error.prop)
            : error;
          return of(getListedError({ error: validationError, fields }));
        }),
      );
    }),
  );
