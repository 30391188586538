import produce from 'immer';
import { IFollowUp } from '@bridebook/models/source/models/Suppliers/FollowUps.types';
import { Action, IReducersImmer } from '../types';
import { FollowUpMessagesAction, IFollowUpMessageListSaved } from './action-types';

interface FollowUpMessagesForm {
  state: 'idle' | 'pending';
}

export interface IFollowUpMessagesState {
  form: FollowUpMessagesForm;
  followUpMessages?: IFollowUp[];
}

const initialState: IFollowUpMessagesState = {
  form: {
    state: 'idle',
  },
};

const reducers: IReducersImmer<IFollowUpMessagesState> = (draft) => ({
  [FollowUpMessagesAction.SaveFollowUpMessage]: () => {
    draft.form.state = 'pending';
  },
  [FollowUpMessagesAction.FollowUpMessageSaved]: () => {
    draft.form.state = 'idle';
  },
  [FollowUpMessagesAction.SaveFollowUpMessageError]: () => {
    draft.form.state = 'idle';
  },
  [FollowUpMessagesAction.FollowUpMessageListSaved]: ({ payload }: IFollowUpMessageListSaved) => {
    draft.followUpMessages = payload;
  },
  [FollowUpMessagesAction.DeleteFollowUpMessage]: () => {
    draft.form.state = 'pending';
  },
  [FollowUpMessagesAction.FollowUpMessageDeleted]: () => {
    draft.form.state = 'idle';
  },
});

const reducer = (state = initialState, action: Action) => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (e) {
    return state;
  }
};

export default reducer;
