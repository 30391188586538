import { WithFieldValue, deleteField } from 'firebase/firestore';
import { SupplierUsers } from '@bridebook/models';
import { ISupplierUser } from '@bridebook/models/source/models/SupplierUsers.types';
import { IUser_Action } from '@bridebook/models/source/models/Users.types';
import { IDeps } from 'lib/types';
import { UserActionTypes } from 'lib/users/action-types';

export const updateUserField =
  <T extends WithFieldValue<ISupplierUser>, K extends keyof T>(key: K, value: T[K]) =>
  ({ getState }: IDeps) => {
    const { viewer } = getState().users;
    if (viewer) {
      SupplierUsers._.getById(viewer.id).set({
        [key]: value,
      });
    }

    return {
      type: UserActionTypes.UPDATE_USER_DETAILS,
      payload: { name: key, value },
    };
  };

export const setProfilePhoto =
  (path: string) =>
  ({ dispatch }: IDeps) => {
    const value = path ? { path } : deleteField();
    dispatch(updateUserField('photo', value));

    return {
      type: UserActionTypes.UPLOAD_PROFILE_PHOTO,
      payload: value,
    };
  };

export const setUserAction =
  (action: keyof IUser_Action, value: boolean) =>
  ({ dispatch }: IDeps) => {
    dispatch(updateUserField('actions', { [action]: value }));

    return {
      type: UserActionTypes.UPDATE_USER_DETAILS,
      payload: { name: action, value },
    };
  };
