import produce from 'immer';
import { AccessControlActionTypes } from 'lib/access-control/action-types';
import { Action } from 'lib/types';
import {
  FetchHistoricRankingsSuccessAction,
  FetchMonoquerySuccessAction,
  FetchPeerPerformanceSuccessAction,
  FetchReviewsRatingSuccessAction,
  FetchSearchRankingSuccessAction,
  FetchStatisticsValueSuccessAction,
  FetchSupplierScoreSuccessAction,
  FetchTierChangeHistorySuccessAction,
  FetchUkCompetitorsRankingsSuccessAction,
  PrepareChartsAction,
  ReadItemsUpdatedAction,
  StatisticsActionTypes,
  SwitchChartGranularityAction,
} from './action-types';
import { CoupleBudgets } from './charts/couple-budgets';
import { ChartGranularity, ChartName } from './constants';
import ReviewsRating from './reviews-rating';
import type { IStatisticsState } from './types';
import {
  extractData,
  getInitialProps,
  getTierChangePlotLines,
  segmentizeTimeseriesData,
} from './utils';

const initialState: IStatisticsState = {
  reviewsRating: { data: { ...ReviewsRating }, loaded: false },
  searchRanking: { data: [], loaded: false },
  coupleBudgets: { ...CoupleBudgets },
  competitorsData: [],
  keywordInsights: {},
  charts: {
    bookedSupplier: {
      chart: null,
      granularity: ChartGranularity.month,
    },
    searchAppearances: {
      chart: null,
      granularity: ChartGranularity.month,
    },
    profileViews: {
      chart: null,
      granularity: ChartGranularity.month,
    },
    enquiryViews: {
      chart: null,
      granularity: ChartGranularity.month,
    },
  },
  monoquery: {
    data: {
      bookedSupplier: {},
      coupleTargetingAppearences: 0,
      coupleTargetingProfileViews: 0,
      enquiryViews: {},
      profileViews: {},
      searchAppearances: {},
      shortlistedSupplier: 0,
      specialOfferViews: 0,
      supplierPhoneClicks: 0,
      supplierWebsiteClicks: 0,
      venueRexAppearences: 0,
    },
    loaded: false,
  },
  tierChangeHistory: {
    loaded: false,
  },
  searchPositionHistory: getInitialProps(ChartName.historicalRankings),
  searchPositionHistoryCompetitors: undefined,
  // Firestore
  score: {},
  scoreLoaded: false,
  readItemsUpdatedAt: 0,
};

// https://github.com/immerjs/immer/issues/296#issuecomment-455859791
const resetState = () => initialState;

const reducers: any = (draft: IStatisticsState) => ({
  [StatisticsActionTypes.STATISTICS_CLEAR_ALL]: resetState,
  [AccessControlActionTypes.SWITCH_SUPPLIER]: resetState,
  ['SWITCH_PERMISSION_START']: resetState,

  [StatisticsActionTypes.FETCH_SUPPLIER_SCORE_SUCCESS]: (
    action: FetchSupplierScoreSuccessAction,
  ) => {
    draft.scoreLoaded = true;
    draft.score = action.payload || {};
  },

  [StatisticsActionTypes.FETCH_STATISTICS_VALUE_SUCCESS]: (
    action: FetchStatisticsValueSuccessAction,
  ) => {
    const {
      payload: { name, value },
    } = action;

    if (name === 'competitorsData') {
      const data = value.reduce(
        (acc: Record<string, any>, { id, name, historicalRankings }: Record<string, any>) => ({
          ...acc,
          [id]: { name, historicalRankings },
        }),
        {},
      );

      draft.searchPositionHistoryCompetitors = extractData({
        type: ChartName.historicalRankingsCompetitors,
        data,
      });
    }

    draft[name as keyof IStatisticsState] = value;
  },

  [StatisticsActionTypes.FETCH_UK_COMPETITORS_RANKINGS_SUCCESS]: (
    action: FetchUkCompetitorsRankingsSuccessAction,
  ) => {
    draft.ukHistoricalRankingsCompetitors = action.payload;
  },

  ['ROUTE_CHANGE_COMPLETE']: () => {
    draft.ukHistoricalRankingsCompetitors = undefined;
  },

  [StatisticsActionTypes.FETCH_PEER_PERFORMANCE_SUCCESS]: (
    action: FetchPeerPerformanceSuccessAction,
  ) => {
    draft.peerPerformance = action.payload;
  },

  [StatisticsActionTypes.FETCH_SEARCH_RANKING_SUCCESS]: (
    action: FetchSearchRankingSuccessAction,
  ) => {
    draft.searchRanking.loaded = true;
    draft.searchRanking.data = action.payload;
  },

  [StatisticsActionTypes.FETCH_REVIEWS_RATING_SUCCESS]: (
    action: FetchReviewsRatingSuccessAction,
  ) => {
    draft.reviewsRating.loaded = true;
    draft.reviewsRating.data = action.payload;
  },
  [StatisticsActionTypes.PREPARE_CHARTS]: (action: PrepareChartsAction) => {
    const data = draft.monoquery.data;

    const { supplierTier, supplierType, supplierCountryCode } = action.payload;

    draft.charts.searchAppearances.granularity = ChartGranularity.month;

    const segmentizedSearchAppearancesData = segmentizeTimeseriesData(data.searchAppearances);
    draft.charts.searchAppearances.chart = extractData({
      type: ChartName.searchAppearances,
      data: segmentizedSearchAppearancesData,
      supplierTier,
      supplierType,
      supplierCountryCode,
    });

    draft.charts.profileViews.granularity = ChartGranularity.month;
    const segmentizedProfilesViewsData = segmentizeTimeseriesData(data.profileViews);
    draft.charts.profileViews.chart = extractData({
      type: ChartName.profileViews,
      data: segmentizedProfilesViewsData,
    });

    draft.charts.enquiryViews.granularity = ChartGranularity.month;
    const segmentizedEnquiryViewsData = segmentizeTimeseriesData(data.enquiryViews);
    draft.charts.enquiryViews.chart = extractData({
      type: ChartName.enquiryViews,
      data: segmentizedEnquiryViewsData,
    });

    draft.charts.bookedSupplier.granularity = ChartGranularity.month;
    const segmentizedBookedSupplierData = segmentizeTimeseriesData(data.bookedSupplier);
    draft.charts.bookedSupplier.chart = extractData({
      type: ChartName.bookedSupplier,
      data: segmentizedBookedSupplierData,
      supplierTier,
    });

    if (draft.tierChangeHistory.loaded) {
      draft.charts.searchAppearances.chart.xAxis.plotLines = getTierChangePlotLines({
        seriesData: segmentizedSearchAppearancesData,
        granularity: ChartGranularity.month,
        tierChangeData: draft.tierChangeHistory,
        supplierType,
      });
      draft.charts.enquiryViews.chart.xAxis.plotLines = getTierChangePlotLines({
        seriesData: segmentizedSearchAppearancesData,
        granularity: ChartGranularity.month,
        tierChangeData: draft.tierChangeHistory,
        supplierType,
      });
      draft.charts.profileViews.chart.xAxis.plotLines = getTierChangePlotLines({
        seriesData: segmentizedSearchAppearancesData,
        granularity: ChartGranularity.month,
        tierChangeData: draft.tierChangeHistory,
        supplierType,
      });
    }
  },
  [StatisticsActionTypes.FETCH_TIER_CHANGE_HISTORY_SUCCESS]: (
    action: FetchTierChangeHistorySuccessAction,
  ) => {
    draft.tierChangeHistory = {
      loaded: true,
      tierChangeByDate: action.payload.data.tierChangeByDate,
      tierChangeByMonth: action.payload.data.tierChangeByMonth,
      tierChangeByWeek: action.payload.data.tierChangeByWeek,
    };
  },

  [StatisticsActionTypes.FETCH_MONOQUERY_SUCCESS]: (action: FetchMonoquerySuccessAction) => {
    const {
      payload: { data },
    } = action;
    draft.monoquery.loaded = true;
    draft.monoquery.data = data;
  },

  [StatisticsActionTypes.SWITCH_CHART_GRANULARITY]: (action: SwitchChartGranularityAction) => {
    const {
      payload: { granularity, supplierTier, supplierType, supplierCountryCode },
    } = action;

    const chart = action.payload.chart as keyof IStatisticsState['charts'];

    draft.charts[chart].granularity = granularity;
    const segmentizedTimeseriesData = segmentizeTimeseriesData(
      draft.monoquery.data[chart] as Record<string, number>,
      granularity,
    );
    draft.charts[chart].chart = extractData({
      type: chart as ChartName,
      data: segmentizedTimeseriesData,
      supplierTier,
      granularity,
      supplierType,
      supplierCountryCode,
    });
    if (draft.tierChangeHistory.loaded) {
      draft.charts[chart].chart.xAxis.plotLines = getTierChangePlotLines({
        seriesData: segmentizedTimeseriesData,
        granularity,
        tierChangeData: draft.tierChangeHistory,
        supplierType,
      });
    }
  },

  [StatisticsActionTypes.FETCH_HISTORIC_RANKINGS_SUCCESS]: (
    action: FetchHistoricRankingsSuccessAction,
  ) => {
    const { data, supplierId } = action.payload;

    draft.searchPositionHistory = extractData({
      type: ChartName.historicalRankings,
      data: data[supplierId] || {},
    });
  },

  [StatisticsActionTypes.READ_ITEMS_UPDATED]: (action: ReadItemsUpdatedAction) => {
    draft.readItemsUpdatedAt = action.payload;
  },
});

const reducer = (state: IStatisticsState = initialState, action: Action): IStatisticsState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
