import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { ISupplierProduct } from '@bridebook/models/source/models/Suppliers/Admin/ledger/Transactions.types';
import { IEpicDeps } from 'lib/types';
import { appError } from '../app/actions';
import { PayPerEnquiryActionTypes } from './action-types';
import { onFirestoreBalance } from './actions';

export const initEnquiryBalanceListener = (action$: Observable<any>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(PayPerEnquiryActionTypes.INIT_BALANCE_LISTENER),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const activeSupplier = state.users.activeSupplierAccessControl;
      const productId = ISupplierProduct.ENQUIRIES;

      if (!activeSupplier?.id) return of();

      return Suppliers._.getById(activeSupplier.id)
        .Admins.ledger.observe()
        .pipe(
          mergeMap((ledger) => of(onFirestoreBalance(ledger?.balance[productId] || 0))),
          takeUntil(action$.pipe(ofType(PayPerEnquiryActionTypes.STOP_BALANCE_LISTENER))),
          catchError((error: Error) => of(appError({ error, feature: 'Pay Per Enquiry' }))),
        );
    }),
  );
