import {
  initQuestionsListener,
  onFirestoreQuestions,
  saveFirestoreQuestions,
  setQuestionAnswer,
} from 'lib/questions/actions';

export enum QuestionsActionTypes {
  INIT_QUESTIONS_LISTENER = '[Questions] Init questions listener',
  ON_FIRESTORE_QUESTIONS = '[Questions] On Firestore questions',
  SAVE_FIRESTORE_QUESTIONS = '[Questions] Save firestore questions',
  SAVE_FIRESTORE_QUESTIONS_ERROR = '[Questions] Save firestore questions error',
  SAVE_FIRESTORE_QUESTIONS_SUCCESS = '[Questions] Save firestore questions success',
  SET_QUESTION_ANSWER = '[Questions] Set question answer',
}

export type OnFirestoreQuestionsAction = ReturnType<typeof onFirestoreQuestions>;
export type SaveFirestoreQuestionsAction = ReturnType<typeof saveFirestoreQuestions>;
export type SetQuestionAnswerAction = ReturnType<typeof setQuestionAnswer>;
export type IInitQuestionsListenerAction = ReturnType<typeof initQuestionsListener>;
