import { deleteField, serverTimestamp } from 'firebase/firestore';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mapTo, switchMap } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { withSupplierId } from 'lib/utils/operators/with-supplier-id';
import { appError } from '../../app/actions';
import { IEpicDeps } from '../../types';
import { EnquiriesActionTypes, ISetEnquiryStatus } from '../action-types';

export const setEnquiryStatusEpic = (
  action$: Observable<ISetEnquiryStatus>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.SET_ENQUIRY_STATUS),
    withSupplierId(state$),
    switchMap(([supplierId, { payload }, state]) => {
      const { id, status, value } = payload || {};

      const isAdmin = state.auth.isAdmin;
      const getPromise = async () => {
        if (isAdmin) return;

        const supplier = Suppliers._.getById(supplierId);
        return supplier.Weddings.getById(id).set({
          flags: { [status]: value },
          timestamps: {
            [status]: value ? serverTimestamp() : deleteField(),
          },
        });
      };

      return from(getPromise()).pipe(
        mapTo({
          type: EnquiriesActionTypes.SET_ENQUIRY_STATUS_SUCCESS,
        }),
        catchError((error: Error) =>
          of(appError({ error, feature: EnquiriesActionTypes.SET_ENQUIRY_STATUS })),
        ),
      );
    }),
  );
