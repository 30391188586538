import { getI18n } from 'react-i18next';
import { IRadioGroupOption } from '@bridebook/ui/src/components/bbcommon/radio-group-v2/radio-group-v2';
import { ITargetingMappingSeasonValues } from 'lib/supplier/types';

interface ITargetingSeason extends IRadioGroupOption<string> {
  value: ITargetingMappingSeasonValues;
}

export const getOptionsSeason = (): ITargetingSeason[] => {
  const i18n = getI18n();

  return [
    {
      label: i18n.t('common:All'),
      value: 'all',
    },
    {
      label: i18n.t('common:Spring'),
      value: 'spring',
    },
    {
      label: i18n.t('common:Summer'),
      value: 'summer',
    },
    {
      label: i18n.t('common:Autumn'),
      value: 'autumn',
    },
    {
      label: i18n.t('common:Winter'),
      value: 'winter',
    },
  ];
};
