import produce from 'immer';
import { IRecommendation } from '@bridebook/models/source/models/Suppliers/Recommendations.types';
import {
  serializeTimestamp,
  serializeTimestamps,
} from '@bridebook/toolbox/src/serialize-timestamps';
import { IFair } from '../../types';
import { Action, IReducersImmer } from '../types';
import {
  RecommendationsActions,
  SaveWeddingFairErrorType,
  UpdateRecommendationsAction,
  UpdateRecommendationsDoneAction,
  UpdateWeddingFairsAction,
} from './action-types';
import { IRecommendationRender } from './actions/update-recommendations-done';

export interface RecommendationsState {
  list: IRecommendation[];
  listRender: IRecommendationRender[];
  notification: string | null;
  error: Record<string, any> | null;
  notificationText: string | null;
  unsaved: boolean;
  added: boolean;
  addedName: string;
  weddingFairs: IFair[];
  fairsLoaded: boolean;
}

const initialState: RecommendationsState = {
  list: [],
  listRender: [],
  notification: null,
  error: null,
  notificationText: null,
  unsaved: false,
  added: false,
  addedName: '',
  weddingFairs: [],
  fairsLoaded: false,
};

export const reducers: IReducersImmer<RecommendationsState> = (draft) => ({
  ['SAVE_WEDDING_FAIR_SUCCESS']: (action) => {
    const { notificationText, notification } = action.payload;

    draft.unsaved = false;
    draft.notificationText = notificationText;
    draft.notification = notification;
  },
  [RecommendationsActions.SAVE_WEDDING_FAIR_ERROR]: (action: SaveWeddingFairErrorType) => {
    const { message } = action.payload;
    draft.notification = 'error';
    draft.notificationText = message;
  },

  ['RESET_NOTIFICATION']: () => {
    draft.notificationText = '';
    draft.notification = null;
    draft.unsaved = false;
    draft.addedName = '';
    draft.added = false;
  },

  [RecommendationsActions.UPDATE_RECOMMENDATIONS]: (action: UpdateRecommendationsAction) => {
    draft.list = action.payload.recommendations;
  },

  [RecommendationsActions.UPDATE_RECOMMENDATIONS_DONE]: (
    action: UpdateRecommendationsDoneAction,
  ) => {
    draft.listRender = action.payload.suppliers;
  },

  [RecommendationsActions.UPDATE_WEDDING_FAIRS]: (action: UpdateWeddingFairsAction) => {
    draft.weddingFairs = action.payload.fairs
      .sort((a, b) => a.dates[0].toMillis() - b.dates[0].toMillis())
      .map(serializeTimestamps)
      .map((fair) => ({
        ...fair,
        dates: fair.dates.map(serializeTimestamp) as number[],
      }));
    draft.fairsLoaded = true;
  },
});

const reducer = (state: RecommendationsState = initialState, action: Action) => {
  try {
    return produce(state, (draft) => reducers(draft, state)[action.type](action));
  } catch (error) {
    return state;
  }
};

export default reducer;
