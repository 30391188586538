type AnyObject = Record<string, any>;

export const dotNotationToObject = <R extends AnyObject = AnyObject>(input: object): R =>
  Object.entries(input).reduce((result, [dotNotation, value]) => {
    // Create an array of keys by splitting the dot notation for each entry
    const keys = dotNotation.split('.');

    // Reduce the keys array to build the nested object. Use result as the initial value.
    keys.reduce((currentLevel, key, index) => {
      if (index === keys.length - 1) {
        currentLevel[key as keyof R] = value;
      } else {
        currentLevel[key as keyof R] = currentLevel[key] || {};
      }
      return currentLevel[key];
    }, result);

    return result;
  }, {} as R);
