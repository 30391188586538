import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ICountry_CmsBanner } from '@bridebook/models/source/models/Countries.types';
import { IDeps } from 'lib/types';
import { UIActionTypes } from 'lib/ui/action-types';
import { INotificationMode } from 'lib/ui/types';

export const toggleAdminPanel = () => ({ type: UIActionTypes.TOGGLE_ADMIN_PANEL });

export const closeAdminPanel = () => ({
  type: UIActionTypes.CLOSE_ADMIN_PANEL,
});

export const resetPanelEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType('LOGOUT'),
    mergeMap(() => of(closeAdminPanel())),
  );

export const hideSnackbar = () => ({
  type: UIActionTypes.HIDE_SNACKBAR,
});

export const toggleSnackbar = (mode: INotificationMode, text: string) => ({
  type: UIActionTypes.TOGGLE_SNACKBAR,
  payload: { mode, text },
});

export const onModalToggle = ({ show, id }: { show: boolean; id: string }) => ({
  type: UIActionTypes.ON_MODAL_TOGGLE,
  payload: { show, id },
});

export const toggleOverlayPortal =
  (show: boolean) =>
  ({ getState }: IDeps) => {
    const { scrollPosition } = getState().ui;
    const setScrollPosition = show && process.browser ? window.scrollY : scrollPosition;

    return {
      type: UIActionTypes.TOGGLE_OVERLAY_PORTAL,
      payload: {
        opened: show,
        scrollPosition: setScrollPosition,
      },
    };
  };

export const setMarketingBanner = (banner: ICountry_CmsBanner | null) => ({
  type: UIActionTypes.SET_MARKETING_BANNER,
  payload: { banner },
});
