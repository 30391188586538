import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { CMSLocales as Locales, RoutingLocales } from '@bridebook/toolbox/src/i18n';

export const RoutingLocalesByCountryCode: Partial<Record<CountryCodes, RoutingLocales>> = {
  [CountryCodes.GB]: RoutingLocales.UK,
  [CountryCodes.DE]: RoutingLocales.DE,
  [CountryCodes.FR]: RoutingLocales.FR,
  [CountryCodes.IE]: RoutingLocales.IE,
};

export const localeToCountryCode: Record<Locales, CountryCodes> = {
  [Locales.DE]: CountryCodes.DE,
  [Locales.GB]: CountryCodes.GB,
  [Locales.PL]: CountryCodes.PL,
  [Locales.US]: CountryCodes.US,
  [Locales.IE]: CountryCodes.IE,
  [Locales.FR]: CountryCodes.FR,
};

export const countryCodeToLocale = Object.entries(localeToCountryCode).reduce(
  (acc, [key, value]) => {
    acc[value] = key as Locales;
    return acc;
  },
  {} as Record<CountryCodes, Locales>,
);

export { RoutingLocales, Locales };
