import { format } from 'date-fns';
import { WithFieldValue } from 'firebase/firestore';
import { IFilterLabelProps } from '@bridebook/analytics';
import { serializeTimestamp } from '@bridebook/toolbox/src/serialize-timestamps';
import { supplierPropertiesGeneric } from 'lib/analytics-utils';
import { Action, State } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';
import { IFair } from '../../types';
import {
  DeletedWeddingFairAnalyticsType,
  FailedToSaveFairAnalyticsType,
  RecommendationsAnalyticsTypes,
  SaveWeddingFairSuccessAnalyticsType,
} from './analytics/action-types';

const filterLabelProps: IFilterLabelProps = (props) => {
  const newProps = { ...props };
  delete newProps.recommendedSupplierEmail;
  return newProps;
};

const supplierFairPropertiesGeneric = (fair: WithFieldValue<IFair>) => {
  let supplierFairDate = '';
  const millis = serializeTimestamp(fair.dates?.[0]);
  if (typeof millis === 'number') {
    supplierFairDate = format(new Date(millis), 'yyyy-MM-dd');
  }

  return {
    supplierFairId: fair.id,
    supplierFairDate,
    supplierFairTitle: fair.name,
    supplierFairDescription: fair.description,
  };
};

export default function recommendationsAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const { payload } = action;

  const { track } = bridebookAnalytics.getMethods('CMS', filterLabelProps);

  switch (action.type) {
    case 'CLICKED_REPORT_ANALYTICS': {
      const { name: reportName } = payload || {};

      track({
        event: 'Supplier clicked to download report on CMS',
        ...supplierPropertiesGeneric(getState()),
        reportName,
      });
      break;
    }
    case RecommendationsAnalyticsTypes.ADDED_WEDDING_FAIR_ANALYTICS: {
      const { fair } = (payload || {}) as SaveWeddingFairSuccessAnalyticsType['payload'];

      track({
        event: 'Added wedding fair on CMS',
        ...supplierPropertiesGeneric(getState()),
        ...supplierFairPropertiesGeneric(fair),
      });
      break;
    }
    case RecommendationsAnalyticsTypes.EDITED_WEDDING_FAIR_ANALYTICS: {
      const { fair } = (payload || {}) as SaveWeddingFairSuccessAnalyticsType['payload'];

      track({
        event: 'Edited wedding fair on CMS',
        ...supplierPropertiesGeneric(getState()),
        ...supplierFairPropertiesGeneric(fair),
      });
      break;
    }
    case RecommendationsAnalyticsTypes.DELETED_WEDDING_FAIR_ANALYTICS: {
      const { fair } = (payload || {}) as DeletedWeddingFairAnalyticsType['payload'];

      track({
        event: 'Deleted wedding fair on CMS',
        ...supplierPropertiesGeneric(getState()),
        ...supplierFairPropertiesGeneric(fair),
      });
      break;
    }
    case RecommendationsAnalyticsTypes.FAILED_TO_ADD_WEDDING_FAIR_ANALYTICS: {
      const { fair, err } = (payload || {}) as FailedToSaveFairAnalyticsType['payload'];

      track({
        event: 'Failed to add wedding fair on CMS',
        ...supplierPropertiesGeneric(getState()),
        ...supplierFairPropertiesGeneric(fair),
        failedToAddFairReason: err.message,
      });
      break;
    }
    case RecommendationsAnalyticsTypes.FAILED_TO_EDIT_WEDDING_FAIR_ANALYTICS: {
      const { fair, err } = (payload || {}) as FailedToSaveFairAnalyticsType['payload'];

      track({
        event: 'Failed to edit wedding fair on CMS',
        ...supplierPropertiesGeneric(getState()),
        ...supplierFairPropertiesGeneric(fair),
        failedToEditFairReason: err.message,
      });
      break;
    }
  }
}
