import {
  deleteFollowUpMessage,
  followUpMessageListSaved,
  initFollowUpMessagesListener,
  saveFollowUpMessage,
} from 'lib/follow-up-messages/actions';

export enum FollowUpMessagesAction {
  DeleteFollowUpMessage = '[Follow-up messages] Delete follow-up message',
  InitFollowUpMessagesListener = '[Follow-up messages] Initialize follow-up messages',
  FollowUpMessageDeleted = '[Follow-up messages] Follow-up message deleted',
  FollowUpMessageListSaved = '[Follow-up messages] Follow-up message list saved',
  FollowUpMessageSaved = '[Follow-up messages] Follow-up message saved',
  SaveFollowUpMessage = '[Follow-up messages] Save follow-up message',
  SaveFollowUpMessageError = '[Follow-up messages] Save follow-up message error',
}

export type ISaveFollowUpMessage = ReturnType<typeof saveFollowUpMessage>;
export type IInitializeFollowUpMessages = typeof initFollowUpMessagesListener;
export type IFollowUpMessageListSaved = ReturnType<typeof followUpMessageListSaved>;
export type IDeleteFollowUpMessage = ReturnType<typeof deleteFollowUpMessage>;
