import { format, formatRelative } from 'date-fns';
import { de, enGB, enUS, fr, pl } from 'date-fns/locale';

export const getDateFnsLocales = (locale: string) => {
  switch (locale) {
    case 'en-US':
      return enUS;
    case 'de-DE':
    case 'de-AT':
    case 'de-CH':
    case 'de':
      return de;
    case 'pl-PL':
      return pl;
    case 'fr':
    case 'fr-FR':
      return fr;
    case 'en-GB':
    default:
      return enGB;
  }
};

export const formatI18nDate = (
  date: Date | number,
  formatStr = 'PP',
  locale: string,
  relative: boolean = false,
) => {
  const jsDate = new Date(date);
  const dateFnsLocale = getDateFnsLocales(locale);

  if (relative) {
    return formatRelative(jsDate, new Date(), {
      locale: dateFnsLocale,
    });
  } else {
    return format(jsDate, formatStr, {
      locale: dateFnsLocale,
    });
  }
};

export default formatI18nDate;
