import { merge } from 'ramda';
import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { getUser } from 'lib/hoc/utils';
import createReduxStore from './create-redux-store';
import { CustomPage, CustomPageContext, Device, IApplicationState } from './types';
import User from './users/user';

if (process.browser) {
  require('intersection-observer');
}

const singletonOnClient = (create: Function) => {
  const isServer = !process.browser;
  let singleton: any;
  return (...args: any) => {
    if (isServer) return create(...args);
    if (!singleton) singleton = create(...args);
    return singleton;
  };
};

const getReduxStore = singletonOnClient(
  (
    initialState: IApplicationState,
    ctx: CustomPageContext,
  ): {
    store: Store<IApplicationState>;
  } => createReduxStore(initialState, ctx),
);

const renderApp = (
  Page: CustomPage,
  store: any,
  props: { headers: any; initialState: IApplicationState },
) => (
  <Provider store={store}>
    <Page {...props} />
  </Provider>
);

const createGetInitialProps = (Page: CustomPage) => async (ctx: CustomPageContext) => {
  const headers = ctx.req ? ctx.req.headers : {};
  const { store } = getReduxStore({}, ctx);

  const props = {
    url: { query: ctx.query, pathname: ctx.pathname },
    ...(await (Page.getInitialProps ? Page.getInitialProps({ ctx, store }) : {})),
  };
  const state = store.getState();
  const { viewer } = store.getState().users;
  const userId = await getUser(ctx.req, ctx.res, store);
  if (userId) store.dispatch({ type: 'SAVE_USER_ANALYTICS', payload: userId });

  if (!process.browser) {
    const device: Partial<Device> = ctx.req?.device || {};
    state.app.device = device || {};
    state.app.pathname = ctx.req.url?.split('?')[0];

    state.i18n = {
      language: device?.serverLocale || gazetteer.getMarketByCountry(CountryCodes.GB).locale,
    };
  }
  return {
    ...props,
    headers,
    initialState: {
      ...state,
      users: {
        ...state.users,
        viewer: viewer || userId ? merge(User, { id: userId }) : null,
      },
    },
  };
};

const app = (Page: CustomPage) => {
  const App = (props: { headers: any; initialState: IApplicationState }) => {
    const { initialState } = props;
    const { store } = getReduxStore(initialState);
    const state = store.getState();
    if (!state.app.started && process.browser) {
      store.dispatch({ type: 'APP_STARTED' });
    }

    return renderApp(Page, store, props);
  };
  App.getInitialProps = createGetInitialProps(Page);
  return App;
};

export default app;
