import { deleteField, where } from 'firebase/firestore';
import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, mergeMap, pluck } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { appError } from 'lib/app/actions';
import {
  FollowUpMessagesAction,
  IDeleteFollowUpMessage,
} from 'lib/follow-up-messages/action-types';
import { followUpMessageDeleted, saveFollowUpMessageError } from 'lib/follow-up-messages/actions';
import {
  followUpMessageDeletedAnalytics,
  scheduledFollowUpDeletedAnalytics,
} from 'lib/follow-up-messages/analytics/actions';
import { IEpicDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';
import { withSupplierId } from 'lib/utils/operators/with-supplier-id';

export const deleteFollowUpMessage = (
  action$: Observable<IDeleteFollowUpMessage>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(FollowUpMessagesAction.DeleteFollowUpMessage),
    pluck('payload'),
    withSupplierId(state$),
    mergeMap(([supplierId, followUpMessage]) => {
      const getPromise = async () => {
        const supplierRef = Suppliers._.getById(supplierId);

        // Retrieve list of supplier weddings with scheduled follow-ups using followUpMessage
        const supplierWeddings = await supplierRef.Weddings.query([
          where('followUps', 'array-contains', followUpMessage.id),
        ]).get();

        // Create promises that delete scheduled follow-ups
        const deleteFollowUpPromises = Object.keys(supplierWeddings).map((weddingId) =>
          supplierRef.Weddings.getById(weddingId).update({
            followUpAt: deleteField(),
            followUps: deleteField(),
          }),
        );

        // Execute deletion of all scheduled follow-ups
        await Promise.all(deleteFollowUpPromises);

        // Delete follow-up message
        await supplierRef.FollowUps.getById(followUpMessage.id).delete();

        return {
          supplierWeddingsIds: Object.keys(supplierWeddings),
        };
      };

      return from(getPromise()).pipe(
        mergeMap(({ supplierWeddingsIds }) => [
          followUpMessageDeletedAnalytics({
            followUpMessage,
          }),
          // Trigger analytics for canceling a scheduled follow-up
          ...supplierWeddingsIds.map((supplierWeddingId) =>
            scheduledFollowUpDeletedAnalytics({
              followUpMessage,
              supplierWeddingId,
              cancelledReason: 'templateDeleted',
            }),
          ),
          followUpMessageDeleted,
          toggleSnackbar('success', getI18n().t('followUpMessages:message.followUpMessageDeleted')),
        ]),
        catchError((error) => [
          appError({ error, feature: 'Follow-up Messages' }),
          toggleSnackbar(
            'alert',
            getI18n().t('followUpMessages:message.deleteFollowUpMessageError'),
          ),
          saveFollowUpMessageError,
        ]),
      );
    }),
  );
