import produce from 'immer';
import { Action } from 'redux';
import { ISupplierAdmin } from '@bridebook/models/source/models/Suppliers/Admin/admin.types';
import { ISupplierAdminChargebee } from '@bridebook/models/source/models/Suppliers/Admin/chargebee.types';
import { ISupplierAdminScore } from '@bridebook/models/source/models/Suppliers/Admin/score.types';
import { ISupplierAdminStripe } from '@bridebook/models/source/models/Suppliers/Admin/stripe.types';
import { AccessControlActionTypes } from 'lib/access-control/action-types';
import {
  AdminActionTypes,
  IEnableDemoMode,
  IFetchSupplierInvitesSuccessAction,
  IGenerateSupplierInviteSuccess,
  IUpdateAdminPremiumObjectAction,
  OnSupplierAdminFetchAction,
} from 'lib/admin/action-types';

export interface IAdminState {
  invites: string[];
  supplierAdminObject: ISupplierAdmin | null;
  supplierAdminScore: ISupplierAdminScore | null;
  demoMode: { enabled: boolean; actualTier?: number } | null;
  supplierAdminStripe: ISupplierAdminStripe | null;
  supplierAdminChargebee: ISupplierAdminChargebee | null;
}

const initialState: IAdminState = {
  invites: [],
  supplierAdminObject: null,
  supplierAdminScore: null,
  demoMode: null,
  supplierAdminStripe: null,
  supplierAdminChargebee: null,
};

const reducers: any = (draft: IAdminState) => ({
  [AdminActionTypes.FETCH_SUPPLIER_INVITES_SUCCESS]: (
    action: IFetchSupplierInvitesSuccessAction,
  ) => {
    draft.invites = action.payload.invites;
  },

  [AccessControlActionTypes.SWITCH_SUPPLIER]: () => {
    draft.invites = [];
    draft.supplierAdminScore = null;
    draft.supplierAdminObject = null;
    draft.supplierAdminStripe = null;
    draft.supplierAdminChargebee = null;
  },

  [AdminActionTypes.GENERATE_INVITE_SUCCESS]: (action: IGenerateSupplierInviteSuccess) => {
    draft.invites = [...draft.invites, action.payload];
  },

  [AdminActionTypes.ON_SUPPLIER_ADMIN_FETCH]: (action: OnSupplierAdminFetchAction) => {
    const { admin, score, stripe, chargebee } = action.payload;
    draft.supplierAdminObject = admin;
    draft.supplierAdminScore = score;
    draft.supplierAdminStripe = stripe;
    draft.supplierAdminChargebee = chargebee || null;
  },

  [AdminActionTypes.UPDATE_SUPPLIER_ADMIN_PREMIUM]: (action: IUpdateAdminPremiumObjectAction) => {
    const { name, value } = action.payload;
    draft.supplierAdminObject = {
      ...draft.supplierAdminObject,
      premium: { ...(draft.supplierAdminObject?.premium || {}), [name]: value },
    };
  },

  [AdminActionTypes.ENABLE_DEMO_MODE]: (action: IEnableDemoMode) => {
    draft.demoMode = { enabled: true, actualTier: draft.supplierAdminObject?.premium?.tier };
    draft.supplierAdminObject = {
      ...draft.supplierAdminObject,
      premium: { ...draft.supplierAdminObject?.premium, tier: action.payload },
    };
  },

  [AdminActionTypes.DISABLE_DEMO_MODE]: () => {
    draft.supplierAdminObject = {
      ...draft.supplierAdminObject,
      premium: { ...draft.supplierAdminObject?.premium, tier: draft.demoMode?.actualTier },
    };
    draft.demoMode = null;
  },
});

/*
  This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: IAdminState = initialState, action: Action): IAdminState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
