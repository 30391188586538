import { getI18n } from 'react-i18next';
import { ValidationError } from '@bridebook/toolbox/src';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import validate from 'lib/validate';
import { IGetListedFields } from '../types';

/**
 * Validate Get Listed form using "validate" library
 * @method validateGetListedFieldsStep1Client
 * @param {Object} containing get listed form fields
 * @returns {Promise}
 */
export const validateGetListedFieldsStep2Client = (
  fields: IGetListedFields,
  addressSetFromGeoCode: boolean,
) => {
  const i18n = getI18n();
  const market = gazetteer.getMarketByCountry(fields.countryCode);

  let validation = validate<IGetListedFields>(fields);

  /**
   * We need to validate the supplier type for any country that has more than the single default one.
   */
  if (market.hasFullDirectory) {
    validation = validation.prop('slug').required(() => i18n.t('validation.required.from'));
  }

  if (!fields.lat || !fields.lng) {
    if (addressSetFromGeoCode) {
      throw new ValidationError(i18n.t('validation.invalid.address'), 'address');
    } else {
      throw new ValidationError(i18n.t('validation.invalid.geoAddress'), 'address');
    }
  }

  validation = validation.prop('address').custom((value, prop) => {
    if (!value) {
      throw new ValidationError(i18n.t('validation.required.address'), prop);
    } else if (!addressSetFromGeoCode) {
      throw new ValidationError(i18n.t('validation.invalid.geoAddress'), prop);
    }
  });

  if (market.hasPostalCode === true) {
    validation = validation.prop('postcode').postcode(market, market.country === CountryCodes.GB);
  }

  validation = validation.prop('town').required();

  return validation.promise;
};
