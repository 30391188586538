import { MessageTemplatesAnalyticsActions } from 'lib/message-templates/analytics/action-types';

export const messageTemplateAddedAnalytics = (payload: {
  templateTitle: string;
  templateId: string;
}) => ({
  type: MessageTemplatesAnalyticsActions.MessageTemplateAdded,
  payload,
});

export const messageTemplateEditedAnalytics = (payload: {
  templateTitle: string;
  templateId: string;
}) => ({
  type: MessageTemplatesAnalyticsActions.MessageTemplateEdited,
  payload,
});

export const messageTemplateDeletedAnalytics = (payload: {
  templateTitle: string;
  templateId: string;
}) => ({
  type: MessageTemplatesAnalyticsActions.MessageTemplateDeleted,
  payload,
});

export const messageTemplateUsedAnalytics = (payload: {
  templateTitle: string;
  templateId: string;
}) => ({
  type: MessageTemplatesAnalyticsActions.MessageTemplateUsed,
  payload,
});

export const messageTemplateFileUploadFailedAnalytics = (payload: {
  failureReason: string;
  templateId: string;
}) => ({
  type: MessageTemplatesAnalyticsActions.MessageTemplateFileUploadFailed,
  payload,
});

export const messageTemplateFileAttachedAnalytics = (payload: { templateId: string }) => ({
  type: MessageTemplatesAnalyticsActions.MessageTemplateFileAttached,
  payload,
});

export const messageTemplateFileRemovedAnalytics = (payload: { templateId: string }) => ({
  type: MessageTemplatesAnalyticsActions.MessageTemplateFileRemoved,
  payload,
});

export const addTemplateClickedAnalytics = {
  type: MessageTemplatesAnalyticsActions.AddTemplateClicked,
};
