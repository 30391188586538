import { IRecommendation } from '@bridebook/models/source/models/Suppliers/Recommendations.types';
import { RecommendationsActions } from '../action-types';

const updateRecommendations = (recommendations: IRecommendation[]) => {
  return {
    type: RecommendationsActions.UPDATE_RECOMMENDATIONS,
    payload: { recommendations },
  };
};

export default updateRecommendations;
