import {
  fetchGroupConnections,
  fetchGroupConnectionsSuccess,
  updateGroupConnections,
} from './actions';

export enum GroupActionTypes {
  UPDATE_GROUP_CONNECTIONS = '[Group] Update group connections',
  FETCH_GROUP_CONNECTIONS = '[Group] Fetch group connections',
  FETCH_GROUP_CONNECTIONS_SUCCESS = '[Group] Fetch group connections success',
}

export type IUpdateGroupConnections = ReturnType<typeof updateGroupConnections>;
export type IFetchGroupConnectionAction = ReturnType<typeof fetchGroupConnections>;
export type IFetchGroupConnectionSuccessAction = ReturnType<typeof fetchGroupConnectionsSuccess>;
