import { serverTimestamp } from 'firebase/firestore';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { AdminActionTypes } from 'lib/admin/action-types';
import { appError } from 'lib/app/actions';
import { deleteAllSupplierRelations } from 'lib/relations/actions';
import { Action, IEpicDeps } from 'lib/types';

export const markSupplierAsDeletedEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(AdminActionTypes.MARK_SUPPLIER_AS_DELETED_START),
    withLatestFrom(state$),
    mergeMap(([{ payload: markAsDeleted }, state]) => {
      const {
        supplier: { collaborators },
      } = state;

      const successActions: Action[] = [];
      const activeSupplier = state.users.activeSupplierAccessControl;

      if (!activeSupplier) return of();

      const getPromise = async () => {
        const adminEntity = Suppliers._.getById(activeSupplier.id).Admins.getById('admin');
        await adminEntity.set({
          actions: { deleted: markAsDeleted ? serverTimestamp() : false },
        });

        successActions.push(deleteAllSupplierRelations());

        collaborators[0]?.collaborators.forEach((collaborator) => {
          successActions.push({
            type: 'DEACTIVATE_SUPPLIER_SUCCESS_ANALYTICS',
            payload: { uid: collaborator.id, value: markAsDeleted },
          });
        });
      };

      return from(getPromise()).pipe(
        mergeMap(() => (successActions.length > 0 ? of(...successActions) : of())),
        catchError((error) =>
          of(appError({ error, feature: AdminActionTypes.MARK_SUPPLIER_AS_DELETED_START })),
        ),
      );
    }),
  );
