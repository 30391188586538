import { IFilterLabelProps } from '@bridebook/analytics';
import { supplierPropertiesGeneric } from 'lib/analytics-utils';
import { clickedInviteCollaboratorAnalytics } from 'lib/settings/actions';
import { Action, State } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';

const filterLabelProps: IFilterLabelProps = (props) => {
  const newProps = { ...props };
  delete newProps.collaboratorEmail;
  delete newProps.collaboratorInvite;
  return newProps;
};

export default function settingsAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const { payload } = action;
  const { track } = bridebookAnalytics.getMethods('CMS', filterLabelProps);

  switch (action.type) {
    case 'COPIED_SUPPLIER_BADGE_CODE_ANALYTICS': {
      const { supplierBadgeType, supplierBadgeName } = payload || {};

      track({
        event: 'Copied supplier badge code',
        ...supplierPropertiesGeneric(getState()),
        supplierBadgeType,
        supplierBadgeName,
      });
      break;
    }

    case 'CLICKED_INVITE_COLLABORATOR_ANALYTICS': {
      const {
        payload: { location = '' },
      } = action as ReturnType<typeof clickedInviteCollaboratorAnalytics>;

      track({
        event: 'Clicked Invite collaborator to supplier profile',
        ...supplierPropertiesGeneric(getState()),
        category: 'Supplier linked accounts',
        location,
      });
      break;
    }

    case 'SUBMIT_LINKED_USER_ACCOUNT_ERROR_ANALYTICS': {
      const {
        collaboratorInvite,
        collaboratorPermissions,
        collaboratorEmail,
        failedToInviteCollaboratorReason,
      } = payload || {};

      track({
        event: 'Failed to invite collaborator to supplier profile',
        ...supplierPropertiesGeneric(getState()),
        category: 'Supplier linked accounts',
        collaboratorInvite,
        collaboratorPermissions,
        collaboratorEmail,
        failedToInviteCollaboratorReason,
      });
      break;
    }
  }
}
