import { IReport } from '@bridebook/models/source/models/Suppliers/Reports.types';
import gazetteer from '@bridebook/toolbox/src/gazetteer';
import {
  type ROIReportAnalytics,
  mapROIReportToAnalyticsPayload,
} from 'lib/analytics-utils/map-ROI-report-to-analytics-payload';
import { AnalyticsService } from 'lib/analytics/analytics-service';
import { AnalyticsEvents } from 'lib/analytics/events';
import { type IReportDefaultValues } from 'lib/roi/hooks/use-ROI-report-default-values';
import { getSupplierCountryCode } from 'lib/supplier/selectors';
import type { IApplicationState } from 'lib/types';

enum ROIReportActionType {
  Viewed = 'viewed',
  Edited = 'edited',
  Downloaded = 'downloaded',
  SupplierGenerated = 'supplierGenerated',
}

const getCommonProperties = (state: IApplicationState) => {
  const supplierCountry = getSupplierCountryCode(state);
  const market = gazetteer.getMarketByCountry(supplierCountry);
  const currency = market?.currency;
  const marketPrefix = market?.prefix;
  const locale = market.locale;

  return {
    marketPrefix,
    locale,
    currency,
  };
};

type ICommonProperties = ReturnType<typeof getCommonProperties>;

export const analyticsROIReportService = (analytics: AnalyticsService) => {
  const event = AnalyticsEvents.SupplierInteractedWithROICalculator;

  return {
    viewed: (report: IReport, reportDefaultValues: IReportDefaultValues) =>
      analytics.createHandler<ICommonProperties, ROIReportAnalytics>(
        ROIReportActionType.Viewed,
        event,
        getCommonProperties,
      )(mapROIReportToAnalyticsPayload(report, reportDefaultValues)),
    edited: (report: IReport, reportDefaultValues: IReportDefaultValues) =>
      analytics.createHandler<ICommonProperties, ROIReportAnalytics>(
        ROIReportActionType.Edited,
        event,
        getCommonProperties,
      )(mapROIReportToAnalyticsPayload(report, reportDefaultValues)),
    supplierGenerated: (report: IReport, reportDefaultValues: IReportDefaultValues) =>
      analytics.createHandler<ICommonProperties, ROIReportAnalytics>(
        ROIReportActionType.SupplierGenerated,
        event,
        getCommonProperties,
      )(mapROIReportToAnalyticsPayload(report, reportDefaultValues)),
    downloaded: (report: IReport, reportDefaultValues: IReportDefaultValues) =>
      analytics.createHandler<ICommonProperties, ROIReportAnalytics>(
        ROIReportActionType.Downloaded,
        event,
        getCommonProperties,
      )(mapROIReportToAnalyticsPayload(report, reportDefaultValues)),
  };
};
