import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import { getPhotos } from 'lib/photos/selectors';
import { IEpicDeps } from 'lib/types';
import { IAddPhoto, PhotosActionTypes } from '../action-types';
import { addPhotoSuccess, setPhotoPriority } from '../actions';

export const setOrderAfterPhotoAdd = (action$: Observable<IAddPhoto>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(PhotosActionTypes.SET_ORDER_AFTER_PHOTO_ADD),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const photos = getPhotos(state);
      return of(...[addPhotoSuccess(), setPhotoPriority(photos)]);
    }),
    catchError((error) => of(appError({ error, feature: 'Photos' }))),
  );
