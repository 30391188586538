import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { ISupplierPackage } from '@bridebook/toolbox/src/map-packages-to-sections';
import { getIsVenue } from 'lib/supplier/selectors';
import { IApplicationState, IEpicDeps } from 'lib/types';
import { PricingActionTypes } from './action-types';
import { onFirestoreBrochures } from './actions';
import { onFirestorePackages, onFirestoreSupplierPackages } from './actions/packages';

export const initPackagesListener = (action$: Observable<any>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(PricingActionTypes.INIT_PACKAGES_LISTENER),
    withLatestFrom(state$),
    mergeMap(([, state]: [any, IApplicationState]) => {
      const activeSupplier = state.users.activeSupplierAccessControl;
      const isVenue = getIsVenue(state);
      if (!activeSupplier?.id) return of();

      return Suppliers._.getById(activeSupplier.id)
        .Packages.query()
        .observe()
        .pipe(
          mergeMap((data) => {
            if (data) {
              return isVenue
                ? of(onFirestorePackages(data))
                : of(onFirestoreSupplierPackages(Object.values(data) as ISupplierPackage[]));
            }
            return of();
          }),
          takeUntil(action$.pipe(ofType(PricingActionTypes.STOP_PACKAGES_LISTENER))),
        );
    }),
  );

export const initBrochuresListener = (action$: Observable<any>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(PricingActionTypes.INIT_BROCHURES_LISTENER),
    withLatestFrom(state$),
    mergeMap(([, state]: [any, IApplicationState]) => {
      const activeSupplier = state.users.activeSupplierAccessControl;
      if (!activeSupplier?.id) return of();
      return Suppliers._.getById(activeSupplier.id)
        .Brochures.query()
        .observe()
        .pipe(
          mergeMap((data) => (data ? of(onFirestoreBrochures(data)) : of())),
          takeUntil(action$.pipe(ofType(PricingActionTypes.STOP_BROCHURES_LISTENER))),
        );
    }),
  );
