import { initAppsListener, setInstagramData } from 'lib/apps/actions';

export enum AppsActionTypes {
  INIT_LISTENER = '[Apps] Init apps listener',
  STOP_LISTENER = '[Apps] Stop apps listener',
  SET_INSTAGRAM_DATA = '[Apps] Set instagram data',
}

export type InitAppsListenerAction = ReturnType<typeof initAppsListener>;
export type SetInstagramDataAction = ReturnType<typeof setInstagramData>;
