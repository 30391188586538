import { IFilterLabelProps } from '@bridebook/analytics';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { ISupplierAdmin } from '@bridebook/models/source/models/Suppliers/Admin/admin.types';
import { IRadioGroupOption } from '@bridebook/ui/src/components/bbcommon/radio-group-v2/radio-group-v2';
import { identifySupplier, supplierPropertiesGeneric } from 'lib/analytics-utils';
import {
  AddedAdditionalLocationAnalyticsAction,
  RemovedAdditionalLocationAnalyticsAction,
  SupplierAnalyticsActionTypes,
} from 'lib/supplier/analytics/action-types';
import { getOptionsDay } from 'lib/targeting/data/day';
import { getOptionsSeason } from 'lib/targeting/data/season';
import { getOptionsYear } from 'lib/targeting/data/year';
import { Action, State } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';
import {
  savedSupplierConciergeOptOutAnalytics,
  savedSupplierInfoOnCmsTargetingAnalytics,
} from './actions';
import { ITargeting } from './types';

const filterLabelProps: IFilterLabelProps = (props) => {
  const newProps = { ...props };
  delete newProps.supplierAddress;
  delete newProps.supplierContactEmail;
  delete newProps.supplierPhone;
  delete newProps.supplierPostcode;
  delete newProps.supplierProfileURL;
  delete newProps.supplierThumbnailURL;
  return newProps;
};

const getTargetingValue = (options: IRadioGroupOption[], targetingValue: any) =>
  (options.find((item) => item.value === `${targetingValue}`) || ({} as IRadioGroupOption)).label;

const supplierTargetingPropertiesGeneric = ({
  targetingBudget,
  targetingSeason,
  targetingWeekday,
  targetingYear,
}: ITargeting) => ({
  targetingBudget: targetingBudget || null,
  targetingSeason: getTargetingValue(getOptionsSeason(), targetingSeason),
  targetingWeekday: getTargetingValue(getOptionsDay(), targetingWeekday),
  targetingYear: getTargetingValue(getOptionsYear(), targetingYear),
});

export default function supplierAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const { payload } = action;

  const { track, identify } = bridebookAnalytics.getMethods('CMS', filterLabelProps);

  switch (action.type) {
    case 'DEACTIVATE_SUPPLIER_SUCCESS_ANALYTICS': {
      const { uid, value: supplierDeleted } = payload || ({} as any);
      identify(uid, {
        supplierDeleted,
        supplierDeletedDate: new Date().toISOString(),
      });
      break;
    }

    case 'UPDATE_SUPPLIER_SCORE_ANALYTICS': {
      const { name, value } = payload as { name: keyof ISupplierAdmin['actions']; value: boolean };
      const {
        supplier: { supplier },
        admin: { supplierAdminObject },
      } = getState();
      const adminActions = supplierAdminObject?.actions as ISupplierAdmin['actions'];
      const manualCheckPropertiesGeneric = {
        deleted: Boolean(adminActions.deleted),
        delisted: Boolean(adminActions.delisted),
        checked: Boolean(adminActions.checked),
        searchable: Boolean(adminActions.searchable),
      };
      manualCheckPropertiesGeneric[name] = value;
      identifySupplier({ supplier: supplier as ISupplier, props: manualCheckPropertiesGeneric });

      track({
        event: 'Admin - Manually checked supplier profile',
        category: 'Admin CMS',
        ...supplierPropertiesGeneric(getState()),
        ...manualCheckPropertiesGeneric,
      });
      break;
    }

    case 'SAVED_SUPPLIER_INFO_ON_CMS_TARGETING_ANALYTICS': {
      type PayloadType = ReturnType<typeof savedSupplierInfoOnCmsTargetingAnalytics>['payload'];
      const { targeting } = payload as PayloadType;

      track({
        event: 'Saved supplier info on CMS targeting',
        ...supplierPropertiesGeneric(getState()),
        ...supplierTargetingPropertiesGeneric(targeting),
      });
      break;
    }

    case 'SAVED_SUPPLIER_CONCIERGE_OPT_OUT_ANALYTICS': {
      type PayloadType = ReturnType<typeof savedSupplierConciergeOptOutAnalytics>['payload'];
      const { conciergeOptOut, id } = payload as PayloadType;
      track({
        event: 'Changed concierge opt-out status in CMS',
        ...supplierPropertiesGeneric(getState()),
        conciergeOptOut,
        id,
      });
      break;
    }

    case 'COPIED_ENQUIRY_WIDGET_CODE_ANALYTICS': {
      track({
        event: 'Copied enquiry widget code',
        ...supplierPropertiesGeneric(getState()),
      });
      break;
    }

    case SupplierAnalyticsActionTypes.ADDED_ADDITIONAL_LOCATION: {
      const { countyName } = payload as AddedAdditionalLocationAnalyticsAction['payload'];
      const countyCount = getState().supplier.supplier?.additionalAreas?.length || 0;
      track({
        event: 'Added additional supplier location',
        ...supplierPropertiesGeneric(getState()),
        countyName,
        countyCount,
      });
      break;
    }

    case SupplierAnalyticsActionTypes.REMOVED_ADDITIONAL_LOCATION: {
      const { countyName } = payload as RemovedAdditionalLocationAnalyticsAction['payload'];
      const countyCount = getState().supplier.supplier?.additionalAreas?.length || 0;
      track({
        event: 'Removed additional supplier location',
        ...supplierPropertiesGeneric(getState()),
        countyName,
        countyCount,
      });
      break;
    }
  }
}
