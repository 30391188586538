import produce from 'immer';
import { assocPath } from 'ramda';
import { INonce_Role } from '@bridebook/models/source/models/Nonces.types';
import { Action, IReducersImmer } from 'lib/types';
import { UserActionTypes } from 'lib/users/action-types';
import { AuthActionTypes } from '../auth/action-types';
import {
  OnBadgeChangeType,
  SetLinkedAccountDataType,
  SettingsActions,
  ToggleInviteCollabPopupType,
} from './action-types';
import LinkedAccountForm from './linkedAccountForm';
import type { IFormFields as LinkedAccoutFormType } from './types';

export interface SettingsState {
  resetPasswordSuccess: boolean;
  resetPasswordStart: boolean;
  changeEmailSuccess: boolean;
  updateUserDetailsSuccess: boolean;
  profilePhotoUpdateSuccess: boolean;
  linkAccountSuccess: Object | null;
  userDetailsInputUpdated: boolean;
  changeTheEmail: boolean;
  badgesBusinessName: string | null;
  showInviteCollabPopup: boolean;
  linkedAccountForm: LinkedAccoutFormType;
  error: Error | null;
}

const initialState: SettingsState = {
  resetPasswordSuccess: false,
  resetPasswordStart: false,
  changeEmailSuccess: false,
  updateUserDetailsSuccess: false,
  profilePhotoUpdateSuccess: false,
  linkAccountSuccess: null,
  userDetailsInputUpdated: false,
  changeTheEmail: false,
  badgesBusinessName: null,
  showInviteCollabPopup: false,
  linkedAccountForm: { ...LinkedAccountForm },
  error: null,
};

const settingsReducer: IReducersImmer<SettingsState> = (draft) => ({
  [SettingsActions.RESET_SETTINGS]: () => {
    draft.resetPasswordSuccess = false;
    draft.resetPasswordStart = false;
    draft.changeEmailSuccess = false;
    draft.profilePhotoUpdateSuccess = false;
    draft.changeTheEmail = false;
    draft.updateUserDetailsSuccess = false;
  },

  SAVE_USER_SETTINGS_SUCCESS: () => {
    draft.updateUserDetailsSuccess = true;
    draft.userDetailsInputUpdated = false;
  },

  [UserActionTypes.SET_FORM_FIELD]: () => {
    draft.updateUserDetailsSuccess = false;
    draft.userDetailsInputUpdated = true;
  },

  [AuthActionTypes.RESET_PASSWORD_SUCCESS]: () => {
    draft.resetPasswordSuccess = true;
    draft.resetPasswordStart = false;
  },

  [AuthActionTypes.CHANGE_AUTH_EMAIL]: () => {
    draft.changeEmailSuccess = false;
  },

  [AuthActionTypes.CHANGE_AUTH_EMAIL_SUCCESS]: () => {
    draft.changeEmailSuccess = true;
    draft.changeTheEmail = false;
  },

  [AuthActionTypes.RESET_PASSWORD]: () => {
    draft.resetPasswordStart = true;
  },

  [AuthActionTypes.RESET_PASSWORD_ERROR]: () => {
    draft.resetPasswordStart = false;
    draft.resetPasswordSuccess = false;
  },

  [SettingsActions.CHANGE_EMAIL]: () => {
    draft.changeTheEmail = true;
  },

  [SettingsActions.ON_BADGES_CHANGE]: (action: OnBadgeChangeType) => {
    draft.badgesBusinessName = action.payload;
  },

  [SettingsActions.TOGGLE_INVITE_COLLAB_POPUP]: (action: ToggleInviteCollabPopupType) => {
    if (typeof action.payload === 'boolean') {
      draft.showInviteCollabPopup = action.payload as boolean;
    } else {
      draft.showInviteCollabPopup = action.payload.display;
      draft.linkedAccountForm = {
        ...action.payload.extraInfo,
        permissionLevel: '' as INonce_Role,
        email: '',
      };
    }
  },

  [SettingsActions.SET_LINKED_ACCOUNT_DATA]: (action: SetLinkedAccountDataType) => {
    const { name, value } = action.payload;

    draft.linkedAccountForm = assocPath<typeof value, SettingsState['linkedAccountForm']>(
      [name],
      value,
    )(draft.linkedAccountForm);
  },

  [SettingsActions.SUBMIT_LINKED_USER_ACCOUNT_ERROR]: (action) => {
    draft.error = action.payload;
  },

  [SettingsActions.SUBMIT_LINKED_USER_ACCOUNT_SUCCESS]: (action) => {
    draft.linkAccountSuccess = action.payload;
    draft.error = null;
  },

  [SettingsActions.RESET_LINK_SUCCESS_MESSAGE]: () => {
    draft.linkAccountSuccess = null;
  },

  [SettingsActions.LINKED_USER_ERROR_RESET]: () => {
    draft.error = null;
  },

  [UserActionTypes.UPLOAD_PROFILE_PHOTO]: () => {
    draft.profilePhotoUpdateSuccess = true;
  },
});

const reducer = (state: SettingsState = initialState, action: Action) => {
  try {
    return produce(state, (draft) => settingsReducer(draft)[action.type](action));
  } catch (e) {
    return state;
  }
};

export default reducer;
