import { ICountry_CmsBanner } from '@bridebook/models/source/models/Countries.types';
import { supplierPropertiesGeneric } from 'lib/analytics-utils';
import { Action, State } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';
import {
  MarketingBannerClickedAction,
  MarketingBannerClosedAction,
  UIAnalyticsActionTypes,
} from './action-types';

const marketingBannerPropertiesGeneric = (banner: ICountry_CmsBanner) => ({
  bannerId: banner.id,
  bannerTitle: banner.title,
  bannerCTA: banner.cta,
  bannerUrl: banner.url,
});

export default function uiAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const { track } = bridebookAnalytics.getMethods('CMS');

  switch (action.type) {
    case UIAnalyticsActionTypes.MARKETING_BANNER_CLICKED: {
      const { banner } = (action as MarketingBannerClickedAction).payload;
      track({
        event: 'Clicked CMS banner link',
        ...supplierPropertiesGeneric(getState()),
        ...marketingBannerPropertiesGeneric(banner),
      });
      break;
    }

    case UIAnalyticsActionTypes.MARKETING_BANNER_CLOSED: {
      const { banner } = (action as MarketingBannerClosedAction).payload;
      track({
        event: 'Closed CMS banner',
        ...supplierPropertiesGeneric(getState()),
        ...marketingBannerPropertiesGeneric(banner),
      });
      break;
    }
  }
}
