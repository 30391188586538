import { Suppliers } from '@bridebook/models';
import { IACLUserExtended } from 'lib/access-control/types';

export const extendUserAccessControl = async (
  userAccessControl: string[],
  userId: string,
): Promise<Record<string, IACLUserExtended> | {}> => {
  const extendedACL: Record<string, IACLUserExtended> = {};

  const promises: Array<Promise<void>> = userAccessControl.map(async (supplierId) => {
    const supplierEntity = Suppliers._.getById(supplierId);
    const supplier = await supplierEntity.get();
    const acl = await supplierEntity.Users.getById(userId).get();
    const photo = await supplierEntity.getThumbnail();
    if (supplier) {
      extendedACL[supplierId] = {
        ...acl,
        id: supplierId,
        postcode: `${supplier.address?.street[0]} • ${supplier.address?.city}` || '',
        street: supplier.address?.street[0],
        city: supplier.address?.city,
        name: supplier.name,
        slug: supplier.type[0],
        thumbnail: photo?.path,
      };
    }
  });

  await Promise.all(promises);

  return extendedACL;
};
