import type { PartialRecursive } from '../abstract/_';
import { AbstractCollection } from '../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Untrackable } from '../abstract/Document';
import { Suppliers } from './Suppliers';
import type { ISupplierUser } from './SupplierUsers.types';
import { arrayUnion, runTransaction } from 'firebase/firestore';
import { mergeDeepRight } from 'ramda';
import { distinctUntilChanged, map, shareReplay, take } from 'rxjs/operators';

@Identifiable
@Timestampable
@Untrackable
export class SupplierUser extends AbstractDocument<ISupplierUser> {
  readonly collections = {};

  get activeSupplier() {
    return this.observe()
      .pipe(
        map((user) => {
          if (Array.isArray(user?.suppliers) && user.suppliers.length > 0) {
            return Suppliers._.getById(user.suppliers.pop()).get();
          }

          return null;
        }),
      )
      .pipe(distinctUntilChanged())
      .pipe(shareReplay(1));
  }

  /**
   * Returns the active supplier.
   */
  async getActiveSupplier() {
    return await this.activeSupplier.pipe(take(1)).toPromise();
  }

  /**
   * Returns the active supplier ACL.
   * Active supplier is determined by the latest `updatedAt` (or `createdAt` as a fallback) of the supplier ACL.
   */
  async getActiveSupplierAccessControl() {
    const user = await this.get(true);

    if (Array.isArray(user.suppliers) && user.suppliers.length > 0) {
      const active = user.suppliers.pop() as string;
      const result = await Suppliers._.getById(active).Users.getById(this.id).get();

      /**
       * The `id` property of `IUser` should be replaced by the active supplier ID.
       */
      return { ...result, id: active };
    }

    return null;
  }

  /**
   * Sets the provided supplier as the active supplier for the user.
   */
  async setActiveSupplier(supplier: string) {
    return runTransaction(this.reference.firestore, (transaction) =>
      transaction.get(this.reference).then((snapshot) => {
        const data = snapshot.data();

        /**
         * Ensure that the supplier always has a reference back to the user.
         */
        transaction.update(Suppliers._.getById(supplier).reference, 'users', arrayUnion(this.id));

        let result: ISupplierUser['suppliers'] = [];

        if (data.suppliers != null) {
          result = data.suppliers.filter((value) => value !== supplier);
        }

        return transaction.update(this.reference, 'suppliers', [...result, supplier]);
      }),
    );
  }

  /**
   * Marks all other user suppliers as non-primary.
   * Also sets the provided supplier as the active supplier for the user.
   */
  async updatePrimarySupplier(supplier: string) {
    const user = await this.get(true);
    const promises: Promise<any>[] = [this.setActiveSupplier(supplier)];

    // TODO @alixaxel: Remove this snippet once all indirect usages have been vetted.
    if (Array.isArray(user.suppliers)) {
      for (const id of user.suppliers) {
        const promise = Suppliers._.getById(id)
          .Users.getById(user.id)
          .set({
            primary: id !== supplier,
          });

        promises.push(promise);
      }
    }

    return Promise.all(promises);
  }
}

export class SupplierUsers extends AbstractCollection<SupplierUser, ISupplierUser> {
  static definitions = {
    _: {} as ISupplierUser,
  };

  /**
   * This collection nomenclature deviates from the single word convention we use for every other collection.
   * It creates difficulties differentiating between `/suppliers/users` and `/supplier-users`.
   * The rationale for us having gone with it, is explained in the the following Notion document:
   *   https://www.notion.so/bridebook/Suppliers-DB-migrations-next-steps-90c90aa49b2d40cb99eaf3f026c591ff?pvs=4#4f6c25db64d44e33a44480c4efb3cc7a
   *
   * In short, we have a collection of users that are associated with suppliers, and a collection of users that are associated with weddings.
   * Both collections share most of the common fields, the crucial difference being `suppliers` and `weddings` array.
   * Being in two distinct collections means we get into trouble if a supplier user signs up as a wedding user by mistake.
   * We could make `/users` the canonical collection, as long as we made `actions` optional and moved over the `suppliers` array.
   *
   * As of 2023-02-24, we have 27053 overlapping users:
   *   WITH
   *     `supplier_users` AS ( -- 35248 supplier users
   *       SELECT `id` FROM `bridebookhq.firestore_bi.supplier_users_current`
   *     ),
   *     `wedding_users` AS ( -- 1376633 wedding users
   *       SELECT `id` FROM `bridebookhq.firestore_bi.users_current`
   *     )
   *   SELECT COUNT(1) FROM `supplier_users` JOIN `wedding_users` USING (`id`);
   */
  static path = 'supplier-users';

  constructor() {
    super(SupplierUsers.path, SupplierUser);
  }

  static new<M extends typeof SupplierUsers.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<ISupplierUser> = {};

    if (key !== '_' && key in SupplierUsers.definitions) {
      result = (result[key as keyof Omit<typeof SupplierUsers.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<M[K]>;
    }

    return result as M[K];
  }

  static get _() {
    return new this();
  }
}
