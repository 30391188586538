import { FeedbackActions } from '../action-types';
import { IFeedbackSerialized } from '../types';

export const deleteFeedback = (feedback: IFeedbackSerialized) => ({
  type: FeedbackActions.DELETE_FEEDBACK,
  payload: { feedback },
});

export const deleteFeedbackSuccess = () => ({
  type: FeedbackActions.DELETE_FEEDBACK_SUCCESS,
});

export const deleteFeedbackError = (error: Error) => ({
  type: FeedbackActions.DELETE_FEEDBACK_ERROR,
  payload: { error },
});
