export enum FailedReason {
  FailedToAddBrochure = 'failedToAddBrochure',
  FailedToAddPhoto = 'failedToAddPhoto',
  FailedToAddRecommendation = 'failedToAddRecommendation',
  FailedToAddTestimonial = 'failedToAddTestimonial',
  FailedToAddVideo = 'failedToAddVideo',
  FailedToAddWeddingFair = 'failedToAddWeddingFair',
  FailedToAttachFile = 'failedToAttachFile',
  FailedToCollaborate = 'failedToCollaborate',
  FailedToEditTestimonial = 'failedToEditTestimonial',
  FailedToEditWeddingFair = 'failedToEditWeddingFair',
  SocialMediaDisconnected = 'socialMediaDisconnected',
  FailedToEditUserDetails = 'failedToEditUserDetails',
  FailedToUpdateAuthDetails = 'failedToUpdateAuthDetails',
}
