import { values } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { mapSerializeTimestamps, serializeTimestamps } from '@bridebook/toolbox/src/serialize-timestamps';
import { IEpicDeps } from '../types';
import { FeedbackActions } from './action-types';
import { updateFeedback } from './actions/update-feedback';
import { IFeedbackSerialized } from './types';

const feedbackListenerEpic = (action$: Observable<any>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(FeedbackActions.INIT_FEEDBACK_LISTENER),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const supplierId = state.supplier.supplier?.id;
      if (!supplierId) return of();
      const feedback$ = Suppliers._.getById(supplierId).Feedback.query().observe();

      return feedback$.pipe(
        mergeMap((data) => {
          const serializedData = values(mapSerializeTimestamps(data));

          const dataWithSerializedResponse = serializedData.map((review) => {
            if (review.response)
              return { ...review, response: { ...serializeTimestamps(review.response) } };
            return review;
          });
          return of(updateFeedback(dataWithSerializedResponse as IFeedbackSerialized[]));
        }),
        takeUntil(action$.pipe(ofType(FeedbackActions.STOP_FEEDBACK_LISTENER))),
      );
    }),
  );

export default feedbackListenerEpic;
