import { filter, map, pluck, switchMap, takeUntil } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import {
  initBadgesListener as initBadgesListenerAction,
  stopBadgesListener,
  updateBadges,
} from 'lib/badges/slice';
import { IEpic } from 'lib/types';

export const initBadgesListener: IEpic<
  ReturnType<typeof initBadgesListenerAction>,
  ReturnType<typeof updateBadges>
> = (action$) =>
  action$.pipe(
    filter(initBadgesListenerAction.match),
    pluck('payload'),
    switchMap((supplierId) =>
      Suppliers._.getById(supplierId)
        .Badges.query()
        .observe()
        .pipe(map(Object.values), map(updateBadges)),
    ),
    takeUntil(action$.pipe(filter(stopBadgesListener.match))),
  );
