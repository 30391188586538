import { serverTimestamp } from 'firebase/firestore';
import differenceWith from 'lodash/differenceWith';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { Relations } from '@bridebook/models/source/models/Suppliers/Relations';
import { appError } from 'lib/app/actions';
import { IUpdateSupplierRelationsAction, RelationsActionTypes } from 'lib/relations/action-types';
import { fetchSupplierRelations } from 'lib/relations/actions';
import { IEpicDeps } from 'lib/types';
import { updateSupplierRelationsSuccess } from '../actions/update-supplier-relations';

export const updateSupplierRelationsEpic = (
  action$: Observable<IUpdateSupplierRelationsAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(RelationsActionTypes.UPDATE_SUPPLIER_RELATIONS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const activeSupplier = state.users.activeSupplierAccessControl;
      if (!activeSupplier?.id) return of();
      const getPromise = async () => {
        const newSupplierRelations = action.payload.supplierRelations;
        const currentSupplierRelations = state.relations.list;

        const addedSupplierRelations = differenceWith(
          newSupplierRelations,
          currentSupplierRelations,
          (value, other) => value.id == other.id,
        );

        await Promise.all(
          addedSupplierRelations.map((supplierRelation) => {
            const supplier = Suppliers._.getById(supplierRelation.id);
            const relations = new Relations(supplier);
            const relation = relations.push(activeSupplier.id);
            return relation.create({
              id: activeSupplier.id,
              type: 'photo',
              active: true,
              createdAt: serverTimestamp(),
              order: 1,
              updatedAt: serverTimestamp(),
            });
          }),
        );

        const deletedSupplierRelations = differenceWith(
          currentSupplierRelations,
          newSupplierRelations,
          (value, other) => value.id == other.id,
        );

        await Promise.all(
          deletedSupplierRelations.map((supplierRelation) => {
            const supplier = Suppliers._.getById(supplierRelation.id);
            const relations = new Relations(supplier);
            const relation = relations.getById(activeSupplier.id);
            return relation.delete();
          }),
        );

        return { supplierRelations: newSupplierRelations };
      };

      return from(getPromise()).pipe(
        mergeMap(({ supplierRelations }) =>
          of(
            updateSupplierRelationsSuccess({
              supplierRelations,
            }),
            fetchSupplierRelations(),
          ),
        ),
        catchError((error) =>
          of(appError({ error, feature: RelationsActionTypes.UPDATE_SUPPLIER_RELATIONS })),
        ),
      );
    }),
  );
