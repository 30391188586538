import { AnyAction } from 'redux';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { IEpic } from 'lib/types';
import { UIActionTypes } from 'lib/ui/action-types';
import { toggleOverlayPortal } from 'lib/ui/actions';
import { noopAction } from 'lib/utils';

export const modalsEpic: IEpic<AnyAction, any> = (action$, { state$ }) =>
  action$.pipe(
    ofType(UIActionTypes.ON_MODAL_TOGGLE),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { openedModalIds } = state.ui;

      const { openedOverlayPortal } = state.ui;
      if (!openedOverlayPortal && action.payload.show && openedModalIds.length > 0) {
        return of(toggleOverlayPortal(true));
      }

      if (openedOverlayPortal && !action.payload.show) {
        return of(toggleOverlayPortal(false));
      }

      return of(noopAction());
    }),
  );

modalsEpic.epicName = 'modalsEpic';
