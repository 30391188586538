import { WithFieldValue } from 'firebase/firestore';
import { IFair } from '@bridebook/models/source/models/Suppliers/Fairs.types';
import { RecommendationsAnalyticsTypes } from './action-types';

const failedToSaveFairAnalytics = ({
  fair,
  err,
  isEdit,
}: {
  fair: WithFieldValue<IFair>;
  err: Error;
  isEdit: boolean;
}) => ({
  type: isEdit
    ? RecommendationsAnalyticsTypes.FAILED_TO_EDIT_WEDDING_FAIR_ANALYTICS
    : RecommendationsAnalyticsTypes.FAILED_TO_ADD_WEDDING_FAIR_ANALYTICS,
  payload: { fair, err },
});

export default failedToSaveFairAnalytics;
