import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { ApiEndpoint } from 'lib/api/api-endpoints';
import { appError } from 'lib/app/actions';
import { getActiveSupplierAccessControlId } from 'lib/supplier/selectors';
import { IEpicDeps } from 'lib/types';
import { GroupActionTypes, IFetchGroupConnectionAction } from '../action-types';
import { fetchGroupConnectionsSuccess } from '../actions';
import { IGroupConnection } from '../types';

export const fetchGroupConnectionsEpic = (
  action$: Observable<IFetchGroupConnectionAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(GroupActionTypes.FETCH_GROUP_CONNECTIONS),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const activeSupplierId = getActiveSupplierAccessControlId(state);
      if (!activeSupplierId) return of({});
      const getPromise = async () => {
        const res = await authenticatedFetch<IGroupConnection[]>(
          ApiEndpoint.groupConnections(activeSupplierId),
        );

        if (res.status !== 200) {
          throw new Error('Error when fetching group connections');
        }

        const response = await res.json();
        return response;
      };

      return from(getPromise()).pipe(
        map((response) => fetchGroupConnectionsSuccess(response)),
        catchError((error) =>
          of(appError({ error, feature: GroupActionTypes.FETCH_GROUP_CONNECTIONS })),
        ),
      );
    }),
  );
