import { getSupplierCountryCode, getSupplierTier, getSupplierType } from 'lib/supplier/selectors';
import { IDeps } from 'lib/types';
import { StatisticsActionTypes } from '../action-types';
import { ChartGranularity, ChartName } from '../constants';

export const switchChartGranularity =
  (chart: ChartName, granularity: ChartGranularity) =>
  ({ getState }: IDeps) => {
    const state = getState();
    return {
      type: StatisticsActionTypes.SWITCH_CHART_GRANULARITY,
      payload: {
        chart,
        granularity,
        supplierTier: getSupplierTier(state),
        supplierType: getSupplierType(state),
        supplierCountryCode: getSupplierCountryCode(state),
      },
    };
  };
