import { IBookingFile } from '@bridebook/toolbox/src/types';
import { YourCouplesActionTypes } from 'lib/couples/action-types';

export const deleteFilesSuccess = (files: IBookingFile[]) => ({
  type: YourCouplesActionTypes.DELETE_FILES_SUCCESS,
  payload: { files },
});

export const fetchFiles = () => ({
  type: YourCouplesActionTypes.FETCH_FILES,
});

export const fetchFilesSuccess = (payload: { files: IBookingFile[] }) => ({
  type: YourCouplesActionTypes.FETCH_FILES_SUCCESS,
  payload,
});
