import { mergeDeepRight } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ISupplier_Translations } from '@bridebook/models/source/models/Suppliers.types';
import { appError } from 'lib/app/actions';
import { getScopedContentTranslations } from 'lib/content-translations/selectors';
import { clearTempContentTranslations } from 'lib/content-translations/slice';
import { SupplierActionTypes } from 'lib/supplier/action-types';
import { getAllFormSectionsFields } from 'lib/supplier/utils/get-all-form-sections-fields';
import { getHandleSaveSupplierDataPromise } from 'lib/supplier/utils/get-save-all-supplier-data-promise';
import { IEpicDeps } from 'lib/types';

export const saveSupplierEpic = (action$: Observable<any>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(SupplierActionTypes.SAVE_SUPPLIER),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const {
        supplier: { supplier, formUpdates, formSectionsUpdates },
      } = state;

      const allFormSectionsFields = getAllFormSectionsFields(formSectionsUpdates);

      const formData = mergeDeepRight(formUpdates || {}, allFormSectionsFields);

      if (!supplier) return of();

      const _translations = getScopedContentTranslations<ISupplier_Translations>(state, 'supplier');

      return from(
        getHandleSaveSupplierDataPromise({
          formData,
          supplier,
          _translations,
        }),
      ).pipe(
        mergeMap(() => [
          { type: 'SAVE_SUPPLIER_SUCCESS' },
          clearTempContentTranslations('supplier'),
        ]),
        catchError((error) => of(appError({ error, feature: 'Supplier' }))),
      );
    }),
  );
