import { prop, sortBy, values } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { appError } from '../../app/actions';
import { RecommendationsActions, UpdateRecommendationsAction } from '../action-types';
import { updateRecommendationsDone } from '../actions/update-recommendations-done';

const sortByOrder = sortBy(prop('order'));

export const updateRecommendationsEpic = (action$: Observable<UpdateRecommendationsAction>) =>
  action$.pipe(
    ofType(RecommendationsActions.UPDATE_RECOMMENDATIONS),
    mergeMap(({ payload: { recommendations } }) => {
      if (recommendations.length > 0) {
        const promises = sortByOrder(recommendations).map(async (recommendation) => {
          const supplierRef = Suppliers._.getById(recommendation.supplierId);

          const [supplier, photos] = await Promise.all([
            supplierRef.get(),
            supplierRef.Photos.query().get().then(values).then(sortByOrder),
          ]);

          return { ...supplier, photos };
        });

        return from(Promise.all(promises)).pipe(
          mergeMap((suppliers) => of(updateRecommendationsDone(suppliers))),
          catchError((error) => of(appError({ error, feature: 'Recommendations' }))),
        );
      }

      return of(updateRecommendationsDone([]));
    }),
  );
