import { IFollowUp } from '@bridebook/models/source/models/Suppliers/FollowUps.types';
import { FollowUpMessagesAction } from 'lib/follow-up-messages/action-types';
import { FollowUpMessage } from 'lib/follow-up-messages/types';

export const saveFollowUpMessage = (payload: FollowUpMessage) => ({
  type: FollowUpMessagesAction.SaveFollowUpMessage,
  payload,
});

export const followUpMessageListSaved = (payload: IFollowUp[]) => ({
  type: FollowUpMessagesAction.FollowUpMessageListSaved,
  payload,
});

export const deleteFollowUpMessage = (payload: FollowUpMessage) => ({
  type: FollowUpMessagesAction.DeleteFollowUpMessage,
  payload,
});

export const followUpMessageSaved = { type: FollowUpMessagesAction.FollowUpMessageSaved };
export const saveFollowUpMessageError = { type: FollowUpMessagesAction.SaveFollowUpMessageError };
export const initFollowUpMessagesListener = {
  type: FollowUpMessagesAction.InitFollowUpMessagesListener,
};
export const followUpMessageDeleted = { type: FollowUpMessagesAction.FollowUpMessageDeleted };
