import { createSelector } from 'reselect';
import { IApplicationState } from '../../types';

const weddingsHosted = (state: IApplicationState) =>
  state.supplier.supplier?.company?.weddingsHosted;

export const getSupplierWeddingsHosted = createSelector(
  [weddingsHosted],
  (weddingsHosted) => weddingsHosted,
);

const team = (state: IApplicationState) => state.supplier.supplier?.company?.teamSize;
export const getSupplierPeopleInTheTeam = createSelector([team], (team) => team);

const insurance = (state: IApplicationState) => state.supplier.supplier?.company?.insurance;
export const getSupplierInsurance = createSelector([insurance], (insurance) => insurance);

const established = (state: IApplicationState) => state.supplier.supplier?.company?.established;
export const getSupplierEstablished = createSelector([established], (established) => {
  if (established == null) {
    return undefined;
  }

  return established;
});
