import { orderBy } from 'firebase/firestore';
import { ofType } from 'redux-observable';
import { filter, map, pluck, switchMap, takeUntil } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { AuthActionTypes } from 'lib/auth/action-types';
import { IEpic } from 'lib/types';
import { AchievementsActionTypes, IInitAchievementsListenerAction } from './action-types';
import { onFirestoreAchievements } from './actions';

export const initAchievementsListener: IEpic<IInitAchievementsListenerAction, any> = (action$) =>
  action$.pipe(
    ofType(AchievementsActionTypes.INIT_ACHIEVEMENTS_LISTENER),
    pluck('payload'),
    switchMap((supplierId) => {
      const achievements = Suppliers._.getById(supplierId).Achievements;

      return achievements
        .query([orderBy('createdAt', 'asc')])
        .observe()
        .pipe(
          filter(hasData),
          map((data) => onFirestoreAchievements(data)),
          takeUntil(action$.pipe(ofType(AuthActionTypes.SIGN_OUT_SUCCESS))),
        );
    }),
  );

// hasData typeguard
const hasData = <T>(data: T | null): data is T => data !== null;
