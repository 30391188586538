import type { ICollaboratorInviteType } from 'lib/auth/types';

const CollaboratorInvite: ICollaboratorInviteType = {
  nonceId: '',
  nonceSecret: '',
  inviteExpired: false,
  inviteSupplier: null,
  inviteSupplierPhoto: null,
  inviteUrl: '',
  invite: null,
};

export default CollaboratorInvite;
