import { ofType } from 'redux-observable';
import { Observable, merge, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import { onAuth } from 'lib/auth/actions';
import { Action, IEpicDeps } from 'lib/types';

export const appStartedEpic = (action$: Observable<Action>, deps: IEpicDeps): Observable<any> => {
  const { firebaseAuth } = deps;

  // firebase.google.com/docs/reference/js/firebase.auth.Auth#onAuthStateChanged
  const onAuth$ = new Observable((observer) => {
    const unsubscribe = firebaseAuth().onIdTokenChanged((firebaseUser) => {
      observer.next(onAuth(firebaseUser));
    });
    return unsubscribe;
  });

  const streams: Array<any> = [onAuth$];

  return action$.pipe(
    ofType('APP_STARTED'),
    switchMap(() => merge(...streams)),
    catchError((error) => of(appError({ error, feature: 'App appStartedEpic' }))),
  );
};
