import { deleteField } from 'firebase/firestore';
import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models/source/models/Suppliers';
import { appError } from 'lib/app/actions';
import { EnquiriesActionTypes, IDeleteScheduledFollowUp } from 'lib/enquiries/action-types';
import { updateEnquiryScheduledFollowUp } from 'lib/enquiries/actions';
import { getEnquiry } from 'lib/enquiries/selectors';
import { scheduledFollowUpDeletedAnalytics } from 'lib/follow-up-messages/analytics/actions';
import { getFollowUpMessages } from 'lib/follow-up-messages/selectors';
import { IEpicDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';
import { withSupplierId } from 'lib/utils/operators/with-supplier-id';

export const deleteScheduledFollowUp = (
  action$: Observable<IDeleteScheduledFollowUp>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(EnquiriesActionTypes.DELETE_SCHEDULED_FOLLOW_UP),
    withSupplierId(state$),
    withLatestFrom(state$),
    mergeMap(([[supplierId], state]) => {
      const getPromise = async () => {
        const enquiry = getEnquiry(state);
        const supplierWeddingId = enquiry?.id;
        const followUpMessageId = enquiry?.followUps?.[0];
        // Needed for analytics
        const followUpMessage = getFollowUpMessages(state).find(
          (followUpMessage) => followUpMessage.id === followUpMessageId,
        ) || {
          id: '',
          title: '',
          content: '',
          delay: { days: 0, businessDaysOnly: false },
        };

        if (!supplierWeddingId) {
          throw Error('No enquiry found to delete scheduled follow-up');
        }

        const supplierRef = Suppliers._.getById(supplierId);

        // Delete scheduled follow-up from enquiry
        await supplierRef.Weddings.getById(supplierWeddingId).update({
          followUpAt: deleteField(),
          followUps: deleteField(),
        });

        return { followUpMessage, supplierWeddingId };
      };

      return from(getPromise()).pipe(
        mergeMap(({ followUpMessage, supplierWeddingId }) => [
          // optimistic update
          updateEnquiryScheduledFollowUp({
            followUpAt: undefined,
            followUps: undefined,
          }),
          scheduledFollowUpDeletedAnalytics({
            followUpMessage,
            cancelledReason: 'cancelledByUser',
            supplierWeddingId,
          }),
          toggleSnackbar(
            'success',
            getI18n().t('enquiryManager:scheduledFollowUp.deletionSuccess'),
          ),
        ]),
        catchError((error) => [
          appError({ error, feature: 'Schedule follow-up' }),
          toggleSnackbar('alert', getI18n().t('enquiryManager:scheduledFollowUp.deletionError')),
        ]),
      );
    }),
  );
