import { ValidationError } from '@bridebook/toolbox/src';
import { FirebaseErrorCodes } from 'lib/auth/types';
import getMessages from '../messages';

export type ErrorCodes = 'auth/no-permissions' | 'auth/existing-supplier' | 'auth/deactivated';

/**
 * Given firebase error code, return new error object with custom error message and
 * "prop" property matching input field
 * @method mapFBErrorToValidationError
 * @param {string} firebase error code
 * @returns {ValidationError}
 */
const mapFBErrorToValidationError = (code: FirebaseErrorCodes | ErrorCodes) => {
  const codesThatMapToEmailFieldFieldMapping: Array<FirebaseErrorCodes | ErrorCodes> = [
    'auth/email-already-in-use',
    'auth/invalid-email',
    'auth/user-not-found',
  ];

  const prop = codesThatMapToEmailFieldFieldMapping.includes(code) ? 'email' : 'password';

  const msg =
    getMessages()[code] && getMessages()[code].defaultMessage
      ? getMessages()[code].defaultMessage
      : code;
  return new ValidationError(msg, prop, code);
};

export default mapFBErrorToValidationError;
