import produce from 'immer';
import { Action } from 'redux';
import { GroupActionTypes, IFetchGroupConnectionSuccessAction } from './action-types';
import { IGroupConnection } from './types';

export interface IGroupConnectionsState {
  list: IGroupConnection[];
}

const initialState: IGroupConnectionsState = {
  list: [],
};

const reducers: any = (draft: IGroupConnectionsState) => ({
  [GroupActionTypes.FETCH_GROUP_CONNECTIONS_SUCCESS]: (
    action: IFetchGroupConnectionSuccessAction,
  ) => {
    draft.list = action.payload;
  },
});

// This is a wrapper function which runs a proper reducer from the object above.
const reducer = (
  state: IGroupConnectionsState = initialState,
  action: Action,
): IGroupConnectionsState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
