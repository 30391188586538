import { ISupplierAdminScore } from '@bridebook/models/source/models/Suppliers/Admin/score.types';
import { StatisticsActionTypes } from '../action-types';

export const fetchSupplierScore = () => ({
  type: StatisticsActionTypes.FETCH_SUPPLIER_SCORE,
});

export const fetchSupplierScoreSuccess = (payload?: ISupplierAdminScore) => ({
  type: StatisticsActionTypes.FETCH_SUPPLIER_SCORE_SUCCESS,
  payload,
});

export const fetchSupplierScoreError = () => ({
  type: StatisticsActionTypes.FETCH_SUPPLIER_SCORE_ERROR,
});
