import { Endorsement as EndorsementType } from './types';

const Endorsement: EndorsementType = {
  id: null,
  from: '',
  date: '',
  title: '',
  message: '',
  link: '',
  photo: null,
  createdAt: null,
  name: '',
};

export default Endorsement;
