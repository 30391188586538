import { serverTimestamp } from 'firebase/firestore';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mapTo, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import uuid from 'uuid-random';
import { Suppliers } from '@bridebook/models';
import { FailedAction } from 'lib/analytics/failed-action';
import { FailedReason } from 'lib/analytics/failed-reason';
import { appError } from '../../app/actions';
import { IEpicDeps } from '../../types';
import { AddRecommendationAction, RecommendationsActions } from '../action-types';
import { addRecommendationDone } from '../actions/add-recommendation';

const addRecommendationEpic = (
  action$: Observable<AddRecommendationAction>,
  { state$, bbanalytics }: IEpicDeps,
) =>
  action$.pipe(
    ofType(RecommendationsActions.ADD_RECOMMENDATION),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { recommendation },
        },
        state,
      ]) => {
        if (!recommendation) {
          return of();
        }

        const activeSupplierId = state.users.activeSupplierAccessControl?.id;
        if (!activeSupplierId) return of();

        const supplierId = recommendation.id;
        const supplierRef = Suppliers._.getById(activeSupplierId);
        const highestOrder = Math.max(...state.recommendations.list.map((r) => r.order));
        const order = highestOrder + 1;
        const id = uuid();
        const newRecommendation = {
          id,
          order,
          supplierId,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        };

        const getPromise = () =>
          Promise.all([
            supplierRef.get(),
            supplierRef.Recommendations.push(id).create(newRecommendation),
          ]);

        return from(getPromise()).pipe(
          tap(() =>
            bbanalytics.supplier.supplierNetwork.added({
              recommendedSupplierId: supplierId,
              recommendedSupplierCategory: recommendation.type,
            }),
          ),
          mapTo(addRecommendationDone()),
          catchError((error) => [
            appError({ error, feature: 'Recommendations' }),
            bbanalytics.error({
              failedAction: FailedAction.AddRecommendation,
              failedReason: FailedReason.FailedToAddRecommendation,
            }),
          ]),
        );
      },
    ),
  );

export default addRecommendationEpic;
