import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { IPhoto } from '@bridebook/models/source/models/Suppliers/Photos.types';
import { RecommendationsActions } from '../action-types';

export interface IRecommendationRender extends ISupplier {
  photos: IPhoto[];
}

export const updateRecommendationsDone = (suppliers: IRecommendationRender[]) => ({
  type: RecommendationsActions.UPDATE_RECOMMENDATIONS_DONE,
  payload: { suppliers },
});
