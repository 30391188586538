import produce from 'immer';
import camelCase from 'lodash/camelCase';
import {
  assocPath,
  equals,
  isNil,
  lensPath,
  mergeDeepRight,
  path as objectPath,
  set,
  union,
  view,
  without,
} from 'ramda';
import { PartialRecursive } from '@bridebook/models/source/abstract/_';
import type { ISupplierSpecialOffers } from '@bridebook/models/source/models/RTDB/Suppliers.types';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { ValidationError } from '@bridebook/toolbox';
import { AccessControlActionTypes } from 'lib/access-control/action-types';
import { ICollaborator } from 'lib/access-control/types';
import { AchievementsActionTypes } from 'lib/achievements/action-types';
import { AdminActionTypes } from 'lib/admin/action-types';
import { updateTempContentTranslations } from 'lib/content-translations/slice';
import { YourCouplesActionTypes } from 'lib/couples/action-types';
import { PhotosActionTypes } from 'lib/photos/action-types';
import { QuestionsActionTypes } from 'lib/questions/action-types';
import { SettingsActions } from 'lib/settings/action-types';
import {
  ISaveFormSectionSuccess,
  IUpdateFormSectionCheckboxGroup,
  IUpdateFormSectionField,
  IUpdateSupplierAction,
  IUpdateSupplierDataAction,
  type SetFormSectionUnsavedAction,
  SupplierActionTypes,
} from 'lib/supplier/action-types';
import { FormSections, ICountriesSuppliers, NoneOptions } from 'lib/supplier/types';
import { getAllFormSectionsFields } from 'lib/supplier/utils/get-all-form-sections-fields';
import { Action, IReducersImmer } from 'lib/types';
import { ISaveFormError } from './action-types';

interface ISupplierCollaborators {
  collaborators: ICollaborator[];
  supplierId: ISupplier['id'];
  supplierName: ISupplier['name'];
  supplierType: string;
}

export interface SupplierState {
  // Supplier retrieved from database. Changes from the supplier forms are applied to this object.
  supplier: ISupplier | null;
  // Used to preserve the original supplier data when editing the form but not yet saved
  supplierUnmodified: ISupplier | null;
  // Subset of only updated fields that will be saved
  formUpdates: PartialRecursive<ISupplier> | null;
  // Set of dirty fields per supplier form section
  formSectionsUpdates: Record<Partial<FormSections>, PartialRecursive<ISupplier>> | null;
  error: ValidationError | Error | null;
  galleryChangesUnsaved: boolean;
  loaded: boolean;
  specialOffers: ISupplierSpecialOffers;
  collaborators: ISupplierCollaborators[];
  countries: ICountriesSuppliers | null;
  collaboratorsLoaded: boolean | null;
  inviteMessage: string | null;
}

const initialState: SupplierState = {
  supplier: null,
  supplierUnmodified: null,
  formUpdates: null,
  formSectionsUpdates: null,
  error: null,
  galleryChangesUnsaved: false,
  loaded: false,
  specialOffers: {},
  collaborators: [],
  collaboratorsLoaded: null,
  countries: null,
  inviteMessage: null,
};

const resetState = () => initialState;

const reducers: IReducersImmer<SupplierState> = (draft: SupplierState, state?: SupplierState) => ({
  [AccessControlActionTypes.SWITCH_SUPPLIER]: () => {
    draft.loaded = false;
    draft.supplier = null;
    draft.supplierUnmodified = null;
    draft.formUpdates = null;
  },

  [AccessControlActionTypes.SWITCH_SUPPLIER]: resetState,

  ['RESET_SUPPLIER_LOADED']: () => {
    draft.loaded = false;
  },

  [SupplierActionTypes.FETCH_COUNTRIES_SUCCESS]: (action) => {
    draft.countries = action.payload || null;
  },

  [AccessControlActionTypes.FETCH_COLLABORATORS_DATA_SUCCESS]: (action) => {
    draft.collaborators = action.payload;
    draft.collaboratorsLoaded = true;
  },

  ['SAVE_SUPPLIER_SUCCESS']: () => {
    draft.formSectionsUpdates = null;
    draft.error = null;
  },

  [SettingsActions.UPDATE_USER_ROLE_SUCCESS]: (action) => {
    draft.collaborators = action.payload;
    draft.collaboratorsLoaded = true;
  },

  [SettingsActions.START_UPDATE_USER_ROLE]: () => {
    draft.collaboratorsLoaded = false;
  },

  [YourCouplesActionTypes.FETCH_INVITE_MESSAGE_SUCCESS]: (action) => {
    draft.inviteMessage = action.payload;
  },

  [YourCouplesActionTypes.RESET_INVITE_MESSAGE]: () => {
    draft.inviteMessage = null;
  },

  ['SAVE_SUPPLIER_ERROR']: (action) => {
    draft.error = action.payload;
  },

  ['SUPPLIER_BROCHURES_ERROR']: (action) => {
    draft.error = action.payload;
  },

  ['ADD_FIELD_TO_LIST']: (action) => {
    const listName = action.payload;
    const updateList = (list) => {
      const newEntry: Record<string, string> = {};
      newEntry['name'] = '';
      newEntry['url'] = '';
      return [...list, newEntry];
    };
    const list = state.supplier[listName];
    draft.supplier[listName] = updateList(list);
  },

  // BELOW IS FIRESTORE RELATED CODE

  [SupplierActionTypes.UPDATE_SUPPLIER]: (action: IUpdateSupplierAction) => {
    const { payload } = action;
    const formSectionsUpdates = state?.formSectionsUpdates;
    const formUpdates = getAllFormSectionsFields(formSectionsUpdates);

    // Add dirty fields of unsaved sections to supplier
    draft.supplier = mergeDeepRight(payload, formUpdates);
    draft.supplierUnmodified = payload;
    draft.loaded = true;
  },

  [SupplierActionTypes.UPDATE_SUPPLIER_DATA]: (action) => {
    const { name, value } = action.payload as IUpdateSupplierDataAction['payload'];
    const path = name?.split('.') || [];
    // TODO: switch assocPath to lens
    // @ts-expect-error Type issues with `assocPath` resolving to `ISupplier`
    draft.supplier = assocPath<ISupplier, any>([...path], value, draft.supplier);
    // @ts-expect-error Type issues with `assocPath` resolving to `formUpdates`
    draft.formUpdates = assocPath<ISupplier, any>([...path], value, draft.formUpdates);
    draft.error = null;
  },

  [SupplierActionTypes.DISCARD_FORM_CHANGES]: () => {
    draft.formUpdates = null;
    draft.formSectionsUpdates = null;
    draft.supplier = draft.supplierUnmodified;
    draft.galleryChangesUnsaved = false;
  },

  [updateTempContentTranslations.type]: ({ payload }) => {
    // Some forms require `unsaved` value to be true in order to enable Save button.
    if (['supplier', 'packages'].includes(payload.scope)) return;
    draft.error = null;
  },

  // For new FAQ compatibility with FormCore.tsx
  [QuestionsActionTypes.SET_QUESTION_ANSWER]: () => {
    // marking that this section is being edited by adding formSection key
    // dirty fields are handled in questions reducer
    draft.formSectionsUpdates = assocPath([FormSections.Faq], {}, draft.formSectionsUpdates);
    draft.error = null;
  },

  // For new Achievements compatibility with FormCore.tsx
  [AchievementsActionTypes.SET_ACHIEVEMENT_FIELD]: () => {
    // marking that this section is being edited by adding formSection key
    // dirty fields are handled in achievements reducer except for achievementsNotApplicable
    draft.formSectionsUpdates = assocPath(
      [FormSections.SelfPromotion],
      state?.formSectionsUpdates?.[FormSections.SelfPromotion] || {},
      draft.formSectionsUpdates,
    );
    draft.error = null;
  },

  [PhotosActionTypes.SET_GALLERY_UNSAVED]: (action: { payload: boolean }) => {
    draft.galleryChangesUnsaved = action.payload;
  },

  [AdminActionTypes.UNLINK_USER_SUCCESS]: (action: { payload: string }) => {
    if (draft.collaborators && draft.collaborators.length > 0) {
      draft.collaborators[0] = {
        ...draft.collaborators[0],
        collaborators: draft.collaborators[0].collaborators.filter(
          (collaborator) => collaborator.id !== action.payload,
        ),
      };
    }
  },

  [SupplierActionTypes.SET_FORM_SECTION_UNSAVED]: (action: SetFormSectionUnsavedAction) => {
    const { formSection } = action.payload;
    draft.formSectionsUpdates = assocPath([formSection], {}, draft.formSectionsUpdates);
  },

  // Form sections
  // mimicking UPDATE_SUPPLIER_DATA but applied per formSection
  // cannot delete UPDATE_SUPPLIER_DATA yet since it's used in Pricing form page
  [SupplierActionTypes.UPDATE_FORM_SECTION_FIELD]: (action) => {
    const { name, value, formSection } = action.payload as IUpdateFormSectionField['payload'];
    const path = name?.split('.') || [];

    const supplierFieldValue = objectPath(path, draft.supplier);

    if (!equals(value, supplierFieldValue)) {
      // updating supplier since field value reads from this object
      draft.supplier = isNil(name) ? draft.supplier : assocPath([...path], value, draft.supplier);
      // adding dirty field to form section
      draft.formSectionsUpdates = assocPath(
        [formSection, ...path],
        value,
        draft.formSectionsUpdates,
      );
      draft.error = null;
    }
  },

  // mimicking UPDATE_SUPPLIER_CHECKBOX_GROUP but applied per formSection
  // cannot delete UPDATE_SUPPLIER_CHECKBOX_GROUP yet since it's used in Pricing form page
  [SupplierActionTypes.UPDATE_FORM_SECTION_CHECKBOX_GROUP]: (
    action: IUpdateFormSectionCheckboxGroup,
  ) => {
    const { name, value, formSection } = action.payload;
    const path = name.split('.');
    // get option name
    const prop = path.pop();
    const isNotApplicableSelected = camelCase(prop) === NoneOptions.NotApplicable && value;

    // path without option name
    const lens = lensPath(path);
    const lensFormSection = lensPath([formSection, ...path]);
    // current selected options
    const current = view(lens, state?.supplier) as string[];
    const addRemoveItem = value ? union(current, [prop]) : without([prop], current);
    const appliedChange = isNotApplicableSelected ? [prop] : addRemoveItem;

    // updating supplier since field value reads from this object
    draft.supplier = set(lens, appliedChange, state?.supplier) || null;
    // adding dirty field to form section
    draft.formSectionsUpdates =
      set(lensFormSection, appliedChange, state?.formSectionsUpdates) || null;
    draft.error = null;
  },

  [SupplierActionTypes.SAVE_FORM_SECTION_SUCCESS]: (action: ISaveFormSectionSuccess) => {
    delete draft.formSectionsUpdates?.[action.payload];
    draft.error = null;
  },

  [SupplierActionTypes.SAVE_FORM_ERROR]: (action: ISaveFormError) => {
    draft.error = action.payload;
  },
});

const reducer = (state: SupplierState = initialState, action: Action): SupplierState => {
  try {
    return produce(state, (draft) => reducers(draft, state)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
