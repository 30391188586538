import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { Packages } from '@bridebook/models/source/models/Suppliers/Packages';
import { PackagesSections } from '@bridebook/toolbox/src/map-packages-to-sections';
import { getContentTranslationsForPackages } from 'lib/content-translations/selectors';
import { clearTempContentTranslations } from 'lib/content-translations/slice';
import { savePackageSectionSuccess } from 'lib/pricing/actions';
import { packagesDefaults } from 'lib/pricing/constants';
import { IEpicDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';
import { appError } from '../../app/actions';
import { PricingActionTypes, SavePackageSectionAction } from '../action-types';

export const savePackageSectionEpic = (
  action$: Observable<SavePackageSectionAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(PricingActionTypes.SAVE_PACKAGE_SECTION),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { packageSection },
        },
        state,
      ]) => {
        const {
          pricing: { packagesSections },
        } = state;

        const translationsToUpdate = getContentTranslationsForPackages(state);
        const activeSupplier = state.users.activeSupplierAccessControl;
        if (!activeSupplier?.id) return of();

        const getPromise = async () => {
          const supplierRef = Suppliers._.getById(activeSupplier.id);
          const data = packagesSections[packageSection];
          const _translations = translationsToUpdate.find((p) => p.id === data.id)?._translations;

          const updateObject = {
            ...data,
            ...(_translations && { _translations }),
          };

          // If the package has an id, we update it
          if (updateObject.id) {
            return supplierRef.Packages.getById(updateObject.id).set(updateObject);
          }
          // If it's a new package, we need to set default values
          else {
            const defaults = packagesDefaults[packageSection as PackagesSections];
            return supplierRef.Packages.push().create(
              Packages.new('_', { ...defaults, ...updateObject }),
            );
          }
        };

        return from(getPromise()).pipe(
          mergeMap(() => [
            toggleSnackbar('success', getI18n().t('common:savedChanges.success')),
            savePackageSectionSuccess(packageSection),
          ]),
          catchError((error) =>
            of(
              toggleSnackbar('alert', getI18n().t('common:savedChanges.error')),
              { type: PricingActionTypes.SAVE_PACKAGE_SECTION_ERROR },
              clearTempContentTranslations(),
              appError({ error, feature: 'Pricing' }),
            ),
          ),
        );
      },
    ),
  );
