import { WithFieldValue, serverTimestamp } from 'firebase/firestore';
import { omit } from 'ramda';
import { getI18n } from 'react-i18next';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import uuid from 'uuid-random';
import { Suppliers } from '@bridebook/models';
import { Packages } from '@bridebook/models/source/models/Suppliers/Packages';
import { IPackage } from '@bridebook/models/source/models/Suppliers/Packages.types';
import { appError } from 'lib/app/actions';
import { IEpicDeps } from 'lib/types';
import { toggleSnackbar } from 'lib/ui/actions';
import { ISaveSupplierPackageAction, PricingActionTypes } from '../action-types';

export const saveSupplierPackageEpic = (
  action$: Observable<ISaveSupplierPackageAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(PricingActionTypes.SAVE_SUPPLIER_PACKAGE),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const supplierId = state.supplier.supplier?.id;

      if (!supplierId) return of();

      const getPromise = async () => {
        const supplierRef = Suppliers._.getById(supplierId);
        const { name, description, price, included, order } = payload;

        const pricingPackage: WithFieldValue<IPackage> = Packages.new('_', {
          name,
          description,
          price,
          included,
          updatedAt: serverTimestamp(),
          order,
        });

        if (payload.id) {
          pricingPackage.id = payload.id;
          const supplierPackage = {
            ...pricingPackage,
            id: payload.id,
          };

          await supplierRef.Packages.getById(payload.id).set(omit(['createdAt'], supplierPackage));
          return { supplierPackage };
        } else {
          const id = uuid();
          const supplierPackage = {
            ...pricingPackage,
            id,
            createdAt: serverTimestamp(),
          };

          await supplierRef.Packages.push(id).create(supplierPackage);
          return { supplierPackage };
        }
      };

      return from(getPromise()).pipe(
        mergeMap(() => of(toggleSnackbar('success', getI18n().t('pricing:successPackageSave')))),
        catchError((error) => [appError({ error, feature: 'Saving supplier packages' })]),
      );
    }),
  );
