import { ITemplate } from '@bridebook/models/source/models/Suppliers/Templates.types';
import { MessageTemplatesAction } from 'lib/message-templates/action-types';
import { MessageTemplate } from 'lib/message-templates/types';

export const saveMessageTemplate = (payload: MessageTemplate) => ({
  type: MessageTemplatesAction.SaveTemplate,
  payload,
});

export const templateListSaved = (payload: ITemplate[]) => ({
  type: MessageTemplatesAction.TemplateListSaved,
  payload,
});

export const deleteMessageTemplate = (payload: MessageTemplate) => ({
  type: MessageTemplatesAction.DeleteTemplate,
  payload,
});

export const messageTemplateSaved = { type: MessageTemplatesAction.TemplateSaved };
export const saveMessageTemplateError = { type: MessageTemplatesAction.SaveTemplateError };
export const initTemplatesListener = { type: MessageTemplatesAction.InitTemplatesListener };
export const messageTemplateDeleted = { type: MessageTemplatesAction.TemplateDeleted };
