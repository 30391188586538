import { authenticatedPOST } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import type { RequestPayload, ResponsePayload } from 'pages/api/nonce/invite/create';
import { submitLinkedUserAccountSuccessAnalytics } from 'lib/settings/analytics/actions/analytics';
import type { IFormFields } from 'lib/settings/types';
import type { IDeps } from 'lib/types';
import { resetLinkSuccessMessage, toggleInviteCollabPopup } from '../../actions';
import checkIfEmailExistsInPerms from './checkIfEmailExistsInPerms';
import validateLinkedAccount from './validateLinkedAccount';

const host =
  process.env.NODE_ENV === 'production'
    ? 'https://business.bridebook.com'
    : 'https://cms-suppliers-dev.herokuapp.com';

const generateInviteUrl = (id: string, nonce: string) => `${host}/?id=${id}&nonce=${nonce}`;

const submitLinkedAccount = async (
  { getState, validate, dispatch }: IDeps,
  formFields: IFormFields,
) => {
  const {
    supplier: { collaborators },
  } = getState();

  const { supplierName, supplierId } = formFields;

  const activeSupplier = getState().users.activeSupplierAccessControl;

  if (!activeSupplier) return {};

  const { id: activeSupplierID, name: activeSupplierName } = activeSupplier;

  const name = supplierName || activeSupplierName;
  const id = supplierId || activeSupplierID;

  await validateLinkedAccount(validate, formFields).promise;
  checkIfEmailExistsInPerms(collaborators, formFields.email);

  const response = await authenticatedPOST<RequestPayload, ResponsePayload>(
    `/api/nonce/invite/create`,
    {
      body: {
        role: formFields.permissionLevel,
        supplierId: id,
      },
    },
    'Failed to fetch invite',
  );

  const { nonceId, nonceSecret } = response;

  const invite = generateInviteUrl(nonceId, nonceSecret);

  dispatch(toggleInviteCollabPopup());
  setTimeout(() => dispatch(resetLinkSuccessMessage()), 3000);
  dispatch(submitLinkedUserAccountSuccessAnalytics({ invite, formFields }));

  return {
    role: formFields.permissionLevel,
    email: formFields.email,
    supplierName: name,
  };
};

export default submitLinkedAccount;
