import { updateConciergeOptOut, updateTargeting } from 'lib/targeting/actions';

export enum TargetingActionTypes {
  UPDATE_TARGETING = '[Targeting] Update supplier targeting',
  UPDATE_TARGETING_SUCCESS = '[Targeting] Update supplier targeting success',
  UPDATE_CONCIERGE_OPT_OUT = '[Targeting] Update concierge opt out',
}

export type UpdateTargetingAction = ReturnType<typeof updateTargeting>;
export type UpdateConciergeOptOutAction = ReturnType<typeof updateConciergeOptOut>;
