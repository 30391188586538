import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Countries } from '@bridebook/models';
import { AdminActionTypes } from 'lib/admin/action-types';
import { isSameSupplier } from 'lib/app/utils';
import { marketingBannerStorageManager } from 'lib/storage-manager/basic/marketing-banner-storage';
import { getIsVenue, getSupplierCountryCode, getSupplierTier } from 'lib/supplier/selectors';
import { IEpic } from 'lib/types';
import { setMarketingBanner } from 'lib/ui/actions';

export const fetchMarketingBannerEpic: IEpic = (action$, { state$ }) =>
  action$.pipe(
    ofType(AdminActionTypes.ON_SUPPLIER_ADMIN_FETCH),
    withLatestFrom(state$),
    distinctUntilChanged(isSameSupplier),
    mergeMap(([, state]) => {
      const countryCode = getSupplierCountryCode(state);
      const isVenue = getIsVenue(state);
      const supplierTier = getSupplierTier(state);

      const getPromise = async () => {
        const { cmsBanners } = await Countries._.getById(countryCode).get();
        if (!cmsBanners) return null;
        const category = isVenue ? 'venues' : 'suppliers';

        // Get the banner content for the current supplier type and tier, with a fallback to 'all' if not found
        const data =
          cmsBanners[category]?.[supplierTier] ||
          cmsBanners[category]?.['all'] ||
          cmsBanners['all']?.[supplierTier] ||
          cmsBanners['all']?.['all'];

        // Do not show the banner if it's already been shown
        const bannerIds = marketingBannerStorageManager.get() || {};
        if (!data || Object.keys(bannerIds).includes(data.id)) return null;

        return data;
      };

      return from(getPromise()).pipe(map(setMarketingBanner));
    }),
  );

fetchMarketingBannerEpic.epicName = 'fetchMarketingBannerEpic';
