import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { getUrlCoupleSideLocale } from '@bridebook/toolbox/src/getSupplierUrl';
import { mapSlugToFriendlyUrlSlug } from '@bridebook/toolbox/src/mapSlugToFriendlyUrlSlug';
import type { Slug } from '@bridebook/toolbox/src/types';
import { env } from 'lib/env';

const buildSupplierUrl = ({
  id,
  path,
  slug,
  url,
  country,
  isPreview,
}: {
  id: string;
  path?: string;
  country?: CountryCodes;
  isPreview?: boolean;
  slug?: Slug;
  url?: string;
}): string => {
  const locale = getUrlCoupleSideLocale(country);

  return slug
    ? `${env.COUPLESIDE_URL}/${locale}/wedding-${
        mapSlugToFriendlyUrlSlug(slug).newSlug
      }/${url}-${id}${isPreview ? '?supplier=true' : ''}`
    : `${env.COUPLESIDE_URL}/${locale}${path}${isPreview ? '?supplier=true' : ''}`;
};

export default buildSupplierUrl;
