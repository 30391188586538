import { ContentTranslationsScope } from 'lib/content-translations/types';
import { OffersActionTypes } from 'lib/offers/action-types';
import { saveSpecialOfferAnalytics } from 'lib/offers/analytics/actions';
import { SupplierSpecialOffer } from 'lib/offers/types';
import { IDeps } from 'lib/types';

export const saveSpecialOffer = (fromProfile = false, scope: ContentTranslationsScope) => ({
  type: OffersActionTypes.SAVE_SPECIAL_OFFER,
  payload: { fromProfile, scope },
});

export const saveSpecialOfferSuccess =
  (previousOffer: SupplierSpecialOffer | null, track: boolean = true) =>
  ({ dispatch }: IDeps) => {
    if (track) {
      dispatch(saveSpecialOfferAnalytics(previousOffer));
    }

    return {
      type: OffersActionTypes.SAVE_SPECIAL_OFFER_SUCCESS,
    };
  };

interface ISaveSupplierDiscount {
  discount: string;
  expiryDate?: number;
  otherDiscount?: string;
  fromProfile?: boolean;
}

export const saveSupplierDiscount = (payload: ISaveSupplierDiscount) => ({
  type: OffersActionTypes.SAVE_SUPPLIER_DISCOUNT,
  payload,
});
