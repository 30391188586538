import { IPhoto } from '@bridebook/models/source/models/Suppliers/Photos.types';
import { extractSupplier, getSupplierUrl, getUrlCoupleSideLocale } from '@bridebook/toolbox';
import { env } from 'lib/env';
import { getPhotos } from 'lib/photos/selectors';
import { IApplicationState } from 'lib/types';
import { buildImgixUrl } from 'lib/utils';

export const supplierListingPropertiesGeneric = (state: IApplicationState) => {
  const { supplier } = state.supplier;
  if (!supplier) return {};

  const {
    website,
    type,
    email,
    address,
    town,
    county,
    country,
    countryCode,
    postcode,
    phone,
    venueType,
  } = extractSupplier(supplier);

  const sortedPhotos = getPhotos(state);
  const photosCount = state.supplier.supplier?.counters?.photos || 0;
  const supplierLocale = getUrlCoupleSideLocale(countryCode);
  const supplierProfileURL = `${env.COUPLESIDE_URL}/${supplierLocale}${getSupplierUrl(supplier)}`;

  const getThumbnail = (photos?: IPhoto[], photosCount?: number) => {
    if (!photos || !photosCount) return '';
    return buildImgixUrl(photos[0]);
  };

  return {
    supplierAddress: address,
    supplierContactEmail: email,
    supplierCountry: country,
    supplierCounty: county,
    supplierPhone: phone,
    supplierPostcode: postcode,
    supplierProfileURL,
    supplierThumbnailURL: getThumbnail(sortedPhotos, photosCount),
    supplierTown: town,
    supplierWebsite: website,
    venueType: type === 'venue' && venueType?.length ? venueType[0] : undefined,
  };
};
