import { getI18n } from 'react-i18next';

export const getValidationMessages = () => {
  const i18n = getI18n();
  return {
    invalid: {
      email: i18n.t('common:validation.invalid.email'),
      password: (minLength: number) => i18n.t('common:validation.invalid.password', { minLength }),
      url: i18n.t('common:validation.invalid.url'),
      ukPhone: i18n.t('common:validation.invalid.ukPhone'),
      postcode: i18n.t('common:validation.invalid.postcode'),
      video: i18n.t('common:validation.invalid.video'),
      coordinate: (prop: string) =>
        i18n.t('common.validation.invalid.coordinate', {
          prop,
        }),
    },
    required: {
      email: i18n.t('common:validation.required.email'),
      password: i18n.t('common:validation.required.password'),
      firstName: i18n.t('common:validation.required.firstName'),
      lastName: i18n.t('common:validation.required.lastName'),
      name: i18n.t('common:validation.required.name'),
      website: i18n.t('common:validation.required.website'),
      postcode: i18n.t('common:validation.required.postcode'),
      phone: i18n.t('common:validation.required.phone'),
      address: i18n.t('common:validation.required.address'),
      town: i18n.t('common:validation.required.town'),
      date: i18n.t('common:validation.required.date'),
      title: i18n.t('common:validation.required.title'),
      from: i18n.t('common:validation.required.from'),
      county: i18n.t('common:validation.required.county'),
      country: i18n.t('common:validation.required.country'),
      permissionLevel: i18n.t('common:validation.required.permissionLevel'),
    },
  };
};
