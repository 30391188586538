import { addAchievementDraft, initAchievementsListener, setAchievementField } from './actions';
import { onFirestoreAchievements } from './actions/misc';

export enum AchievementsActionTypes {
  ADD_ACHIEVEMENT_DRAFT = '[Achievements] Add achievement draft',
  INIT_ACHIEVEMENTS_LISTENER = '[Achievements] Init Achievements listener',
  ON_FIRESTORE_ACHIEVEMENTS = '[Achievements] On Firestore Achievements',
  SET_ACHIEVEMENT_FIELD = '[Achievements] Set achievement field',
  STOP_ACHIEVEMENTS_LISTENER = '[Achievements] Stop Achievements listener',
}

export type AddAchievementDraftAction = ReturnType<typeof addAchievementDraft>;
export type OnFirestoreAchievementsAction = ReturnType<typeof onFirestoreAchievements>;
export type SetAchievementFieldAction = ReturnType<typeof setAchievementField>;
export type IInitAchievementsListenerAction = ReturnType<typeof initAchievementsListener>;
