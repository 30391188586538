import produce from 'immer';
import { reject } from 'ramda';
import { IPhoto } from '@bridebook/models/source/models/Suppliers/Photos.types';
import { FetchPhotosSuccessAction, PhotosActionTypes } from 'lib/photos/action-types';
import { Action, IReducersImmer } from '../types';
import { Photo } from './types';

export interface PhotosState {
  list: Photo[];
  photos: Record<string, IPhoto>; // Firestore photos
}

const initialState: PhotosState = {
  list: [],
  photos: {},
};

const reducers: IReducersImmer<PhotosState> = (draft: PhotosState) => ({
  [PhotosActionTypes.FETCH_PHOTOS_SUCCESS]: (action: FetchPhotosSuccessAction) => {
    draft.photos = reject((photo) => !photo.path, action.payload);
  },
});

const reducer = (state: PhotosState = initialState, action: Action): PhotosState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
