import { colors } from '@bridebook/ui';
import { getSupplierScoreCategoryLabel } from 'lib/statistics/utils';

const ProfileScore = {
  chart: {
    type: 'solidgauge',
    width: 190,
    height: 230,
  },

  title: {
    text: null,
  },

  tooltip: {
    enabled: false,
  },

  pane: {
    startAngle: 0,
    endAngle: 360,
    background: [
      {
        outerRadius: '112%',
        innerRadius: '88%',
        backgroundColor: colors.space08,
        borderWidth: 0,
      },
    ],
  },

  yAxis: {
    min: 0,
    max: 1,
    lineWidth: 0,
    tickPositions: [],
  },

  plotOptions: {
    solidgauge: {
      dataLabels: {
        enabled: true,
        borderWidth: 0,
        color: colors.space,
        verticalAlign: 'middle',
        y: 0,
        style: {
          fontDefault: 16,
        },
        formatter: function () {
          return getSupplierScoreCategoryLabel(this.y);
        },
      },
      linecap: 'round',
      stickyTracking: false,
      rounded: false,
    },
  },

  series: [
    {
      name: '',
      data: [],
    },
  ],
  credits: { enabled: false },
  loaded: false,
};

export default ProfileScore;
