import { WithFieldValue } from 'firebase/firestore';
import { IFair } from '@bridebook/models/source/models/Suppliers/Fairs.types';
import { RecommendationsAnalyticsTypes } from './action-types';

const saveWeddingFairSuccessAnalytics = (fair: WithFieldValue<IFair>, isEdit: boolean) => ({
  type: isEdit
    ? RecommendationsAnalyticsTypes.EDITED_WEDDING_FAIR_ANALYTICS
    : RecommendationsAnalyticsTypes.ADDED_WEDDING_FAIR_ANALYTICS,
  payload: { fair },
});

export default saveWeddingFairSuccessAnalytics;
