import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import { env } from 'lib/env';
import { IEpicDeps } from '../../types';
import { StatisticsActionTypes } from '../action-types';
import { fetchCompetitorsSuccess } from '../actions';

const fetchCompetitorsEpic = (action$: Observable<any>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(StatisticsActionTypes.FETCH_COMPETITORS),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const supplierId = state.supplier.supplier?.id!;
      const promise = fetch(
        `https://api.bridebook.com/statistics-${
          env.LIVE ? 'production' : 'dev'
        }/competitor_list_shortlists/${supplierId}`,
      ).then((d) => d.json() as Promise<Record<string, number>>);

      return from(promise).pipe(
        mergeMap((competitors) => of(fetchCompetitorsSuccess(competitors))),
        catchError((error) => of(appError({ error, feature: 'fetchCompetitorsEpic' }))),
      );
    }),
  );

export default fetchCompetitorsEpic;
