export enum FailedAction {
  AddBrochure = 'Add brochure',
  AddPhoto = 'Add photo',
  AddRecommendation = 'Add recommendation',
  AddTestimonial = 'Add testimonial',
  AddVideo = 'Add video',
  AddWeddingFair = 'Add wedding fair',
  AttachFileToMessageTemplate = 'Attach file to message template',
  CollaborateOnSupplierProfile = 'Collaborate on supplier profile',
  EditTestimonial = 'Edit testimonial',
  EditWeddingFair = 'Edit wedding fair',
  SocialMediaDisconnected = 'Social media disconnected',
  EditUserDetails = 'Edit user details',
  UpdateAuthDetails = 'Update auth details',
}
