import { getI18n } from 'react-i18next';

export interface IEnquiryWidgetSettings {
  email: boolean;
  partnerName: boolean;
  phone: boolean;
  partnerName2: boolean;
  guestsInitialTarget: boolean;
  weddingDate: boolean;
  message: boolean;
}

export type TEnquiryWidgetOption = keyof IEnquiryWidgetSettings;

export const enquiryWidgetSettingsDefault: IEnquiryWidgetSettings = {
  email: true,
  partnerName: true,
  phone: false,
  partnerName2: false,
  guestsInitialTarget: false,
  weddingDate: false,
  message: false,
};

export const getEnquiryWidgetSettingsLabels = () => {
  const i18n = getI18n();
  return {
    email: i18n.t('enquiryWidget:settings.email'),
    partnerName: i18n.t('enquiryWidget:settings.yourName'),
    phone: i18n.t('enquiryWidget:settings.phone'),
    partnerName2: i18n.t('enquiryWidget:settings.partnerName'),
    guestsInitialTarget: i18n.t('enquiryWidget:settings.estimatedGuestNumber'),
    weddingDate: i18n.t('enquiryWidget:settings.idealDate'),
    message: i18n.t('enquiryWidget:settings.message'),
  };
};

export const enquiryWidgetUrlProps: Record<TEnquiryWidgetOption, string> = {
  email: 'email',
  partnerName: 'partnerName',
  phone: 'phone',
  partnerName2: 'partnerName2',
  guestsInitialTarget: 'guestsInitialTarget',
  weddingDate: 'weddingDate',
  message: 'message',
};

export const enquiryWidgetSectionHeights: Record<TEnquiryWidgetOption, number> = {
  email: 60,
  partnerName: 60,
  phone: 60,
  partnerName2: 60,
  guestsInitialTarget: 60,
  weddingDate: 150,
  message: 182,
};
