import { GetListedActionTypes } from 'lib/get-listed/action-types';
import { IGetListedAPIResponse, IGetListedFields } from 'lib/get-listed/types';
import { BridebookError } from 'lib/types';

export const scrollToGetListedFormAnalytics = () => {
  return {
    type: GetListedActionTypes.SCROLL_TO_GET_LISTED_ANALYTICS,
  };
};

export const getListedValidateStep1ErrorAnalytics = ({
  fields,
  error,
}: {
  fields: IGetListedFields;
  error: BridebookError;
}) => ({
  type: GetListedActionTypes.GET_LISTED_VALIDATE_STEP1_ERROR_ANALYTICS,
  payload: { fields, error },
});

export const getListedValidateStep1SuccessAnalytics = (fields: IGetListedFields) => ({
  type: GetListedActionTypes.GET_LISTED_VALIDATE_STEP1_SUCCESS_ANALYTICS,
  payload: { fields },
});

export const getListedSuccessAnalytics = (payload: IGetListedAPIResponse) => ({
  type: GetListedActionTypes.GET_LISTED_SUCCESS_ANALYTICS,
  payload,
});

export const getListedErrorAnalytics = ({
  fields,
  error,
}: {
  fields: IGetListedFields;
  error: BridebookError;
}) => ({
  type: GetListedActionTypes.GET_LISTED_ERROR_ANALYTICS,
  payload: { error, fields },
});
