import deletedWeddingFairAnalytics from './deleted-wedding-fair-analytics';
import failedToSaveFairAnalytics from './failed-to-save-fair-analytics';
import saveWeddingFairSuccessAnalytics from './save-wedding-fair-success-analytics';

export enum RecommendationsAnalyticsTypes {
  ADDED_WEDDING_FAIR_ANALYTICS = '[Fairs] Added wedding fair analytics',
  EDITED_WEDDING_FAIR_ANALYTICS = '[Fairs] Edited wedding fair analytics',
  DELETED_WEDDING_FAIR_ANALYTICS = '[Fairs] Deleted wedding fair analytics',
  FAILED_TO_ADD_WEDDING_FAIR_ANALYTICS = '[Fairs] Failed to add wedding fair analytics',
  FAILED_TO_EDIT_WEDDING_FAIR_ANALYTICS = '[Fairs] Failed to edit wedding fair analytics',
}

export type SaveWeddingFairSuccessAnalyticsType = ReturnType<
  typeof saveWeddingFairSuccessAnalytics
>;
export type DeletedWeddingFairAnalyticsType = ReturnType<typeof deletedWeddingFairAnalytics>;
export type FailedToSaveFairAnalyticsType = ReturnType<typeof failedToSaveFairAnalytics>;
