import type { GeoPoint } from 'firebase/firestore';
import { isNil } from 'ramda';
import { authenticatedPOST } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import {
  IGetCustomAreaRequestBody,
  IGetCustomAreaResponse,
} from '../../../../pages/api/search/get-custom-area';

export default async function getSupplierCustomArea(
  coordinates: GeoPoint | null,
): Promise<string | null> {
  const lon = coordinates?.longitude;
  const lat = coordinates?.latitude;

  if (isNil(lon) || isNil(lat)) return null;

  try {
    const response = await authenticatedPOST<IGetCustomAreaRequestBody, IGetCustomAreaResponse>(
      `/api/search/get-custom-area`,
      {
        body: { lon, lat },
      },
    );

    return response?.customArea || null;
  } catch {
    return null;
  }
}
