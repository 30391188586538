/**
 * Function `friendlyUrl` accepts name, town, county and makes it url-friendly
 *
 * @function friendlyUrl
 * @param {Object} - {name, town, county}
 * @returns {String} - returns slugified friendly url
 */
const friendlyUrl = <
  T extends {
    name: string;
    town?: string;
    county?: string;
  },
>({
  name,
  town,
  county,
}: T) => {
  const nameProp = `${name}-`;
  const townProp = `${town}-`;
  const countyProp = `${county}`;
  return `${nameProp}${townProp}${countyProp}`
    .replace(/-undefined/g, '')
    .replace(/-false/g, '')
    .replace(/[^a-z0-9]/gi, '-')
    .replace(/-+/g, '-')
    .toLowerCase();
};

export default friendlyUrl;
