import { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { getSupplierLocale } from 'lib/app/selectors';
import {
  FollowUpMessagesAnalyticsActions,
  IFollowUpMessageAddedAnalytics,
  IFollowUpMessageDeletedAnalytics,
  IFollowUpMessageEditedAnalytics,
  IFollowUpMessageScheduledAnalytics,
  IScheduledFollowUpDeletedAnalytics,
} from 'lib/follow-up-messages/analytics/action-types';
import { FollowUpMessage } from 'lib/follow-up-messages/types';
import { getSupplierId, getSupplierTier, getSupplierType } from 'lib/supplier/selectors';
import { getSupplierCountryCode, getSupplierName } from 'lib/supplier/selectors/contact';
import { Action, IApplicationState, State } from 'lib/types';
import CmsAnalyticsContext from 'lib/utils/cms-analytics-context';

const commonAnalyticsProperties = (state: IApplicationState) => {
  const countryCode = getSupplierCountryCode(state);
  const countryName = countryCode ? Gazetteer.getCountryName(countryCode) : undefined;
  return {
    category: 'CMS',
    countryCode,
    countryName,
    locale: getSupplierLocale(state),
    supplierId: getSupplierId(state),
    supplierName: getSupplierName(state),
    supplierTier: getSupplierTier(state),
    supplierCategory: getSupplierType(state),
  };
};

const followUpProperties = (followUpMessage: FollowUpMessage) => ({
  templateTitle: followUpMessage.title,
  templateId: followUpMessage.id,
  followUpDays: followUpMessage.delay.days,
  followUpBusinessDaysOnly: followUpMessage.delay.businessDaysOnly,
});

export default function followUpMessagesAnalytics(
  action: Action,
  bridebookAnalytics: CmsAnalyticsContext,
  getState: () => State,
) {
  const { track } = bridebookAnalytics.getMethods('CMS');
  const state = getState();

  switch (action.type) {
    case FollowUpMessagesAnalyticsActions.AddFollowUpMessageClicked: {
      track({
        ...commonAnalyticsProperties(state),
        event: 'Clicked to add follow up template in CMS',
      });
      break;
    }

    case FollowUpMessagesAnalyticsActions.FollowUpMessageAdded: {
      const { followUpMessage } = action.payload as IFollowUpMessageAddedAnalytics['payload'];
      track({
        ...commonAnalyticsProperties(state),
        ...followUpProperties(followUpMessage),
        event: 'Added follow up template in CMS',
      });
      break;
    }

    case FollowUpMessagesAnalyticsActions.FollowUpMessageEdited: {
      const { followUpMessage } = action.payload as IFollowUpMessageEditedAnalytics['payload'];
      track({
        ...commonAnalyticsProperties(state),
        ...followUpProperties(followUpMessage),
        event: 'Edited follow up template in CMS',
      });
      break;
    }

    case FollowUpMessagesAnalyticsActions.FollowUpMessageDeleted: {
      const { followUpMessage } = action.payload as IFollowUpMessageDeletedAnalytics['payload'];
      track({
        ...commonAnalyticsProperties(state),
        ...followUpProperties(followUpMessage),
        event: 'Deleted follow up template in CMS',
      });
      break;
    }

    case FollowUpMessagesAnalyticsActions.FollowUpMessageScheduled: {
      const { followUpMessage, supplierWeddingId } =
        action.payload as IFollowUpMessageScheduledAnalytics['payload'];
      track({
        ...commonAnalyticsProperties(state),
        ...followUpProperties(followUpMessage),
        event: 'Scheduled follow up message',
        enquiryId: supplierWeddingId, // Keeping incorrect key for compatibility with other events
      });
      break;
    }

    case FollowUpMessagesAnalyticsActions.ScheduledFollowUpDeleted: {
      const { followUpMessage, cancelledReason, supplierWeddingId } =
        action.payload as IScheduledFollowUpDeletedAnalytics['payload'];
      track({
        ...commonAnalyticsProperties(state),
        ...followUpProperties(followUpMessage),
        event: 'Cancelled follow up message',
        enquiryId: supplierWeddingId, // Keeping incorrect key for compatibility with other events
        cancelledReason,
      });
      break;
    }

    default:
      break;
  }
}
