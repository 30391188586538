import { PayPerEnquiryAnalyticsActionTypes } from 'lib/pay-per-enquiry/analytics/action-types';
import { TPaymentCallbackPopup } from 'lib/pay-per-enquiry/types';
import { TTouchPointLocation } from 'lib/types';

export const triggeredBuyEnquiriesPopupAnalytics = (payload: {
  touchpointLocation?: TTouchPointLocation;
}) => ({
  type: PayPerEnquiryAnalyticsActionTypes.TRIGGERED_BUY_ENQUIRIES_POPUP,
  payload,
});

export const closedBuyEnquiriesPopupAnalytics = () => ({
  type: PayPerEnquiryAnalyticsActionTypes.CLOSED_BUY_ENQUIRIES_POPUP,
});

export const clickedPayForEnquiryCreditsAnalytics = (payload: { priceId: string }) => ({
  type: PayPerEnquiryAnalyticsActionTypes.CLICKED_PAY_FOR_ENQUIRY_CREDITS,
  payload,
});

export const triggeredBuyEnquiriesCallbackPopupAnalytics = (payload: TPaymentCallbackPopup) => ({
  type: PayPerEnquiryAnalyticsActionTypes.TRIGGERED_BUY_ENQUIRIES_CALLBACK_POPUP,
  payload,
});

export const closedBuyEnquiriesCallbackPopupAnalytics = (payload: TPaymentCallbackPopup) => ({
  type: PayPerEnquiryAnalyticsActionTypes.CLOSED_BUY_ENQUIRIES_CALLBACK_POPUP,
  payload,
});

export const clickedTryAgainCallbackPopupAnalytics = () => ({
  type: PayPerEnquiryAnalyticsActionTypes.CLICKED_TRY_AGAIN_CALLBACK_POPUP,
});

export const triggeredUnlockEnquiryPopupAnalytics = (payload: { enquiryId: string }) => ({
  type: PayPerEnquiryAnalyticsActionTypes.TRIGGERED_UNLOCK_ENQUIRY_POPUP,
  payload,
});

export const clickedUnlockEnquiryAnalytics = (payload: { enquiryId: string }) => ({
  type: PayPerEnquiryAnalyticsActionTypes.CLICKED_UNLOCK_ENQUIRY,
  payload,
});

export const closedUnlockEnquiryPopupAnalytics = (payload: { enquiryId: string }) => ({
  type: PayPerEnquiryAnalyticsActionTypes.CLOSED_UNLOCK_ENQUIRY_POPUP,
  payload,
});
