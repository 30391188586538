import { isNil, mergeDeepRight } from 'ramda';
import { PartialRecursive } from '@bridebook/models/source/abstract/_';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { FormSections } from 'lib/supplier/types';

export const getAllFormSectionsFields = (
  formSectionsUpdates:
    | Record<Partial<FormSections>, PartialRecursive<ISupplier>>
    | null
    | undefined,
) =>
  !isNil(formSectionsUpdates)
    ? Object.keys(formSectionsUpdates).reduce(
        (acc, section) => mergeDeepRight(acc, formSectionsUpdates[section as FormSections]),
        {},
      )
    : {};
