import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs/operators';
import { AuthActionTypes } from 'lib/auth/action-types';
import { EnquiryCTAPopupStorage } from 'lib/storage-manager/basic/enquiry-cta-popup-storage';
import { Action } from 'lib/types';

const clearEnquiryCTAPopupDisplayEpic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(AuthActionTypes.SIGN_OUT_SUCCESS),
    tap(clearLocalStorageRecord),
    ignoreElements(),
  );

export default clearEnquiryCTAPopupDisplayEpic;

const clearLocalStorageRecord = () => EnquiryCTAPopupStorage.set(false);
