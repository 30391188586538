import { createSlice } from '@reduxjs/toolkit';
import { AccessControlActionTypes } from 'lib/access-control/action-types';
import {
  ContentTranslationsActionPayload,
  ContentTranslationsCollectionScope,
  ContentTranslationsScope,
  IContentTranslationsState,
} from 'lib/content-translations/types';
import type { ActionWithPayload } from 'lib/types';

const initialState: IContentTranslationsState = { original: {}, temp: {} };

const contentTranslationsSlice = createSlice({
  name: 'contentTranslations',
  initialState,
  reducers: {
    saveContentTranslations(
      state,
      { payload }: ActionWithPayload<ContentTranslationsActionPayload>,
    ) {
      state.original[payload.scope] = payload.data;
    },
    updateTempContentTranslations(
      state,
      { payload }: ActionWithPayload<ContentTranslationsActionPayload>,
    ) {
      state.temp[payload.scope] = { ...state.temp[payload.scope], ...payload.data };
    },
    clearTempContentTranslations(
      state,
      {
        payload: scope,
      }: ActionWithPayload<
        ContentTranslationsScope | ContentTranslationsCollectionScope | undefined
      >,
    ) {
      if (!scope) return;

      const [collection, docId] = scope.split('.');

      if (!docId) {
        // Clear all translations for a collection
        Object.keys(state.temp).forEach((key) => {
          if (key.startsWith(collection)) {
            delete state.temp[key as ContentTranslationsScope];
          }
        });

        return;
      }
      delete state.temp[scope as ContentTranslationsScope];
    },
  },
  extraReducers: (builder) => {
    // Clear temporary translations when the route changes
    builder.addCase('ROUTE_CHANGE_COMPLETE', (state) => {
      state.temp = {};
    });

    // Clear all translations when the supplier is switched
    builder.addCase(AccessControlActionTypes.SWITCH_SUPPLIER, (state) => {
      state.temp = {};
      state.original = {};
    });
  },
});
export const {
  updateTempContentTranslations,
  saveContentTranslations,
  clearTempContentTranslations,
} = contentTranslationsSlice.actions;
export default contentTranslationsSlice.reducer;
