import { YourCouplesActionTypes } from 'lib/couples/action-types';
import { ICoupleBooking } from 'lib/couples/types';

export const toggleCoupleCollaboration = <
  T extends Pick<ICoupleBooking, 'profileId' | 'userId'>,
>(payload: {
  booking: T;
  isCollaborating: boolean;
}) => ({
  type: YourCouplesActionTypes.TOGGLE_COUPLE_COLLABORATION,
  payload,
});
