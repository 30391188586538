import { StatisticsActionTypes } from 'lib/statistics/action-types';
import { IReadItemsStorage, ReadItemsStorage } from 'lib/storage-manager/basic/read-items-storage';
import { IDeps } from 'lib/types';

/**
 * When all items are shown (e.g. entered page with reviews), then update the storage value
 * with the number of items seen.
 *
 * @param key
 * @param value
 */
export const updateReadItems =
  <K extends keyof Required<IReadItemsStorage>>(key: K, value: Required<IReadItemsStorage>[K]) =>
  ({ getState }: IDeps) => {
    const readItemsStorage = ReadItemsStorage(getState().supplier.supplier?.id);
    const readItems = readItemsStorage.get();
    readItemsStorage.set({
      ...readItems,
      [key]: value,
    });

    // Date updated in the state to refresh counter components
    return {
      type: StatisticsActionTypes.READ_ITEMS_UPDATED,
      payload: Date.now(),
    };
  };
