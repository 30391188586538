import { ISupplierWedding } from '@bridebook/models/source/models/Suppliers/Weddings.types';
import { IWedding } from '@bridebook/models/source/models/Weddings.types';

export enum TidyInboxStatus {
  // supplier wedding with matching email was marked as booked
  MarkedAsBooked = 'markedAsBooked',
  // supplier wedding with matching email was not found or no corresponding wedding found
  NotMatched = 'notMatched',
  // email is invalid
  Invalid = 'invalidEmail',
  // supplier wedding with matching email was already booked
  AlreadyBooked = 'alreadyBooked',
  // error occurred while marking supplier wedding as booked
  Error = 'error',
}

export interface TidyInboxQueryParams {
  supplierId: string;
}

export interface TidyInboxRequestBody {
  emails: string;
}

export interface TidyInboxResponseItem {
  status: TidyInboxStatus;
  weddingId: string | undefined;
  userId: string | undefined;
  supplierWeddingId: string | undefined;
  email: string | undefined;
  partners?: IWedding['partners'];
  photoCover?: IWedding['photoCover'];
}

export type TidyInboxResponse = {
  [TidyInboxStatus.MarkedAsBooked]: TidyInboxResponseItem[];
  [TidyInboxStatus.Error]: TidyInboxResponseItem[];
  [TidyInboxStatus.NotMatched]: string[];
  [TidyInboxStatus.Invalid]: string[];
  [TidyInboxStatus.AlreadyBooked]: TidyInboxResponseItem[];
};

export interface ISupplierWeddingMapped {
  supplierWedding: ISupplierWedding;
  email: string | undefined;
  partners?: IWedding['partners'];
  photoCover?: IWedding['photoCover'];
}
