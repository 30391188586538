import moment from 'moment';
import type { IDatePickerUI } from '@bridebook/toolbox/src/datepicker/types';

const getSeasonMonth = (season: IDatePickerUI['season']) => {
  switch (season.toLowerCase()) {
    case 'summer':
      return 5;
    case 'autumn':
      return 8;
    case 'winter':
      return 11;
    case 'spring':
      return 2;
    default:
      throw new Error('There is no such season!!');
  }
};

/**
 * Function `mapToExactDate`
 * map DatepickerDate to a full string to be used across the website
 *
 * @function mapToExactDate
 * @param {Object} - DatePickerDateModel
 * @returns {String || null}
 */
const mapToExactDate = (datePickerDate: IDatePickerUI): string | null => {
  if (!datePickerDate) return '';
  const { year, month, season, monthUndecided, day } = datePickerDate;
  if (year != null && month != null && day != null) {
    return moment().year(parseFloat(year)).month(month).date(parseFloat(day)).format('YYYY-MM-DD');
  }
  if (!!year && !!monthUndecided) {
    const currentYear = moment().year();
    if (year.toString() === currentYear.toString()) return null;
    return moment().year(parseFloat(year)).month(0).date(1).format('YYYY-MM-DD');
  }
  if (!!year && !!month) {
    return moment().year(parseFloat(year)).month(month).date(1).format('YYYY-MM-DD');
  }
  if (!!year && !!season) {
    const month = getSeasonMonth(season);
    return moment().year(parseFloat(year)).month(month).date(1).format('YYYY-MM-DD');
  }

  return null;
};

export default mapToExactDate;
