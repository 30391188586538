import { BasicKeyedStorageManager } from '@bridebook/toolbox/src/storage-managers';

// Stores a Record<weddingId, timestamp>
export type IContactedCouplesStorage = Record<string, number>;

export const ContactedCouplesStorage = (id: string) =>
  new BasicKeyedStorageManager<IContactedCouplesStorage>({
    storageKey: 'contacted-couples',
    id,
  });
