import { ITargeting } from 'lib/supplier/types';
import { BUDGET_MAX } from './constants';

export const getRangeSliderBudgetHeadingValue = (
  value?: ITargeting['targetingBudget'],
  currencySign = '£',
) => {
  const isMax = (amount: number): boolean =>
    Boolean(amount) && amount >= BUDGET_MAX && amount !== 0;

  if (
    value &&
    Array.isArray(value) &&
    value.length === 2 &&
    value.every((item) => typeof item === 'number') &&
    !(value[0] === 0 && isMax(value[1]))
  ) {
    const valueToAmount = (amount: number): string =>
      amount > 0
        ? amount > BUDGET_MAX
          ? `${currencySign}40k`
          : `${currencySign}${amount / 1000}k`
        : '0';

    const [min, max] = value;
    const from = `${valueToAmount(min)}${isMax(max) ? '+' : ''}`;
    const upTo = isMax(max) ? '' : ` - ${valueToAmount(max)}`;

    return `${from}${upTo}`;
  }

  return null;
};
