import { format } from 'date-fns';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import { FetchTierChangeHistoryAction, StatisticsActionTypes } from 'lib/statistics/action-types';
import { fetchTierChangeHistorySuccess } from 'lib/statistics/actions/fetch';
import { ChartGranularity } from 'lib/statistics/constants';
import { TTierChangeRecord } from 'lib/statistics/types';
import { fetchTierChangeHistory, segmentizeTierChangeHistory } from 'lib/statistics/utils';
import { IEpicDeps } from 'lib/types';

export const fetchTierChangeHistoryEpic = (
  action$: Observable<FetchTierChangeHistoryAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(StatisticsActionTypes.FETCH_TIER_CHANGE_HISTORY),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const getPromise = async () => {
        const supplierId = state.supplier.supplier?.id;
        if (!supplierId)
          throw new Error('Cannot load tier change history without selected supplier');
        return fetchTierChangeHistory(supplierId);
      };

      return from(getPromise()).pipe(
        map((response) => {
          const tierChangeRecords = response.results;
          const tierChangeByDate = tierChangeRecords.reduce(
            (previousValue, currentValue) => {
              const tierChangeDateAsYMD = format(
                new Date(currentValue.tierChangeDate),
                'yyyy-MM-dd',
              );
              if (!previousValue[tierChangeDateAsYMD]) {
                previousValue[tierChangeDateAsYMD] = {
                  all: [currentValue],
                  last: currentValue,
                };
              } else {
                previousValue[tierChangeDateAsYMD].all.push(currentValue);
                previousValue[tierChangeDateAsYMD].last = currentValue;
              }

              return previousValue;
            },
            {} as Record<
              string,
              {
                all: TTierChangeRecord[];
                last: TTierChangeRecord;
              }
            >,
          );

          const tierChangeByMonth = segmentizeTierChangeHistory(
            tierChangeByDate,
            ChartGranularity.month,
          );
          const tierChangeByWeek = segmentizeTierChangeHistory(
            tierChangeByDate,
            ChartGranularity.week,
          );

          return fetchTierChangeHistorySuccess({
            tierChangeByDate,
            tierChangeByWeek,
            tierChangeByMonth,
          });
        }),
        catchError((error: Error) => [
          { type: StatisticsActionTypes.FETCH_TIER_CHANGE_HISTORY_ERROR },
          appError({ error, feature: 'fetchTierChangeHistoryEpic' }),
        ]),
      );
    }),
    catchError((error) => of(appError({ error, feature: 'fetchTierChangeHistoryEpic' }))),
  );
