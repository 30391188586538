import produce from 'immer';
import { append, contains, values, without } from 'ramda';
import uuid from 'uuid-random';
import { AccessControlActionTypes } from 'lib/access-control/action-types';
import { FetchPhotosSuccessAction, PhotosActionTypes } from 'lib/photos/action-types';
import {
  OnModalToggleAction,
  SetMarketingBannerAction,
  ToggleOverlayPortalAction,
  ToggleSnackbarAction,
  UIActionTypes,
} from 'lib/ui/action-types';
import { defaultMenuNumbers, defaultSnackbarState } from 'lib/ui/constants';
import { UIState } from 'lib/ui/types';
import { Action } from '../types';
import { FetchVideosSuccessAction, VideosActionTypes } from '../videos/action-types';

const initialState: UIState = {
  menu: defaultMenuNumbers,
  showAdminPanel: false,
  snackbarState: defaultSnackbarState,
  openedModalIds: [],
  openedOverlayPortal: false,
  scrollPosition: 0,
  marketingBanner: null,
};

const reducers: any = (draft: UIState) => ({
  [UIActionTypes.TOGGLE_ADMIN_PANEL]: () => {
    draft.showAdminPanel = !draft.showAdminPanel;
  },

  [UIActionTypes.CLOSE_ADMIN_PANEL]: () => {
    draft.showAdminPanel = false;
  },

  [PhotosActionTypes.FETCH_PHOTOS_SUCCESS]: (action: FetchPhotosSuccessAction) => {
    draft.menu.totalPhotos = values(action.payload || []).length || 0;
  },

  [VideosActionTypes.FETCH_VIDEOS_SUCCESS]: (action: FetchVideosSuccessAction) => {
    draft.menu.totalVideos = values(action.payload.videos || []).length || 0;
  },

  [UIActionTypes.TOGGLE_SNACKBAR]: (action: ToggleSnackbarAction) => {
    draft.snackbarState = {
      ...defaultSnackbarState,
      ...action.payload,
      uuid: uuid(),
      show: true,
    };
  },

  [UIActionTypes.HIDE_SNACKBAR]: () => {
    draft.snackbarState.show = false;
  },

  [UIActionTypes.TOGGLE_OVERLAY_PORTAL]: (action: ToggleOverlayPortalAction) => {
    const { opened, scrollPosition } = action.payload;

    draft.openedOverlayPortal = opened;
    draft.scrollPosition = scrollPosition;
  },

  [UIActionTypes.ON_MODAL_TOGGLE]: (action: OnModalToggleAction) => {
    const { id, show } = action.payload;

    let openedModalIds = [...draft.openedModalIds];

    if (show && !contains(id, openedModalIds)) {
      openedModalIds = append(id, openedModalIds);
    }

    if (!show && contains(id, openedModalIds)) {
      openedModalIds = without([id], openedModalIds);
    }

    draft.openedModalIds = openedModalIds;
  },

  [UIActionTypes.SET_MARKETING_BANNER]: (action: SetMarketingBannerAction) => {
    draft.marketingBanner = action.payload.banner;
  },

  [AccessControlActionTypes.SWITCH_SUPPLIER]: () => initialState,
});

/*
  This is a wrapper function which runs a proper reducer from the object above.
*/
const reducer = (state: UIState = initialState, action: Action): UIState => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
