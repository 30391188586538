import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, pluck, take } from 'rxjs/operators';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { IApplicationState } from 'lib/types';

export const withSupplierId =
  <A>(state$: StateObservable<IApplicationState>) =>
  (source: Observable<A>) =>
    source.pipe(
      mergeMap((value: A) =>
        state$.pipe(
          pluck<IApplicationState, ISupplier>('supplier', 'supplier'),
          filter((supplier) => !!supplier?.id),
          map<ISupplier, [string, A, IApplicationState]>((supplier) => [
            supplier.id,
            value,
            state$.value,
          ]),
          take(1),
        ),
      ),
    );
