import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, withLatestFrom } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import {
  FetchMonoquerySuccessAction,
  FetchTierChangeHistoryAction,
  StatisticsActionTypes,
} from 'lib/statistics/action-types';
import { prepareCharts } from 'lib/statistics/actions';
import { getSupplierCountryCode, getSupplierTier, getSupplierType } from 'lib/supplier/selectors';
import { IApplicationState, IEpicDeps } from 'lib/types';

export const prepareChartsEpic = (
  action$: Observable<FetchTierChangeHistoryAction | FetchMonoquerySuccessAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(
      StatisticsActionTypes.FETCH_TIER_CHANGE_HISTORY_SUCCESS,
      StatisticsActionTypes.FETCH_MONOQUERY_SUCCESS,
    ),
    withLatestFrom(state$),
    filter(
      ([_, state]: [unknown, IApplicationState]) =>
        state.statistics.monoquery.loaded && state.statistics.tierChangeHistory.loaded,
    ),
    map(([_, state]) => {
      const supplierType = getSupplierType(state);
      const supplierTier = getSupplierTier(state);
      const supplierCountryCode = getSupplierCountryCode(state);
      return prepareCharts(supplierTier, supplierCountryCode, supplierType);
    }),
    catchError((error) => of(appError({ error, feature: 'prepareChartsEpic' }))),
  );
