import { ValidationError } from '@bridebook/toolbox';
import { RWActions } from './action-types';
import { RealWedding, RealWeddingForm } from './types';

export const editRW = (payload: RealWeddingForm) => ({
  payload,
  type: RWActions.EDIT,
});

export const updateRWList = (payload: RealWedding[]) => ({
  payload,
  type: RWActions.UPDATE_LIST,
});

export const updateRWForm = (payload: Partial<RealWeddingForm>) => ({
  payload,
  type: RWActions.UPDATE_FORM,
});

export const saveRW = () => ({
  type: RWActions.SAVE,
});

export const clearRW = () => ({
  type: RWActions.CLEAR,
});

export const saveRWError = (error: ValidationError) => ({
  payload: error,
  type: RWActions.SAVE_ERROR,
});

export const saveRWSuccess = () => ({
  type: RWActions.SAVE_SUCCESS,
});

export const deleteRW = (payload: RealWedding) => ({
  payload,
  type: RWActions.DELETE,
});

export const deleteRWError = (error: Error) => ({
  payload: error,
  type: RWActions.DELETE_ERROR,
});

export const deleteRWSuccess = () => ({
  type: RWActions.DELETE_SUCCESS,
});

export const initRWListener = () => ({
  type: RWActions.INIT_LISTENER,
});

export const stopRWListener = () => ({
  type: RWActions.STOP_LISTENER,
});

export const resetRWNotification = () => ({
  type: RWActions.RESET_NOTIFICATION,
});
