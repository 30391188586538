import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, mapTo, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { appError } from '../../app/actions';
import { IEpicDeps } from '../../types';
import { DeleteRecommendationAction, RecommendationsActions } from '../action-types';
import { deleteRecommendationDone } from '../actions/delete-recommendation';

export const deleteRecommendationsEpic = (
  action$: Observable<DeleteRecommendationAction>,
  { state$, bbanalytics }: IEpicDeps,
) =>
  action$.pipe(
    ofType(RecommendationsActions.DELETE_RECOMMENDATION),
    withLatestFrom(state$),
    mergeMap(
      ([
        {
          payload: { recommendation },
        },
        state,
      ]) => {
        if (!recommendation) {
          return of();
        }

        const recommendationId = state.recommendations.list.find(
          (i) => i.supplierId === recommendation.id,
        )?.id;

        if (!recommendationId) {
          return of();
        }
        const supplierId = state.supplier.supplier?.id;
        if (!supplierId) return of();

        const promise = Suppliers._.getById(supplierId)
          .Recommendations.getById(recommendationId)
          .delete();

        return from(promise).pipe(
          tap(() =>
            bbanalytics.supplier.supplierNetwork.deleted({
              recommendedSupplierId: recommendation.id,
              recommendedSupplierCategory: recommendation.type[0],
            }),
          ),
          mapTo(deleteRecommendationDone()),
          catchError((error) => of(appError({ error, feature: 'Recommendations' }))),
        );
      },
    ),
  );
