import { SupplierStatusActionTypes } from './action-types';

export const updateSupplierStatus = (status: string) => ({
  type: SupplierStatusActionTypes.UPDATE_SUPPLIER_STATUS,
  payload: status,
});

export const updateSupplierStatusSuccess = () => ({
  type: SupplierStatusActionTypes.UPDATE_SUPPLIER_STATUS_SUCCESS,
});

export const addSupplierStatusAnalytics = () => ({
  type: SupplierStatusActionTypes.ADD_SUPPLIER_STATUS_ANALYTICS,
});

export const editSupplierStatusAnalytics = () => ({
  type: SupplierStatusActionTypes.EDIT_SUPPLIER_STATUS_ANALYTICS,
});

export const removeSupplierStatusAnalytics = () => ({
  type: SupplierStatusActionTypes.REMOVE_SUPPLIER_STATUS_ANALYTICS,
});
