import { FollowUpMessagesAnalyticsActions } from 'lib/follow-up-messages/analytics/action-types';
import { FollowUpMessage } from 'lib/follow-up-messages/types';

type CancelledReasonTypes = 'cancelledByUser' | 'coupleReplied' | 'templateDeleted';

export const followUpMessageAddedAnalytics = (payload: { followUpMessage: FollowUpMessage }) => ({
  type: FollowUpMessagesAnalyticsActions.FollowUpMessageAdded,
  payload,
});

export const followUpMessageEditedAnalytics = (payload: { followUpMessage: FollowUpMessage }) => ({
  type: FollowUpMessagesAnalyticsActions.FollowUpMessageEdited,
  payload,
});

export const followUpMessageDeletedAnalytics = (payload: { followUpMessage: FollowUpMessage }) => ({
  type: FollowUpMessagesAnalyticsActions.FollowUpMessageDeleted,
  payload,
});

export const followUpScheduledAnalytics = (payload: {
  followUpMessage: FollowUpMessage;
  supplierWeddingId: string;
}) => ({
  type: FollowUpMessagesAnalyticsActions.FollowUpMessageScheduled,
  payload,
});

export const scheduledFollowUpDeletedAnalytics = (payload: {
  followUpMessage: FollowUpMessage;
  supplierWeddingId: string;
  cancelledReason: CancelledReasonTypes;
}) => ({
  type: FollowUpMessagesAnalyticsActions.ScheduledFollowUpDeleted,
  payload,
});

export const addFollowUpMessageClickedAnalytics = {
  type: FollowUpMessagesAnalyticsActions.AddFollowUpMessageClicked,
};
