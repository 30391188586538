import { RecommendationsActions } from '../action-types';

export const addRecommendation = (recommendation: any) => ({
  type: RecommendationsActions.ADD_RECOMMENDATION,
  payload: { recommendation },
});

export const addRecommendationDone = () => ({
  type: RecommendationsActions.ADD_RECOMMENDATION_DONE,
});
