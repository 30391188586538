import { format } from 'date-fns';
import { getI18n } from 'react-i18next';
import { dashify } from '@bridebook/toolbox/src';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { countriesWithPowerMatch } from '@bridebook/toolbox/src/i18n/features';
import { IBookingFile } from '@bridebook/toolbox/src/types';
import { ContactedCouplesStorage } from 'lib/couples/contacted-couples-storage';
import { ICoupleActivity, ICoupleBooking, ISendContactCoupleMessage } from 'lib/couples/types';

/**
 * Generate a unique id for couple details overview page
 * @param profileId
 * @param partnerName1
 * @param partnerName2
 */
export const getCoupleDetailsPageId = ({
  profileId,
  partnerName1,
  partnerName2,
}: ICoupleBooking) => {
  const name1 = partnerName1.trim();
  const name2 = partnerName2.trim();
  const names = name1 && name2 ? `${name1} ${name2}` : name1 || name2 || '';
  return dashify(`${names}--${profileId}`);
};

/**
 * Get a profile id from couple details overview page URL
 * @param pageId - consists of name1-name2--profileId,
 * eg: /couples/kris-tina--7bf9110b-0463-42bc-bb78-c8887aed5b01
 */
export const getProfileIdFromPageId = (pageId: string) => pageId.split('--').slice(-1)[0] || null;

/**
 * Returns a checklist progress in % for a given booking
 * @param booking
 */
export const getCoupleChecklistProgress = (booking: ICoupleBooking) =>
  booking.tasksTotal ? Math.round((booking.tasksDone / booking.tasksTotal) * 100) : 0;

export const formatBookingWeddingDate = ({
  weddingDate,
  dateFormat,
  locale,
}: {
  weddingDate: number;
  dateFormat: string;
  locale: any;
}) =>
  weddingDate > 0
    ? format(new Date(weddingDate), dateFormat, { locale })
    : getI18n().t('common:undecided');

/**
 * Returns an index of a file within a given files array
 * @param file
 * @param files
 */
export const findFileIndex = (file: IBookingFile, files: IBookingFile[] | null) =>
  files?.findIndex((f) => f.file.id === file.file.id) ?? -1;

export const sendMessage = ({
  supplierId,
  weddingId,
  eventType,
  message,
  supplierUserId,
}: ISendContactCoupleMessage) =>
  authenticatedFetch(`/api/supplier/${supplierId}/contact-couple`, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({ supplierId, weddingId, eventType, message, supplierUserId }),
  }).then((response) => {
    if (response.status === 400) throw Error('message already sent');
    return response.json();
  });

/**
 * Merges data from Big Query with Contacted Couples data stored in Local Storage
 * BigQuery data is cached and we need contacted couples earlier.
 */
export const mergeWithContactedCouplesStorage = (
  data: ICoupleActivity[],
  supplierId: string,
): ICoupleActivity[] => {
  const contactedCouples = ContactedCouplesStorage(supplierId).get();

  if (!contactedCouples) return data;

  return data.map((activity) => ({
    ...activity,
    contactedCoupleTime: contactedCouples[activity.weddingId] || activity.contactedCoupleTime,
  }));
};

export const isPowerMatchAvailable = (countryCode: CountryCodes) =>
  countriesWithPowerMatch.includes(countryCode);
