import { ChangeEvent, FocusEvent } from 'react';
import { IPackage } from '@bridebook/models/source/models/Suppliers/Packages.types';
import mapToPackagesSections, {
  ISupplierPackage,
  PackagesSections,
} from '@bridebook/toolbox/src/map-packages-to-sections';
import { IDeps } from 'lib/types';
import resolveInputType from 'lib/utils/resolve-input-type';
import { PricingActionTypes } from '../action-types';
import { resolvePackageInput } from '../utils';

export const onFirestorePackages = (payload: Record<string, IPackage>) => () => ({
  type: PricingActionTypes.ON_FIRESTORE_PACKAGES,
  payload: mapToPackagesSections(payload),
});

export const onFirestoreSupplierPackages = (payload: ISupplierPackage[]) => () => ({
  type: PricingActionTypes.ON_FIRESTORE_SUPPLIER_PACKAGES,
  payload,
});

export const updatePackageCheckboxGroup = (event: ChangeEvent<HTMLInputElement>) => {
  const value = resolveInputType(event.target);

  return {
    type: PricingActionTypes.UPDATE_PACKAGE_CHECKBOX_GROUP,
    payload: { name: event.target.name, value },
  };
};

export const setPackageSectionUnsaved = (packageSection: PackagesSections) => ({
  type: PricingActionTypes.SET_PACKAGE_SECTION_UNSAVED,
  payload: { packageSection },
});

export const updatePackageField =
  (packageSection: PackagesSections, event: FocusEvent<HTMLInputElement>) =>
  ({ getState }: IDeps) => {
    const { name, value } = resolvePackageInput(packageSection, event, getState);
    return {
      type: PricingActionTypes.UPDATE_PACKAGE_FIELD,
      payload: { packageSection, name, value },
    };
  };

export const savePackageSection = (packageSection: PackagesSections) => ({
  type: PricingActionTypes.SAVE_PACKAGE_SECTION,
  payload: { packageSection },
});

export const savePackageSectionSuccess = (packageSection: PackagesSections) => ({
  type: PricingActionTypes.SAVE_PACKAGE_SECTION_SUCCESS,
  payload: { packageSection },
});

export const saveSupplierPackage = (pricingPackage: ISupplierPackage) => ({
  type: PricingActionTypes.SAVE_SUPPLIER_PACKAGE,
  payload: pricingPackage,
});

export const deleteSupplierPackage = (pricingPackage: ISupplierPackage) => ({
  type: PricingActionTypes.DELETE_SUPPLIER_PACKAGE,
  payload: pricingPackage,
});

export const discardPackagesFormChanges = () => ({
  type: PricingActionTypes.DISCARD_PACKAGES_FORM_CHANGES,
});
