import { createSelector } from 'reselect';
import { IApplicationState } from 'lib/types';

const getFollowUpMessagesState = (state: IApplicationState) => state.followUpMessages;
const getFollowUpMessagesForm = createSelector(getFollowUpMessagesState, ({ form }) => form);

export const isSavingFollowUpMessage = createSelector(
  getFollowUpMessagesForm,
  ({ state }) => state === 'pending',
);

export const getFollowUpMessages = createSelector(
  getFollowUpMessagesState,
  ({ followUpMessages }) => followUpMessages || [],
);

export const getFollowUpMessagesLoaded = createSelector(
  getFollowUpMessagesState,
  ({ followUpMessages }) => Boolean(followUpMessages),
);
