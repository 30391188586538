import { addDays, addMonths, format as dateFnsFormat, startOfWeek } from 'date-fns';
import { de, enGB, enUS, fr, pl } from 'date-fns/locale';
import { isNil } from 'ramda';
import { getI18n } from 'react-i18next';
import type {
  ISupplierSpecialOffers,
  ISupplierSpecialOffersLateAvailability,
} from '@bridebook/models/source/models/RTDB/Suppliers.types';
import { IOffer } from '@bridebook/models/source/models/Suppliers/Offers.types';
import { PremiumTiers } from '@bridebook/toolbox';
import { mapDateToUI, mapToExactDate, mergeClean } from '@bridebook/toolbox/src';
import gazetteer, { CountryCodes, Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { Locales } from 'lib/i18n/constants';
import { getLocale } from 'lib/i18n/utils/get-locale';
import {
  BBDiscountOptions,
  FirestoreOfferDiscountTypes,
  OfferDiscountTypes,
  OfferTypes,
} from 'lib/offers/constants';
import {
  supplierSpecialOffersBBDiscount,
  supplierSpecialOffersFormDefault,
  supplierSpecialOffersLateAvailabilityFormDefault,
} from 'lib/supplier/schemas/generic';
import { getIsVenue, getSupplierTier } from 'lib/supplier/selectors';
import { IApplicationState } from 'lib/types';

export const getTimeLeft = (endDate: number): string | null => {
  // Maximum number of weeks to display the time left
  const MAX_WEEKS = 8;

  let res = '';

  if (!endDate || !Number.isInteger(endDate)) {
    return null;
  }

  const secondsLeft = Number(new Date(endDate)) - Date.now();
  // Math.ceil so we have at least 1 day left
  const days = Math.ceil(secondsLeft / 1000 / 60 / 60 / 24);
  const weeks = Math.round(days / 7);

  if (days < 0 || weeks > MAX_WEEKS) {
    return null;
  }

  if (days > 31) {
    res = `${weeks} ${getI18n().t('offers:singleWeek', {
      count: weeks,
    })}`;
  } else {
    res = `${days} ${getI18n().t('offers:singleDay', {
      count: days,
    })}`;
  }

  return res;
};

/**
 * Returns date-fns language files based on the current next-i18next locale
 */
export const getDateFnsLocale = () => {
  const locale = getLocale();

  switch (locale) {
    case Locales.DE:
      return de;
    case Locales.FR:
      return fr;
    case Locales.GB:
      return enGB;
    case Locales.PL:
      return pl;
    case Locales.US:
    default:
      return enUS;
  }
};

/**
 * Wrapper over date-fns format, which has localisation options passed
 */
export const format: typeof dateFnsFormat = (date, format, options) =>
  dateFnsFormat(date, format, {
    locale: getDateFnsLocale(),
    ...options,
  });

const monthFormat = {
  full: 'MMMM', // January, February, ..., December
  medium: 'MMM', // Jan, Feb, ..., Dec
};

const weekdayFormat = {
  full: 'EEEE', // Monday, Tuesday, ..., Sunday
  medium: 'EEE', // Mon, Tue, Wed, ..., Sun
  short: 'EEEEEE', // Mo, Tu, We, Th, Fr, Su, Sa
};

/**
 * Generates an array of names for all days of week
 */
export const getLocalisedWeekdays = (
  length: keyof typeof weekdayFormat = 'full',
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 1,
) => {
  const firstDayOfWeek = startOfWeek(new Date(), { weekStartsOn });

  return Array.from(Array(7)).map((_, i) =>
    format(addDays(firstDayOfWeek, i), weekdayFormat[length]),
  );
};

/**
 * Generates an array of names for all months
 */
export const getLocalisedMonths = (length: keyof typeof monthFormat = 'full') =>
  Array.from(Array(12)).map((_, i) => format(addMonths(0, i), monthFormat[length]));

/**
 * Convert array of unix timestamps to array of datepicker objects
 * @param dates
 */
export const offerTimestampsToDatepickerObjects = (timestamps: number[] = []): IOffer['dates'] =>
  timestamps.map((ts) => {
    const date = new Date(ts);

    return {
      d: date.getDate(),
      m: date.getMonth(),
      y: date.getFullYear(),
    };
  });

/**
 * Convert Firestore Offer dates array of datepicker objects to array of unix timestamps
 * @param dates
 */
const firestoreOfferDatesToTimestamps = (dates: IOffer['dates'] = []) =>
  dates
    .map((date) => {
      const exactDate = mapToExactDate(
        mapDateToUI(date),
        gazetteer.getMarketByCountry(CountryCodes.GB),
      );

      return exactDate ? new Date(exactDate).valueOf() : null;
    })
    .filter(Boolean) as number[];

/**
 * Create form structure for Late Availability offer
 */
const getLateAvailabilityFormStructure = ({
  lateDates = [],
  state,
}: {
  lateDates?: IOffer['dates'];
  state: IApplicationState;
}) => {
  const isVenue = getIsVenue(state);
  const supplierTier = getSupplierTier(state);

  // Null is used to display an empty date input field
  const datesArray: Array<number | null> =
    lateDates.length > 0 ? firestoreOfferDatesToTimestamps(lateDates) : [null];

  const { enabledDates, disabledDates } = getMaxLateAvailabilityDates(supplierTier, isVenue);
  const hasUnlimitedDates = isNil(enabledDates);
  const totalNumberOfVisibleInputs = (enabledDates ?? 0) + (disabledDates ?? 0);

  const remainingInputs =
    !hasUnlimitedDates && totalNumberOfVisibleInputs > 0
      ? new Array(totalNumberOfVisibleInputs - datesArray.length).fill(null)
      : [];

  return datesArray.concat(remainingInputs);
};

/**
 * convert Firestore offer object to legacy format used in offers form
 * @param offer - Firestore offer object
 */
const convertFirestoreOfferToForm = (offer: IOffer, state: IApplicationState) => {
  const converted: Record<string, any> = {
    ...offer,
    discountValue: offer.discount,
    discountType:
      offer.discountType === FirestoreOfferDiscountTypes.setValue
        ? OfferDiscountTypes.setPrice
        : offer.discountType === FirestoreOfferDiscountTypes.absoluteOff
        ? OfferDiscountTypes.poundOff
        : offer.discountType,
  };

  if (Array.isArray(offer.dates)) {
    converted.lateDates = getLateAvailabilityFormStructure({ lateDates: offer.dates, state });
  } else if (offer.expiration) {
    converted.expiryDate = offer.expiration;
  }

  return converted;
};

export const getSpecialOfferInitialForm = (
  specialOffersFromState: ISupplierSpecialOffers,
  type: OfferTypes,
  state: IApplicationState,
) => {
  // @ts-ignore - this requires refactoring from RTDB to Firestore types
  const givenTypeOfferFromState = specialOffersFromState ? specialOffersFromState[type] : null;

  const givenTypeDefault =
    type === OfferTypes.bbDiscount
      ? supplierSpecialOffersBBDiscount
      : type === OfferTypes.lateAvailability
      ? {
          ...supplierSpecialOffersLateAvailabilityFormDefault,
          lateDates: getLateAvailabilityFormStructure({ state }),
        }
      : supplierSpecialOffersFormDefault;

  return givenTypeOfferFromState
    ? mergeClean(
        givenTypeDefault,
        'id' in givenTypeOfferFromState
          ? convertFirestoreOfferToForm(givenTypeOfferFromState, state)
          : givenTypeOfferFromState,
      )
    : givenTypeDefault;
};

/**
 * Returns true if a given date is in the future
 * @param date
 */
export const isDateInFuture = (date?: number) => !!date && new Date(date) > new Date();

/**
 * Returns the date as timestamp furthest in the future for lateAvailabilty offer
 * @param offer - offer object
 */
export const getOfferLatestDate = (offer: IOffer) => {
  if (!offer) {
    return undefined;
  }

  if (!Array.isArray((offer as any).dates)) {
    return undefined;
  }

  const dates = firestoreOfferDatesToTimestamps(offer.dates || []);

  return Math.max(...dates);
};

export const getLateAvailabilityOfferExpiredDates = (offer: IOffer) => {
  const dates = firestoreOfferDatesToTimestamps(offer?.dates || []);

  return dates.filter((date) => !isDateInFuture(date));
};

/**
 * Returns the earliest date as timestamp for lateAvailabilty offer
 */
export const getOfferEarliestDate = (offer: IOffer) => {
  if (!offer) {
    return undefined;
  }

  const dates = firestoreOfferDatesToTimestamps(offer?.dates || []);

  return Math.min(...dates);
};

/**
 * get expiry date of offer
 * @param type - offer type
 * @param offer - offer object
 */
export const getExpiryDate = (type: OfferTypes, offer?: IOffer) => {
  if (!offer) return undefined;

  if (type === OfferTypes.lateAvailability) {
    // @ts-ignore - this requires refactoring from RTDB to Firestore types
    return getOfferLatestDate(offer as ISupplierSpecialOffersLateAvailability);
  }

  return offer.expiration;
};

/**
 * Returns discount string represantation
 * @param offer Object
 * @param currency
 */
export const getDiscountLabel = (offer?: IOffer, currency?: any): string => {
  if (!offer) {
    return '';
  }

  const { discountType } = offer;
  const discountValue = offer.discount || 0;

  switch (discountType) {
    // @ts-ignore - this requires refactoring from RTDB to Firestore types
    case OfferDiscountTypes.percentOff:
      return getI18n().t('offers:discountOff', { discountValue });

    case FirestoreOfferDiscountTypes.absoluteOff:
      return getI18n().t('offers:discountMoneyOff', {
        discountValue: Gazetteer.getCurrencySymbol(currency) + discountValue,
      });

    case FirestoreOfferDiscountTypes.setValue:
      return getI18n().t(
        'offers:discountJust',
        //WIP
        { discountValue: Gazetteer.getCurrencySymbol(currency) + discountValue },
      );

    default:
      return '';
  }
};

/**
 * Get number value from legacy bbDiscount string (e.g. bbDiscount5PerCentDiscount)
 * @param value - legacy bbDiscount string
 */
export const discountStringToExactValue = (value: BBDiscountOptions): number | string => {
  switch (value) {
    case BBDiscountOptions.bbDiscount5PerCentDiscount:
      return 5;
    case BBDiscountOptions.bbDiscount10PerCentDiscount:
      return 10;
    case BBDiscountOptions.bbDiscount20PerCentDiscount:
      return 20;
    case BBDiscountOptions.bbDiscountOther:
      return '';
    case BBDiscountOptions.bbDiscountNone:
    default:
      return 0;
  }
};

/**
 * Get legacy bbDiscount string (e.g. bbDiscount5PerCentDiscount) from exact value
 * @param value
 */
export const exactValueToDiscountString = (value: number | string): BBDiscountOptions => {
  switch (true) {
    case value === 5:
      return BBDiscountOptions.bbDiscount5PerCentDiscount;
    case value === 10:
      return BBDiscountOptions.bbDiscount10PerCentDiscount;
    case value === 20:
      return BBDiscountOptions.bbDiscount20PerCentDiscount;
    case typeof value === 'string':
      return BBDiscountOptions.bbDiscountOther;
    default:
      return BBDiscountOptions.bbDiscountNone;
  }
};

export const getFirestoreDiscountType = (
  discountType: OfferDiscountTypes,
): IOffer['discountType'] =>
  // @ts-ignore - this requires refactoring from RTDB to Firestore types
  discountType === OfferDiscountTypes.setPrice
    ? FirestoreOfferDiscountTypes.setValue
    : discountType === OfferDiscountTypes.poundOff
    ? FirestoreOfferDiscountTypes.absoluteOff
    : discountType;

/**
 * Returns object with maximum amount of date inputs and amount of extra date inputs
 * available for Late Availability offer
 */
export const getMaxLateAvailabilityDates = (
  tier: PremiumTiers,
  isVenue: boolean,
): {
  enabledDates?: number;
  disabledDates?: number;
} => {
  if (isVenue) {
    // Venues
    if (tier === PremiumTiers.Tier_2_5)
      return {
        enabledDates: 4,
        disabledDates: 4,
      };
    if (tier === PremiumTiers.Tier_3)
      return {
        enabledDates: 8,
        disabledDates: 0,
      };
    // tier_4 can add unlimited number of dates
    if (tier === PremiumTiers.Tier_4)
      return {
        enabledDates: undefined,
        disabledDates: undefined,
      };
  } else {
    // Suppliers
    if (tier === PremiumTiers.Tier_2)
      return {
        enabledDates: 4,
        disabledDates: 0,
      };
  }

  return {
    enabledDates: 0,
    disabledDates: 0,
  };
};
