import {
  initPricing,
  onFirestoreBalance,
  revealEnquiry,
  revealEnquiryFailure,
  revealEnquirySuccess,
  selectPrice,
  toggleBuyEnquiriesPopup,
  togglePaymentCallbackPopup,
  toggleRevealEnquiryPopup,
} from 'lib/pay-per-enquiry/actions';

export enum PayPerEnquiryActionTypes {
  TOGGLE_BUY_ENQUIRIES_POPUP = '[PayPerEnquiry] Toggle Buy Enquiries popup',
  TOGGLE_PAYMENT_CALLBACK_POPUP = '[PayPerEnquiry] Toggle payment callback popup',
  TOGGLE_REVEAL_ENQUIRY_POPUP = '[PayPerEnquiry] Toggle reveal enquiry popup',
  SELECT_PRICE = '[PayPerEnquiry] Select price',
  INIT_PRICING = '[PayPerEnquiry] Init pricing',
  INIT_BALANCE_LISTENER = '[PayPerEnquiry] Init balance listener',
  STOP_BALANCE_LISTENER = '[PayPerEnquiry] Stop balance listener',
  ON_FIRESTORE_BALANCE = '[PayPerEnquiry] On Firestore balance',
  SELECT_BALANCE = '[PayPerEnquiry] Select enquiry balance',
  FETCH_PRICING = '[PayPerEnquiry] Fetch pricing',
  REVEAL_ENQUIRY = '[PayPerEnquiry] Reveal enquiry',
  REVEAL_ENQUIRY_SUCCESS = '[PayPerEnquiry] Reveal enquiry success',
  REVEAL_ENQUIRY_FAILURE = '[PayPerEnquiry] Reveal enquiry failure',
}

export type IToggleBuyEnquiriesPopupAction = ReturnType<ReturnType<typeof toggleBuyEnquiriesPopup>>;
export type ITogglePaymentCallbackPopupAction = ReturnType<typeof togglePaymentCallbackPopup>;

export type IToggleRevealEnquiryPopupAction = ReturnType<typeof toggleRevealEnquiryPopup>;

export type ISelectPriceAction = ReturnType<typeof selectPrice>;
export type IInitPricingAction = ReturnType<ReturnType<typeof initPricing>>;
export type IRevealEnquiryAction = ReturnType<typeof revealEnquiry>;
export type IRevealEnquirySuccess = ReturnType<ReturnType<typeof revealEnquirySuccess>>;
export type IRevealEnquiryFailure = ReturnType<ReturnType<typeof revealEnquiryFailure>>;
export type IOnFirestoreBalanceAction = ReturnType<typeof onFirestoreBalance>;
