import { ContentTranslationsScope } from 'lib/content-translations/types';
import { FeedbackActions } from '../action-types';
import { IFeedbackSerialized } from '../types';

export const saveFeedback = (feedback: IFeedbackSerialized, scope?: ContentTranslationsScope) => ({
  type: FeedbackActions.SAVE_FEEDBACK,
  payload: { feedback, scope },
});

export const saveFeedbackSuccess = (feedback: IFeedbackSerialized) => ({
  type: FeedbackActions.SAVE_FEEDBACK_SUCCESS,
  payload: { feedback },
});

export const saveReviewResponse = (id: string, response: string) => ({
  type: FeedbackActions.SAVE_REVIEW_RESPONSE,
  payload: { id, response },
});

export const clearTestimonialModal = () => ({
  type: FeedbackActions.CLEAR_MODAL_VALUES,
});
