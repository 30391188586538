import { I18nActionTypes } from '../action-types';
import { setClientLocaleCookie } from '../utils';

export const onLanguageChanged = (locale: string) => {
  setClientLocaleCookie(locale);

  return {
    type: I18nActionTypes.ON_CHANGED_LANGUAGE,
    payload: locale,
  };
};
