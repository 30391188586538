// Temporary utils to handle mapping between RTDB and Firestore

// Generic combiners
export const combinerString = (p?: string) => p || '';

export const combinerNum = (p?: number) => p || 0;

export const combinerBool = (p?: boolean) => p || false;

export const combinerMapArray = (p?: string[]) => p || [];

export const combinerMap = (p?: Record<string, any>) => p || {};

export const combinerArray = (p?: any[]) => p || [];
