import produce from 'immer';
import { ITemplate } from '@bridebook/models/source/models/Suppliers/Templates.types';
import { Action, IReducersImmer } from '../types';
import { ITemplateListSaved, MessageTemplatesAction } from './action-types';

interface MessageTemplatesForm {
  state: 'idle' | 'pending';
}

export interface IMessageTemplatesState {
  form: MessageTemplatesForm;
  templates?: ITemplate[];
}

const initialState: IMessageTemplatesState = {
  form: {
    state: 'idle',
  },
};

const reducers: IReducersImmer<IMessageTemplatesState> = (draft) => ({
  [MessageTemplatesAction.SaveTemplate]: () => {
    draft.form.state = 'pending';
  },
  [MessageTemplatesAction.TemplateSaved]: () => {
    draft.form.state = 'idle';
  },
  [MessageTemplatesAction.SaveTemplateError]: () => {
    draft.form.state = 'idle';
  },
  [MessageTemplatesAction.TemplateListSaved]: ({ payload }: ITemplateListSaved) => {
    draft.templates = payload;
  },
  [MessageTemplatesAction.DeleteTemplate]: () => {
    draft.form.state = 'pending';
  },
  [MessageTemplatesAction.TemplateDeleted]: () => {
    draft.form.state = 'idle';
  },
});

const reducer = (state = initialState, action: Action) => {
  try {
    return produce(state, (draft) => reducers(draft)[action.type](action));
  } catch (e) {
    return state;
  }
};

export default reducer;
