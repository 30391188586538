import { ofType } from 'redux-observable';
import { filter, map, pluck, switchMap, takeUntil } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { AuthActionTypes } from 'lib/auth/action-types';
import { IEpic } from 'lib/types';
import { mapCleanTimestamps } from 'lib/utils';
import { IInitQuestionsListenerAction, QuestionsActionTypes } from './action-types';
import { onFirestoreQuestions } from './actions';

export const initQuestionsListener: IEpic<IInitQuestionsListenerAction> = (action$) =>
  action$.pipe(
    ofType(QuestionsActionTypes.INIT_QUESTIONS_LISTENER),
    pluck('payload'),
    switchMap((supplierId) =>
      Suppliers._.getById(supplierId)
        .Questions.query()
        .observe()
        .pipe(
          filter(hasData),
          map((data) => onFirestoreQuestions(mapCleanTimestamps(data))),
          takeUntil(action$.pipe(ofType(AuthActionTypes.SIGN_OUT_SUCCESS))),
        ),
    ),
  );

// hasData typeguard
const hasData = <T>(data: T | null): data is T => data !== null;
