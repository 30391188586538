import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { appError } from 'lib/app/actions';
import { FetchMonoqueryAction, StatisticsActionTypes } from 'lib/statistics/action-types';
import { fetchMonoquerySuccess } from 'lib/statistics/actions/fetch';
import { fetchMonoquery } from 'lib/statistics/utils';
import { IEpicDeps } from 'lib/types';

export const fetchMonoqueryEpic = (
  action$: Observable<FetchMonoqueryAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(StatisticsActionTypes.FETCH_MONOQUERY),
    withLatestFrom(state$),
    mergeMap(([_, state]) => {
      const getPromise = async () => {
        const supplierId = state.supplier.supplier?.id;

        return fetchMonoquery(supplierId);
      };

      return from(getPromise()).pipe(
        map((data) => fetchMonoquerySuccess(data)),
        catchError((error: Error) => [
          { type: StatisticsActionTypes.FETCH_MONOQUERY_ERROR },
          appError({ error, feature: 'fetchMonoqueryEpic' }),
        ]),
      );
    }),
    catchError((error) => of(appError({ error, feature: 'fetchMonoqueryEpic' }))),
  );
