import { MenuType, SnackbarStateType } from './types';

export const defaultMenuNumbers: MenuType = {
  totalPhotos: 0,
  totalVideos: 0,
  totalRecommendations: 0,
};

export const defaultSnackbarState: SnackbarStateType = {
  show: false,
  mode: '',
  text: '',
  uuid: '',
};
