import { User as FirebaseUser } from 'firebase/auth';
import { SupplierUsers } from '@bridebook/models';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { serializeTimestamps } from '@bridebook/toolbox/src/serialize-timestamps';
import { IUserSerialized } from 'lib/users/types';
import { cleanTimestamps } from 'lib/utils';

const createUserFirebase = (
  firebaseUser: FirebaseUser | null,
  supplier?: ISupplier | null,
): IUserSerialized | null => {
  if (!firebaseUser || !firebaseUser.providerData) return null;
  const providerData = firebaseUser.providerData && firebaseUser.providerData[0];

  const name =
    providerData && typeof providerData.displayName === 'string'
      ? providerData.displayName.split(' ')[0]
      : undefined;

  const newUser = SupplierUsers.new('_', {
    id: firebaseUser.uid,
    name: name ? [name] : undefined,
    provider: (providerData && providerData.providerId) || 'password',
    contacts: {
      email: (providerData && providerData.email) || '',
    },
    ...(supplier && {
      l10n: {
        locale: gazetteer.getMarketByCountry(supplier.l10n.country || CountryCodes.GB).locale,
        currency: supplier.l10n.currency,
      },
    }),
  });

  /**
   * @FIXME:
   * Type mismatch. We are cleaning only 'createdAt' and 'updatedAt' fields, but IUserSerialized
   * has more Timestamp fields (lastSeenAt, registeredAt, etc.), that are not being cleaned. Futhermore,
   * the returned IUserSerialized type is misleading, because it consists of fields which are actually
   * stripped off by cleanTimestamps function.
   */

  return cleanTimestamps(serializeTimestamps(newUser));
};

export default createUserFirebase;
