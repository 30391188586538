import { Reducer, ReducersMapObject, combineReducers } from 'redux';
import { abTestingReducer } from '@bridebook/toolbox/src/ab-testing';
import { AuthActionTypes } from 'lib/auth/action-types';
import { Action, IApplicationState } from 'lib/types';
import achievements from './achievements/reducer';
import admin from './admin/reducer';
import app from './app/reducer';
import apps from './apps/reducer';
import auth from './auth/reducer';
import badDebt from './bad-debt/slice';
import badges from './badges/slice';
import contentTranslations from './content-translations/slice';
import couplesActivity from './couples-activity/reducer';
import couples from './couples/reducer';
import endorsements from './endorsements/reducer';
import enquiries from './enquiries/reducer';
import feedback from './feedback/reducer';
import followUpMessages from './follow-up-messages/reducer';
import getListed from './get-listed/reducer';
import groupConnections from './groups/reducer';
import i18n from './i18n/reducer';
import messageTemplates from './message-templates/reducer';
import offers from './offers/reducer';
import payPerEnquiry from './pay-per-enquiry/reducer';
import photos from './photos/reducer';
import premium from './premium/reducer';
import pricing from './pricing/reducer';
import questions from './questions/reducer';
import realWeddings from './real-weddings/reducer';
import recommendations from './recommendations/reducer';
import relations from './relations/reducer';
import roi from './roi/slice';
import search from './search/reducer';
import settings from './settings/reducer';
import sidebar from './sidebar/slice';
import statistics from './statistics/reducer';
import supplier from './supplier/reducer';
import ui from './ui/reducer';
import users from './users/reducer';
import videos from './videos/reducer';

const combineReducersAlias = (reducers: ReducersMapObject): Reducer<IApplicationState> =>
  combineReducers<IApplicationState>(reducers);

const resetStateOnSignOut =
  (reducer: Reducer<IApplicationState>, initialState: IApplicationState) =>
  (state: Partial<IApplicationState>, action: Action): IApplicationState => {
    const userWasSignedOut = action.type === AuthActionTypes.SIGN_OUT_SUCCESS;
    if (userWasSignedOut) {
      state = {
        app: initialState.app,
        ui: state.ui || undefined,
        i18n: state.i18n,
      };
    }
    return reducer(state as IApplicationState, action);
  };

const configureReducer = (initialState: IApplicationState): Reducer<IApplicationState> => {
  let reducer: Reducer<IApplicationState> = combineReducersAlias({
    abTesting: abTestingReducer,
    achievements,
    app,
    apps,
    auth,
    badges,
    endorsements,
    enquiries,
    feedback,
    getListed,
    offers,
    photos,
    premium,
    pricing,
    questions,
    recommendations,
    relations,
    search,
    settings,
    statistics,
    supplier,
    ui,
    users,
    videos,
    admin,
    couples,
    i18n,
    payPerEnquiry,
    realWeddings,
    couplesActivity,
    messageTemplates,
    followUpMessages,
    groupConnections,
    roi,
    badDebt,
    contentTranslations,
    sidebar,
  });

  reducer = resetStateOnSignOut(reducer, initialState);
  return reducer;
};

export default configureReducer;
