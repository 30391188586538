import { appError } from 'lib/app/actions';
import { AuthActionTypes } from 'lib/auth/action-types';
import getFirebaseMessages from 'lib/auth/firebase-messages';
import { AuthBridebookError, IAuthFields } from 'lib/auth/types';
import mapFBErrorToValidationError from 'lib/auth/utils/map-fb-error-to-validation-error';
import { IDeps, IIntlMessageDescriptor } from 'lib/types';

export const signUp = (fields: IAuthFields) => ({
  type: AuthActionTypes.SIGN_UP,
  payload: { fields },
});

export const signUpError =
  (fields: IAuthFields, error: AuthBridebookError) =>
  ({ dispatch, getState }: IDeps) => {
    const oneOfFirebaseErrors: IIntlMessageDescriptor = getFirebaseMessages()[error.code];

    dispatch({
      type: 'REGISTRATION_FAILED_ANALYTICS',
      payload: { error, fields },
    });

    if (oneOfFirebaseErrors) {
      error = mapFBErrorToValidationError(error.code);
    }
    dispatch(appError({ error, feature: 'Auth' }));

    const { collaboratorInvite } = getState().auth;
    if (collaboratorInvite?.invite?.id) {
      dispatch({
        type: 'FAILED_TO_CLAIM_SUPPLIER_PROFILE',
        payload: {
          supplierId: collaboratorInvite?.invite?.id,
          error,
        },
      });
    }

    return {
      type: AuthActionTypes.SIGN_UP_ERROR,
      payload: { error },
    };
  };
